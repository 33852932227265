import React from 'react';
import Notification from './Notification';

export const SuccessNotification: React.FC<{id: string, message: Object | Object[]; onClose?: (id: string) => void}> = ({
    id,
    message,
    onClose
}) => {
    return (
        <Notification
            id={id}
            message={message}
            containerClass="text-success-700 bg-success-100 border border-success-700"
            iconClass="fill-success-700"
            iconSrc="/icons/check-green.svg"
            onClose={onClose}
        />
    );
};

export default SuccessNotification;