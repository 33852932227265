import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment/moment';

import API from 'services/API.jsx';
import ExternalOutcomeReportsRow from '../components/table/ExternalOutcomeReportsRow';
import Input from 'input/Input.jsx';
import Page from '../components/Page';
import { renderLoader } from '../utility/LoaderUtils';

const ExternalReports = () => {
    const [errors, setErrors] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filteredPatients, setFilteredPatients] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [outcomeReports, setOutcomeReports] = useState(null);
    const [patients, setPatients] = useState(null);
    const [shouldReceiveApprovedReports, setShouldReceiveApprovedReports] = useState(false);
    const [user, setUser] = useState(null);
    const tableRef = useRef(null);

    /**---==== KarunaCLOUD ====---**/

    const api_GetUser = async () => {
        await API.get(`/user/`).then((response) => {
            if (!response) {
                throw new Error('No response from server');
            }
            let user = response.data;
            setUser(user);
            setShouldReceiveApprovedReports(
                user.should_receive_approved_outcome_reports
            );
        });
    };

    const api_UpdateUser = async (receiveApprovedReports) => {
        await API.patch(
                     `/user/`,
                     JSON.stringify({
                         first_name: user.first_name,
                         last_name: user.last_name,
                         should_receive_approved_outcome_reports: receiveApprovedReports
                     })
                 )
                 .then((response) => {
                     let user = response.data;
                     setShouldReceiveApprovedReports(
                         user.should_receive_approved_outcome_reports
                     );
                     localStorage.setItem('success', 'Updated Email Report Preference');
                 })
                 .catch((error) => {
                     setErrors(error);
                 });
    };

    const api_GetPatients = async () => {
        await API.get(`/patients/`).then((response) => {
            if (!response) {
                throw new Error('No response from server');
            }

            setPatients(response.data.results);
        });
    };

    const api_GetOutcomeReports = async () => {
        await API.get(`/outcome_reports/`)
                 .then(
                     (response) => {
                         if (!response) {
                             throw new Error('No response from server');
                         }

                         let reports = response.data.results;
                         if (reports) {
                             reports.sort((a, b) => {
                                 const dateA = moment(a.prepared_date);
                                 const dateB = moment(b.prepared_date);
                                 return dateA !== dateB
                                     ? dateB - dateA
                                     : b.plan_week - a.plan_week;
                             });
                         }
                         setOutcomeReports(reports ? reports : []);
                     });
    };

    /** Utilities **/

    const dispatchUpdateUserEmailPreferences = (event) => {
        event.preventDefault();
        let receiveApprovedReports = event.target.checked;
        void api_UpdateUser(receiveApprovedReports);
    };

    const isAllRequiredDataAvailable = () => {
        return user && patients && outcomeReports;
    };

    /** Render **/

    const breadCrumbLinks = () => {
        return [
            {
                label: 'Reports',
                url: '/reportsExternal'
            }
        ];
    };

    useEffect(() => {
        api_GetUser()
            .then((_response) => {
                api_GetPatients().then((_response) => {
                    void api_GetOutcomeReports();
                });
            })
            .catch((error) => {
                setPatients([]);
                setOutcomeReports([]);
                setErrors(error);
            });
    }, []);

    useEffect(() => {
        if (patients) {
            const normalizedFilter = filter ? filter.trim().toLowerCase() : '';
            const filtered = patients.filter((patient) => {
                let patientName = `${patient.first_name} ${patient.last_name}`;
                return filter
                    ? patientName.toLowerCase().includes(normalizedFilter)
                    : true;
            });
            setFilteredPatients(filtered);
        }
    }, [patients, filter]);

    useEffect(() => {
        if (isAllRequiredDataAvailable()) {
            if (isLoading) {
                setIsLoading(false);
            }
        }
    }, [isLoading, user, patients, outcomeReports]);

    /**---==== Render ====---**/

    const renderHeader = () => {
        return (
            <React.Fragment>
                <div className="w-full h-[10%] inline-flex justify-start items-center gap-4 z-40 relative pt-5 pb-[19px]">
                    <div className="w-[50%] h-full flex items-center justify-start gap-5 ml-4">
                        <div className="text-gray-900 text-lg font-medium leading-7">
                            Patient Outcome Reports
                        </div>
                        <div className="w-[100%] h-full flex grow shrink items-center gap-2">
                            <input
                                className="w-3.5 h-3.5 p-0.5 bg-gray-100 rounded border focus:ring-0"
                                type="checkbox"
                                checked={shouldReceiveApprovedReports}
                                onChange={(event) => {
                                    dispatchUpdateUserEmailPreferences(event);
                                }}
                            />
                            <div className="w-[100%] grow shrink whitespace-nowrap text-gray-700 text-xs leading-tight">
                                Receive Emails for Future Reports
                            </div>
                        </div>
                    </div>
                    <div className="grow shrink basis-0 h-5 px-[25px] justify-end items-center gap-2 flex"/>
                    <div className="w-[25%] flex-col justify-start items-start inline-flex">
                        <div className="self-stretch h-full flex items-start justify-start gap-0">
                            <div className="self-stretch pl-3.5 pr-1 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                                <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                                    <img
                                        className="w-6 h-6 relative"
                                        src="/icons/search.svg"
                                        alt="search"
                                    />
                                    <Input
                                        className="w-full h-6  grow shrink overflow-hidden basis-0 font-normal leading-normal text-gray-500 text-base outline-none border-0 focus:outline-none active:border-0 focus:border-0 focus:ring-0"
                                        type="text"
                                        name="Search Names"
                                        onChange={setFilter}
                                        value={filter ? filter : ''}
                                        required
                                        formErrors={errors}
                                        overrideText={'Search Names'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const renderNoReportsFound = () => {
        return (
            <React.Fragment>
                <div className="w-full h-full justify-center items-center inline-flex flex-col">
                    <p className="px-6 text-gray-900 text-lg font-medium ">
                        No Reports Found
                    </p>
                </div>
            </React.Fragment>
        );
    };

    const renderTableRows = () => {
        let tableData = [];

        filteredPatients.forEach((patient, index) => {
            let sortedReports = outcomeReports
                .filter((report) => report.patient_id === patient.id)
                .sort((reportA, reportB) => {
                    const dateA = moment(reportA.prepared_date);
                    const dateB = moment(reportB.prepared_date);
                    return dateA !== dateB
                        ? dateB - dateA
                        : reportB.plan_week - reportA.plan_week;
                });

            tableData.push(
                <ExternalOutcomeReportsRow
                    index={index}
                    patient={patient}
                    patientOutcomeReports={sortedReports}
                    // Callbacks
                    onSetIsDownloading={setIsDownloading}
                />
            );
        });

        return tableData;
    };

    const renderTable = () => {
        return (
            <React.Fragment>
                <div className="grid grid-col w-full whitespace-nowrap overflow-y-auto">
                    <table
                        className="table-auto w-full border-collapse border-x-0"
                        ref={tableRef}
                    >
                        <thead className="whitespace-nowrap	w-full">
                            <tr className="whitespace-nowrap sticky shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] h-[58px] py-3 bg-gray-50 text-gray-500 border-gray-200 border-x-0 border-y-2">
                                <th
                                    scope="col"
                                    className="w-auto px-6 text-left   text-xs font-medium text-gray-500"
                                >
                                    Patient
                                </th>
                                <th
                                    scope="col"
                                    className="w-auto pr-4 text-center text-xs font-medium text-gray-500"
                                >
                                    Reports
                                </th>
                                <th
                                    scope="col"
                                    className="w-auto px-8 text-center text-xs font-medium text-gray-500"
                                ></th>
                                <th
                                    scope="col"
                                    className="w-auto px-4 text-left   text-xs font-medium text-gray-500"
                                >
                                    Physician
                                </th>
                                <th
                                    scope="col"
                                    className="w-auto px-4 text-left   text-xs font-medium text-gray-500"
                                >
                                    TPA
                                </th>
                                <th
                                    scope="col"
                                    className="w-auto px-4 text-left   text-xs font-medium text-gray-500"
                                >
                                    Insurer
                                </th>
                            </tr>
                        </thead>
                        <tbody className="whitespace-nowrap">
                            <React.Fragment>{renderTableRows()}</React.Fragment>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    };

    return (
        <Page
            errors={errors}
            headerObj={{ trail: breadCrumbLinks() }}
            internal={true}
        >
            <div className="w-full h-full flex items-start my-8">
                {isLoading || isDownloading ? (
                    renderLoader()
                ) : (
                    <React.Fragment>
                        <div
                            id="table-margin-left-fixed"
                            className={'w-20 flex-shrink-0'}
                        />
                        <div
                            id="table-container-stretchy"
                            className="w-full flex flex-grow items-start justify-center whitespace-nowrap overflow-hidden"
                        >
                            <div
                                id="table-container"
                                className={
                                    'w-fit flex flex-col overflow-hidden bg-white border-2 border-gray-200 rounded-lg shadow-lg min-w-0'
                                }
                            >
                                {renderHeader()}
                                {filteredPatients && filteredPatients.length > 0
                                    ? renderTable()
                                    : renderNoReportsFound()}
                            </div>
                        </div>
                        <div
                            id="table-margin-right-shrink"
                            className={'w-20 flex-shrink-0'}
                        />
                    </React.Fragment>
                )}
            </div>
        </Page>
    );
};

export default ExternalReports;
