import React, { useCallback, useEffect, useState } from 'react';
import ErrorNotification from './ErrorNotification.tsx';
import SuccessNotification from './SuccessNotification.tsx';

type ManagedNotification = {
    id: string;
    type: 'error' | 'success';
    component: React.ReactElement;
};

const NotificationManager = (
    {
        clear = false,
        errors,
        successes
    }) => {
    
    const [notifications, setNotifications] = useState<ManagedNotification[]>([]);
    
    /** Utilities **/
    
    const getNotificationType = (Component: typeof ErrorNotification | typeof SuccessNotification) => {
        if (Component === ErrorNotification) {
            return 'error';
        }
        else if (Component === SuccessNotification) {
            return 'success';
        }
        else {
            throw new Error(`Notification type not found for component[${Component}]`);
        }
    };
    
    const createNotifications = (messages: Object | Object[], startIndex: number, Component: typeof ErrorNotification | typeof SuccessNotification): ManagedNotification[] => {
        let notificationIndex = startIndex;
        let createdNotifications = [];
        
        if (messages) {
            let messagesArray = Array.isArray(messages) ? messages : [messages];
            messagesArray
                .filter(message => message != null)
                .forEach((message: any[]) => {
                    const type = getNotificationType(Component);
                    const id = `${type}-${notificationIndex}`;
                    createdNotifications.push(
                        {
                            id,
                            type,
                            component: (
                                <Component
                                    id={id}
                                    key={id}
                                    message={message}
                                    onClose={() => removeNotificationEventHandler(id)}
                                />
                            )
                        }
                    );
                    notificationIndex++;
                });
        }
        return createdNotifications;
    };
    
    const createErrorNotifications = (errors: Object | Object[], startIndex: number) => {
        return createNotifications(errors, startIndex, ErrorNotification);
    };
    
    const createSuccessNotifications = (successes: Object | Object[], startIndex: number) => {
        return createNotifications(successes, startIndex, SuccessNotification);
    };
    
    const removeAllNotificationsByType = (Component: typeof ErrorNotification | typeof SuccessNotification): ManagedNotification[] => {
        const type = getNotificationType(Component);
        const updatedNotifications = notifications.filter(notification => notification.type !== type);
        setNotifications(updatedNotifications);
        return updatedNotifications;
    };
    
    const removeAllErrorNotifications = (): ManagedNotification[] => {
        return removeAllNotificationsByType(ErrorNotification);
    };
    
    const removeAllSuccessNotifications = (): ManagedNotification[] => {
        return removeAllNotificationsByType(SuccessNotification);
    };
    
    /**
     * Handles the removal of a notification from the onClose event handlers of
     * a ManagedNotification. Using useCallback will ensure the event handler
     * has the latest updated state of the notifications dictionary.
     */
    const removeNotificationEventHandler = useCallback((id: string) => {
        setNotifications(currentNotifications =>
            currentNotifications.filter(notification => notification.id !== id)
        );
    }, []);
    
    /** Render **/
    
    useEffect(
        () => {
            // let updatedNotifications: ManagedNotification[];
            //
            // if (clear === true || (errors == null && successes == null)) {
            //     updatedNotifications = [];
            // }
            // else if (errors == null) {
            //     updatedNotifications = removeAllErrorNotifications();
            // }
            // else if (successes == null) {
            //     updatedNotifications = removeAllSuccessNotifications();
            // }
            // else {
            //     updatedNotifications = [...notifications];
            // }
            //
            // if (errors) {
            //     updatedNotifications = [...updatedNotifications, ...createErrorNotifications(errors, Object.keys(updatedNotifications).length)];
            // }
            //
            // if (successes) {
            //     updatedNotifications = [...updatedNotifications, ...createSuccessNotifications(successes, Object.keys(updatedNotifications).length)];
            // }
            //
            // setNotifications(updatedNotifications);
            
            if (errors || successes) {
                let updatedNotifications = [...notifications];
                
                if (errors) {
                    updatedNotifications = [...updatedNotifications, ...createErrorNotifications(errors, Object.keys(updatedNotifications).length)];
                }
                
                if (successes) {
                    updatedNotifications = [...updatedNotifications, ...createSuccessNotifications(successes, Object.keys(updatedNotifications).length)];
                }
                
                setNotifications(updatedNotifications);
            }
            
        }, [errors, successes, clear]
    );
    
    return (
        Object.keys(notifications).length > 0 &&
        <div
            id="notification-manager"
            className={'z-199 fixed top-[12px] right-0 max-w-[100%-24px] m-[12px] flex flex-col overflow-x-hidden justify-end items-end gap-2'}
        >
            {
                notifications.map(({ id, component }) => component)
            }
        </div>
    );
};

export default NotificationManager;