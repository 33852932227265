import { AxiosError } from 'axios';

// ---==============================================---
//     KarunaCLOUD Universal Response Error Handler
// ---==============================================---

export const KarunaCLOUD_responseValidator = (response: any, onErrors: ((errors: any) => void) | null = null) => {
    
    if (!response) {
        onErrors && onErrors(`Unable to process your request. Try refreshing the page. error[No Response from server]`);
        return;
    }
    
    let status = response.status;
    let code = response.code;
    let data = response.data;
    let error = data?.error || null;
    
    if (response instanceof AxiosError) {
        if (status && status >= 400) {
            let responseCodeStr = `(${status}${code ? `-${code}` : ''})`;
            onErrors && onErrors(`Unable to process your request. ${responseCodeStr} ${error ? ` error[${error}]` : ` reason[${data ? data : 'unknown'}]`}`);
        }
        return null;
    }
    
    return data;
};

// ---==============================================---