import React, { useState } from 'react';
import moment from 'moment/moment';

import API from 'services/API.jsx';

import DownloadFolderIcon from 'icons/folder-download.svg';
import DownloadFileIcon from 'icons/file-download.svg';

const ExternalOutcomeReportsRow = ({
    patient,
    patientOutcomeReports = [],
    // Callbacks
    onSetIsDownloading
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    /** Utilities **/

    const toggleIsDownloading = () => {
        let downloading = !isDownloading;
        setIsDownloading(downloading);
        onSetIsDownloading(downloading);
    };

    const handleToggleOpen = (event) => {
        event.preventDefault();
        setIsOpen(!isOpen);
    };

    const handleDownloadReport = async (event, report) => {
        event.preventDefault();

        if (!isDownloading) {
            if (report.id && report.included_weeks && report.prepared_date) {
                toggleIsDownloading();

                API.get(`/outcome_reports/${report.id}/?pdf_data=${true}/`)
                    .then((response) => {
                        if (!response) {
                            throw new Error('No response from server');
                        }
                        if (response.data.report_pdf_data) {
                            localStorage.setItem('pdf', JSON.stringify(response.data));
                            window.open(`/report/${report.id}`, '_blank');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally((_) => {
                        toggleIsDownloading();
                    });
            }
        }
    };

    const handleDownloadAllReports = (event) => {
        event.preventDefault();

        if (!isDownloading) {
            toggleIsDownloading();
            localStorage.clear();
            window.open(`/reports/${patient.id}`, '_blank');
            toggleIsDownloading();
        }
    };

    /** Render **/

    const renderTableRows = () => {
        let tableData = [];

        patientOutcomeReports.forEach((report, _index) => {
            let isPostProgramReport = report.is_post_program_checkin;
            tableData.push(
                <React.Fragment>
                    <tr className="relative whitespace-nowrap h-[58px] text-xs bg-brand-50 border-x-0 border-y-2 border-gray-200">
                        <td/>
                        <td
                            colSpan={3}
                            className="absolute py-2 leading-[36px]"
                        >
                            <button
                                // className={`w-full text-center ${!report.in_progress ? "bg-brand-600 hover:bg-brand-500 shadow-lg shadow-brand-700" : "bg-gray-500"} text-white rounded-lg`}
                                className={`w-full text-center ${!report.in_progress ? 'bg-brand-600 hover:bg-brand-500' : 'bg-gray-400'} text-white rounded-lg`}
                                onClick={
                                    !report.in_progress
                                        ? (event) => {
                                            void handleDownloadReport(
                                                event,
                                                report
                                            );
                                        }
                                        : undefined
                                }
                            >
                                <div className="flex items-center rounded-lg">
                                    <div className="w-[137px] flex justify-center items-center rounded-l-lg">
                                        {!isPostProgramReport
                                            ? `Week ${report.included_weeks}`
                                            : '6 Months Post'}
                                    </div>
                                    <div className="w-[152px] flex justify-center items-center">
                                        {!report.in_progress
                                            ? moment(
                                                report.prepared_date
                                            ).format('MMM D, YYYY')
                                            : '-'}
                                    </div>
                                    <div className="w-[170px] flex justify-start items-center rounded-r-lg">
                                        {
                                            <div className="inline-flex justify-center">
                                                {!report.in_progress ? (
                                                    <div className="flex items-center gap-1 ml-10">
                                                        <DownloadFileIcon/>
                                                        <div>Download</div>
                                                    </div>
                                                ) : (
                                                    <div className="ml-14">
                                                        In Progress
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </button>
                        </td>

                        <td/>
                        <td/>
                        <td/>
                        <td/>
                        <td/>
                    </tr>
                </React.Fragment>
            );
        });

        return tableData;
    };

    return (
        <React.Fragment>
            <tr
                onClick={
                    patientOutcomeReports && patientOutcomeReports.length > 0
                        ? handleToggleOpen
                        : undefined
                }
                className="whitespace-nowrap py-3 text-sm cursor-pointer border-x-0 border-y-2 border-gray-200"
            >
                <td className="w-auto flex-inline px-6 text-left text-gray-900">
                    {patient.profile.payer_type !== 2
                        ? `${patient.first_name} ${patient.last_name}`
                        : `#${patient.id}`}
                </td>
                <td className="pr-4">
                    <div
                        // className={`flex justify-end items-center w-[120px] h-[30px] bg-slate-300  ${patientOutcomeReports && patientOutcomeReports.length > 0 ? "hover:bg-slate-400 shadow-lg shadow-brand-700" : ""} rounded-2xl`}
                        className={`flex justify-end items-center w-[120px] h-[30px] bg-brand-50 rounded-2xl`}
                    >
                        {
                            <div className="text-brand-700">
                                {patientOutcomeReports
                                    ? patientOutcomeReports.length
                                    : 0}{' '}
                                Reports
                            </div>
                        }
                        {
                            <div className="w-[18px] h-[18px] ml-2 mr-2.5">
                                {patientOutcomeReports &&
                                    patientOutcomeReports.length > 0 && (
                                        <img
                                            className="opacity-40"
                                            src={`/icons/chevron-${isOpen ? 'down' : 'right'}.svg`}
                                            alt="chevron"
                                        />
                                    )}
                            </div>
                        }
                    </div>
                </td>
                <td className="w-[152px] h-[58px] px-2">
                    {patientOutcomeReports &&
                        patientOutcomeReports.length > 0 && (
                            <button
                                className="inline-flex whitespace-nowrap gap-2 justify-center items-center h-[32px] px-4 bg-brand-600 hover:bg-brand-500 text-white rounded-lg"
                                onClick={
                                    patientOutcomeReports &&
                                    patientOutcomeReports.length > 0
                                        ? handleDownloadAllReports
                                        : undefined
                                }
                            >
                                <DownloadFolderIcon/>
                                <div className="text-xs leading-tight">
                                    Download All
                                </div>
                            </button>
                        )}
                </td>
                <td className="w-auto px-4 text-left text-gray-500">
                    {patient ? patient.physician_name : '\u2014'}
                </td>
                <td className="w-auto px-4 text-left text-gray-500">
                    {patient
                        ? patient.profile.third_party_administrators
                        : '\u2014'}
                </td>
                <td className="w-auto px-4 text-left text-gray-500">
                    {patient ? patient.profile.insurers : '\u2014'}
                </td>
            </tr>
            {isOpen &&
                patientOutcomeReports &&
                patientOutcomeReports.length > 0 &&
                renderTableRows()}
        </React.Fragment>
    );
};

export default ExternalOutcomeReportsRow;
