import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import cookie from 'react-cookies';

import API from 'services/API.jsx';
import Input from 'input/Input.jsx';
import Page from '../components/Page';
import ProfileInput from 'input/ProfileInput.jsx';
import PropTypes from 'prop-types';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const EditPatient = ({ history }) => {

    const [patientId, setPatientId] = useState(useParams().id);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profile, setProfile] = useState(null);
    const [loadedProfile, setLoadedProfile] = useState(null);
    const [email, setEmail] = useState(null);
    const [clinicianId, setClinicianId] = useState(undefined);
    const [clinicians, setClinicians] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const role = cookie.load('role') ? cookie.load('role') : 'None';

    useEffect(() => {
        if (patientId) {
            getPatientData();
        }

        getClinicians();

    }, []);

    useEffect(() => {
        if (clinicians != null) {
            checkIfClinician();
        }

    }, [clinicians]);

    useEffect(() => {
        console.log('loaded profile set');
        setProfile(loadedProfile);
    }, [loadedProfile]);

    const checkIfClinician = () => {
        if (cookie.load('role').toLowerCase() === 'physician') {
            //have to do this because the clinician's user id is not the
            //same as the clinician id
            let userId = cookie.load('user').pk;
            let clinician = clinicians.find(c => c.id == userId);
            if (clinician) {
                setClinicianId(clinician.role_id);
            }
        }
    };

    const handleSubmit = (event) => {

        event.preventDefault();

        let data = {
            first_name: firstName,
            last_name: lastName,
            profile: profile,
            clinician: clinicianId
        };

        if (email) {
            data.email = email;
        }
        else {
            setErrors('Please provide an email for the Patient.');
            return;
        }

        if (patientId) {
            updateData(JSON.stringify(data));
        }
    };

    const getClinicians = () => {
        let role_type = cookie.load('role').toLowerCase();
        if (role_type !== 'coach' && role_type !== 'facilitator') {

            API.get('/physicians/')
               .then(response => {
                   setClinicians(response.data.results);
               });
        }
    };

    const getPatientData = () => {
        API.get(`/patients/${patientId}`)
           .then(response => {
               setFirstName(response.data.first_name);
               setLastName(response.data.last_name);
               setEmail(response.data.email);
               if (cookie.load('role').toLowerCase() !== 'coach') {
                   setClinicianId(response.data.physician_role_id);
               }
               setLoadedProfile({
                   height: Number(response.data.profile.height),
                   gender: Number(response.data.profile.gender),
                   language: Number(response.data.profile.language),
                   payer_type: Number(response.data.profile.payer_type)
               });
               setLoading(false);

           })
           .catch(error => console.error(error));
    };

    const updateData = (data) => {
        API.put(`/patients/${patientId}/`, data)
           .then((response) => {
               setLoading(false);
               localStorage.setItem('success', `Saved ${firstName} ${lastName}'s Profile`);
               loadDefaultPatientPageForRole(patientId, true);
           })
           .catch(error => {
                   setErrors(error);
               }
           );
    };

    const toPatient = () => {
        loadDefaultPatientPageForRole(patientId);
    };

    const loadDefaultPatientPageForRole = (patientId) => {
        if (patientId) {
            let roleType = role.toLowerCase();
            if (roleType === 'coach') {
                history.push(`/patient/${patientId}/coaching-plan`);
            }
            else if (roleType === 'facilitator') {
                history.push(`/patient/${patientId}/reports`);
            }
            else {
                history.push(`/reportsExternal`);
            }
        }
    };

    const breadcrumbLinks = () => {
        if (patientId) {
            return [
                {
                    label: 'Patients',
                    url: '/'
                },
                {
                    label: firstName !== '' ? `${firstName} ${lastName}` : 'Patient',
                    url: `/patient/${patientId}`
                },
                {
                    label: 'Edit Patient',
                    url: `/patient/${patientId}/edit/`
                }
            ];
        }
        else {
            return [
                {
                    label: 'Patients',
                    url: '/patients'
                },
                {
                    label: 'Create patient',
                    url: '/create-patient'
                }
            ];
        }

    };

    const wrapper = {
        alignItems: 'right',
        justifyContent: 'left',
        display: 'flex',
        verticalAlign: 'middle',
        width: '50px',
        whiteSpace: 'nowrap'

    };

    return (
        <Page errors={errors} headerObj={{ trail: breadcrumbLinks(), body: { subheader: 'Edit Patient', name: firstName + ' ' + lastName, buttons: [{ key: 'back', title: 'back', click: toPatient, img: '/icons/flip-backward.svg' }] } }} internal={true}>
{
    !loading ?
        <form onSubmit={handleSubmit}>

		<div className=" w-full  flex-col  items-start gap-5 m-0 pl-6 pt-6 inline-flex">

    <div className=" w-1/3  flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch h-[540px] flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch justify-start items-start gap-8 inline-flex">
                <div className="grow w-full shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="self-auto w-full h-[70px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-slate-700 text-sm w-full font-medium  leading-tight">First Name</div>
						<Input
                            className="self-auto text-gray-500 text-base w-full  font-normal  px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"
                            type="text"
                            name="First Name"
                            value={firstName ? firstName : ''}
                            onChange={setFirstName}
                            required
                            validations={[{ type: 'minLength', value: 2 }, { type: 'maxLength', value: 30 }]}
                            formErrors={errors}
                        />

                    </div>
                </div>
                <div className="grow shrink w-full basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                    <div className="self-stretch h-[70px] w-full flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-gray-700 text-sm  w-full font-medium  leading-tight">Last Name</div>
					<Input
                        className="self-auto text-gray-500 text-base font-normal  w-full  px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"
                        type="text"
                        name="Last Name"
                        value={lastName}
                        onChange={setLastName}
                        required
                        validations={[{ type: 'minLength', value: 2 }, { type: 'maxLength', value: 30 }]}
                        formErrors={errors}
                    />


                    </div>
                </div>
            </div>
            <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-slate-700 text-sm font-medium  leading-tight">Email</div>
					<Input
                        className="self-stretch  text-gray-500 text-base font-normal px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex"
                        type="text"
                        name="Email"
                        value={email}
                        onChange={setEmail}
                        required
                        validations={[{ type: 'email' }]}
                        formErrors={errors}
                    />

                </div>
            </div>
			<ProfileInput setProfile={setProfile} loadedProfile={loadedProfile} formErrors={errors}/>

        </div>
        <div className="self-stretch h-12 flex-col justify-start items-start gap-4 flex">
            <button className="self-stretch px-[18px] py-3 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 inline-flex">
                <div className="px-0.5 justify-center items-center flex">
                    <div className="text-white text-base font-semibold  leading-normal">Save Patient Info</div>
                </div>
            </button>
        </div>
    </div>
		</div>
		</form>
        :
        renderLoader()
}

		</Page>

    );

};

EditPatient.propTypes = {
    patientHeight: PropTypes.number,
    inches: PropTypes.number,
    feet: PropTypes.number
};

export default withRouter(EditPatient);
