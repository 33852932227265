export const OutcomeMeasureTypes = Object.freeze({
    FABQActivity: 'FABQActivity',
    FABQTotal: 'FABQTotal',
    FABQWork: 'FABQWork',
    PCS: 'PCS',
    PSEQ: 'PSEQ',
    PHQ9: 'PHQ9',
    GAD7: 'GAD7',
    Oswestry: 'Oswestry',
    DASH: 'DASH',
    NDI: 'NDI',
    WHODAS: 'WHODAS',
    UEFI: 'UEFI',
    Pain: 'Pain',
    PCSS: 'PCSS',
    Activity: 'Activity',
    Confidence: 'Confidence',
    Sleep: 'Sleep',
    Medication: 'Medication',
    Work: 'Work',
    Mood: 'Mood',
    KarunaRating: 'KarunaRating',
    LeftFlexion: 'leftFlexion',
    RightFlexion: 'RightFlexion',
    LeftScaption: 'leftScaption',
    RightScaption: 'RightScaption',
    LeftAbduction: 'leftAbduction',
    RightAbduction: 'RightAbduction',
    LowerFlexion: 'LowerFlexion'
});

export const outcomeMeasureEnum = (stringType) => {
    for (const [key, value] of Object.entries(OutcomeMeasureTypes)) {
        if (stringType === key || stringType === value) {
            return OutcomeMeasureTypes[key];
        }
    }
    return 'other';
};

export const payerEnums = Object.freeze({
    KarunaHome: 0,
    Reclaim: 1,
    VA: 2
});

export const NotificationTypes = {
    NewSymptoms: 'NewSymptoms',
    PsychotherapyReferral: 'PsychotherapyReferral',
    MedicationReEvaluation: 'MedicationReEvaluation',
    NewInjury: 'NewInjury'
};

export const notifications = [
    { id: 0, name: 'Psychotherapy Referral', value: NotificationTypes.PsychotherapyReferral },
    { id: 1, name: 'Medication Re-Evaluation', value: NotificationTypes.MedicationReEvaluation },
    { id: 2, name: 'New Injury', value: NotificationTypes.NewInjury },
    { id: 3, name: 'New Symptoms', value: NotificationTypes.NewSymptoms }
];

export const templateWeekOptions = [
    { id: 1, name: 'Week 1', value: '1' },
    { id: 2, name: 'Week 2', value: '2' },
    { id: 3, name: 'Week 3', value: '3' },
    { id: 4, name: 'Week 4', value: '4' },
    { id: 5, name: 'Week 5', value: '5' },
    { id: 6, name: 'Week 6', value: '6' },
    { id: 7, name: 'Week 7', value: '7' },
    { id: 8, name: 'Week 8', value: '8' },
    { id: 9, name: 'Week 9', value: '9' },
    { id: 10, name: 'Week 10', value: '10' },
    { id: 11, name: 'Week 11', value: '11' },
    { id: 12, name: 'Week 12', value: '12' }
];