import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import moment from 'moment-timezone';
import { getTimezone } from 'utility/UserUtility.jsx';
import { TailSpin } from 'react-loader-spinner';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const Downloader = ({ history }) => {
    const timezone = getTimezone();
    const key = useParams().key;
    const [errors, setErrors] = useState(null);
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        if (key && !downloaded) {
            let result = localStorage.getItem('pdf');
            if (result === null || result === undefined) {
                // window.close();
            }
            localStorage.removeItem('pdf');

            const data = JSON.parse(result);
            if (key !== data.id) {
                //window.close();

            }

            download(data);
        }
    }, []);

    const DateDisplay = (day) => {
        let d = moment(day).tz(timezone);
        return d.format('MM-DD-YYYY');

    };

    const download = (data) => {
        console.log(data);
        let patientName = (data.payer_type === undefined || (data.payer_type !== undefined && data.payer_type !== 2)) ? data.patient_name.split(' ').join('_') : '#' + data.patient_id;
        let name = patientName + (data.is_post_program_checkin !== undefined && data.is_post_program_checkin === true ? '_6_Months_Post_' : '_Week_' + data.included_weeks + '_') + DateDisplay(data.prepared_date) + '.pdf';

        let pdf = data.report_pdf_data;
        let a = document.createElement('a');
        a.download = name;
        a.href = pdf;
        a.click();
        setDownloaded(true);
    };

    return (
        <Page internal={false}>
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            {
                !downloaded ?
                    (
                        renderLoader()
                    )
                    :
                    (
                        <React.Fragment>
                            <h3 className=" text-lg">Download Complete</h3>
                            <h4 className="">You can close this tab.</h4>
                        </React.Fragment>
                    )
            }
            </div>
        </Page>
    );

};

export default withRouter(Downloader);
