import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import API from 'services/API.jsx';

const SideMenu = ({onLogout, role, name}) => {
    const [issues, setIssues] = useState(0);
    const [pendingReports, setPendingReports] = useState(0);
    const [physicianAccess, setPhysicianAccess] = useState(false);
    const [isReviewer, setIsReviewer] = useState(false);
    const isAdmin = role.toLowerCase().includes("admin");
    const isPhysician = role.toLowerCase() === "physician";

    const GetDashBoardLink = () => {
        let version = window.location.origin;

        if (version.includes("dev")) {
            return "https://api.dev.karunalabs.com/admin/login/";
        }
        else if (version.includes("qa")) {
            return "https://api.qa.karunalabs.com/admin/login/";
        }
        else if (version.includes("staging")) {
            return "https://api.staging.karunalabs.com/admin/login/";
        }
        else if (version.includes("localhost")) {
            return "https://api.dev.karunalabs.com/admin/login/";
        }
        else {
            return "https://api.karunalabs.com/admin/login/";
        }
    };

    const FixName = (n) => {
        return n.slice(0, 20).slice(0, -3) + '...';
    };

    useEffect(() => {
        if (!isAdmin) {
            API.get('/patient_alerts/').then(response => {
                let data = response.data;
                if (data) {
                    setIsReviewer(data.can_review_outcome_reports ? data.can_review_outcome_reports : false);
                    setIssues(data.accounts_requiring_attentio ? data.accounts_requiring_attention : 0);
                    setPendingReports(data.pending_outcome_reports ? data.pending_outcome_reports : 0);
                    setPhysicianAccess(data.can_view_patient_usage_data ? data.can_view_patient_usage_data : false);
                }
            });
        }
    }, []);

    const logout = (e) => {
        // e.preventDefault();
        onLogout();
    };

    return (
        <div id="side-menu" className="z-100 w-[196px] h-full flex-shrink-0 bg-white border border-r border-gray-200">
            <div className="flex-col w-[196px] h-[216px] gap-6 items-start justify-start pt-8 inline-flex">
                <div className="self-stretch h-8 pl-6 pr-5 flex-col justify-start items-start flex">
                    <div className="justify-start items-start inline-flex">
                        <div className="w-full h-8 justify-center items-center flex">
                            <img className="w-full h-[142px]" src="/icons/karuna_logo_full_blue.png"/>
                        </div>
                    </div>
                </div>
                <div className="self-stretch h-32 px-4 flex-col justify-start items-start  flex">
                    {
                        (!isAdmin && (!isPhysician || (isPhysician && physicianAccess))) &&
                        <div className="self-stretch justify-start items-start inline-flex">
                            <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                                <Link className="*:hover:underline" to="/">
                                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                                        <img className="w-6 h-6 relative" src="/icons/users.svg"/>
                                        <div className="text-gray-700 text-base font-semibold  leading-normal">Patients</div>
                                    </div>
                                </Link>
                                {
                                    issues > 0 &&
                                    <div className="px-2 py-0.5 bg-amber-50 rounded-full border border-amber-200 justify-start items-center flex">
                                        <div className="text-center text-amber-700 text-xs font-medium  leading-[18px]">{issues}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        (!isAdmin && !isPhysician) &&
                        <div className="self-stretch h-10 justify-start items-start inline-flex">
                            <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                                <Link className="*:hover:underline" target="_blank" to={{pathname: GetDashBoardLink()}}>
                                    <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                                        <img className="w-6 h-6 relative" src="/icons/bar-chart-square.svg"/>
                                        <div className="text-gray-700 text-base font-semibold  leading-normal">Analytics</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    }
                    {
                        (isReviewer) &&
                        <div className="self-stretch justify-start items-start inline-flex">
                                <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                                    <Link className="*:hover:underline" to="/reports">
                                        <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                                            <img className="w-6 h-6 relative" src="/icons/file.svg"/>
                                            <div className="text-gray-700 text-base font-semibold leading-normal">Reports</div>
                                        </div>
                                    </Link>
                                    {
                                        pendingReports > 0 &&
                                        <div className="px-2 py-0.5 bg-brand-50 rounded-full border border-brand-200 justify-start items-center flex">
                                                <div className="text-center text-brand-800 text-xs font-medium  leading-[18px]">{pendingReports}</div>
                                            </div>
                                    }
                                </div>
                            </div>
                    }
                    {
                        (isAdmin || isPhysician) &&
                        <div className="self-stretch justify-start items-start inline-flex">
                                <div className="grow shrink basis-0 self-stretch px-3 py-2 bg-white rounded-md justify-start items-center gap-2 flex">
                                    <Link className="*:hover:underline" to="/reportsExternal">
                                        <div className="grow shrink basis-0 h-6 justify-start items-center gap-3 flex">
                                            <img className="w-6 h-6 relative" src="/icons/file.svg"/>
                                            <div className="text-gray-700 text-base font-semibold  leading-normal">Reports</div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className="self-stretch w-[196px] pl-1 pr-0 pb-8 flex-col justify-center items-center gap-4 flex absolute bottom-0">
                <button className="*:hover:underline" onClick={logout}>
                    <div className="w-full pr-3 pt-4 border-t border-gray-200 justify-center items-start gap-4 inline-flex">
                        <div className="h-10 justify-start items-center gap-[5px] flex">
                            <div className="w-full px-2 py-2 bg-white rounded-md justify-center items-center gap-2 inline-flex">
                                <div className="grow basis-0 h-6 justify-start items-center gap-3 flex">
                                    <img className="w-6 h-6 relative" src="/icons/log-out.svg"/>
                                    <div className="text-gray-700  text-base font-semibold  leading-normal">Log Out</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </button>
                <Link className="*:hover:underline" to="/user">
                    <div className="w-full pt-4 border-t border-gray-200 justify-start items-start gap-4 inline-flex">
                        <div className="grow basis-0 h-10 justify-start items-center gap-[5px] flex overflow-x-hidden">
                            <img className="w-10 h-10 relative" src="/icons/user.svg"/>
                            <div className="flex-col justify-start items-start inline-flex">
                                <div className="text-gray-700 text-sm font-semibold whitespace-nowrap leading-tight"><p className="text-ellipsis ">{name.length <= 20 ? name : FixName(name)}</p></div>
                                <div className="text-gray-600 text-sm font-normal leading-tight">{role}</div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default SideMenu;
