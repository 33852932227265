import React, { useState, Fragment, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames (...classes) {
    return classes.filter(Boolean).join(' ');
}

const CustomDropDown = ({
    badgeColors = null,
    badgePercent = true,
    badgeText = null,
    borderColor = '',
    customBadgePadding = '',
    customwidth = -1,
    defaultValue = null,
    fullwidth = false,
    jumpBackToDefault = false,
    locked = false,
    maxH = null,
    nameLimit = 0,
    onchange,
    options = ([]),
    posBottom = true,
    right = true,
    textcolor = ' text-gray-700 font-semibold ',
    truncate = false
}) => {
    
    const [select, setSelect] = useState(options[0]);
    const [changed, setChanged] = useState(false);
    const [init, setInit] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [JumpSelect, setJumpSelect] = useState(options[0]);
    
    useEffect(() => {
        if (!init && select && onchange && options) {
            onchange(select.value);
            if (jumpBackToDefault) {
                setSelect(null);
            }
        }
        
        if (init) {
            setInit(false);
        }
        
    }, [select]);
    
    useEffect(() => {
        
        if ((options && defaultValue !== null && changed === false) || refresh) {
            let def = {};
            options.forEach(element => {
                if (defaultValue === element.value) {
                    def = element;
                }
            });
            
            if (def !== options[0]) {
                setSelect(def);
                setJumpSelect(def);
            }
            else {
                setSelect(def);
            }
        }
        
    }, [defaultValue, refresh, options]);
    
    return (
        <React.Fragment> {(options !== null && options.length > 0 ? <Listbox value={!jumpBackToDefault ? select : JumpSelect} onChange={setSelect}>
                {({ open }) => (
                    
                    <span className="w-full">
          <div className={'relative  ' + ('w-full')}>
            <Listbox.Button
                className={'relative ' + ('w-full') + ' cursor-default  border ' + (borderColor === '' ? 'border-gray-300' : borderColor) + ' justify-start items-center gap-2 inline-flex   py-2.5  pl-3 pr-10 text-left ' + (locked ? 'text-gray-400' : textcolor) +
                    ' text-sm  shadow ring-0 ring-inset ring-gray-300 focus:outline-none focus:ring-2  basis-0 self-stretch rounded-lg  ' + (locked ? 'bg-gray-50 ' : 'bg-white ') + (locked ? 'focus:ring-gray-300' : 'focus:ring-brand-500')}>
              <span className="flex font-semibold	 items-center">
               {(!jumpBackToDefault ? select.name !== undefined : JumpSelect.name !== undefined) &&
                   <span className=" block truncate">{!jumpBackToDefault ? (nameLimit === 0 ? select.name : (select.name.length <= nameLimit ? select.name : select.name.slice(0, nameLimit) + '...')) : (nameLimit === 0 ? JumpSelect.name : (JumpSelect.length <= nameLimit
                       ? JumpSelect.name
                       : JumpSelect.name.slice(0, nameLimit) + '...'))}</span>}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-2 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
            </Listbox.Button>

            <Transition
                show={open && !locked}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="ring-black/10"
                leaveTo="ring-black/0"
            
            >
              <Listbox.Options
                  className={'absolute  ' + (right ? 'right-0' : 'left-0') + '	' + (fullwidth ? 'w-full' : (customwidth > 0 ? 'w-' + customwidth + ' ' : '')) + '  z-10 ' + (posBottom ? 'mt-1' : 'bottom-0 mb-10') +
                      '   overflow-y-auto overflow-x-hidden	  rounded-md bg-white  text-base shadow-lg ring-1 ring-black/10 focus:outline-none sm:text-sm ' + (maxH !== null ? maxH : 'max-h-56')}>
                {options.map((element) => (
                    <Listbox.Option
                        key={element.id}
                        className={({ active }) =>
                            classNames(
                                active ? 'bg-brand-500 text-white' : ((!jumpBackToDefault ? select.name === element.name : JumpSelect.name === element.name) ? 'text-brand-700' : textcolor),
                                'relative cursor-default select-none py-2 pl-3 pr-9 w-full  whitespace-nowrap '
                            )
                        }
                        value={element}
                    >
                        {({ selected, active }) => (
                            <span className="w-full">


                        <div className="inline-flex items-center whitespace-nowrap	gap-x-2">
                         {element.img !== undefined && <img src={element.img} alt="" className="h-5 w-5 flex-shrink-0 rounded-full"/>}
                            {
                                
                                element.name !== undefined && <span
                                    className={classNames((selected || (!jumpBackToDefault ? select.name === element.name : JumpSelect.name === element.name)) ? 'font-semibold ' : 'font-normal', 'ml-3  ', (customBadgePadding !== '' ? customBadgePadding : 'mr-10 '))}
                                >
                            {element.name}
                          </span>
                            }
                            
                            {
                                
                                element.badge !== undefined && <span className="absolute right-0 mr-2 ml-2">{
                                    (<div className={'px-2 py-0.5  rounded-full gap-x-1  justify-start items-center inline-flex ' + (badgeColors[element.badge.color])}>
                                        {element.badge.img !== undefined && <img className="w-3 h-3 relative" src={element.badge.img} alt="badge"/>}
                                        
                                        <div className={'text-center  text-xs font-medium  leading-[18px] ' + badgeText[element.badge.color]}>{badgePercent ? Math.round(element.badge.value * 100) + '%' : element.badge.value}</div>
                                    </div>)
                                }</span>
                            }
                            {(selected || (!jumpBackToDefault ? select.name === element.name : JumpSelect.name === element.name)) ? (
                                <span
                                    className={classNames(
                                        active ? 'text-white' : 'text-blue-600',
                                        'inset-y-0  flex items-center '
                                    )}
                                >
                            {/*<CheckIcon className="h-5 w-5" aria-hidden="true" />*/}
                          </span>
                            ) : null}
                        </div>


                      </span>
                        )}
                    </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </span>
                )}
            </Listbox> :
            <Listbox value={!jumpBackToDefault ? select : JumpSelect} onChange={setSelect}>
                {() => (
                    <span className=" w-full">
      <div className="relative w-full ">
      <Listbox.Button
          className={'relative w-full cursor-default  border ' + (borderColor === '' ? 'border-gray-300' : borderColor) + ' justify-start items-center gap-2 inline-flex  bg-white py-2.5  pl-3 pr-10 text-left ' + textcolor +
              ' text-sm  shadow ring-0 ring-inset ring-gray-300/5 focus:outline-none focus:ring-2 focus:ring-brand-500 basis-0 self-stretch rounded-lg '}>
      <span className="flex font-semibold	 items-center">
       <span className=" block truncate">No Options Found</span>
      </span>

    </Listbox.Button>


      </div>
    </span>
                )}
            </Listbox>)}
        </React.Fragment>
    );
};

export default CustomDropDown;
