import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';

import API from 'services/API.jsx';
import ChangePassword from 'pages/ChangePassword.jsx';
import DownloadZip from 'pages/DownloadZip.jsx';
import Downloader from 'pages/Downloader.jsx';
import EditCoachingPlan from 'pages/EditCoachingPlan.js';
import EditPatient from 'pages/EditPatient.jsx';
import EditUser from 'pages/EditUser.jsx';
import ExternalReports from './pages/ExternalReports';
import Gallery from 'pages/Gallery.jsx';
import Login from 'pages/Login.jsx';
import Maintenance from 'pages/Maintenance.jsx';
import PageNotFound from './pages/PageNotFound';
import PatientActivityPage from 'pages/PatientActivityPage.js';
import PatientProfileRedirect from './pages/PatientProfileRedirect';
import PatientReviewReportPage from './pages/PatientReviewReportPage';
import ReportGeneration from 'pages/ReportGeneration.jsx';
import ReportReviewPage from './pages/ReportReviewPage';
import ReportVerification from 'pages/ReportVerification.jsx';
import ResetPassword from 'pages/ResetPassword.jsx';
import SessionPage from './pages/SessionPage';
import SetPassword from 'pages/SetPassword.jsx';
import UserSettings from './pages/UserSettings';
import ValidateEmail from 'pages/ValidateEmail.jsx';
import { api_Diagnostics } from 'services/AccountServices.jsx';

const App = () => {
    const [loggedIn, setLoggedIn] = useState(cookie.load('token'));

    // Replace console.error to capture errors
    const originalConsoleError = console.error;
    console.error = (...args) => {
        originalConsoleError(...args);
        void api_Diagnostics(JSON.stringify(args));
    };

    const ProtectedRoute = ({ component: Component, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    (loggedIn ?
                            <Component {...props} /> :
                            <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
                    )}
            />
        );
    };

    API.interceptors.request.clear();
    API.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            if (!error || !error.config || !error.config.url || !error.config.url.includes('diagnostics')) {
                void api_Diagnostics(error);
            }
        }
    );

    API.interceptors.response.clear();
    API.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            if (!error || !error.config || !error.config.url || !error.config.url.includes('diagnostics')) {
                void api_Diagnostics(error);
            }

            if (error.response && error.response.status) {
                if (error.response.status === 503) {
                    history.push('/maintenance');
                }
            }

            return error;
        }
    );

    useEffect(() => {
        console.log('Token: ' + cookie.load('token'));
        if (!loggedIn && cookie.load('token')) {
            console.log('setting logged in cause token is ' + cookie.load('token'));
            setLoggedIn(true);
        }
        else if (loggedIn && !cookie.load('token')) {
            console.log('setting logged out');
            setLoggedIn(false);
        }
        else if (!loggedIn && !cookie.load('token')) {
            console.log('logged out');
            setLoggedIn(false);
        }

    }, [loggedIn]);

    return (
        <BrowserRouter forceRefresh={true}>
			<Switch>
                {/* Public Routes */}
                <Route exact path="/login" component={Login}/>
				<Route exact path="/maintenance" component={Maintenance}/>
                <Route exact path="/account-confirm-email/:key/" component={ValidateEmail}/>
                <Route exact path="/password/reset" component={ResetPassword}/>
                <Route exact path="/password/reset/confirm/:uid/:token" component={SetPassword}/>
                <Route exact path="/report/:key/" component={Downloader}/>
                <Route exact path="/reports/:key/" component={DownloadZip}/>
                {/* Protected Routes */}
                <ProtectedRoute exact path="/reports" component={ReportReviewPage}/>
				<ProtectedRoute exact path="/reportsExternal" component={ExternalReports}/>
				<ProtectedRoute exact path="/create-patient" component={EditPatient}/>
				<ProtectedRoute exact path="/outcomes/:outcomeId" component={ReportVerification}/>
				<ProtectedRoute exact path="/password/change/" component={ChangePassword}/>
				<ProtectedRoute exact path="/patient/:id" component={PatientProfileRedirect}/>
				<ProtectedRoute exact path="/patient/:id/activity" component={PatientActivityPage}/>
				<ProtectedRoute exact path="/patient/:id/coaching-plan" component={EditCoachingPlan}/>
				<ProtectedRoute exact path="/patient/:id/edit" component={EditPatient}/>
				<ProtectedRoute exact path="/patient/:id/outcomes" component={ReportGeneration}/>
				<ProtectedRoute exact path="/patient/:id/reports" component={PatientReviewReportPage}/>
				<ProtectedRoute exact path="/patient/:patientId/day/:dayName" component={SessionPage}/>
				<ProtectedRoute exact path="/user" component={UserSettings}/>
				<ProtectedRoute exact path="/user/edit" component={EditUser}/>
				<ProtectedRoute path="/" exact component={Gallery}/>
                {/* No-Match Route */}
                <Route component={PageNotFound}/>
			</Switch>
		</BrowserRouter>
    );
};

export default App;
