import React from 'react';
import { TailSpin } from 'react-loader-spinner';

export const renderLoader = (
    message = 'Loading',
    size = 100
) => {
    return (
        <React.Fragment>
            <div className="w-full h-full justify-center items-center inline-flex flex-col">
                <TailSpin
                    height={size.toString()}
                    width={size.toString()}
                    color="grey"
                    ariaLabel="loading"
                />
                <br/>
                <br/>
                <div/>
                <h4 className="">{message}</h4>
            </div>
        </React.Fragment>
    );
};
