import React, { useState, useEffect } from 'react';
import Input from 'input/Input.jsx';
import cookie from 'react-cookies';
import { withRouter, useHistory, Redirect } from 'react-router';
import Page from '../components/Page';
import { login } from 'services/AccountServices.jsx';

const Login = ({history, location, ...rest}) => {

    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [loginText, setLoginText] = useState("Sign in");
    const [errors, setErrors] = useState(null);

    const usernameChanged = (value) => {
        setLoginText("Sign in");
        setUsername(value);
    };

    const passwordChanged = (value) => {
        setLoginText("Sign in");
        setPassword(value);
    };

    const onLoginSuccess = (userData) => {
        if (location && location.state && location.state.from) {
            history.push(location.state.from);
        }

        let roleType = userData.user.role_type.toLowerCase();
        if (roleType.includes("admin") || roleType === "physician") {
            history.push('/reportsExternal');
        }
        else {
            history.push('/');
        }
    };

    const onLoginFail = (message) => {
        setLoginText("Sign in");
        setErrors(message);
    };

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoginText("Signing In");
        setErrors(null);
        login(username, password, onLoginSuccess, onLoginFail);
    };

    const clearCookie = () => {
        cookie.remove('token', {path: '/'});
        cookie.remove('csrftoken', {path: '/'});
        cookie.remove('role', {path: '/'});
        cookie.remove('user', {path: '/'});
        localStorage.clear();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginText("Signing In");
        setErrors(null);
        login(username, password, onLoginSuccess, onLoginFail);
    };

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    };

    clearCookie();

    return (
        <Page errors={errors} internal={false}>
		    <div className="h-screen flex items-center justify-center pb-12 ">
                <div className=" w-96  h-96 flex-col justify-center  content-center	 items-center gap-8 inline-flex ">
                    <div className="self-stretch h-36 flex-col justify-start items-center gap-6 flex" id="title">
                        <div className="justify-start items-start inline-flex">
                            <img className="w-12 h-12" src="./icons/icon.png"/>
                        </div>
                        <div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex lg:h-40">
                            <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-9 ">Karuna Portal</div>
                            <div className="self-stretch text-center text-gray-600 text-base font-normal  leading-normal">Welcome back! Please enter your details.</div>
                        </div>
                    </div>

                    <form className="w-full max-w-sm" onSubmit={handleSubmitButton} onKeyPress={(e) => handleKeypress(e)}>
						<div className="self-stretch h-64 rounded-xl flex-col justify-start items-center gap-6 flex" id="form">
							<div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
									<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
										<div className="text-slate-700 text-sm font-medium  leading-tight">Email</div>
                                        <Input name="Email" className={"shadow appearance-none border rounded-lg w-full py-2.5 px-3.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} value={username ? username : ''} type="text" onChange={usernameChanged} noLabel required validations={[{type: "email"}]} formErrors={errors}/>
									</div>
								</div>
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
									<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
										<div className="text-slate-700 text-sm font-medium  leading-tight">Password</div>
										<Input name="Password" className={"shadow appearance-none border rounded-lg w-full py-2.5 px-3.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"} value={password ? password : ''} type="password" onChange={passwordChanged} noLabel required formErrors={errors}/>
									</div>
								</div>
							</div>
							<div className="self-stretch justify-center items-center inline-flex">
                                <a href="/password/reset" className="text-brand-600 text-sm font-semibold  leading-tight">Forgot password</a>
							</div>
							<div className="self-stretch h-11 flex-col justify-start items-start gap-4 flex">
								<button type="submit" className="self-stretch px-4 py-2.5 bg-brand-600 hover:bg-brand-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
									<div className="px-0.5 justify-center items-center flex">
										<div className="text-white text-base font-semibold  leading-normal">{loginText}</div>
									</div>
								</button>
							</div>
						</div>
                    </form>
                </div>
		    </div>
		</Page>
    );
};

export default withRouter(Login);
