import React from 'react'

const SessionDivider = ({label, activityDivider=false }) => {
    return (
        <div className={"inline-flex relative items-center justify-center w-full "+(!activityDivider?"py-7 px-5":"py-1")}>
        <hr className="w-full h-0.5 bg-gray-200 border-0 rounded "/>
        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 text-center text-slate-600 text-sm font-medium  leading-tight">
           {label}
        </div>
    </div>
      )
}

export default SessionDivider