import React from 'react';
import Notification from './Notification';

export const ErrorNotification: React.FC<{id: string, message: Object | Object[]; onClose?: (id: string) => void}> = ({
    id,
    message,
    onClose
}) => {
    return (
        <Notification
            id={id}
            message={message}
            containerClass="text-error-900 bg-error-100 rounded-md border border-error-900"
            iconSrc="/icons/x-circle.svg"
            onClose={onClose}
        />
    );
};

export default ErrorNotification;