import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import moment from 'moment-timezone';
import { getTimezone } from 'utility/UserUtility.jsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import API from 'services/API.jsx';
import { TailSpin } from 'react-loader-spinner';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const DownloadZip = ({ history }) => {
    const timezone = getTimezone();
    const key = useParams().key;
    const [errors, setErrors] = useState(null);
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        if (key) {

            API.get(`outcome_reports/?patient_id=${key}&&pdf_data=${true}`).then((response) => {

                console.log(response.data);
                let data = response.data;

                let patientName = (data.results[0].payer_type === undefined || (data.results[0].payer_type !== undefined && data.results[0].payer_type !== 2)) ? data.results[0].patient_name.split(' ').join('_') : '#' + data.results[0].patient_id;

                download(data, patientName);

            }).catch(error => {
                console.log(error);
                window.close();

            });

        }
        else {
            window.close();

        }
    }, []);

    const DateDisplay = (day) => {
        let d = moment(day).tz(timezone);
        return d.format('MM-DD-YYYY');

    };

    const download = (data, patientname) => {

        let obj = Object.entries(data.results);
        let zip = new JSZip();
        let pdf = zip.folder('pdf');

        obj.forEach(element => {
            let e = element[1];
            console.log(e);

            if (e.report_pdf_data !== undefined) {
                let uri = e.report_pdf_data;
                let idx = uri.indexOf('base64,') + 'base64,'.length; // or = 28 if you're sure about the prefix
                let content = uri.substring(idx);
                pdf.file(`${patientname}` + (e.is_post_program_checkin !== undefined && e.is_post_program_checkin === true ? `_6_Month_Post` : `_Week_${e.included_weeks}`) + `_Outcomes_${DateDisplay(e.prepared_date)}.pdf`, content, { base64: true });

            }

        });
        zip.generateAsync({ type: 'Blob' }).then(function (content) {
            saveAs(content, patientname + '_' + DateDisplay(new Date()) + '.zip');
            //window.close()
            setDownloaded(true);

        });

    };

    return (
        <Page internal={false}>
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            {
                !downloaded ?
                    (
                        renderLoader()
                    )
                    :
                    (
                        <React.Fragment>
                            <h3 className=" text-lg">Download Complete</h3>
                            <h4 className="">You can close this tab.</h4>
                        </React.Fragment>
                    )
            }
            </div>
        </Page>
    );
};

export default withRouter(DownloadZip);
