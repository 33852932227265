import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router';

import API from 'services/API.jsx';
import ActivityPanel from '../components/compositions/ActivityPanel';
import CoachingPlanPanel from '../components/compositions/CoachingPlanPanel';
import PatientProfile from './PatientProfile';
import RomPanel from '../components/compositions/RomPanel';
import VisitsPanel from '../components/compositions/VisitsPanel';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const PatientActivityPage = () => {
    
    const [activities, setActivities] = useState(null);
    const [coachingPlanData, setCoachingPlanData] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(null);
    const [dataFound, setDataFound] = useState(false);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState(null);
    const [roms, setRoms] = useState(null);
    const [sessions, setSessions] = useState(null);
    const [successes, setSuccesses] = useState(null);
    const patientId = (useParams() as {id: string}).id;
    
    const getActivityData = async () => {
        await API
            .get(`/exercises/?visit__patient=${patientId}&limit=5000&most_recent_coaching_plan=true`)
            .then(
                response => {
                    setActivities(response.data.results);
                }
            );
    };
    
    const getSessions = async () => {
        await API
            .get(`/session_history/?patient_id=${patientId}&limit=5000&most_recent_coaching_plan=true`)
            .then(
                response => {
                    setSessions(response.data);
                }
            );
    };
    
    const getRomData = async () => {
        await API
            .get(`/roms/?patient=${patientId}&limit=5000&most_recent_coaching_plan=true`)
            .then(
                response => {
                    setRoms(response.data.results);
                }
            );
    };
    
    const getStats = async () => {
        await API
            .get(`/patient_usage_stats/?detail=true&patient_id=${patientId}&exclude_visits_without_coaching_plans=true`)
            .then(
                response => {
                    setCoachingPlanData(response.data.results);
                }
            );
    };
    
    const loadData = async () => {
        setIsLoading(true);
        
        Promise
            .all([
                getActivityData(),
                getSessions(),
                getRomData(),
                getStats()
            ])
            .then(
                () => {
                }
            )
            .catch(
                (error) => {
                    setErrors(error);
                }
            )
            .finally(
                () => {
                    setIsLoading(false);
                }
            );
    };
    
    const onPatientData = (pData) => {
        setPatientData(pData);
        
        if (pData.coaching_plan) {
            setCurrentWeek(pData.coaching_plan['plan_week']);
            setDataFound(true);
        }
        else if (pData.last_coaching_plan_week) {
            setCurrentWeek(pData.last_coaching_plan_week);
            setDataFound(true);
        }
    };
    
    useEffect(
        () => {
            void loadData();
        },
        []
    );
    
    return (
        <PatientProfile
            errors={errors}
            forceOverFlow={true}
            successes={successes}
            // Callbacks
            onPatientData={onPatientData}
        >
        {
            isLoading ?
                (
                    renderLoader()
                )
                :
                (
                    !dataFound || !(coachingPlanData && coachingPlanData[0].plan_stats) ?
                        (
                            <div className=" w-full h-full justify-center gap-6 items-center inline-flex flex-col">
                                <div className="w-14 h-14 p-3.5 bg-[#ffe9e2] rounded-full border border-gray-50 justify-center items-center inline-flex">
                                    <img className="w-7 h-7 relative flex-col justify-start items-start flex" src="/icons/data.svg" alt="icon"/>
                                </div>
                                <div className="text-wrap w-1/2 [@media(min-width:1600px)]:w-1/4 break-words">
                                    <div className="self-stretch text-center text-gray-700 text-3xl font-semibold leading-[38px]">No Data</div>
                                    <div className=" break-words inline-block text-center text-gray-500 text-base font-normal">
                                        There is no activity data to display for this patient yet. Once they have an active coaching plan and begin completing VET activities, you can track their usage data here.
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className="w-full h-[99%] max-[1800px]:h-[99%]	grid min-[1200px]:grid-rows-2 grid-cols-2 max-[1200px]:grid-cols-1 gap-4 mx-4 my-2">
                                <div className="justify-center items-center inline-flex max-[1200px]:w-full max-[1200px]:h-[400px] min-w-0 min-h-0 relative rounded-[10px] shadow border border-gray-200">
                                    <ActivityPanel activities={activities} currentWeek={currentWeek}/>
                                </div>
                                <div className="justify-center items-center inline-flex max-[1200px]:w-full max-[1200px]:h-[400px] min-w-0 min-h-0 relative rounded-[10px] shadow border border-gray-200">
                                    <RomPanel roms={roms} currentWeek={currentWeek}/>
                                </div>
                                <div className="justify-start items-center inline-flex max-[1200px]:w-full max-[1200px]:h-[400px] min-w-0 min-h-0 relative rounded-[10px] shadow border border-gray-200">
                                    <VisitsPanel visits={sessions} current={currentWeek} patientId={patientId} name={`${patientData.first_name}_${patientData.last_name}`} rawData={activities}/>
                                </div>
                                <div className="justify-center items-center inline-flex max-[1200px]:w-full max-[1200px]:h-[400px] min-w-0 min-h-0 relative rounded-[10px] shadow border border-gray-200">
                                {
                                    currentWeek && coachingPlanData && coachingPlanData.length > 0 ?
                                        <CoachingPlanPanel currentWeek={currentWeek} stats={coachingPlanData && coachingPlanData[0].plan_stats && coachingPlanData[0].plan_stats ? coachingPlanData[0].plan_stats : -1}/>
                                        :
                                        <div className="w-full h-[90%] justify-start items-center inline-flex flex-col">No Data Found</div>
                                }
                                </div>
                            </div>
                        )
                )
        }
        </PatientProfile>
    );
};

export default withRouter(PatientActivityPage);
