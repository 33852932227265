import React from 'react';
import AddWeekDropDown from './AddWeekDropDown.tsx';
import CustomDropDown from '../../input/CustomDropDown';

export enum ViewState {
    Active = 1,
    Completed = 2,
    Pending = 3,
    Interrupted = 4,
    Cancelled = 5,
    CompletedPost = 6
}

interface EditCoachingPlanHeadPros {
    addWeeks: any;
    coachingPlan: any;
    coachingPlanTemplate: any;
    completeWeek: any;
    currentTemplate: any;
    endPlan: any;
    goToCurrent: any;
    saveChanges: any;
    selectedWeek: any;
    setCoachingPlanTemplate: any;
    setSelectedWeek: any;
    startNewPlan: any;
    state: ViewState;
    weekOptions: any;
}

const EditCoachingPlanHead = ({
    addWeeks,
    coachingPlan,
    coachingPlanTemplate,
    completeWeek,
    currentTemplate,
    endPlan,
    goToCurrent,
    saveChanges,
    selectedWeek,
    setCoachingPlanTemplate,
    setSelectedWeek,
    startNewPlan,
    state,
    weekOptions
}: EditCoachingPlanHeadPros) => {
    
    const stateBadgeColor = ['bg-brand-50', 'bg-warning-50', 'bg-success-50', 'bg-error-50'];
    const stateTextColor = [
        'text-brand-500', 'text-warning-500', 'text-success-500', 'text-error-500'
    ];
    
    return (
        <React.Fragment key={state}>
            {
                state && state === ViewState.Active &&
                (
                    <div className="w-full grow shrink relative  max-[1200px]:text-xs  max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14 max-[1200px]:text-xs '}
                                defaultValue={selectedWeek}
                                jumpBackToDefault={true}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <div className="justify-start items-center gap-2.5 flex">
                            {
                                currentTemplate &&
                                <CustomDropDown
                                    defaultValue={currentTemplate}
                                    jumpBackToDefault={true}
                                    onchange={setCoachingPlanTemplate}
                                    options={coachingPlanTemplate}
                                    right={false}
                                    textcolor="text-gray-700"
                                />
                            }
                            <div className="w-6 h-6 justify-center items-center flex has-tooltip">
                                <span className="tooltip rounded text-wrap shadow-lg p-1 mt-24">Changing the Coaching Plan Track mid-plan will overwrite all custom activity assignments.</span>
                                <img className=" hs-tooltip w-6 h-6 relative flex-col justify-start items-start flex" src="/icons/alert-circle.svg" alt="icon"/>
                            </div>
                        </div>
                        {
                            selectedWeek === weekOptions.length - 1 &&
                            <AddWeekDropDown AddWeek={addWeeks}/>
                        }
                        <div className="grow shrink flex basis-0 self-stretch"/>
                        <button onClick={saveChanges} className="px-4 py-2 max-[1200px]:text-wrap hover:bg-brand-700 hover:border-brand-700 bg-brand-600 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 flex">
                            <img className="w-5 h-5 relative" src="/icons/save-01.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white text-base max-[1200px]:text-xs font-semibold  leading-normal">Save Changes</div>
                            </div>
                        </button>
                        {
                            selectedWeek !== weekOptions.length - 1 &&
                            <button
                                className="px-4 py-2 max-[1200px]:text-wrap hover:bg-success-700 hover:border-success-700 bg-success-600 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 flex"
                                onClick={completeWeek}
                            >
                                    <img className="w-5 h-5 relative" src="/icons/check-circle-white.svg" alt="icon"/>
                                    <div className="px-0.5 justify-center items-center flex">
                                        <div className="text-white text-base max-[1200px]:text-xs font-semibold leading-normal">Complete Week</div>
                                    </div>
                                </button>
                        }
                        <button
                            className="px-4 py-2 max-[1200px]:text-wrap hover:bg-error-800 hover:border-error-800 bg-error-700 rounded-lg shadow border border-error-700 justify-center items-center gap-1.5 flex"
                            onClick={endPlan}
                        >
                            <img className="w-5 h-5 relative" src="/icons/slash-octagon.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white text-base max-[1200px]:text-xs font-semibold  leading-normal">End Plan</div>
                            </div>
                        </button>
                    </div>
                )
            }
            {
                state && state === ViewState.Pending &&
                (
                    <div className="w-full grow shrink relative max-[1200px]:text-xs max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14'}
                                defaultValue={selectedWeek}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <button
                            className="px-4 py-2 bg-white hover:bg-gray-300 max-[1200px]:text-wrap rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex"
                            onClick={() => {goToCurrent();}}
                        >
                            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/arrow-circle-broken-right.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-gray-700 max-[1200px]:text-xs text-sm font-semibold leading-normal">Current Week</div>
                            </div>
                        </button>
                        {
                            selectedWeek === weekOptions.length - 1 &&
                            <AddWeekDropDown AddWeek={addWeeks}/>
                        }
                        <div className="grow shrink flex basis-0 self-stretch"/>
                        <button
                            className="px-4 py-2 text-wrap hover:bg-brand-700  max-[1200px]:text-wrap hover:border-brand-700 bg-brand-600 rounded-lg shadow border border-brand-600 justify-center items-center gap-1.5 flex"
                            onClick={saveChanges}
                        >
                            <img className="w-5 h-5 relative" src="/icons/save-01.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white max-[1200px]:text-xs text-base font-semibold leading-normal">Save Changes</div>
                            </div>
                        </button>
                    </div>
                )
            }
            {
                state && state === ViewState.Completed &&
                (
                    <div className="w-full grow shrink relative max-[1200px]:text-xs max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14 '}
                                defaultValue={selectedWeek}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <button
                            className="px-4 py-2 max-[1200px]:text-wrap bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex"
                            onClick={() => {goToCurrent();}}
                        >
                            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/arrow-circle-broken-right.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-gray-700 max-[1200px]:text-xs text-sm font-semibold leading-normal">Current Week</div>
                            </div>
                        </button>
                        <div className="mix-blend-multiply h-7 justify-start items-start inline-flex">
                            <div className="pl-3 pr-2.5 py-1 bg-success-50 rounded-2xl justify-center items-center gap-[5px] flex">
                                <img className="w-3 h-3 relative" src="/icons/check-green.svg" alt="icon"/>
                                <div key={coachingPlan} className="text-center max-[1200px]:text-wrap max-[1200px]:text-xs text-success-700 text-sm font-medium  leading-tight">
                                    Week Completed {coachingPlan && coachingPlan[selectedWeek] && coachingPlan[selectedWeek].end_date && coachingPlan[selectedWeek].end_date.format('MM/DD/YYYY')}
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink flex basis-0 self-stretch"/>
                    </div>
                )
            }
            {
                state && state === ViewState.CompletedPost &&
                (
                    <div className="w-full grow shrink relative max-[1200px]:text-xs max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14'}
                                defaultValue={selectedWeek}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <div className="mix-blend-multiply h-7 justify-start items-start inline-flex">
                            <div className="pl-3 pr-2.5 py-1 bg-success-50 rounded-2xl justify-center items-center gap-[5px] flex">
                                <img className="w-3 h-3 relative" src="/icons/check-green.svg" alt="icon"/>
                                <div className="text-center max-[1200px]:text-xs text-success-700 text-sm font-medium leading-tight">
                                    Week Completed {coachingPlan && coachingPlan[selectedWeek] && coachingPlan[selectedWeek].end_date && coachingPlan[selectedWeek].end_date.format('MM/DD/YYYY')}</div>
                            </div>
                        </div>
                        <div className="grow shrink flex basis-0 self-stretch"/>
                        <button onClick={startNewPlan} className="px-4 py-2 bg-success-600 hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex">
                            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white text-base max-[1200px]:text-xs font-semibold leading-normal">Start New Plan</div>
                            </div>
                        </button>
                    </div>
                )
            }
            {
                coachingPlan && state && state === ViewState.Interrupted &&
                (
                    <div className="w-full grow shrink relative max-[1200px]:text-xs max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14'}
                                defaultValue={selectedWeek}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <div className="mix-blend-multiply h-7 justify-start items-start inline-flex">
                            <div className="pl-3 pr-2.5 py-1 bg-error-50 rounded-2xl justify-center items-center gap-[5px] max-[1200px]:text-wrap flex">
                                <img className="w-3 h-3 relative" src="/icons/x-circle.svg" alt="icon"/>
                                <div className="text-center max-[1200px]:text-xs  max-[1200px]:text-wrap text-error-700 text-sm font-medium leading-tight">
                                    Week Interrupted {coachingPlan && coachingPlan[selectedWeek] && coachingPlan[selectedWeek].end_date && coachingPlan[selectedWeek].end_date.format('MM/DD/YYYY')}
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink flex basis-0 self-stretch"/>
                        <button
                            className="px-4 py-2 bg-success-600 max-[1200px]:text-wrap hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex"
                            onClick={startNewPlan}
                        >
                            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white max-[1200px]:text-xs text-base font-semibold leading-normal">Start New Plan</div>
                            </div>
                        </button>
                    </div>
                )
            }
            {
                state && state === ViewState.Cancelled &&
                (
                    <div className="w-full grow shrink relative max-[1200px]:text-xs max-[1200px]:gap-2 justify-start items-center gap-6 inline-flex">
                        <div className="flex-col justify-start items-start inline-flex">
                        {
                            weekOptions && selectedWeek !== null &&
                            <CustomDropDown
                                badgeColors={stateBadgeColor}
                                badgePercent={false}
                                badgeText={stateTextColor}
                                customBadgePadding={'mr-14'}
                                defaultValue={selectedWeek}
                                onchange={setSelectedWeek}
                                options={weekOptions}
                                right={false}
                                textcolor="text-gray-700"
                            />
                        }
                        </div>
                        <div className="mix-blend-multiply h-7 justify-start items-start inline-flex">
                            <div className="pl-3 pr-2.5 py-1 bg-error-50 rounded-2xl max-[1200px]:text-wrap justify-center items-center gap-[5px] flex">
                                <img className="w-3 h-3 relative" src="/icons/x-circle.svg" alt="icon"/>
                                <div className="text-center max-[1200px]:text-xs text-error-700 text-sm font-medium leading-tight">
                                    Week Cancelled {coachingPlan && coachingPlan[selectedWeek] && coachingPlan[selectedWeek].end_date && coachingPlan[selectedWeek].end_date.format('MM/DD/YYYY')}
                                </div>
                            </div>
                        </div>
                        <div className="grow shrink flex basis-0 self-stretch"/>
                        <button
                            className="px-4 py-2 max-[1200px]:text-wrap bg-success-600 hover:border-success-700 hover:bg-success-700 rounded-lg shadow border border-success-600 justify-center items-center gap-1.5 inline-flex"
                            onClick={startNewPlan}
                        >
                            <img className="w-5 h-5 p-[1.67px] justify-center items-center flex" src="/icons/plus-circle.svg" alt="icon"/>
                            <div className="px-0.5 justify-center items-center flex">
                                <div className="text-white max-[1200px]:text-xs text-base font-semibold leading-normal">Start New Plan</div>
                            </div>
                        </button>
                    </div>
                )
            }
        </React.Fragment>
    );
};

export default EditCoachingPlanHead;
