import React, { useState, useEffect } from 'react';

const FilterDropDown = ({ options }) => {
    const [dropdownOpen, setDropDownOpen] = useState(false);
    
    const checks = options && options.length && options.map(
        option => {
            return (
                <label className="px-4 cursor-pointer gap-x-2 py-1 text-xs capitalize text-gray-700 inline-flex leading-tight font-semibold">
                    <input type="checkbox" checked={option.value} onChange={(e) => { option.onchange(e.target.checked);}} className=" cursor-pointer p-0.5 bg-gray-50 rounded border border-blue-600 justify-center items-center flex"/>
                    <div>{option.name}</div>
                </label>
            );
        }
    );
    
    return (
        <React.Fragment>
            <div className="flex">
                <div className="">
                    <button
                        onClick={() => {setDropDownOpen(!dropdownOpen);}}
                        className="text-slate-700 grow shrink text-sm font-semibold  leading-tight px-3 py-[11px] mt-1 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-1 inline-flex"
                    >
                        <img className="w-4 h-4 leading-tight inline-flex" src="/icons/filter.svg" alt="icon"/>
                        <div className="leading-tight">Filter</div>
                    </button>
                    {
                        dropdownOpen &&
                        <div x-show="dropdownOpen" onClick={() => {setDropDownOpen(!dropdownOpen);}} className="fixed inset-0 h-full w-full z-10"></div>
                    }
                    {
                        dropdownOpen &&
                        <div className="absolute py-2 w-64 bg-white rounded-md shadow-xl z-20 ing-0 ring-1 ring-black/10">
                            {
                                checks !== null && checks.length > 0 && checks
                            }
                            </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default FilterDropDown;