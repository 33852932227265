import React, { useEffect } from 'react';

const SingleInput = (
    {
        eraseFunction,
        id,
        index,
        multiInput = false,
        multiPlaceholder = '',
        multiVal = null,
        placeholder = '',
        title = '',
        val = null
    }
) => {

    useEffect(() => {
    }, []);

    const Remove = (e) => {
        e.preventDefault();
        eraseFunction(index);
    };

    return (
        <React.Fragment key={`${id}-${index}`}>
            <div className="w-full">
                <span className="inline-flex gap-x-1 pb-2">
                    <p className="text-gray-700 text-sm font-medium leading-tight">{title} {index + 1}</p>
                    <button type="button" onClick={Remove}>
                        <img className="h-4 w-4 " src={'/icons/trash.svg'} alt="icon"></img>
                    </button>
                </span>
                <br/>
                {
                    multiInput &&
                    <React.Fragment key={`${id}-multi-${index}`}>
                        <textarea
                            id={`${id}-multi-${index}`}
                            className={'w-full h-[5.5vh] mb-1 resize-none bg-white rounded-lg shadow border border-gray-300'}
                            defaultValue={multiVal}
                            placeholder={multiPlaceholder}
                        />
                        <br/>
                    </React.Fragment>
                }
                {
                    <React.Fragment key={`${id}-input-${index}`}>
                        <textarea
                            id={`${id}-input-${index}`}
                            className={'w-full h-[10vh] resize-none bg-white rounded-lg shadow border border-gray-300'}
                            defaultValue={val}
                            placeholder={placeholder}
                        />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
};

export default SingleInput;