import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { TailSpin } from 'react-loader-spinner';

import API from 'services/API.jsx';
import Page from '../components/Page';

const ValidateEmail = ({ history }) => {

    const key = useParams().key;
    console.log(key);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        if (key) {
            console.log(key);
            //login(email, "changeme", onLoginSuccess, onLoginFail );
            ValidateEmail(key);
        }
    }, []);

    const ValidateEmail = async (keyVal) => {
        await API.get('/verify-email/' + keyVal + '/')
                 .then(response => {
                     console.log(response);
                     if (response.code === 'ERR_BAD_REQUEST') {
                         throw new Error('Your account could not be found. Contact a Karuna Admin for support.');

                         return;
                     }
                     let url = response.data.reset_url;

                     if (url === undefined) {
                         throw new Error('Sorry, you have already set a password or this link has already been used.');
                         return;
                     }

                     console.log(url);
                     history.push('/password/' + url);

                 }).catch(error => {
                console.error(error);
                if (error.response === undefined) {
                    setErrors(error);
                }
                else {
                    setErrors(error.response && error.response.data);
                }
            });
    };

    return (
        <Page errors={errors} internal={false}>
            {errors === null ? <React.Fragment>
                <div className="h-screen flex items-center justify-center pb-12 ">
                    <TailSpin
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                        wrapperStyle={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    />
                </div>
            </React.Fragment> : <React.Fragment>
                <div className="h-screen flex items-center justify-center pb-12 ">
                    <div className="w-96 h-56 flex-col justify-start items-center gap-8 inline-flex">
                        <div className="self-stretch h-44 flex-col justify-start items-center gap-6 flex">
                            <div className="w-14 h-14 p-3.5 bg-rose-100 rounded-full border border-gray-50 justify-center items-center inline-flex">
                                <img src="/icons/link-broken-01.svg" className="w-7 h-7 relative flex-col justify-start items-start flex"/>
                            </div>
                            <div className="self-stretch h-24 flex-col justify-start items-start gap-3 flex">
                                <div className="self-stretch text-center text-gray-900 text-3xl font-semibold leading-9">Authentication Error</div>
                                <div className="self-stretch text-center text-gray-600 text-base font-normal leading-normal">Your email address could not be authenticated. Contact a Karuna Admin for support.</div>
                            </div>
                        </div>
                        <a href="/src/pages/Login">
                            <div className="justify-center items-center gap-1.5 inline-flex">
                                <img className="w-5 h-5" src="/icons/arrow-left.svg"/>
                                <div className="text-gray-600 text-sm font-semibold  leading-tight">Go to log in</div>
                            </div>
                        </a>
                    </div>
                </div>

            </React.Fragment>}


        </Page>

    );

};

export default withRouter(ValidateEmail);
