import React, { act, useState, useEffect } from 'react';
import { CheckIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import SessionDivider from './SessionDivider';
import { getRoms } from 'utility/Visit';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const SessionBonusPanel = ({activity, routine = false}) => {
    const [opened, setOpened] = useState(routine);
    const [dontRender, setDontRender] = useState(false);

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    useEffect(() => {
        setDontRender(GetHasNothing());
    }, [activity]);

    const GetHasNothing = () => {
        if (activity !== null) {
            let hasMotion = activity.motion_time !== undefined;
            let hasEvents = activity.events !== undefined && HasEvents(Object.values(activity.events));
            let hasRoms = activity.roms !== undefined && HasRoms(activity.roms);
            let hasGoals = activity.scores !== undefined && activity.scores.goals !== undefined && HasGoals(activity.scores.goals);

            if (activity.type === "Movement") {
                if (!hasEvents && !hasMotion && !hasRoms && !hasGoals) {return true;}

            }
            else {
                if (!hasEvents && !hasGoals) {return true;}

            }
            return false;
        }
        else {
            return false;
        }
    };

    const GetActivityBadge = (badge, type) => {

        if (badge !== null) {
            let color = "";

            switch (badge) {
                case 1:
                    color = "Bronze";
                    break;
                case 2:
                    color = "Silver";
                    break;
                default:
                    color = "Gold";
                    break;
            }
            console.log(`/icons/badges/${type}Activity_${color}.png`);
            return `/icons/badges/${type}Activity_${color}.png`;
        }
        else {
            return `/icons/badges/${type}Activity_None.png`;

        }

    };

    const CleanLabels = (label) => {
        let limit = 20;
        if (dimensions.width > 1600) {
            limit = 15;
        }
        if (dimensions.width < 1200) {
            limit = 15;
        }
        if (label.length <= limit) {
            return (
                <div className="text-gray-700 text-sm font-medium  leading-5">{label}</div>

            );
        }
        else {
            return (
                <div className="text-gray-700 text-sm font-medium  has-tooltip relative leading-5 flex"><span className="tooltip rounded shadow-lg p-1 ml-10 mt-4">{label}</span>
                    <div className="hs-tooltip">{label.substring(0, limit) + "..."}</div>
                </div>

            );
        }
    };

    const ContextTextColor = (raw) => {
        switch (true) {
            case raw === 0:
                return "text-gray-700";
            case raw < 1000:
                return "text-yellow-700";
            case raw < 2500:
                return 'text-lime-800';
            case raw < 4000:
                return 'text-green-light-700';
            default:
                return 'text-success-700';

        }

    };

    const ContextBadgeBackgroundColor = (raw) => {
        switch (true) {
            case raw === 0:
                return "bg-gray-50";
            case raw < 1000:
                return "bg-yellow-50";
            case raw < 2500:
                return 'bg-lime-50';
            case raw < 4000:
                return 'bg-green-light-50';
            default:
                return 'bg-success-50';

        }

    };

    const ContextBarColor = (raw) => {
        switch (true) {

            case raw === 0:
                return "bg-gray-400";
            case raw < 1000:
                return "bg-yellow-300";
            case raw < 2500:
                return 'bg-lime-300';
            case raw < 4000:
                return 'bg-green-light-300';
            default:
                return 'bg-success-300';

        }

    };

    const RomBarColor = (raw, type) => {
        if (type.includes("Back")) {
            switch (true) {

                case raw <= 15:
                    return "bg-error-300";
                case raw <= 60:
                    return "bg-warning-300";
                case raw <= 70:
                    return 'bg-yellow-300';
                case raw <= 80:
                    return 'bg-green-light-300';
                default:
                    return 'bg-success-300';

            }
        }
        else {
            switch (true) {

                case raw < 30:
                    return "bg-error-300";
                case raw < 120:
                    return "bg-warning-300";
                case raw < 145:
                    return 'bg-yellow-300';
                case raw < 165:
                    return 'bg-green-light-300';
                default:
                    return 'bg-success-300';

            }
        }

    };

    const ContextLevel = (raw) => {
        let level = "";
        switch (true) {
            case raw === 0:
                level = "None";
                break;
            case raw < 1000:
                level = "Keep Going ";
                break;
            case raw < 2500:
                level = 'Good';
                break;
            case raw < 4000:
                level = 'Great';
                break;
            default:
                level = "Excellent";
                break;
        }

        return level;
    };

    const ContextTooltip = (raw) => {
        let level = "";
        switch (true) {

            case raw < 1000:
                level = "0-999";
                break;
            case raw < 2500:
                level = '1000-2499';
                break;
            case raw < 4000:
                level = '2500-3999';
                break;
            default:
                level = "4000-5000+";
                break;
        }

        return level;
    };

    const RomBadgeTextColor = (raw, type) => {
        if (type.includes("Back")) {
            switch (true) {

                case raw <= 15:
                    return "text-error-700";
                case raw <= 60:
                    return "text-warning-700";
                case raw <= 70:
                    return 'text-yellow-700';
                case raw <= 80:
                    return 'text-green-light-700';
                default:
                    return 'text-success-700';

            }
        }
        else {
            switch (true) {

                case raw < 30:
                    return "text-error-700";
                case raw < 120:
                    return "text-warning-700";
                case raw < 145:
                    return 'text-yellow-700';
                case raw < 165:
                    return 'text-green-light-700';
                default:
                    return 'text-success-700';

            }
        }

    };

    const RomBadgeColor = (raw, type) => {
        if (type.includes("Back")) {
            switch (true) {

                case raw <= 15:
                    return "bg-error-50";
                case raw <= 60:
                    return "bg-warning-50";
                case raw <= 70:
                    return 'bg-yellow-50';
                case raw <= 80:
                    return 'bg-green-light-50';
                default:
                    return 'bg-success-50';

            }
        }
        else {
            switch (true) {

                case raw < 30:
                    return "bg-error-50";
                case raw < 120:
                    return "bg-warning-50";
                case raw < 145:
                    return 'bg-yellow-50';
                case raw < 165:
                    return 'bg-green-light-50';
                default:
                    return 'bg-success-50';

            }
        }

    };

    const RomLevel = (raw, type) => {
        if (type.includes("Back")) {
            switch (true) {

                case raw <= 15:
                    return "Sev. Limited";
                case raw <= 60:
                    return "Mod. Limited";
                case raw <= 70:
                    return 'Min. Limited';
                case raw <= 80:
                    return 'Functional';
                default:
                    return 'Full Function';

            }
        }
        else {
            switch (true) {

                case raw < 30:
                    return "Sev. Limited";
                case raw < 120:
                    return "Mod. Limited";
                case raw < 145:
                    return 'Min. Limited';
                case raw < 165:
                    return 'Functional';
                default:
                    return 'Full Function';
            }
        }
    };

    const RomTooltip = (raw, type) => {
        if (type.includes("Back")) {
            switch (true) {

                case raw <= 15:
                    return "Severely Limited 0°-15°";
                case raw <= 60:
                    return "Moderately Limited 15°-60°";
                case raw <= 70:
                    return 'Minimally Limited 60°-70°';
                case raw <= 80:
                    return 'Functional 70°-80°';
                default:
                    return 'Full Function 80°-90°';

            }
        }
        else {
            switch (true) {

                case raw < 30:
                    return "Severely Limited 0°-30°";
                case raw < 120:
                    return "Moderately Limited 30°-120°";
                case raw < 145:
                    return 'Minimally Limited 120°-145°';
                case raw < 165:
                    return 'Functional 145°-165°';
                default:
                    return 'Full Function 165°-180°';
            }
        }
    };

    const weeklyBestDecorator = () => {
        return (
            <div className=" mix-blend-multiply justify-start items-start inline-flex">
                <div className="pl-1.5 pr-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
                    <img className="w-3 h-3 relative" src="/icons/award-blue.svg"/>
                    <div className="text-center text-brand-700 text-xs font-medium  leading-[18px]">Weekly Best</div>
                </div>
            </div>
        );

    };

    const allTimeBestDecorator = () => {
        return (
            <div className=" mix-blend-multiply justify-start items-start inline-flex">
                <div className="pl-1.5 pr-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center gap-1 flex">
                    <img className="w-3 h-3 relative" src="/icons/award-blue.svg"/>
                    <div className="text-center text-brand-700 text-xs font-medium  leading-[18px]">All-Time Best</div>
                </div>
            </div>
        );
    };

    const MotionRow = (duration) => {
        duration = duration * 1000;

        return (
            <div className="w-full  justify-start items-start gap-2 inline-flex">
                <div className="justify-start items-start gap-2 inline-flex   [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%] ">

                    <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/clock-refresh.svg"/>
                    {CleanLabels("Motion Time")}
                </div>

                <div className="text-gray-700 text-sm font-medium   leading-5">
                    {duration > 0 ? `${moment.duration(duration).hours() > 0 ? (moment.duration(duration).hours() + "h ") : ""}${moment.duration(duration).minutes() ? moment.duration(duration).minutes() + "m " : ""}  ${moment.duration(duration).seconds() > 0 ? moment.duration(duration).seconds() + "s" : ""}` : `No motion time found`}

                </div>
            </div>

        );

    };

    const RomRow = (label, score) => {
        let divider = 180;
        if (label.includes("Back")) {
            divider = 90;
        }
        return (
            <div className="w-full  justify-start items-start gap-2 inline-flex">
            <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/compass.svg"/>
                {CleanLabels(label + (label.includes("Back") ? " (Estimate)" : ""))}
            </span>
                <div className="grow shrink mt-1.5 relative">
                    <div className="w-[100%] absolute h-2 bg-gray-200 rounded-full"/>
                    <div className={classNames("h-2 absolute rounded-full", RomBarColor(score, label))} style={{width: (Math.round((score / divider) * 100)) + '%'}}/>
                </div>
                <span className="inline-flex gap-2 text-sm">
            {Math.round(parseInt(score))}°
            <div className={"px-2 py-0.5 has-tooltip relative  rounded-2xl justify-center items-center flex " + RomBadgeColor(score, label)}>
                    <span className="tooltip rounded shadow-lg p-1 -mt-12">{RomTooltip(score, label)}</span>
                    <div className={"text-center hs-tooltip  text-xs font-medium  leading-[18px] text-nowrap " + RomBadgeTextColor(score, label)}>{RomLevel(score, label)}</div>
      </div>
            </span>
            </div>

        );

    };

    const ScoreRow = (score) => {
        return (
            <div className="w-full  justify-start items-start gap-2 inline-flex">
            <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/score.svg"/>
                {CleanLabels("Score")}
            </span>
                <div className="grow shrink mt-1.5 relative">
                    <div className="w-[100%] absolute h-2 bg-gray-200 rounded-full"/>
                    <div className={classNames("h-2 absolute rounded-full", ContextBarColor(score))} style={{width: (Math.round((score / 5000) * 100)) + '%'}}/>


                </div>

                <span className="inline-flex gap-2 text-sm">
                {score}
                    <div className={"px-2 py-0.5 has-tooltip relative rounded-2xl justify-center items-center flex " + ContextBadgeBackgroundColor(score)}>
            <span className="tooltip rounded shadow-lg p-1 -mt-12">{ContextTooltip(score)}</span>
                <div className={"text-center hs-tooltip  text-xs font-medium  leading-[18px] text-nowrap " + ContextTextColor(score)}>{ContextLevel(score)}</div>
            </div>
                    {activity.scores !== undefined && activity.scores.top_alltime !== undefined && activity.scores.top_alltime ? allTimeBestDecorator() : <span></span>}
                    {activity.scores !== undefined && activity.scores.top_weekly !== undefined && activity.scores.top_weekly ? weeklyBestDecorator() : <span></span>}

            </span>
            </div>

        );

    };

    const GoalRow = (label, goals, alltime = false, weekly = false) => {
        return (
            <div className="w-full  justify-start items-start gap-2 inline-flex ">
            <span className="justify-start items-start gap-2 inline-flex  [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
            <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/target.svg"/>
                {CleanLabels(label)}
            </span>

                <div className="text-gray-700 text-sm font-medium    ">
                    <span className="pr-2">{goals}</span>
                    <span>{alltime && allTimeBestDecorator()}</span>
                    <span>{weekly && weeklyBestDecorator()}</span>

                </div>

            </div>

        );

    };

    const EventRow = (label, event, newline = false) => {
        return (
            <div className="w-full  justify-start items-start gap-2 inline-flex">
            <span className="justify-start items-start gap-2 inline-flex [@media(max-width:1200px)]:w-[25%] w-[20%] [@media(min-width:1600px)]:w-[15%]  ">
            {!newline && <img className="w-5 h-5 relative flex-col justify-start items-start flex" src="/icons/info.svg"/>}
                {CleanLabels(label)}
            </span>
                <div className="text-gray-700 text-sm font-medium  leading-5">
                    <span className="pr-2">{event}</span>
                </div>
            </div>

        );
    };

    const RenderCompleted = (completed) => {
        if (completed) {
            return (
                <div className="mix-blend-multiply justify-start items-start flex">
                    <div className="pl-1.5 pr-2 py-0.5 bg-success-50 rounded-2xl justify-center items-center gap-1.5 flex">
                        <div className="w-2 h-2 relative">
                            <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-success-500 rounded-full"/>
                        </div>
                        <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">Completed</div>
                    </div>
                </div>
            );
        }
        else {
            if (activity.restarted !== undefined && activity.restarted === true) {
                return (
                    <div className="mix-blend-multiply justify-start items-start flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-error-100 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full"/>
                            </div>
                            <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">Restarted</div>
                        </div>
                    </div>
                );
            }
            if (activity.timed_out !== undefined && activity.timed_out === true) {
                return (
                    <div className="mix-blend-multiply justify-start items-start flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-error-100 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full"/>
                            </div>
                            <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">Timed Out</div>
                        </div>
                    </div>
                );
            }
            if (activity.skipped !== undefined && activity.skipped === true) {
                return (
                    <div className="mix-blend-multiply justify-start items-start flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-error-100 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full"/>
                            </div>
                            <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">Skipped</div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className="mix-blend-multiply justify-start items-start flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-warning-500 rounded-full"/>
                            </div>
                            <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">Incomplete</div>
                        </div>
                    </div>
                );
            }
        }
    };

    const timeConvert = (raw) => {
        let times = raw.split(':');
        let hours = times[0];
        let minutes = times[1];
        let secones = times[2];
        let AmOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12;
        let finalTime = hours + ":" + minutes + AmOrPm;
        return finalTime;

    };

    const topElement = (actType) => {

        if (actType === "Movement") {
            return <React.Fragment>{activity.scores !== undefined && activity.scores.score !== undefined ? ScoreRow(activity.scores.score) : ScoreRow(0)
            }</React.Fragment>;
        }
        else {

            return <React.Fragment>{activity.motion_time !== undefined ? MotionRow(parseFloat(activity.motion_time)) : MotionRow(0)}</React.Fragment>;
        }
    };

    const parseCamelCaseTitle = (title) => {
        return title.split(/(?=[A-Z])/).join(' ');

    };

    const RenderRoms = (roms) => {
        let render = [];
        Object.keys(roms).forEach(rom => {
            render.push(RomRow(parseCamelCaseTitle(rom), roms[rom]));

        });
        return render;
    };

    const HasRoms = (roms) => {
        let render = [];
        Object.keys(roms).forEach(rom => {
            render.push(RomRow(parseCamelCaseTitle(rom), roms[rom]));

        });
        return render.length > 0;
    };

    const HasGoals = (goals) => {
        let render = [];
        Object.keys(goals).forEach(goal => {

            render.push(GoalRow(goals[goal].label, goals[goal].completions, goals[goal].top_alltime, goals[goal].top_weekly));

        });
        return render.length > 0;

    };

    const HasEvents = (events) => {
        let render = [];

        let ArmEvents = (events.filter(event => event.type === "AFFECTED_ARM" || event.type === "affected_arm"));
        let SitEvents = (events.filter(event => event.type === "seated or standing" || event.type === "POSE"));
        let RaiseEvents = (events.filter(event => event.type.includes("PaintingPuzzle__PIECES_RAISED")));
        let ifsBubbleEvents = (events.filter(event => event.type.includes("IFS__THOUGHT_BUBBLE_USES")));

        if (ArmEvents.length > 0) {
            render.push(EventRow("Affected Arm", ArmEvents[0].value));

        }

        if (SitEvents.length > 0) {
            render.push(EventRow("Patient Position", SitEvents[0].value));

        }
        if (RaiseEvents.length > 0) {
            render.push(EventRow("Raised Pieces", RaiseEvents.length));

        }
        if (ifsBubbleEvents.length > 0) {
            render.push(EventRow("Thought Bubble Uses", ifsBubbleEvents.length));

        }

        if (activity.name === "Left Right Discrimination") {
            events.forEach(e => {
                switch (e.type) {
                    case "LeftRightDiscrimination__SET_1_GUESS_ACCURACY":
                        render.push(EventRow("Set 1 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__SET_2_GUESS_ACCURACY":
                        render.push(EventRow("Set 2 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__SET_3_GUESS_ACCURACY":
                        render.push(EventRow("Set 3 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__BASIC_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Basic Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__BASIC_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Basic Decision Time Right", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__INTERMEDIATE_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Intermediate Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__INTERMEDIATE_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Intermediate Decision Time Right", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__ADVANCED_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Advanced Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__ADVANCED_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Advanced Decision Time Right", e.value.toString() + " s"));
                        break;
                }
            });

        }
        console.log(activity.name);

        if (activity.name === "Grocery Shopping") {
            events.forEach(e => {

                switch (e.type) {
                    case "GroceryShopping__BUDGET_CONTROL_ENABLED":
                        render.push(EventRow("Budget Control", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__DISCOUNTS_ENABLED":
                        render.push(EventRow("Discounts", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__HIDDEN_ITEMS_ENABLED":
                        render.push(EventRow("Hidden Items", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__HIDDEN_ITEMS_ENABLED":
                        render.push(EventRow("Hidden Items", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__DISTRACTIONS_ENABLED":
                        render.push(EventRow("Distractions", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__SHELF_SHUFFLE_ENABLED":
                        render.push(EventRow("Shelf Shuffle", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__MAX_ACHIEVABLE_SCORE":
                        render.push(EventRow("Max Achievable Score", e.value));
                        break;

                }
            });

        }

        if (activity.name === "Ruling In Out") {
            let rulings = events.filter(event => event.type === "RESULTS" || event.type === "ruling_results" || event.type === "RulingInOut__RESULTS");

            if (rulings.length > 0) {
                render.push(1);

            }

        }

        return render.length > 0;
    };

    const RenderGoals = (goals) => {
        let render = [];
        Object.keys(goals).forEach(goal => {

            render.push(GoalRow(goals[goal].label, goals[goal].completions, goals[goal].top_alltime, goals[goal].top_weekly));

        });
        return render;

    };
    const RenderEvents = (events) => {
        let render = [];

        let ArmEvents = (events.filter(event => event.type === "AFFECTED_ARM" || event.type === "affected_arm"));
        let SitEvents = (events.filter(event => event.type === "seated or standing" || event.type === "POSE"));
        let RaiseEvents = (events.filter(event => event.type.includes("PaintingPuzzle__PIECES_RAISED")));
        let ifsBubbleEvents = (events.filter(event => event.type.includes("IFS__THOUGHT_BUBBLE_USES")));

        if (ArmEvents.length > 0) {
            render.push(EventRow("Affected Arm", ArmEvents[0].value));

        }

        if (SitEvents.length > 0) {
            render.push(EventRow("Patient Position", SitEvents[0].value));

        }
        if (RaiseEvents.length > 0) {
            render.push(EventRow("Raised Pieces", RaiseEvents.length));

        }
        if (ifsBubbleEvents.length > 0) {
            render.push(EventRow("Thought Bubble Uses", ifsBubbleEvents.length));

        }
        if (activity.name === "Flower Garden") {
            let skipped = (events.filter(event => event.type.includes("SKIP")));

            render.push(EventRow("Flowers Skipped", skipped.length));

        }
        if (activity.name === "Dish Stacking") {
            let skipped = (events.filter(event => event.type.includes("SKIP")));

            render.push(EventRow("Dishes Skipped", skipped.length));

        }
        if (activity.name === "Lotus Toss") {
            let skipped = (events.filter(event => event.type.includes("SKIP")));

            render.push(EventRow("Flowers Skipped", skipped.length));

        }
        if (activity.name === "Unload Dishwasher") {
            let skipped = (events.filter(event => event.type.includes("SKIP")));

            render.push(EventRow("Dishes Skipped", skipped.length));

        }
        if (activity.name === "Left Right Discrimination") {
            events.forEach(e => {
                switch (e.type) {
                    case "LeftRightDiscrimination__SET_1_GUESS_ACCURACY":
                        render.push(EventRow("Set 1 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__SET_2_GUESS_ACCURACY":
                        render.push(EventRow("Set 2 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__SET_3_GUESS_ACCURACY":
                        render.push(EventRow("Set 3 Guess Accuracy", (e.value * 100).toString() + "%"));
                        break;
                    case "LeftRightDiscrimination__BASIC_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Basic Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__BASIC_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Basic Decision Time Right", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__INTERMEDIATE_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Intermediate Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__INTERMEDIATE_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Intermediate Decision Time Right", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__ADVANCED_AVG_DECISION_TIME_LEFT":
                        render.push(EventRow("Advanced Decision Time Left", e.value.toString() + " s"));
                        break;
                    case "LeftRightDiscrimination__ADVANCED_AVG_DECISION_TIME_RIGHT":
                        render.push(EventRow("Advanced Decision Time Right", e.value.toString() + " s"));
                        break;
                }
            });

        }
        if (activity.name === "Grocery Shopping") {
            events.forEach(e => {
                switch (e.type) {
                    case "GroceryShopping__BUDGET_CONTROL_ENABLED":
                        render.push(EventRow("Budget Control", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__DISCOUNTS_ENABLED":
                        render.push(EventRow("Discounts", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__HIDDEN_ITEMS_ENABLED":
                        render.push(EventRow("Hidden Items", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__HIDDEN_ITEMS_ENABLED":
                        render.push(EventRow("Hidden Items", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__DISTRACTIONS_ENABLED":
                        render.push(EventRow("Distractions", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__SHELF_SHUFFLE_ENABLED":
                        render.push(EventRow("Shelf Shuffle", e.value === 'true' ? "Enabled" : "Disabled"));
                        break;
                    case "GroceryShopping__MAX_ACHIEVABLE_SCORE":
                        render.push(EventRow("Max Achievable Score", e.value));
                        break;

                }
            });

        }
        if (activity.name === "Ruling In Out") {
            let rulings = events.filter(event => event.type === "RESULTS" || event.type === "ruling_results" || event.type === "RulingInOut__RESULTS");

            if (rulings.length > 0) {
                let rulingsvalue = rulings[0].value.split('[');
                rulingsvalue.shift();
                let rulingstring;
                rulingsvalue.forEach((element, i) => {
                    let label = element.substring(element.length - 2, element.length - 1) === "1" ? (element.substring(0, element.length - 4)) : "";
                    let answer = (element.substring(element.length - 2, element.length - 1) === "1" ? "yes" : "no");
                    if (label !== "") {
                        render.push(EventRow(label, answer));
                    }

                });

            }

        }

        return render;
    };

    const RenderBody = () => {
        return (
            <React.Fragment>
                {activity.roms !== undefined && RenderRoms(activity.roms)}
                {activity.type === "Movement" && activity.motion_time !== undefined && MotionRow(parseFloat(activity.motion_time))}
                {
                    activity.scores !== undefined && activity.scores.goals !== undefined && RenderGoals(activity.scores.goals)
                }
                {
                    activity.events !== undefined && RenderEvents(Object.values(activity.events))
                }

            </React.Fragment>
        );
    };

    const RenderTense = (tense) => {
        if (tense === "past") {
            return (
                <div className="mix-blend-multiply justify-start items-start flex">
                    <div className="pl-1.5 pr-2 py-0.5 bg-lime-50 rounded-2xl justify-center items-center gap-1.5 flex">
                        <div className="w-2 h-2 relative">
                            <img className="w-2 h-2 left-[1px] top-[1px] absolute " src="/icons/refresh-ccw-01.svg"/>
                        </div>
                        <div className="text-center text-lime-900 text-xs font-medium  leading-[18px]">Completed on {moment(activity.date).format("MMMM DD, YYYY")}</div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="mix-blend-multiply justify-start items-start flex">
                    <div className="pl-1.5 pr-2 py-0.5 bg-lime-50 rounded-2xl justify-center items-center gap-1.5 flex">
                        <div className="w-2 h-2 relative">
                            <img className="w-2 h-2 left-[1px] top-[1px] absolute " src="/icons/refresh-cw-01.svg"/>
                        </div>
                        <div className="text-center text-lime-900 text-xs font-medium  leading-[18px]">completed on {moment(activity.date).format("MMMM DD, YYYY")}</div>
                    </div>
                </div>
            );
        }

    };
    const RenderInfo = () => {
        return (
            <div className={!routine ? "grow shrink w-full basis-0 px-6 py-[15px] rounded-[10px] shadow border border-gray-200 flex-col justify-start items-start gap-[15px] inline-flex" : "grow shrink w-full basis-0 py-2.5   flex-col justify-start items-start gap-[15px] inline-flex"}>
                <div className="self-stretch justify-start items-center gap-[15px] inline-flex">
                    <div className="text-gray-900 text-xl font-semibold  leading-[30px]">{activity.name}</div>
                    {activity.iteration !== undefined && activity.iteration !== null && activity.completed ? <div className="has-tooltip relative inline-flex 	">
                        <span className="tooltip rounded shadow-lg ml-5 -mt-5 ">{`Completion ${activity.iteration}`}</span>
                        <img className="w-7 h-7" src={GetActivityBadge(activity.iteration, activity.type)}/>
                    </div> : <img className="w-7 h-7" src={GetActivityBadge(null, activity.type)}/>}
                    {RenderCompleted(activity.completed)}
                    {activity.visit_tense !== undefined && activity.visit_tense !== "present" && RenderTense(activity.visit_tense)}
                    <div className="grow shrink basis-0 h-6"/>
                    <button onClick={() => {
                        setOpened(!opened);
                    }}>
                        {!routine && (opened ? dontRender === false && <ChevronUpIcon className="h-5 w-5 text-gray-700 relative" aria-hidden="true"/> : dontRender === false && <ChevronDownIcon className="h-5 w-5 text-gray-700 relative" aria-hidden="true"/>)}
                    </button>
                </div>
                {activity.start_time && topElement(activity.type)}
                {opened && dontRender === false && <span className="w-full h-full justify-center items-center inline-flex flex-col gap-2.5">
           {!routine && <SessionDivider label={"Details"} activityDivider={true}/>}
                    {RenderBody()}

            </span>}

            </div>
        );
    };

    if (!routine) {
        return (
            <div className="w-full  justify-start items-center gap-5 inline-flex">
                <div className=" self-stretch  flex-col justify-start items-end gap-2.5 inline-flex">
                    <div className="text-right text-gray-500 text-sm font-medium  leading-tight">{activity.start_time != null ? timeConvert(activity.start_time) : "n/a"}</div>
                    <div className="w-px grow shrink basis-0 bg-gray-400"/>
                    <div className="text-right text-gray-500 text-sm font-medium  leading-tight">{activity.start_time != null ? timeConvert(activity.end_time) : "n/a"}</div>
                </div>
                {RenderInfo()}

            </div>
        );
    }
    else {
        return (
            <div className="w-full  justify-start items-center gap-2.5 inline-flex">

                {RenderInfo()}

            </div>
        );
    }
};

export default SessionBonusPanel;
