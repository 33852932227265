import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { TailSpin } from 'react-loader-spinner';
import cookie from 'react-cookies';
import { Chart as ChartJS, defaults } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import DropdownInput from 'input/DropdownInput.jsx';
import { LabelList } from 'recharts';
import { getDurationComplete } from 'utility/Visit.jsx';
import { getDuration } from 'utility/Visit.jsx';

import moment from 'moment';
import CustomDropDown from '../input/CustomDropDown';

const RomPanel = ({ roms = null, currentWeek = -1 }) => {

    const [activityTypes, setActivityTypes] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [typeOptions, setTypeOptions] = useState(null);
    const [data, setData] = useState([]);
    const [labels, setLabels] = useState([]);

    const [activityData, setActivityData] = useState(null);

    const custom_canvas_background_color = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart, args, options) => {
            const {
                ctx,
                chartArea: { top, right, bottom, left, width, height },
                scales: { x, y }
            } = chart;
            ctx.save();
            ctx.fillStyle = 'rgba(0,255,0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(180), width, y.getPixelForValue(165) - y.getPixelForValue(180));
            ctx.fillStyle = 'rgba(173,255,47,0.3)';
            ctx.fillRect(left, y.getPixelForValue(165), width, y.getPixelForValue(145) - y.getPixelForValue(165));
            ctx.fillStyle = 'rgba(255, 255, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(145), width, y.getPixelForValue(120) - y.getPixelForValue(145));
            ctx.fillStyle = 'rgba(255, 165, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(120), width, y.getPixelForValue(30) - y.getPixelForValue(120));
            ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(30), width, y.getPixelForValue(0) - y.getPixelForValue(30));

            ctx.restore();
        }
    };

    const custom_canvas_background_color2 = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart, args, options) => {
            const {
                ctx,
                chartArea: { top, right, bottom, left, width, height },
                scales: { x, y }
            } = chart;
            ctx.save();
            ctx.fillStyle = 'rgba(0,255,0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(90), width, y.getPixelForValue(80) - y.getPixelForValue(90));
            ctx.fillStyle = 'rgba(173,255,47,0.3)';
            ctx.fillRect(left, y.getPixelForValue(80), width, y.getPixelForValue(70) - y.getPixelForValue(80));
            ctx.fillStyle = 'rgba(255, 255, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(70), width, y.getPixelForValue(60) - y.getPixelForValue(70));
            ctx.fillStyle = 'rgba(255, 165, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(60), width, y.getPixelForValue(15) - y.getPixelForValue(60));
            ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(15), width, y.getPixelForValue(0) - y.getPixelForValue(15));

            ctx.restore();
        }
    };

    useEffect(() => {
        let types = [];
        let actData = {};
        if (roms !== null && roms !== undefined) {
            for (let e in roms) {
                if (!types.includes(roms[e].motion)) {types.push(roms[e].motion);}
                if (actData[roms[e].motion] === undefined) {
                    actData[roms[e].motion] = {};
                    actData[roms[e].motion]['scores'] = {};
                    actData[roms[e].motion]['latest'] = [roms[e].date_created_client, roms[e].max];
                    actData[roms[e].motion]['first'] = [roms[e].date_created_client, roms[e].max];

                    let week = roms[e].plan_week;
                    actData[roms[e].motion]['scores'][week] = (roms[e].max !== undefined ? roms[e].max : 0);

                }
                else {

                    let week = roms[e].plan_week;
                    if (actData[roms[e].motion]['scores'][week] === undefined) {
                        actData[roms[e].motion]['scores'][week] = (roms[e].max !== undefined ? roms[e].max : 0);
                    }
                    else {
                        let score = (roms[e].max !== undefined ? roms[e].max : 0);
                        if (actData[roms[e].motion]['scores'][week] < score) {
                            actData[roms[e].motion]['scores'][week] = score;
                        }
                    }
                    if (moment(roms[e].date_created_client).isAfter(moment(actData[roms[e].motion]['latest'][0]))) {
                        actData[roms[e].motion]['latest'] = [roms[e].date_created_client, roms[e].max];

                    }
                    if (moment(roms[e].date_created_client).isBefore(moment(actData[roms[e].motion]['first'][0]))) {
                        actData[roms[e].motion]['first'] = [roms[e].date_created_client, roms[e].max];

                    }

                }
            }
            types.sort();

            let options = [];
            let index = 1;
            types.forEach(e => {
                options.push(
                    {
                        id: index,
                        name: e.replace(/([a-z])([A-Z])/g, '$1 $2'),
                        value: e,
                        badge: GetBadge(e, actData[e]['latest'])
                    }
                );
                index++;
            });

            if (currentType === null) {
                setCurrentType(types[0]);
            }
            setTypeOptions(options);
            setActivityTypes(types);
            setActivityData(actData);

        }
    }, [roms]);

    useEffect(() => {
        if (currentType !== null && activityData !== null && currentWeek !== -1 && activityData[currentType] !== undefined && activityData[currentType]['scores'] !== undefined) {
            let tempdata = [];
            let labels = [];
            for (let i = 1; i <= currentWeek; i++) {

                labels.push(i);
                tempdata.push(activityData[currentType]['scores'][i] !== undefined ? Math.round(activityData[currentType]['scores'][i]) : null);

            }

            setData(tempdata);
            setLabels(labels);
        }
        else {
            setData([]);
        }

    }, [currentType]);

    const GetBadge = (motion, latest) => {
        let badge = {};

        if (motion.includes('Back')) {
            let raw = latest[1];
            let percent = raw / 90;
            badge['value'] = percent;
            let level = -1;
            switch (true) {
                case raw <= 15:
                    level = 0;
                    break;
                case raw <= 60:
                    level = 1;
                    break;
                case raw <= 75:
                    level = 2;
                    break;
                case raw <= 85:
                    level = 3;
                    break;
                default:
                    level = 4;
                    break;
            }
            badge['color'] = level;
        }
        else {
            let raw = latest[1];

            let percent = (raw / 180);
            badge['value'] = percent;
            let level = '';
            switch (true) {
                case raw < 30:
                    level = 0;
                    break;
                case raw < 120:
                    level = 1;
                    break;
                case raw < 145:
                    level = 2;
                    break;
                case raw < 165:
                    level = 3;
                    break;
                default:
                    level = 4;
                    break;
            }

            badge['color'] = level;

        }

        return badge;

    };

    const ContextLevel = (context) => {
        let raw = (context[0].raw);
        let level = '';
        if (currentType.includes('Back')) {
            switch (true) {
                case raw <= 15:
                    level = 'Severely Limited';
                    break;
                case raw <= 60:
                    level = 'Moderately Limited';
                    break;
                case raw <= 75:
                    level = 'Minimally Limited';
                    break;
                case raw <= 85:
                    level = 'Functional';
                    break;
                default:
                    level = 'Full Function';
                    break;
            }
        }
        else {
            switch (true) {
                case raw < 30:
                    level = 'Severely Limited';
                    break;
                case raw < 120:
                    level = 'Moderately Limited';
                    break;
                case raw < 145:
                    level = 'Minimally Limited';
                    break;
                case raw < 165:
                    level = 'Functional';
                    break;
                default:
                    level = 'Full Function';
                    break;
            }
        }

        return level;
    };

    const ContextColor = (context) => {
        let raw = (context.raw);
        let level = '';
        if (currentType.includes('Back')) {
            switch (true) {
                case raw <= 15:
                    level = 'rgba(255, 0, 0, 0.2)';
                    break;
                case raw <= 60:
                    level = 'rgba(255, 165, 0, 0.2)';
                    break;
                case raw <= 75:
                    level = 'rgba(255, 255, 0, 0.2)';
                    break;
                case raw <= 85:
                    level = 'rgba(173,255,47,0.3)';
                    break;
                default:
                    level = 'rgba(0,255,0, 0.2)';
                    break;
            }
        }
        else {
            switch (true) {
                case raw < 30:
                    level = 'rgba(255, 0, 0, 0.2)';
                    break;
                case raw < 120:
                    level = 'rgba(255, 165, 0, 0.2)';
                    break;
                case raw < 145:
                    level = 'rgba(255, 255, 0, 0.2)';
                    break;
                case raw < 165:
                    level = 'rgba(173,255,47,0.3)';
                    break;
                default:
                    level = 'rgba(0,255,0, 0.2)';
                    break;
            }
        }

        return level;
    };

    const RenderTable = (type) => {

        let table = [];
        let first = Math.round(activityData[currentType]['first'][1]);
        let latest = Math.round(activityData[currentType]['latest'][1]);
        let totalchange = Math.round(((latest - first) / first) * 100);
        let weeks = Object.keys(activityData[currentType]['scores']);

        let recentlast = weeks[weeks.length > 1 ? weeks.length - 2 : 0];

        let recentlatest = weeks[weeks.length - 1];

        let recentchange = Math.round(((activityData[currentType]['scores'][recentlatest] - activityData[currentType]['scores'][recentlast]) / activityData[currentType]['scores'][recentlast]) * 100);

        table.push(
            <tr className=" border-y-0  even:bg-white odd:bg-white border-gray-200">
                <td className="	w-1/2	 border-x-0 border-t-0 border-b-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className="text-gray-900 	">First Measurement</span>
                    <div className="float-end 	">{first}°</div>
                </td>
                <td className="	w-1/2	 border-x-0 border-t-0 border-b-0  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className="text-gray-900 	">All-Time Change</span>
                    <div className=" align-text-bottom	has-tooltip float-bottom text-center	content-center	items-center	 pl-2 object-bottom whitespace-nowrap  inline-flex 	"><span className="tooltip rounded shadow-lg p-1  mb-14">Change between first<br/> and latest measurements</span><img className="h-4 w-4 hs-tooltip "
                        src={'/icons/help-circle.svg'}></img></div>
                    <div className={'float-end 	' + (totalchange >= 0 ? 'text-success-600' : 'text-error-600')}>{totalchange >= 0 ? '+' : ''}{totalchange}%</div>
                </td>
            </tr>
        );
        table.push(
            <tr className=" border-y-2  even:bg-white odd:bg-white border-gray-200">
                <td className="	w-1/2	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className="text-gray-900 	">Latest Measurement</span>
                    <div className="float-end 	">{latest}°</div>
                </td>
                <td className=" w-1/2		 grow shrink border-t-0 border-b-0 border-x-0 px-6  text-start  text-xs font-medium text-gray-500  leading-[18px] "><p className="whitespace-nowrap  inline-flex"><span className="text-gray-900 	">Recent Changes</span></p>
                    <div className=" align-text-bottom	has-tooltip float-bottom text-center	content-center	items-center	 pl-2 object-bottom whitespace-nowrap  inline-flex 	"><span className="tooltip rounded shadow-lg p-1 ml-4 mb-12">Change from previous<br/> weekly best</span><img className="h-4 w-4 hs-tooltip "
                        src={'/icons/help-circle.svg'}></img></div>
                    <div className={'float-end 	' + (recentchange >= 0 ? 'text-success-600' : 'text-error-600')}>{recentchange >= 0 ? '+' : ''}{recentchange}%</div>
                </td>
            </tr>
        );

        return table;
    };

    return (

        <React.Fragment>
            {(roms === null || activityTypes === null || currentType === null || typeOptions === null || activityData === null || currentWeek === -1) ?
                (<div className=" w-full h-full justify-start items-center inline-flex flex-col 	">
                    <TailSpin
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                    />
                    <br/> <br/>
                    <div/>
                    <h4 className="">Loading...</h4>
                </div>) : (
                    Object.keys(activityData).length > 0 ?
                        (<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	">
                            <div className="w-full h-[10%]   gap-5 inline-flex justify-start items-center ">
                                <div className="grow shrink basis-0 text-gray-900 text-xl font-semibold  ml-[26px]">Range of Motion History</div>
                                <span className="gap-0 mr-[26px]">
                      <div className=" 	mr-2    align-text-bottom	has-tooltip float-bottom text-center	content-center	items-center	 pl-2 object-bottom whitespace-nowrap  inline-flex 	"><span className="tooltip rounded shadow-lg p-1 -ml-56 mt-16 align-text-bottom">Select motion to view.<br/>Colored decorator shows latest measurement<br/>as percentage of full range.</span><img
                          className="h-[18px] w-[18px] hs-tooltip " src={'/icons/help-circle.svg'}></img></div>

                      <div className=" flex-col justify-start items-start inline-flex mr-2 ">
                      {
                          /*
                           ["bg-[#FFEBEE]",
                           "bg-[#FFF8E1]",
                           'bg-[#FFFDE7]',
                           'bg-[#F1F8E9]',
                           "bg-[#E8F5E9]"]
                           */
                          typeOptions !== null && typeOptions.length > 0 && <CustomDropDown options={typeOptions} onchange={setCurrentType} badgeColors={
                              [
                                  'bg-[#ff000033]',
                                  'bg-[#ffa50033]',
                                  'bg-[#ffff0033]',
                                  'bg-[#adff2f4d]',
                                  'bg-[#00ff0033]'
                              ]}
                              badgeText={[
                                  'text-[#D32F2F]',
                                  'text-[#FFA000]',
                                  'text-[#FBC02D]',
                                  'text-[#689F38]',
                                  'text-[#388E3C]'
                              ]}/>
                      }
                      </div>
                      </span>
                            </div>
                            <div className="w-[calc(100%-48px)] h-[65%]   mt-5  ">
                                {data.length > 0 && <Line key={currentType}
                                    data={
                                        {
                                            labels: labels,
                                            datasets: [
                                                {
                                                    label: 'Rom', // Name the series,
                                                    connectNullData: true,
                                                    data: data, // Specify the data values array
                                                    fill: false,
                                                    borderColor: '#2196f3',

                                                    backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
                                                    borderWidth: 1, // Specify bar border width
                                                    spanGaps: true,
                                                    segment: {
                                                        borderDash: ctx => {
                                                            return data[ctx.p0DataIndex] === null || data[ctx.p1DataIndex] === null ? [10, 19] : [];

                                                        },
                                                        borderColor: ctx => {
                                                            return data[ctx.p0DataIndex] === null || data[ctx.p1DataIndex] === null ? '#808080' : '#2196f3';

                                                        }

                                                    }

                                                }
                                            ]
                                        }
                                    }

                                    options={{
                                        plugins: {
                                            tooltip: {
                                                usePointStyle: true,

                                                footerFont: { weight: 'normal' },
                                                callbacks: {
                                                    labelPointStyle: function (context) {
                                                        return {
                                                            pointStyle: 'rect'
                                                        };
                                                    },
                                                    label: (context) => {
                                                        return context.formattedValue + '°';
                                                    },
                                                    title: (context) => {
                                                        return ['Week ' + context[0].label];
                                                    },
                                                    footer: (context) => {
                                                        return [ContextLevel(context)];
                                                    },

                                                    labelColor: function (context) {
                                                        let color = ContextColor(context);
                                                        return {
                                                            borderColor: color,
                                                            backgroundColor: color,
                                                            borderWidth: 2
                                                        };
                                                    }
                                                }
                                            },
                                            legend: {
                                                display: false
                                            },
                                            beforeDraw: function (chart) {
                                                let ctx = chart.chart.ctx;
                                                ctx.save();
                                                let xAxis = chart.scales['x-axis-0'];
                                                let yAxis = chart.scales['y-axis-0'];
                                                let dataset = chart.data.datasets[0];
                                                let valueFrom = null;
                                                let valueFromIndex = 0;
                                                let xFrom = null;
                                                let yFrom = null;
                                                ctx.strokeStyle = dataset.borderColor;
                                                dataset.data.forEach((value, index) => {
                                                    if (value != null) {
                                                        let x = xAxis.getPixelForTick(index);
                                                        let y = yAxis.getPixelForValue(value);
                                                        if (valueFrom != null) {
                                                            ctx.lineWidth = dataset.borderWidth;
                                                            if (index - valueFromIndex > 1) {
                                                                ctx.setLineDash([5, 5]);
                                                            }
                                                            else {
                                                                ctx.setLineDash([]);
                                                            }
                                                            ctx.beginPath();
                                                            ctx.moveTo(xFrom, yFrom);
                                                            ctx.lineTo(x, y);
                                                            ctx.stroke();
                                                        }
                                                        valueFrom = value;
                                                        valueFromIndex = index;
                                                        xFrom = x;
                                                        yFrom = y;
                                                    }
                                                });
                                                ctx.restore();
                                            }

                                        },
                                        scales: {
                                            x: {
                                                display: true,
                                                grid: {
                                                    display: false
                                                },
                                                title: {
                                                    display: true,
                                                    text: 'Week',
                                                    font: {
                                                        family: 'Inter'

                                                    }
                                                }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                max: (currentType.includes('Back') ? 90 : 180),
                                                ticks: {
                                                    stepSize: 30,
                                                    callback: function (value, index, ticks) {
                                                        return value + '°';
                                                    }

                                                },
                                                display: true,
                                                title: {
                                                    display: true,
                                                    text: 'Best Measurement',
                                                    font: {
                                                        family: 'Inter'

                                                    }
                                                }
                                            }
                                        },

                                        maintainAspectRatio: false,
                                        responsive: true,
                                        xAxes: [{ gridLines: { display: false } }]
                                    }}

                                    plugins={[currentType.includes('Back') ? custom_canvas_background_color2 : custom_canvas_background_color]}/>}

                            </div>
                            <div className="w-[calc(100%-48px)]  border-0  ">

                                <table className="w-full h-[100%] divide-gray-700 border-0  dark:divide-gray-700 rounded-[10px]">

                                    <tbody className="border-0 ">
                                    <React.Fragment>

                                        {RenderTable()}


                                    </React.Fragment>
                                    </tbody>
                                </table>
                            </div>


                        </div>) :
                        (<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	"> No Data Found</div>)
                )
            }
        </React.Fragment>
    );
};

export default RomPanel;
