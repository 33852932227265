import React, { useState, useEffect } from 'react';
import Breadcrumbs from './Breadcrumbs.jsx';

const ModularHeader = (
    {
        children,
        headerObj,
        internal
    }
) => {
    const [body, setBody] = useState(null);
    const [buttons, setButtons] = useState(null);
    const [insurer, setInsurer] = useState(null);
    const [name, setName] = useState(null);
    const [physician, setPhysician] = useState(null);
    const [provider, setProvider] = useState(null);
    const [sideButton, setSideButton] = useState(null);
    const [subheader, setSubheader] = useState(null);
    const [tabs, setTabs] = useState(null);
    const [tpa, setTpa] = useState(null);
    const [trail, setTrail] = useState(null);
    const [vetkit, setVetkit] = useState(null);

    /** Render **/

    useEffect(() => {
        if (headerObj) {
            headerObj.trail && setTrail(headerObj.trail);

            if (headerObj.body) {
                setBody(headerObj.body);

                let body = headerObj.body;
                body.name && setName(body.name);
                body.subheader && setSubheader(body.subheader);
                body.buttons && setButtons(body.buttons);
                body.vetkit && setVetkit(body.vetkit);

                if (body.info) {
                    setPhysician(body.info.physician);
                    setProvider(body.info.provider);
                    setInsurer(body.info.insurer);
                    setTpa(body.info.tpa);
                }

                body.sideButton && setSideButton(body.sideButton);
                body.tabs && setTabs(body.tabs);
            }
        }
    }, [headerObj]);

    const headerButtons = buttons && buttons.map((b, i) => (
        <React.Fragment key={`header-button-${i}`}>
            <button className="h-[80%] max-[1200px]:h-[70%]  max-[1200px]:bottom-[15%] max-[1200px]:px-1  px-3 py-2 bg-white hover:bg-gray-100 rounded-lg shadow	 border border-gray-300 justify-center items-center gap-1 inline-flex" onClick={b.click}>
                {b.img && <img className="w-5 h-5 relative" src={b.img} alt="header-button"/>}
                <div className="px-0.5 justify-center items-center flex ">
                    <div className="text-gray-700 text-sm max-[1200px]:text-xs  font-semibold  leading-tight">{b.title.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                </div>
            </button>
        </React.Fragment>
    ));

    const headerTabs = tabs !== null && tabs.map((tab, i) => (
        <React.Fragment key={`header-tab-${i}`}>
            <button className={'h-[80%] max-[1200px]:h-[60%] w-1/6 bottom-0  max-[1200px]:px-1  px-3   bg-white hover:bg-gray-100  	 justify-center items-center  inline-flex ' + (tab.current === true ? 'border-b-2	border-brand-600 text-brand-600' : 'border-0 text-gray-700 ')}
                onClick={tab.click}>
                <div className="px-0.5 justify-center items-center flex ">
                    <div className=" text-sm max-[1200px]:text-xs  font-semibold  leading-tight">{tab.text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                </div>
            </button>
        </React.Fragment>
    ));

    return (
        <React.Fragment>
            {
                headerObj && internal &&
                <div id="modular-header" className={'w-full'}>
                    {
                        trail &&
                        <span id="modular-header-breadcrumbs" className={`inline-flex items-center w-full h-[3vh] max-[1200px]:h-[3vh] pl-[1.3rem] py-0 my-0 text-sm max-[1200px]:text-xs font-normal bg-inherit shadow-none border-0`}>
                            <Breadcrumbs links={trail}/>
                        </span>
                    }
                    {
                        body &&
                        <span id="modular-header-body" className={`inline-flex relative w-full h-[14vh] max-[1200px]:h-[11vh] my-0 py-0 pl-4 inset-x-0 bg-inherit shadow-none`}>
                            <div id="modular-header-patient-info" className="flex-col inline-flex justify-start items-start relative self-stretch w-max max-[1200px]:gap-y-0 h-2/3 m-0 mr-[50px] max-[1200px]:mr-2 pt-1 max-[1200px]:pt-0 shadow-none">
                                <span className="inline-flex gap-x-2 max-[1200px]:gap-x-0">
                                    <div className="text-4xl max-[1200px]:text-xl font-medium leading-[44px] max-[1200px]:leading-tight max-[1200px]:m-0 text-gray-900 shadow-none">
                                        {
                                            name
                                        }
                                    </div>
                                    {
                                        sideButton &&
                                        <div className="inline-flex justify-start items-start w-9 max-[1200px]:w-8 h-9 max-[1200px]:h-8 max-[1200px]:ml-1 rounded-lg">
                                            <div className="has-tooltip">
                                                <div
                                                    className="flex gap-2 justify-center items-center p-2 bg-white hover:bg-gray-100 border border-gray-300 rounded-lg shadow"
                                                    onClick={
                                                        () => {
                                                            sideButton.click();
                                                        }
                                                    }
                                                >
                                                    <img className="w-5 h-5 max-[1200px]:w-4 max-[1200px]:h-4 relative" src={sideButton.img} alt="side-button"/>
                                                </div>
                                                <span className="tooltip whitespace-nowrap inline-flex rounded shadow-lg p-1 -mt-12 ml-4">{sideButton.text}</span>
                                            </div>
                                        </div>
                                    }
                                </span>
                                <div className="text-gray-600 text-sm shadow-none	max-[1200px]:text-xs font-normal leading-tight max-[1200px]:m-0">{subheader}</div>
                            </div>
                            {
                                provider &&
                                <div id="modular-header-provider-info" className="h-2/3 relative shadow-none justify-center items-start">
                                    <div className="text-sm max-[1200px]:text-xs pt-3 gap-[50px] max-[1200px]:gap-[5px] inline-flex">
                                        <div className="flex-col justify-start items-start gap-[5px] max-[1200px]:gap-[1px] inline-flex">
                                            <div className="justify-start items-start gap-[11px] max-[1200px]:gap-[15px] inline-flex">
                                                <div className="text-gray-900 font-semibold leading-tight">TPA</div>
                                                <div className="text-gray-600 font-normal leading-tight">{tpa}</div>
                                            </div>
                                            <div className="justify-start items-start gap-[11px] max-[1200px]:gap-[5px] inline-flex">
                                                <div className="text-gray-900 font-semibold leading-tight">Insurer</div>
                                                <div className="text-gray-600 font-normal leading-tight">{insurer}</div>
                                            </div>
                                        </div>
                                        <div className="flex-col justify-start items-start gap-[5px] max-[1200px]:gap-[1px] inline-flex">
                                            <div className="justify-start items-start gap-[11px] inline-flex">
                                                <div className="text-gray-900 font-semibold leading-tight">Provider</div>
                                                <div className="text-gray-600 font-normal leading-tight">{provider}</div>
                                            </div>
                                            <div className="justify-start items-start gap-[11px] inline-flex">
                                                <div className="text-gray-900 font-semibold leading-tight">Physician</div>
                                                <div className="text-gray-600 font-normal leading-tight">{physician}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                vetkit &&
                                <div id="modular-header-vetkit-info" className="absolute right-0 h-2/3 justify-end items-center inline-flex mr-16 max-[1200px]:mr-0 shadow-none">
                                    <div className="flex-col justify-start items-center inline-flex pt-1">
                                        <div className="text-gray-900 text-sm max-[1200px]:text-xs font-semibold leading-tight">VET™ Version</div>
                                        <div className="text-gray-600 text-xl max-[1200px]:text-lg font-normal leading-[30px]">{vetkit}</div>
                                    </div>
                                </div>
                            }
                            <div id="modular-header-buttons" className={`absolute bottom-0 h-1/3 w-full flex justify-start ${headerTabs ? 'ml-[-16px] items-end' : ' gap-3 items-start'} shadow-none`}>
                                {
                                    headerButtons && headerButtons
                                }
                                {
                                    headerTabs && headerTabs
                                }
                            </div>
                        </span>
                    }
                    {children}
                </div>
            }
        </React.Fragment>
    );
};

export default ModularHeader;
