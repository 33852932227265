import axios from 'axios';
import cookie from 'react-cookies';

const headers = cookie.load('token') ?
    {
        'Accept': 'application/json; version=1.0',
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + cookie.load('token')
    }
    :
    {
        'Accept': 'application/json; version=1.0',
        'Content-Type': 'application/json'
    };

export default axios.create({
    baseURL: import.meta.env.VITE_APP_BACKEND_API_URL,
    responseType: 'json',
    headers: headers,
    withCredentials: false
});
