import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
import ActivityAssignmentPanel from './ActivityAssignmentPanel.tsx';
import { ViewState } from './EditCoachingPlanHead.tsx';
import EditCoachingPlan from 'pages/EditCoachingPlan.js';
import { getValueAtPath } from '../../../utility/ObjectUtils.tsx';

interface BonusVar {
    state: ViewState;
    activityStats: any;
    selectedWeek: any;
    coachingplan: any;
    setActivity: any;
    setDuration: any;
    constPlan: any;
    setOption;
}

const BonusSection = ({ state, activityStats, selectedWeek, coachingplan, setActivity, setDuration, constPlan, setOption }: BonusVar) => {
    const [refresh, setRefresh] = useState(false);
    
    useEffect(() => {
    }, [coachingplan, refresh]);
    
    const RefreshSection = () => {
        setRefresh(!refresh);
    };
    
    const hasDuration = (val) => {
        if (val && typeof (val) !== 'string') {
            let duration: number = getValueAtPath(activityStats[val.Id], 'Settings.Duration.Value', 120);
            return getValueAtPath(val, 'Settings.Duration', duration);
        }
        return 0;
    };
    
    const badgeParse = (rank) => {
        if (rank === 1) {return 'Bronze';}
        if (rank === 2) {return 'Silver';}
        if (rank >= 3) {return 'Gold';}
    };
    
    const GetActivityBadge = (actType, rank) => {
        let rankString = badgeParse(rank);
        return '/icons/badges/' + actType + 'Activity_' + rankString + '.png';
    };
    return (
        
        <div key={coachingplan} className="text-wrap w-full h- px-6 py-[15px]   flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="self-stretch justify-start items-center gap-6 inline-flex">
                <div className="text-gray-900 text-xl font-semibold  leading-[30px]">Bonus Activities</div>


            </div>
            
            {state && (state === ViewState.Pending || state === ViewState.Active) && <div className="w-full justify-start items-start gap-6 inline-flex">
                <div className="w-full text-wrap	 text-gray-900 text-sm font-normal  leading-tight">Assign up to four additional activities for the patient to complete this week. Choose from Coaching, Meditation, and Movement activities.</div>
            </div>}
            {state && (state === ViewState.Cancelled) && <div className="w-full justify-start items-start gap-6 inline-flex">
                <div className="w-full text-wrap	 text-gray-900 text-sm font-normal  leading-tight">No recorded Bonus Activity data for this week.
                </div>
            </div>}
            {state && activityStats !== undefined && coachingplan !== undefined && coachingplan[selectedWeek] !== undefined && coachingplan[selectedWeek].assignments !== undefined &&
                (state === ViewState.Pending || state === ViewState.Active || state === ViewState.Completed || state === ViewState.Interrupted || state === ViewState.CompletedPost) &&
                (<div className="w-full h-[100%] max-[1800px]:h-[100%]	  grid min-[1200px]:grid-rows-2 grid-cols-2  max-[1200px]:grid-cols-1 gap-4  my-2">
                    <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full    min-w-0 min-h-0	 relative rounded-[10px] shadow border border-gray-200">
                        <ActivityAssignmentPanel setOptionMethod={setOption}
                            refreshCheck={refresh}
                            refreshMethod={RefreshSection}
                            key={selectedWeek}
                            setActivityMethod={setActivity}
                            setDurationMethod={setDuration}
                            originalDuration={hasDuration(constPlan[selectedWeek].assignments[0])}
                            defaultDuration={hasDuration(coachingplan[selectedWeek].assignments[0])}
                            state={state}
                            index={0}
                            activities={activityStats}
                            week={selectedWeek}
                            originalAssignment={constPlan[selectedWeek].assignments[0] !== undefined ? constPlan[selectedWeek].assignments[0] : null}
                            defaultAssignment={coachingplan[selectedWeek].assignments[0] !== undefined ? coachingplan[selectedWeek].assignments[0] : null}
                            isCalibrationActivity={false}
                            isRoutineActivity={false}
                            currentAssignments={coachingplan[selectedWeek].assignments}
                            completedRoutineData={null}/>
                    </div>
                    <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full  min-w-0   min-h-0	relative rounded-[10px] shadow border border-gray-200">
                        <ActivityAssignmentPanel setOptionMethod={setOption}
                            refreshCheck={refresh}
                            refreshMethod={RefreshSection}
                            key={selectedWeek}
                            setActivityMethod={setActivity}
                            setDurationMethod={setDuration}
                            originalDuration={hasDuration(constPlan[selectedWeek].assignments[1])}
                            defaultDuration={hasDuration(coachingplan[selectedWeek].assignments[1])}
                            state={state}
                            index={1}
                            activities={activityStats}
                            week={selectedWeek}
                            originalAssignment={constPlan[selectedWeek].assignments[1] !== undefined ? constPlan[selectedWeek].assignments[1] : null}
                            defaultAssignment={coachingplan[selectedWeek].assignments[1] !== undefined ? coachingplan[selectedWeek].assignments[1] : null}
                            isCalibrationActivity={false}
                            isRoutineActivity={false}
                            currentAssignments={coachingplan[selectedWeek].assignments}
                            completedRoutineData={null}/>

                    </div>
                    <div className=" 	justify-start items-center inline-flex  max-[1200px]:w-full    min-w-0   min-h-0	 relative rounded-[10px] shadow border border-gray-200">
                        <ActivityAssignmentPanel setOptionMethod={setOption}
                            refreshCheck={refresh}
                            refreshMethod={RefreshSection}
                            key={selectedWeek}
                            setActivityMethod={setActivity}
                            setDurationMethod={setDuration}
                            originalDuration={hasDuration(constPlan[selectedWeek].assignments[2])}
                            defaultDuration={hasDuration(coachingplan[selectedWeek].assignments[2])}
                            state={state}
                            index={2}
                            activities={activityStats}
                            week={selectedWeek}
                            originalAssignment={constPlan[selectedWeek].assignments[2] !== undefined ? constPlan[selectedWeek].assignments[2] : null}
                            defaultAssignment={coachingplan[selectedWeek].assignments[2] !== undefined ? coachingplan[selectedWeek].assignments[2] : null}
                            isCalibrationActivity={false}
                            isRoutineActivity={false}
                            currentAssignments={coachingplan[selectedWeek].assignments}
                            completedRoutineData={null}/>


                    </div>
                    <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full    min-w-0   min-h-0  relative rounded-[10px] shadow border border-gray-200">
                        <ActivityAssignmentPanel setOptionMethod={setOption}
                            refreshCheck={refresh}
                            refreshMethod={RefreshSection}
                            key={selectedWeek}
                            setActivityMethod={setActivity}
                            setDurationMethod={setDuration}
                            originalDuration={hasDuration(constPlan[selectedWeek].assignments[3])}
                            defaultDuration={hasDuration(coachingplan[selectedWeek].assignments[3])}
                            state={state}
                            index={3}
                            activities={activityStats}
                            week={selectedWeek}
                            originalAssignment={constPlan[selectedWeek].assignments[3] !== undefined ? constPlan[selectedWeek].assignments[3] : null}
                            defaultAssignment={coachingplan[selectedWeek].assignments[3] !== undefined ? coachingplan[selectedWeek].assignments[3] : null}
                            isCalibrationActivity={false}
                            isRoutineActivity={false}
                            currentAssignments={coachingplan[selectedWeek].assignments}
                            completedRoutineData={null}/>

                    </div>
                </div>)
            }


        </div>);
};

export default BonusSection;
