const isArrayIndexer = (pathPart: string): boolean =>
    /^\[\d+]+$/.test(pathPart);

const parsePathPart = (pathPart: string, index: number): string => {
    if (isArrayIndexer(pathPart)) {
        const parsedPart = pathPart.slice(1, -1);
        if (!/^\d+$/.test(parsedPart)) {
            throw new Error(`Invalid array index at pathSegment['${pathPart}'] pathIndex[${index}]!`);
        }
        return parsedPart;
    }
    return pathPart;
};

const generateValueAtField = (pathPart: string, index: number): any => {
    if (/^\[(\d+)]$/.test(pathPart)) {
        const match = pathPart.match(/^\[(\d+)]$/);
        return Array.from({ length: match ? parseInt(match[1]) + 1 : 0 });
    }
    if (pathPart.startsWith('[') && pathPart.endsWith(']')) {
        throw new Error(`Invalid array index at pathSegment['${pathPart}'] pathIndex[${index}]!`);
    }
    return {};
};

export const getValueAtPath = (object: any, path: string, defaultValue: any = undefined): any => {
    if (!object || typeof object !== 'object' || path === undefined || path === null) {
        console.warn(`[getValueAtPath] Invalid object[${object ? JSON.stringify(object) : object}] or path[${path}]. Returning default value[${JSON.stringify(defaultValue)}]`);
        return defaultValue;
    }
    
    try {
        const pathParts: string[] = path.split('.');
        let current = object;
        
        for (let index = 0; index < pathParts.length; index++) {
            const p = pathParts[index];
            const parsedP = parsePathPart(p, index);
            
            if (!current || typeof current !== 'object') {
                // console.warn(`[getValueAtPath] Value does not exist in object[${object ? JSON.stringify(object) : object}] at path[${path}] pathSegment['${p}'] pathIndex[${index}]. (Returning default value[${JSON.stringify(defaultValue)}])`);
                return defaultValue;
            }
            
            if (Array.isArray(current) && !isArrayIndexer(p)) {
                throw new Error(`Invalid array index at pathSegment['${p}'] pathIndex[${index}] for array${current ? JSON.stringify(current) : current}!`);
            }
            
            if (!Array.isArray(current) && isArrayIndexer(p)) {
                throw new Error(`Expected array but found non-array object[${current ? JSON.stringify(current) : current}] at pathSegment['${p}'] pathIndex[${index}]!`);
            }
            
            if (!(parsedP in current)) {
                // console.warn(`[getValueAtPath] Value does not exist in object[${object ? JSON.stringify(object) : object}] at path[${path}] pathSegment['${p}'] pathIndex[${index}]. (Returning default value[${JSON.stringify(defaultValue)}])`);
                return defaultValue;
            }
            
            current = current[parsedP];
        }
        
        return current;
    } catch (e: any) {
        console.error(`[getValueAtPath] Error getting value from object[${object ? JSON.stringify(object) : object}] at path[${path}]: ${e.message} (Returning default value[${JSON.stringify(defaultValue)}])`);
        return defaultValue;
    }
};

export const getValueAtPaths = (object: any, paths: string[], defaultValue: any = undefined): any => {
    return paths.map(path => getValueAtPath(object, path, undefined)).find(value => value !== undefined) ?? defaultValue;
};

export const setValueAtPath = (object: any, path: string, value: any): any => {
    if (!(object && typeof object === 'object' && path !== undefined && path !== null)) {
        // console.warn(`[setValueAtPath] [${object ? JSON.stringify(object) : object}] or path[${path}]. Value[${JSON.stringify(value)}] could not be set.`);
        return false;
    }
    
    try {
        const pathParts = path.split('.');
        const pathPartsLength = pathParts.length;
        let current = object;
        
        for (let index = 0; index < pathPartsLength; index++) {
            const p = pathParts[index];
            const parsedP = parsePathPart(p, index);
            
            if (index === pathPartsLength - 1) {
                current[parsedP] = value;
            }
            else {
                if (!current[parsedP]) {
                    current[parsedP] = generateValueAtField(pathParts[index + 1], index);
                }
                
                if (typeof current[parsedP] !== 'object') {
                    throw new Error(`${current[parsedP] ? JSON.stringify(current[parsedP]) : current[parsedP]} at pathSegment['${p}'] pathIndex[${index}] is not an object!`);
                }
                
                current = current[parsedP];
            }
        }
        
        return object;
    } catch (error: any) {
        console.error(`[setValueAtPath] Error setting value[${JSON.stringify(value)}] of object[${JSON.stringify(object)}] at path['${path}']: ${error.message}`);
    }
    
    return false;
};

export const delValueAtPath = (object: any, path: string): any => {
    if (!object || typeof object !== 'object' || path === undefined || path === null) {
        // console.warn(`[delValueAtPath] Invalid object[${object ? JSON.stringify(object) : object}] or path[${path}].`);
        return false;
    }
    
    try {
        const pathParts = path.split('.');
        const pathPartsLength = pathParts.length;
        let current = object;
        
        for (let index = 0; index < pathPartsLength; index++) {
            const p = pathParts[index];
            const parsedP = parsePathPart(p, index);
            
            if (!current || typeof current !== 'object') {
                // console.warn(`[getValueAtPath] Value does not exist in object[${object ? JSON.stringify(object) : object}] at path[${path}] pathSegment['${p}'] pathIndex[${index}].`);
                return false;
            }
            
            if (Array.isArray(current) && !isArrayIndexer(p)) {
                throw new Error(`Invalid array index at pathSegment['${p}'] pathIndex[${index}] for array${current ? JSON.stringify(current) : current}!`);
            }
            
            if (!Array.isArray(current) && isArrayIndexer(p)) {
                throw new Error(`Expected array but found non-array object[${current ? JSON.stringify(current) : current}] at pathSegment['${p}'] pathIndex[${index}]!`);
            }
            
            if (index === pathPartsLength - 1) {
                if (!(parsedP in current)) {
                    // console.warn(`[delValueAtPath] pathSegment['${p}'] pathIndex[${index}] does not exist in object[${object ? JSON.stringify(object) : object}].`);
                    return false;
                }
                delete current[parsedP];
                return object;
            }
            
            current = current[parsedP];
        }
    } catch (error: any) {
        console.error(`[delValueAtPath] Error deleting value of object[${JSON.stringify(object)}] at path['${path}']: ${error.message}`);
    }
    
    return false;
};

export const delValueAtPaths = (object: any, paths: string[]): boolean => {
    return paths.some(path => delValueAtPath(object, path) !== false);
};

// export const testAtPath = (): void => {
//     console.log("");
//     console.log("---- getValueAtPath Tests ----")
//
//     console.log(`getValueAtPath: Test Result  1: ${JSON.stringify(getValueAtPath(undefined, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  2: ${JSON.stringify(getValueAtPath(null, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  3: ${JSON.stringify(getValueAtPath({}, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  4: ${JSON.stringify(getValueAtPath({duration_increment: undefined}, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  5: ${JSON.stringify(getValueAtPath({duration_increment: null}, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  6: ${JSON.stringify(getValueAtPath({duration_increment: 999}, 'duration_increment'))}`);
//     console.log(`getValueAtPath: Test Result  7: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: undefined}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`getValueAtPath: Test Result  8: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: null}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`getValueAtPath: Test Result  9: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: 999}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`getValueAtPath: Test Result 10: ${JSON.stringify(getValueAtPath(undefined, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 11: ${JSON.stringify(getValueAtPath(null, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 12: ${JSON.stringify(getValueAtPath({}, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 13: ${JSON.stringify(getValueAtPath({duration_increment: undefined}, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 14: ${JSON.stringify(getValueAtPath({duration_increment: null}, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 15: ${JSON.stringify(getValueAtPath({duration_increment: 999}, 'duration_increment', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 16: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: undefined}}}, 'Settings.DurationIncrement.Value', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 17: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: null}}}, 'Settings.DurationIncrement.Value', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 18: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: 999}}}, 'Settings.DurationIncrement.Value', "Test Default String"))}`);
//     console.log(`getValueAtPath: Test Result 19: ${JSON.stringify(getValueAtPath(undefined, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 20: ${JSON.stringify(getValueAtPath(null, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 21: ${JSON.stringify(getValueAtPath({}, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 22: ${JSON.stringify(getValueAtPath({duration_increment: undefined}, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 23: ${JSON.stringify(getValueAtPath({duration_increment: null}, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 24: ${JSON.stringify(getValueAtPath({duration_increment: 999}, 'duration_increment', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 25: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: undefined}}}, 'Settings.DurationIncrement.Value', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 26: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: null}}}, 'Settings.DurationIncrement.Value', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 27: ${JSON.stringify(getValueAtPath({Settings: {DurationIncrement: {Value: 999}}}, 'Settings.DurationIncrement.Value', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 28: ${JSON.stringify(getValueAtPath({Settings: {Array: { 1: 999}}}, 'Settings.Array.1', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 29: ${JSON.stringify(getValueAtPath({Settings: {Array: { 1: 999}}}, 'Settings.Array.[1]', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 30: ${JSON.stringify(getValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.1', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 31: ${JSON.stringify(getValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.[1]', {Test: {Default: "Value"}}))}`);
//     console.log(`getValueAtPath: Test Result 32: ${JSON.stringify(getValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.[abc]', {Test: {Default: "Value"}}))}`);
//
//     console.log("");
//     console.log("---- setValueAtPath Tests ----")
//
//     console.log(`setValueAtPath: Test Result  1: ${JSON.stringify(setValueAtPath(undefined, 'duration_increment', 999))}`);
//     console.log(`setValueAtPath: Test Result  2: ${JSON.stringify(setValueAtPath(null, 'duration_increment', 999))}`);
//     console.log(`setValueAtPath: Test Result  3: ${JSON.stringify(setValueAtPath({}, 'duration_increment', undefined))}`);
//     console.log(`setValueAtPath: Test Result  4: ${JSON.stringify(setValueAtPath({}, 'duration_increment', null))}`);
//     console.log(`setValueAtPath: Test Result  5: ${JSON.stringify(setValueAtPath({}, 'duration_increment', 999))}`);
//     console.log(`setValueAtPath: Test Result  6: ${JSON.stringify(setValueAtPath({}, 'Settings.DurationIncrement.Value', undefined))}`);
//     console.log(`setValueAtPath: Test Result  7: ${JSON.stringify(setValueAtPath({}, 'Settings.DurationIncrement.Value', null))}`);
//     console.log(`setValueAtPath: Test Result  8: ${JSON.stringify(setValueAtPath({}, 'Settings.DurationIncrement.Value', 999))}`);
//     console.log(`setValueAtPath: Test Result  9: ${JSON.stringify(setValueAtPath({}, 'Settings.Array.0', 999))}`);
//     console.log(`setValueAtPath: Test Result 10: ${JSON.stringify(setValueAtPath({}, 'Settings.Array.1', 999))}`);
//     console.log(`setValueAtPath: Test Result 11: ${JSON.stringify(setValueAtPath({}, 'Settings.Array.[1]', 888))}`);
//     console.log(`setValueAtPath: Test Result 12: ${JSON.stringify(setValueAtPath({}, 'Settings.Array.[abc]', 888))}`);
//
//     console.log("");
//     console.log("---- delValueAtPath Tests ----")
//
//     console.log(`delValueAtPath: Test Result  1: ${JSON.stringify(delValueAtPath(undefined, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  2: ${JSON.stringify(delValueAtPath(null, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  3: ${JSON.stringify(delValueAtPath({}, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  4: ${JSON.stringify(delValueAtPath({duration_increment: undefined}, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  5: ${JSON.stringify(delValueAtPath({duration_increment: null}, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  6: ${JSON.stringify(delValueAtPath({duration_increment: 999}, 'duration_increment'))}`);
//     console.log(`delValueAtPath: Test Result  7: ${JSON.stringify(delValueAtPath({Settings: {DurationIncrement: {Value: undefined}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`delValueAtPath: Test Result  8: ${JSON.stringify(delValueAtPath({Settings: {DurationIncrement: {Value: null}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`delValueAtPath: Test Result 10: ${JSON.stringify(delValueAtPath({Settings: {DurationIncrement: {Value: 999}}}, 'Settings.DurationIncrement.Value'))}`);
//     console.log(`delValueAtPath: Test Result 11: ${JSON.stringify(delValueAtPath({Settings: {Array: { 1: 999}}}, 'Settings.Array.1'))}`);
//     console.log(`delValueAtPath: Test Result 12: ${JSON.stringify(delValueAtPath({Settings: {Array: { 1: 999}}}, 'Settings.Array.[1]'))}`);
//     console.log(`delValueAtPath: Test Result 13: ${JSON.stringify(delValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.1'))}`);
//     console.log(`delValueAtPath: Test Result 14: ${JSON.stringify(delValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.[1]'))}`);
//     console.log(`delValueAtPath: Test Result 15: ${JSON.stringify(delValueAtPath({Settings: {Array: [999, 888, 777]}}, 'Settings.Array.[abc]'))}`);
//
//     console.log("");
// }
