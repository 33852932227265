import React from 'react';
import API from 'services/API.jsx';
import { useHistory } from 'react-router-dom';
import Page from '../components/Page';
import { withRouter } from 'react-router';

import cookie from 'react-cookies';

const Maintenance = ({}) => {
    return (
        <Page title="Maintence Page" showMenu={false}>
			<div className="gallery">
				<h3>KarunaCLOUD is currently under maintenance. Please try again later or contact a Karuna Admin.</h3>
				<br/>	<br/>
			</div>
		</Page>
    );
};

export default withRouter(Maintenance);


