import React, { JSX } from 'react';

import SingleInput from 'input/SingleInput';
import { payerEnums } from 'utility/OutcomeMeasureTypes.tsx';

// ---=== Utilities ===--- //

export const stringContainsName = (str: string, name: string): boolean => {
    return name.split(' ').some(part => str.toLowerCase().includes(part.toLowerCase()));
};

const cleanInput = (text: string) => {
    return `${text}${text.endsWith('.') ? '' : '.'}`;
};

const cleanInputs = (inputs: string[]) => {
    return inputs.map((text: string, i) => cleanInput(text));
};

// ---=== Plan ===--- //

export const validatePlan = (
    reportWeek: number,
    hasPlan: boolean,
    plan: string,
    assessment: string,
    social: boolean,
    socialText: string,
    physical: boolean,
    physicalText: string,
    behavioral: boolean,
    behavioralText: string,
    continuedNeed: boolean,
    continuedNeedText: string,
    summary: string,
    patientNameCheck: string,
    payerType: number,
    // Callbacks
    onErrors: ((errors: any) => void) | null = null
) => {
    
    let validationErrors = [];
    
    if (reportWeek === 0) {
        validationErrors.push('Cannot create reports during Coaching Plan Week 0.');
    }
    if (hasPlan === false) {
        validationErrors.push('Cannot create reports without active an Coaching Plan.');
    }
    
    if (validationErrors.length === 0) {
        if (plan.trim() === '') {
            validationErrors.push('Plan: Select a plan template.');
        }
        if (assessment.trim() === '') {
            validationErrors.push('Assessments: Select at least one assessment.');
        }
        if (social && socialText.length > 600) {
            validationErrors.push('Assessments: Social is above character limit.');
        }
        if (physical && physicalText.length > 900) {
            validationErrors.push('Assessments: Physical is above character limit.');
        }
        if (behavioral && behavioralText.length > 900) {
            validationErrors.push('Assessments: Behavioral is above character limit.');
        }
        if (continuedNeed && continuedNeedText.length > 900) {
            validationErrors.push('Assessments: Continued Needs is above character limit.');
        }
        if (summary.trim() === '') {
            validationErrors.push('Subjective Outcomes: Provide a Subjective Progress Summary.');
        }
        
        let [validatedGoals, goalErrors] = validateGoals();
        goalErrors && validationErrors.push(...goalErrors);
        
        let [validatedDiscoveries, discoveryErrors] = validateDiscoveries();
        discoveryErrors && validationErrors.push(...discoveryErrors);
        
        if (validationErrors.length === 0) {
            if (payerType === payerEnums.VA) {
                let nameWarnings = [];
                
                if (stringContainsName(plan, patientNameCheck)) {
                    nameWarnings.push('Final Plan');
                }
                if (stringContainsName(behavioralText, patientNameCheck)) {
                    nameWarnings.push('Assessments: Behavioral');
                }
                if (stringContainsName(physicalText, patientNameCheck)) {
                    nameWarnings.push('Assessments: Physical');
                }
                if (stringContainsName(socialText, patientNameCheck)) {
                    nameWarnings.push('Assessments: Social');
                }
                if (stringContainsName(summary, patientNameCheck)) {
                    nameWarnings.push('Summary');
                }
                if (goalsContainName(validatedGoals, patientNameCheck)) {
                    nameWarnings.push('Subjective Outcomes: Goals');
                }
                if (stringContainsName(validatedDiscoveries, patientNameCheck)) {
                    nameWarnings.push('Subjective Outcomes: Discoveries');
                }
                
                if (nameWarnings.length > 0) {
                    let text = `WARNING\n\nVA patients should have their names hidden in reports!\n\nPart or all of the patient name '${patientNameCheck}' was found in:\n\n${nameWarnings.join('\n')}\n\nAre you sure you wish to submit the report?`;
                    if (!window.confirm(text)) {
                        return;
                    }
                }
            }
            
            return true;
        }
    }
    
    onErrors && onErrors(validationErrors);
    return false;
};

// ---=== Graphs ===--- //

export const keyImprovementsGraphId = 'key-improvements-graph';

// ---=== Goals ===--- //

export var goalsHtml: JSX.Element[] = [];
let onGoalsRefresh: ((refreshCount: number) => void) | null = null;
let goalsRefreshCount = 0;

export const goalsContainName = (goals: {goal: string, detail: string}[], name: string) => {
    return goals.some(goal => {return stringContainsName(goal.goal, name) || stringContainsName(goal.detail, name);});
};

export const clearGoals = () => {
    goalsHtml = [];
    onGoalsRefresh && onGoalsRefresh(++goalsRefreshCount);
};

export const addGoal = (onRefresh: ((refreshCount: number) => void) | null = null) => {
    goalsHtml = goalsHtml.concat(
        [
            <SingleInput
                eraseFunction={removeGoal}
                id={`goal`}
                index={goalsHtml.length}
                multiInput={true}
                multiPlaceholder="Title (1-6 words)"
                placeholder={'Description (Progress and Action Steps)'}
                title="Goal"
            />
        ]
    );
    
    onGoalsRefresh = onRefresh;
    onGoalsRefresh && onGoalsRefresh(++goalsRefreshCount);
};

export const addGoalsManual = (goals: {goal: string, detail: string}[], onRefresh: ((refreshCount: number) => void) | null = null, revertOverride = false) => {
    if (!goals || goals.length === 0) {
        return;
    }
    
    let newGoalsHtml = [];
    goals.forEach(
        (goal, index: number) => {
            newGoalsHtml = newGoalsHtml.concat(
                [
                    <SingleInput
                        eraseFunction={removeGoal}
                        id={`goal`}
                        index={newGoalsHtml.length}
                        multiInput={true}
                        multiVal={goal[0]}
                        title="Goal"
                        val={goal[1]}
                    />
                ]
            );
        }
    );
    goalsHtml = newGoalsHtml;
    
    if (revertOverride) {
        const [goalValues, detailValues]: [string[], string[]] = goals.reduce((acc, item) => {
            acc[0].push(item.goal);
            acc[1].push(item.detail);
            return acc;
        }, [[], []]);
        
        setGoalInputs(detailValues);
        setGoalMultiInputs(goalValues);
    }
    
    onGoalsRefresh = onRefresh;
    onGoalsRefresh && onGoalsRefresh(++goalsRefreshCount);
};

export const removeGoal = (index: number) => {
    let goalInputs = getGoalInputs();
    let goalMultiInputs = getGoalMultiInputs();
    
    goalInputs.splice(index, 1);
    goalMultiInputs.splice(index, 1);
    
    let newGoalsHtml: JSX.Element[] = [];
    goalInputs.forEach(
        (goalInput, index) => {
            newGoalsHtml.push(
                <SingleInput
                    eraseFunction={removeGoal}
                    id={`goal`}
                    index={index}
                    multiInput={true}
                    multiPlaceholder={goalMultiInputs[index] === '' ? 'Title (1-6 words)' : goalMultiInputs[index]}
                    multiVal={goalMultiInputs[index] === '' ? null : goalMultiInputs[index]}
                    placeholder={goalInput === '' ? 'Description (Progress and Action Steps)' : goalInput}
                    title="Goal"
                    val={goalInput === '' ? null : goalInput}
                />
            );
        }
    );
    goalsHtml = newGoalsHtml;
    
    setGoalInputs(goalInputs);
    setGoalMultiInputs(goalMultiInputs);
    
    onGoalsRefresh && onGoalsRefresh(++goalsRefreshCount);
};

export const getGoalInputs = (): string[] => {
    return Array.from<HTMLInputElement>(document.querySelectorAll('[id^="goal-input"]')).map(el => el.value);
};

export const setGoalInputs = (goalInputs: string[]) => {
    document.querySelectorAll<HTMLInputElement>('[id^="goal-input"]')
            .forEach((el, i) => el.value = goalInputs[i]);
};

export const getGoalMultiInputs = (): string[] => {
    return Array.from<HTMLInputElement>(document.querySelectorAll('[id^="goal-multi"]')).map(el => el.value);
};

export const setGoalMultiInputs = (goalMultiInputs: string[]) => {
    document.querySelectorAll<HTMLInputElement>('[id^="goal-multi"]')
            .forEach((el, i) => el.value = goalMultiInputs[i]);
};

export const getGoals = (clean = true): {goal: string, detail: string}[] => {
    let goals: {goal: string, detail: string}[] = [];
    let goalInputs: string[] = getGoalInputs();
    let goalMultiInputs: string[] = getGoalMultiInputs();
    
    if (clean) {
        goalInputs = cleanInputs(goalInputs);
    }
    
    return goalMultiInputs.map((goalMultiInput, i) => ({ goal: goalMultiInput, detail: goalInputs[i] }));
};

export const validateGoals = (): [{goal: string, detail: string}[], string[]] => {
    let validatedGoals: {goal: string, detail: string}[] = [];
    let errors: string[] = [];
    let goalInputs: string[] = getGoalInputs();
    let goalMultiInputs: string[] = getGoalMultiInputs();
    
    goalMultiInputs
        .forEach((goalMultiInput: string, i) => {
            const cleanGoalInput = goalInputs[i].trim();
            const cleanMultiInput = goalMultiInput.trim();
            (cleanGoalInput && cleanMultiInput)
                ? validatedGoals.push({ goal: cleanMultiInput, detail: cleanInput(cleanGoalInput) })
                : errors.push(`Subjective Outcomes: Incomplete Goal ${i}. Please remove or complete.`);
        });
    
    return [validatedGoals, errors];
};

// ---=== Discoveries ===--- //

export var discoveriesHtml: JSX.Element[] = [];
let onDiscoveriesRefresh: ((refreshCount: number) => void) | null = null;
let discoveriesRefreshCount = 0;

export const clearDiscoveries = () => {
    discoveriesHtml = [];
    onDiscoveriesRefresh && onDiscoveriesRefresh(++discoveriesRefreshCount);
};

export const addDiscovery = (onRefresh: ((refreshCount: number) => void) | null = null) => {
    discoveriesHtml = discoveriesHtml.concat(
        [
            <SingleInput
                eraseFunction={removeDiscovery} title="Discovery"
                id={`discovery`}
                index={discoveriesHtml.length}
                placeholder="Description"
            />
        ]
    );
    
    onDiscoveriesRefresh = onRefresh;
    onDiscoveriesRefresh && onDiscoveriesRefresh(++discoveriesRefreshCount);
};

export const addDiscoveriesManual = (discoveries: string[], onRefresh: ((refreshCount: number) => void) | null = null, revertOverride = false) => {
    if (!discoveries || discoveries.length === 0) {
        return;
    }
    
    let newDiscoveriesHtml: JSX.Element[] = [];
    discoveries.forEach(
        (discovery: string, index: number) => {
            newDiscoveriesHtml = newDiscoveriesHtml.concat(
                [
                    <SingleInput
                        eraseFunction={removeDiscovery}
                        id={`discovery`}
                        index={newDiscoveriesHtml.length}
                        val={discovery}
                        title="Discovery"
                    />
                ]
            );
        }
    );
    discoveriesHtml = newDiscoveriesHtml;
    
    if (revertOverride) {
        setDiscoveryInputs(discoveries);
    }
    
    onDiscoveriesRefresh = onRefresh;
    onDiscoveriesRefresh && onDiscoveriesRefresh(++discoveriesRefreshCount);
};

export const removeDiscovery = (index: number) => {
    let discoveryInputs = getDiscoveryInputs();
    
    discoveryInputs.splice(index, 1);
    
    let newDiscoveriesHtml = [];
    discoveryInputs.forEach(
        (discoveryInput: string, index) => {
            newDiscoveriesHtml.push(
                <SingleInput
                    eraseFunction={removeDiscovery}
                    id={`discovery`}
                    index={index}
                    placeholder={discoveryInput === '' ? 'Description' : discoveryInput}
                    title="Discovery"
                    val={discoveryInput === '' ? null : discoveryInput}
                />
            );
        }
    );
    discoveriesHtml = newDiscoveriesHtml;
    
    setDiscoveryInputs(discoveryInputs);
    
    onDiscoveriesRefresh && onDiscoveriesRefresh(++discoveriesRefreshCount);
};

export const getDiscoveryInputs = (): string[] => {
    return Array.from<HTMLInputElement>(document.querySelectorAll('[id^="discovery-input"]'))
                .map(el => el.value);
};

export const setDiscoveryInputs = (discoveryInputs: string[]) => {
    document.querySelectorAll<HTMLInputElement>('[id^="discovery-input"]')
            .forEach((el, i) => el.value = discoveryInputs[i]);
};

export const getDiscoveries = (clean = true): string => {
    let discoveries = '';
    let discoveryInputs = getDiscoveryInputs();
    
    if (clean) {
        discoveryInputs = cleanInputs(discoveryInputs);
    }
    
    discoveryInputs.map((discoveryInput, i) => { discoveries += `${discoveryInput}\n\n`;});
    
    return discoveries;
};

export const validateDiscoveries = (): [string, string[]] => {
    let validatedDiscoveries = '';
    let errors: string[] = [];
    let discoveryInputs = getDiscoveryInputs();
    
    discoveryInputs
        .forEach((discoveryInput: string, i) =>
            discoveryInput.trim()
                ? validatedDiscoveries += `${cleanInput(discoveryInput)}\n\n`
                : errors.push(`Subjective Outcomes: Incomplete Discovery ${i}. Please remove or complete.`)
        );
    
    return [validatedDiscoveries, errors];
};