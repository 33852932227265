import React from 'react';

import { isTypeError, isTypeString } from "../../utility/TypeUtils.tsx";

type NotificationProps = {
    id: string;
    message: Object | Object[];
    containerClass?: string;
    iconClass?: string;
    iconSrc: string;
    buttonClass?: string;
    buttonIcon?: React.ReactNode;
    onClose?: (id: string) => void;
};

const Notification: React.FC<NotificationProps> = ({
    id,
    message,
    containerClass = '',
    iconClass = '',
    iconSrc,
    buttonClass = '',
    buttonIcon,
    onClose
}) => {
    
    const defaultButtonIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="fill-current w-5 h-5 text-gray-700"
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
        </svg>
    );
    
    const formatMessage = (message: Object | Object[]) => {
        if (isTypeString(message)) {
            return message;
        }
        else if (isTypeError(message)) {
            return message.stack.toString();
        }
        else {
            return message?.toString() || 'Unknown error';
        }
    };
    
    return (
        <div id={id} className={`inline-flex gap-2 max-w-full justify-end items-center rounded-md border ${containerClass}`}>
            <img className={`flex-shrink-0 m-2 ${iconClass}`} src={iconSrc} alt="icon"/>
            <div className="flex-grow truncate overflow-hidden text-sm font-normal">
                <pre>{formatMessage(message)}</pre>
            </div>
            {
                onClose && (
                    <button
                        className={`flex-shrink-0 m-2 ${buttonClass}`}
                        onClick={() => onClose(id)}
                    >
                        {buttonIcon || defaultButtonIcon}
                    </button>
                )
            }
        </div>
    );
};

export default Notification;