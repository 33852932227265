import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import Page from '../components/Page';
import API from 'services/API.jsx';
import cookie from 'react-cookies';
import Input from 'input/Input.jsx';
import Cookies from 'universal-cookie';
import { TailSpin } from 'react-loader-spinner';

const UserSettings = ({ history }) => {

    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(true);
    const [reset, setReset] = useState(false);
    const user = cookie.load('user') ? cookie.load('user') : null;

    useEffect(() => {
        if (reset) {
            window.location.reload();
        }
    }, [reset]);

    useEffect(() => {
        getData();
    }, []);

    const breadcrumbLinks = () => {
        if (user) {
            return [
                {
                    label: user.first_name && (user.first_name + ' ' + user.last_name),
                    url: `/user`
                }

            ];
        }
        else {
            return [
                {
                    label: 'user profile',
                    url: '/user'
                }

            ];
        }

    };
    const handleSubmit = (event) => {
        setLoading(true);
        event.preventDefault();

        const data = JSON.stringify({

            first_name: firstName,
            last_name: lastName
        });

        updateData(data);

    };

    const getData = () => {
        API.get(`/user/`)
           .then(response => {
               console.log(response);
               setFirstName(response.data.first_name);
               setLastName(response.data.last_name);
               setEmail(response.data.email);
               setLoading(false);

           })
           .catch(error => {
               console.error(error);
               setErrors(error);
               setLoading(false);

           });
    };

    const updateData = (data) => {
        const cookies = new Cookies(null, { path: '/' });
        const isSecure = window.location.protocol === 'https:';

        API.post(`/user/`, data)
           .then((response) => {

               user.first_name = firstName;
               user.last_name = lastName;
               cookies.set('user', user, { maxAge: 7200, path: '/', sameSite: isSecure ? 'none' : false, secure: isSecure });

               localStorage.setItem('success', 'Updated Patient Profile');

               setReset(true);

           })
           .catch(error => {
               console.error(error.response);
               setErrors(error.response && error.response.data);
               setLoading(false);

           });

    };

    const goToUser = () => {
        history.push(`/user/`);
    };

    const changePassword = () => {
        history.push('/password/change/');
    };

    return (
        !loading ?

            <Page errors={errors}
                headerObj={{ trail: breadcrumbLinks(), body: { subheader: 'Edit Profile', name: (user !== null ? user.first_name + ' ' + user.last_name : ''), buttons: [{ key: 'change-password', title: 'change password', click: changePassword, img: '/icons/passcode-lock.png' }] } }}
                clear={loading}
                internal={true}>

    <form className="  relative" onSubmit={handleSubmit}>
    <div className="w-full  p-6 flex-col justify-end items-start gap-5 inline-flex">
    <div className=" w-1/3 flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch  h-[164px] flex-col justify-start items-start gap-6 flex">
            <div className="grow shrink self-stretch  flex  gap-8 ">
                <div className=" grow shrink self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="self-stretch grow shrink h-[70px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-slate-700 text-sm font-medium  leading-tight">First Name</div>

                        <Input className="text-gray-500 text-base font-normal self-auto px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex" type="text"
                            name="First Name"
                            value={firstName ? firstName : ''}
                            onChange={setFirstName}
                            required
                            formErrors={errors}/>

                    </div>
                </div>
                <div className=" grow shrink self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="grow shrink self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                        <div className="text-slate-700 flex text-sm font-medium  leading-tight">Last Name</div>
                        <Input className="text-gray-500  text-base font-normal self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex" type="text"
                            name="Last Name"
                            value={lastName ? lastName : ''}
                            onChange={setLastName}
                            required
                            formErrors={errors}/>

                    </div>
                </div>
            </div>
            <div className=" grow shrink self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                <div className="self-stretch h-[70px] flex-col justify-start items-start gap-1.5 flex">
                    <div className="text-gray-700 text-sm font-medium  leading-tight">Email</div>

                    <Input className="text-gray-500 text-base font-normal self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex" type="text"
                        name="Email (optional)"
                        value={email}
                        onChange={setEmail}
                        validations={[{ type: 'email' }]}
                        readonly={true}
                        formErrors={errors}/>
                    <p className=" text-gray-600 whitespace-nowrap text-sm font-normal">Contact a Karuna Admin to change your account’s email address.</p>

                </div>
            </div>
        </div>
        <div className="self-stretch h-12 flex-col justify-start items-start gap-4 mt-5 flex">
            <button className="self-stretch px-[18px] py-3 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600  justify-center items-center gap-1.5 inline-flex">
                <div className="w-5 h-5 relative"/>
                <div className="px-0.5 justify-center items-center flex">
                    <div className="text-white text-base font-semibold  leading-normal">Save User</div>
                </div>
            </button>
        </div>
    </div>
</div>
</form>
</Page>

            :
            <Page errors={errors}
                headerObj={{ trail: breadcrumbLinks(), body: { subheader: 'Edit Profile', name: (user !== null ? user.first_name + ' ' + user.last_name : ''), buttons: [{ key: 'change-password', title: 'change password', click: changePassword, img: '/icons/passcode-lock.png' }] } }}
                clear={loading}
                internal={true}>
<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin
        height="100"
        width="100"
        color="grey"
        ariaLabel="loading"
    />
			  <br/> <br/>
			  <div/>
		  <h4 className="">Loading...</h4>
	</div>
    </Page>

    );
};

export default withRouter(UserSettings);
