import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import cookie from 'react-cookies';

import API from 'services/API.jsx';
import Input from 'input/Input.jsx';
import Page from '../components/Page';
import { renderLoader } from '../utility/LoaderUtils';

const Gallery = ({}) => {
    const [activeCount, setActiveCount] = useState(0);
    const [attentionCount, setAttentionCount] = useState(0);
    const [clearNotifications, setClearNotifications] = useState(false);
    const [errors, setErrors] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filteredpatients, setFilteredPatients] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [issues, setIssues] = useState(true);
    const [patients, setPatients] = useState(null);
    const [showInactive, setShowInactive] = useState(false);
    const [successes, setSuccesses] = useState(null);
    const [width, setWidth] = useState(null);
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    const tableRef = useRef(null);

    const batchPatients = async (next, retrieved, setCount = false) => {
        let nextPath = new URL(next).pathname;
        let queryParams = new URL(next).search;
        await API.get(`${nextPath}${queryParams}`)
                 .then(response => {
                     retrieved.push(...response.data.results);
                     if (response.data.next === null) {
                         setPatients(retrieved);
                         if (!setCount) {
                             setIsLoading(false);
                         }
                         else {
                             setActiveCount(retrieved.length);
                         }
                     }
                     else {
                         batchPatients(response.data.next, retrieved);
                     }
                 })
                 .catch(
                     error => {
                         setErrors(error);
                         setPatients([]);
                     }
                 );
    };

    const GetPatients = async () => {

        setIsLoading(true);

        let retrievedPatients = [];

        if (showInactive) {
            await API.get(`/patient_usage_stats/?ordering=first_name&limit=10&detail=true&exclude_visits_without_coaching_plans=true`)
                     .then(response => {
                         retrievedPatients.push(...response.data.results);

                         if (response.data.next === null) {
                             setPatients(retrievedPatients);
                             setIsLoading(false);
                         }
                         else {
                             batchPatients(response.data.next, retrievedPatients);
                         }

                     })
                     .catch(
                         error => {
                             setErrors(error);
                             setPatients([]);
                             setIsLoading(false);
                         }
                     );
        }
        else {
            await API.get(`/patient_usage_stats/?ordering=first_name&limit=10&&active=true&detail=true&exclude_visits_without_coaching_plans=true`)
                     .then(response => {
                         retrievedPatients.push(...response.data.results);
                         if (response.data.next === null) {
                             setPatients(retrievedPatients);
                             setActiveCount(retrievedPatients.length);

                         }
                         else {
                             batchPatients(response.data.next, retrievedPatients, true);
                         }

                     })
                     .catch(
                         error => {
                             setPatients([]);
                             setErrors(error);
                             setIsLoading(false);

                         }
                     );
            await API.get('/patient_alerts/').then(response => {
                         let data = response.data;
                         setIssues(data);
                         setAttentionCount(data.accounts_requiring_attention !== undefined ? data.accounts_requiring_attention : 0);
                         setIsLoading(false);
                     })
                     .catch(
                         error => {
                             setErrors(error);
                             setAttentionCount(0);
                             setIsLoading(false);
                         }
                     );
        }
    };

    useEffect(() => {
    }, [errors, successes, clearNotifications]);

    useEffect(() => {
        GetPatients();
    }, [showInactive]);

    useEffect(() => {
    }, [width]);

    useEffect(() => {

        if (tableRef.current !== null) {

            setWidth(tableRef.current.offsetWidth);

        }
    }, [tableRef.current, isLoading]);

    useEffect(() => {
        if (filter) {
            let filtered = [];
            let f = filter.trim();
            f = f.toLowerCase();
            filtered = patients.filter(p => {
                let name = p.first_name + ' ' + p.last_name;
                return name.toLowerCase().includes(f);
            });
            setFilteredPatients(filtered);
        }
        else {
            setFilteredPatients(patients);
        }

    }, [patients, filter]);

    const breadcrumbLinks = () => {

        return [
            {
                label: 'Patients',
                url: '/'
            }

        ];

    };

    let history = useHistory();

    const RenderState = (state, week, sync, trueWeek = null, hasPB) => {
        if (!sync && hasPB && state !== 'EXPIRED') {
            if (state === 'INACTIVE') {
                return (<div className="  pl-1.5 pr-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                    <div className="w-2 h-2 relative">
                        <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-gray-500 rounded-full"/>
                    </div>
                    <div className="text-center text-gray-700 text-xs font-medium  leading-[18px]">Inactive</div>
                </div>);
            }
            else {
                return (

                    <div className="has-tooltip relative  pl-1.5 pr-2 py-0.5 bg-error-100 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                        <span className="tooltip rounded shadow-lg p-1 ml-[36rem] [@media(max-width:1200px)]:ml-[30rem]   -mt-16  absolute">Coaching Plan has fallen behind the coaching program.<br/> Advance the plan to be 1 week ahead of the number of coaching sessions.</span>

                        <div className="w-2 h-2 relative">
                            <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full"/>
                        </div>
                        <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">Out of Sync</div>
                    </div>
                );
            }
        }
        else {
            switch (state) {
                case 'ACTIVE':
                    return (
                        <div className="  pl-1.5 pr-2 py-0.5 bg-emerald-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-emerald-500 rounded-full"/>
                            </div>
                            <div className="text-center text-emerald-700 text-xs font-medium  leading-[18px]">Active (Week {week !== null ? week.week : (trueWeek !== null ? trueWeek : 'not found')})</div>
                        </div>
                    );

                case 'DEFAULT':
                    return (
                        <div className="has-tooltip relative pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                            <span className="tooltip rounded shadow-lg p-1  ml-[36rem] [@media(max-width:1200px)]:ml-[30rem] -mt-16  absolute">Default Week 0 plan is still assigned.<br/> Create the Coaching Plan and advance to Week 1 after the patient\’s Set-Up.</span>

                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full"/>
                            </div>
                            <div className="text-center text-amber-700 text-xs font-medium  leading-[18px]">Not Started</div>
                        </div>
                    );

                case 'PENDING':
                    return (
                        <div className="   pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">

                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full"/>
                            </div>
                            <div className="text-center text-amber-700 text-xs font-medium  leading-[18px]">Not Started</div>
                        </div>
                    );
                    break;
                case 'LAPSED':
                    return (
                        <div className="  pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full"/>
                            </div>
                            <div className="text-center text-amber-700 text-xs font-medium  leading-[18px]">Paused</div>
                        </div>
                    );

                case 'EXPIRED':
                    return (
                        <div className=" has-tooltip relative pl-1.5 pr-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                            <span className="tooltip rounded shadow-lg p-1 ml-[40rem] [@media(max-width:1200px)]:ml-[24rem] -mt-16  absolute">Coaching Plan has been in its final week for too long.<br/> End the plan if the patient has completed the program, or add more weeks to the plan.</span>

                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-500 rounded-full"/>
                            </div>
                            <div className="text-center text-amber-700 text-xs font-medium  leading-[18px]">Not Ended</div>
                        </div>
                    );

                case 'INACTIVE':
                    return (<div className="  pl-1.5 pr-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                        <div className="w-2 h-2 relative">
                            <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-gray-500 rounded-full"/>
                        </div>
                        <div className="text-center text-gray-700 text-xs font-medium  leading-[18px]">Inactive</div>
                    </div>);
                default:
                    break;
            }
        }

    };

    const loadDefaultPatientPageForRole = (patientId) => {
        if (patientId) {
            let roleType = role.toLowerCase();
            if (roleType === 'coach') {
                history.push(`/patient/${patientId}/coaching-plan`);
            }
            else if (roleType === 'facilitator') {
                history.push(`/patient/${patientId}/reports`);
            }
            else {
                history.push(`/reportsExternal`);
            }
        }
    };

    const RenderTable = () => {
        let t = [];
        for (let patientId = 0; patientId < filteredpatients.length; patientId++) {
            let id = (filteredpatients[patientId].id);
            let week = (filteredpatients[patientId].plan_stats !== undefined && filteredpatients[patientId].plan_stats[Object.keys(filteredpatients[patientId].plan_stats).pop()].plan_weeks !== undefined ? {
                week: Object.keys(filteredpatients[patientId].plan_stats[Object.keys(filteredpatients[patientId].plan_stats).pop()].plan_weeks).pop(),
                weekData: filteredpatients[patientId].plan_stats[Object.keys(filteredpatients[patientId].plan_stats).pop()].plan_weeks[Object.keys(filteredpatients[patientId].plan_stats[Object.keys(filteredpatients[patientId].plan_stats).pop()].plan_weeks).pop()]
            } : null);

            let trueWeek = filteredpatients[patientId].coaching_plan_week !== undefined ? filteredpatients[patientId].coaching_plan_week : null;
            let name = filteredpatients[patientId].first_name.length < 15 ? (filteredpatients[patientId].first_name + ' ' + filteredpatients[patientId].last_name) : (filteredpatients[patientId].first_name + ' ' + filteredpatients[patientId].last_name[0]);
            let sync = filteredpatients[patientId].practice_better_synced;
            let hasPB = filteredpatients[patientId].practice_better_account;

            t.push(<React.Fragment>
                <tr key={`patients-${patientId}`} className="whitespace-nowrap border-y-2	 even:bg-white odd:bg-white border-gray-200 hover:bg-gray-100 hover:cursor-pointer" onClick={() => {
                    loadDefaultPatientPageForRole(id);
                }}>
                    {hasPB ? <td key={`patients-${patientId}-a`} className="whitespace-nowrap relative   gap-x-2	border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-900  ">
                        <div className="has-tooltip float-left mt-2  pr-2 whitespace-nowrap 	"><span className="tooltip rounded shadow-lg p-1 ml-4 -mt-6">Has link to Practice Better</span><img className="h-4 w-4 hs-tooltip " src={'/icons/link-green.svg'}></img></div>
                        {' ' + name}<br/><span className="text-gray-500">{' #' + id}</span></td> : <td key={`patients-${patientId}-a`} className="whitespace-nowrap relative   gap-x-2	border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-900  ">
                        <div className="has-tooltip float-left mt-2  pr-2 whitespace-nowrap 	"><span className="tooltip rounded shadow-lg p-1 ml-4 -mt-6">No link to Practice Better</span><img className="h-4 w-4 hs-tooltip " src={'/icons/link-broken-red.svg'}></img></div>
                        {' ' + name}<br/><span className="text-gray-500">{' #' + id}</span></td>}
                    <td key={`patients-${patientId}-b`} className="whitespace-nowrap border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{RenderState(filteredpatients[patientId].coaching_plan_state, week, sync, trueWeek, hasPB)}</td>
                    <td key={`patients-${patientId}-c`} className="whitespace-nowrap border-x-0	 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{filteredpatients[patientId].total_stats !== undefined ? filteredpatients[patientId].total_stats.unique_days : '\u2014'}</td>
                    <td key={`patients-${patientId}-d`}
                        className="whitespace-nowrap border-x-0	 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{filteredpatients[patientId].total_stats !== undefined ? filteredpatients[patientId].total_stats.time_spent > 0
                        ? `${(filteredpatients[patientId].total_stats.time_spent) < 60 ? moment.duration(filteredpatients[patientId].total_stats.time_spent * 1000).seconds() + ' s' : ''} ${moment.duration(filteredpatients[patientId].total_stats.time_spent * 1000).hours() > 0
                            ? (moment.duration(filteredpatients[patientId].total_stats.time_spent * 1000).hours() + ' hrs ')
                            : ''}${moment.duration(filteredpatients[patientId].total_stats.time_spent * 1000).minutes() ? moment.duration(filteredpatients[patientId].total_stats.time_spent * 1000).minutes() + ' m ' : ''}`
                        : '0 s' : '\u2014'}</td>
                    <td key={`patients-${patientId}-e`} className="whitespace-nowrap	border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{week !== null && week.weekData.unique_days !== undefined ? week.weekData.unique_days : '\u2014'}</td>
                    <td key={`patients-${patientId}-f`}
                        className="whitespace-nowrap	border-x-0  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{week !== null && week.weekData.time_spent !== undefined ? week.weekData.time_spent > 0 ? `${(week.weekData.time_spent) < 60
                        ? moment.duration(week.weekData.time_spent * 1000).seconds() + ' s'
                        : ''} ${moment.duration(week.weekData.time_spent * 1000).hours() > 0 ? (moment.duration(week.weekData.time_spent * 1000).hours() + ' hrs ') : ''}${moment.duration(week.weekData.time_spent * 1000).minutes()
                        ? moment.duration(week.weekData.time_spent * 1000).minutes() + ' m '
                        : ''}` : '0 s' : '\u2014'}</td>
                    <td key={`patients-${patientId}-g`} className="whitespace-nowrap	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{week !== null && week.weekData.personal_routine !== undefined ? week.weekData.personal_routine.completions : '\u2014'}</td>
                    <td key={`patients-${patientId}-h`} className="whitespace-nowrap	 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">{week !== null && week.weekData.bonus_activity !== undefined ? week.weekData.bonus_activity.completions : '\u2014'}</td>

                </tr>
            </React.Fragment>);
        }
        return t;

    };

    return (
        <Page errors={errors} successes={successes} clearNotifications={clearNotifications} headerObj={{ trail: breadcrumbLinks() }} internal={true}>
            <div className="w-full h-full justify-center items-start inline-flex mt-8">
                {
                    isLoading || !filteredpatients ?
                        (
                            renderLoader('Loading Patients')
                        )
                        :
                        (
                            <div className={' bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex ' + (filteredpatients.length > 0 ? (width < window.innerWidth ? 'w-[90%]' : 'w-[90%]') : 'w-[90%]')}>
                                <div className="w-full h-[10%] relative px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex">
                                    <div className="w-[50%] h-full  justify-start items-center gap-2 flex">
                                        <div className="text-gray-900 text-lg font-medium  leading-7">Your Patients</div>
                                        {activeCount > 0 && <div className="mix-blend-multiply justify-start items-start flex">
                                            <div className="px-2 py-0.5 bg-slate-50 rounded-2xl justify-center items-center flex">
                                                <div className="text-center text-blue-800 text-xs font-medium  leading-[18px]">{activeCount} active</div>
                                            </div>
                                        </div>}
                                        {attentionCount > 0 && <div className="mix-blend-multiply  justify-start items-start flex">
                                            <div className=" has-tooltip px-2 py-0.5 bg-amber-50 rounded-2xl justify-center items-center flex">
                                                <span className="tooltip rounded shadow-lg p-1 ml-40 mt-24 absolute ">
                                                    {
                                                        issues.expired_plans > 0 ? <React.Fragment key={'plans-1'}>Not Ended Plans: {issues.expired_plans}<br/></React.Fragment> : ''
                                                    }
                                                    {
                                                        issues.lapsed_plans > 0 ? <React.Fragment key={'plans-2'}>Lapsed Plans: {issues.lapsed_plans}<br/></React.Fragment> : ''
                                                    }
                                                    {
                                                        issues.practice_better_missing_accounts > 0 ? <React.Fragment key={'plans-3'}>Missing Practice Better Account: {issues.practice_better_missing_accounts}<br/></React.Fragment> : ''
                                                    }
                                                    {
                                                        issues.unsynchronized_plans > 0 ? <React.Fragment key={'plans-4'}>Unsynchronized Plans: {issues.unsynchronized_plans}<br/></React.Fragment> : ''
                                                    }
                                                    {
                                                        issues.week_zero_plans > 0 ? <React.Fragment key={'plans-5'}>Not Started Plans: {issues.week_zero_plans}<br/></React.Fragment> : ''
                                                    }
                                                </span>
                                                <div className="text-center text-amber-700 text-xs  font-medium  leading-[18px]">{attentionCount} require attention</div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="grow shrink w-[100%] basis-0 h-full  items-center gap-1 flex  ">
                                        <div className="justify-center items-center flex">
                                            <input
                                                className="w-4 h-4 p-0.5 bg-slate-50 rounded border border-blue-600 justify-center items-center flex"
                                                type="checkbox"
                                                checked={showInactive}
                                                onChange={() => {
                                                    setShowInactive(!showInactive);
                                                }}
                                            />
                                        </div>
                                        <div className="text-slate-700  grow shrink text-sm  w-[100%] leading-tight">Include Inactive Patients</div>
                                    </div>
                                    <div className="w-[30%] flex-col justify-start items-start inline-flex">
                                        <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
                                            <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
                                                <div className="self-stretch px-3.5 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                                                    <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                                                        <img className="w-6 h-6 relative" src="/icons/search.svg"/>
                                                        <Input className="  outline-none border-0 focus:outline-none	 active:border-0 focus:border-0 focus:ring-0 	overflow-hidden	w-full grow shrink basis-0 h-6 text-gray-500 text-base font-normal  leading-normal	"
                                                            type="text"
                                                            name="Search Patients"
                                                            onChange={setFilter}
                                                            required
                                                            formErrors={errors}
                                                            overrideText={'Search Patients'}
                                                            value={filter ? filter : ''}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filteredpatients && filteredpatients.length > 0 ?
                                        (
                                            <div className="grid grid-col w-full whitespace-nowrap  overflow-y-auto">
                                                <div className=" whitespace-nowrap w-full">
                                                    <div className="whitespace-nowrap w-full inline-block align-middle ">
                                                        <div className=" whitespace-nowrap w-full">
                                                            <table className="border-0 w-full table-auto" ref={tableRef} id="table">
                                                                <thead className="whitespace-nowrap w-full border-0 ">
                                                                <tr className="shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-50 whitespace-nowrap sticky top-0 border-x-0	">
                                                                    <th key={`f-1`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">Name</th>
                                                                    <th key={`f-2`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Plan Status</th>
                                                                    <th key={`f-3`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Total Days Used</th>
                                                                    <th key={`f-4`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Total Activity Time</th>
                                                                    <th key={`f-5`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Current Week<br/> Days Used</th>
                                                                    <th key={`f-6`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Current Week<br/> Activity Time</th>
                                                                    <th key={`f-7`} scope="col" className="whitespace-nowrap border-x-0 sticky top-0 	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Current Week<br/> Routine Completions</th>
                                                                    <th key={`f-8`} scope="col" className="whitespace-nowrap border-x-0	sticky top-0   px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Current Week<br/> Bonus Activities</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody className="whitespace-nowrap	">
                                                                    <React.Fragment>
                                                                        {
                                                                            filteredpatients && RenderTable()
                                                                        }
                                                                    </React.Fragment>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
                                                <p className="px-6 text-gray-900 text-lg font-medium ">No patients found</p>
                                            </div>
                                        )
                                }
                            </div>
                        )
                }
            </div>
        </Page>
    );
};

export default withRouter(Gallery);


