import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import Page from '../components/Page';
import API from 'services/API.jsx';
import Input from 'input/Input.jsx';
import Spacer from '../components/Spacer';
import cookie from 'react-cookies';

const ChangePassword = ({history}) => {

    const [password1, setPassword1] = useState(null);
    const [password2, setPassword2] = useState(null);

    const [changed, setChanged] = useState(false);
    const [errors, setErrors] = useState(null);
    const [clear, setClear] = useState(false);
    const user = cookie.load('user') ? cookie.load('user') : null;

    const checkErrors = () => {
        if (password1 === null || password2 === null || password1.length < 8 || password2.length < 8) {
            setErrors("password must be at least 8 characters long");
            return false;
        }

        if (password1 !== password2) {
            setErrors("Entered Passwords dont match");
            return false;
        }
        return true;

    };
    useEffect(() => {
        if (clear) {
            setClear(false);
        }

    }, [clear]);

    const handleSubmit = (event) => {
        setClear(true);
        event.preventDefault();
        setErrors(null);

        const data = JSON.stringify({
            new_password1: password1,
            new_password2: password2
        });

        if (!checkErrors()) {return;}

        API.post('password/change/', data)
            .then(response => {
                console.log(response);
                if (response.status !== 200) {
                    throw new Error(response.response.data.errors[0].message);
                    return;
                }
                setChanged(true);

            }).catch(error => {
            console.error(error);

            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors[0] && error.response.data.errors[0].message) {
                setErrors(error.response.data.errors[0].message);
            }
            else {
                console.log(error.message);

                setErrors(error.message && error.message);
            }
        });
    };

    const breadcrumbLinks = () => {

        if (user) {
            return [
                {
                    label: user.first_name && (user.first_name + " " + user.last_name),
                    url: `/user`
                },
                {
                    label: "Change Password",
                    url: "/password/change"
                }

            ];
        }
        else {
            return [
                {
                    label: "user profile",
                    url: "/user"
                },
                {
                    label: "change password",
                    url: "/password/change"
                }

            ];
        }

    };

    const goToUser = () => {
        history.push(`/user/`);
    };

    return (
        <Page errors={errors} headerObj={{trail: breadcrumbLinks, body: {subheader: "Change Password", name: (user !== null ? user.first_name + " " + user.last_name : ""), buttons: [{key: 'back', title: "back", click: goToUser, img: "/icons/flip-backward.svg"}]}}} internal={true} clear={clear}>
		{

            changed === false ?
                (
                    <div className="flex justify-center mt-24 h-full">
						<div className="w-96 bg-white h-96 flex-col justify-start items-center gap-8  inline-flex">
						  <div className="self-stretch h-28 flex-col justify-start items-center gap-6 flex">
							<div className="w-14 h-14 p-3.5 rounded-xl shadow border border-gray-50 justify-center items-center inline-flex">
							  <img src="/icons/lock-01.svg" className="w-7 h-7 relative flex-col justify-start items-start flex"/>
							</div>
							<div className="self-stretch h-9 flex-col justify-start items-start gap-3 flex">
							  <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-9">Set a password</div>
							</div>
						  </div>

						  <form className="w-full max-w-sm" onSubmit={handleSubmit}>
						  <div className="self-stretch h-56 rounded-xl flex-col justify-start items-center gap-6 flex">
							<div className="self-stretch h-40 flex-col justify-start items-start gap-5 flex">
							  <div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
								  <div className="text-gray-700 text-sm font-medium  leading-tight">Password</div>
								  	<Input name="Password" className={"self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex grow shrink basis-0 text-gray-500 text-base font-normal  leading-normal"} value={password1 ? password1 : ''} type="password" onChange={setPassword1} noLabel required formErrors={errors} validations={[{type: "minLength", value: 8}]}/>


								</div>
							  </div>
							  <div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
								<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
								  <div className="text-gray-700 text-sm font-medium  leading-tight">Confirm password</div>
								  <Input name="Password" className={"self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex grow shrink basis-0 text-gray-500 text-base font-normal  leading-normal"} value={password2 ? password2 : ''} type="password" onChange={setPassword2} noLabel required formErrors={errors} validations={[{type: "minLength", value: 8}]}/>

								</div>
							  </div>
							</div>
							<button className="self-stretch px-4 py-2.5 bg-blue-500 hover:bg-blue-600 rounded-lg shadow border-2 border-blue-600 justify-center items-center gap-1.5 inline-flex text-white text-base font-semibold  leading-normal" onClick={handleSubmit}>
								Set Password
							</button>
						  </div>
							</form>
						  <a href="/user">
						  <div className="justify-center items-center gap-1.5 inline-flex">
							<img src="/icons/arrow-left.svg" className="w-5 h-5 relative"/>
							<div className="text-gray-600 text-sm font-semibold  leading-tight">Go to profile</div>
						  </div>
						  </a>

						</div>
						</div>
                ) :
                (
                    <div className="h-screen flex items-center justify-center pb-12 ">

  <div className="w-96 h-52 flex-col justify-start items-center gap-8 inline-flex">
    <div className="self-stretch h-40 flex-col justify-start items-center gap-6 flex">
      <div className="w-14 h-14 p-3.5 rounded-xl shadow border border-gray-50 justify-center items-center inline-flex">
	  <img src="/icons/check-circle.svg" className="w-7 h-7 relative flex-col justify-start items-start flex"/>
      </div>
      <div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex">
        <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-9">Password Set</div>
        <div className="self-stretch text-center text-gray-600 text-base font-normal  leading-normal">Your password has been successfully changed</div>
      </div>
    </div>
	<a href="/user">
		<div className="justify-center items-center gap-1.5 inline-flex">
			<img src="/icons/arrow-left.svg" className="w-5 h-5 relative"/>
			<div className="text-gray-600 text-sm font-semibold  leading-tight">Go to profile</div>
		</div>
	</a>
  </div>
</div>
                )

        }
		</Page>

    );

};

export default withRouter(ChangePassword);
