import cookie from 'react-cookies';
import Cookies from 'universal-cookie';
import API from './API.jsx';

export const isLoggedIn = () => {
    let token = cookie.load('token');
    return !!token;
};

export const login = (email, password, onLoginSuccess, onLoginFail) => {
    const cookies = new Cookies(null, { path: '/' });
    console.log('Token: ' + cookie.load('token'));

    cookie.remove('token', { path: '/' });
    cookie.remove('csrftoken', { path: '/' });
    cookie.remove('role', { path: '/' });
    cookie.remove('user', { path: '/' });
    localStorage.clear();

    API.post(
           'login/',
           JSON.stringify(
               {
                   email: email,
                   password: password
               }
           )
       )
       .then(
           response => {

               if (!response || response.isAxiosError || !response.data || !response.status) {
                   onLoginFail && onLoginFail('Unable to connect to KarunaCLOUD. Please try again later.');
                   return;
               }

               if (response.status === 401 || !response.data.token || response.data.use || !response.data.user.role_type) {
                   onLoginFail && onLoginFail('Username or password is incorrect');
                   return;
               }

               const isSecure = window.location.protocol === 'https:';

               !isSecure && console.log('IS NOT SECURE');

               cookies.set(
                   'token',
                   response.data.key,
                   {
                       maxAge: 34560000,
                       path: '/',
                       sameSite: isSecure ? 'none' : false,
                       secure: isSecure
                   }
               );

               cookies.set(
                   'role',
                   response.data.user.role_type,
                   {
                       maxAge: 34560000,
                       path: '/',
                       sameSite: isSecure ? 'none' : false,
                       secure: isSecure
                   }
               );

               cookies.set(
                   'user',
                   response.data.user,
                   {
                       maxAge: 34560000,
                       path: '/',
                       sameSite: isSecure ? 'none' : false,
                       secure: isSecure
                   }
               );

               onLoginSuccess && onLoginSuccess(response.data);
           }
       )
       .catch(
           error => {
               console.log(cookie);
               console.error(error);
               cookie.remove('token');
               cookie.remove('csrftoken', { path: '/' });
               cookie.remove('role', { path: '/' });
               cookie.remove('user', { path: '/' });
               onLoginFail && onLoginFail(error.message);
           }
       );
};

export const logout = (onLogout) => {
    API.post(
           '/logout/'
       )
       .then(
           response => {
               console.log(response);
               cookie.remove('token', { path: '/' });
               cookie.remove('csrftoken', { path: '/' });
               cookie.remove('role', { path: '/' });
               cookie.remove('user', { path: '/' });
               localStorage.clear();
               onLogout && onLogout();
           }
       )
       .catch(
           error => {
               console.log('removing cookies');
               cookie.remove('token', { path: '/' });
               cookie.remove('csrftoken', { path: '/' });
               cookie.remove('role', { path: '/' });
               cookie.remove('user', { path: '/' });
               localStorage.clear();
               onLogout && onLogout();
           }
       );
};

export const api_Diagnostics = async (error, onDiagnosticsSuccess = null, onDiagnosticsFail = null) => {
    try {
        let user = cookie.load('user');
        let userInfo = user ? `${user.first_name} ${user.last_name} (${user.role_type}) ${user.email}` : 'Unauthenticated User';
        let payload = JSON.stringify(
            {
                message: `[KarunaPortal] ${JSON.stringify({
                    user: userInfo,
                    url: window.location.href,
                    error: error
                })}`
            }
        );

        await API.post(`/diagnostics/`, payload)
                 .then(
                     () => {
                         onDiagnosticsSuccess && onDiagnosticsSuccess();
                     }
                 )
                 .catch(
                     error => {
                         console.error(`[api_Diagnostics]: ${error}`);
                         onDiagnosticsFail && onDiagnosticsFail();
                     }
                 );
    }
    catch (e) {
        // We tried our best. Don't cause circular references!
    }
};
