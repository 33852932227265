import React from 'react';

const SessionBadgeRow = ({sessionData}) => {
    const timeConvert = (raw) => {
        let times = raw.split(':');
        let hours = times[0];
        let minutes = times[1];
        let secones = times[2];
        let AmOrPm = hours >= 12 ? 'pm' : 'am';
        hours = (hours % 12) || 12;
        let finalTime = hours + ":" + minutes + AmOrPm;
        return finalTime;

    };

    const GetBadgeText = (badge) => {
        if (badge.includes("1x")) {return "Bronze";}
        if (badge.includes("2x")) {return "Silver";}
        if (badge.includes("3x")) {return "Gold";}

    };

    const GetBadgeImage = (badge) => {
        if (badge.includes("1x")) {return "/icons/badges/Session_Bronze.png";}
        if (badge.includes("2x")) {return "/icons/badges/Session_Silver.png";}
        if (badge.includes("3x")) {return "/icons/badges/Session_Gold.png";}

    };

    return (
        <div className={"inline-flex h-fit  relative items-center justify-center w-full "}>

            {<div className=" pr-2.5 text-center text-gray-500 text-sm font-medium  leading-tight">{sessionData !== null && sessionData !== undefined && sessionData.time !== null && sessionData.time !== undefined ? timeConvert(sessionData.time) : "n/a"}</div>}

            <hr className="w-full h-0.5  bg-gray-200 border-0 rounded "/>
            <div className="absolute inline-flex self-stretch  gap-x-2  px-4 -translate-x-1/2 bg-white left-1/2 text-center text-slate-600 text-sm font-medium  leading-tight">
                <img className="w-[22.37px] h-[28px] -translate-y-1 float-start" src={GetBadgeImage(sessionData.badge)}/>
                {GetBadgeText(sessionData.badge) + " Session Badge Earned"}
            </div>
        </div>
    );
};

export default SessionBadgeRow;