import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import API from 'services/API.jsx';
import Input from 'input/Input.jsx';
import Page from '../components/Page';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const ReportReviewPage = ({ history }) => {

    const [errors, setErrors] = useState(null);
    const [filter, setFilter] = useState(null);
    const [filteredOutcomeReports, setFilteredOutcomeReports] = useState(null);
    const [loading, setLoading] = useState(true);
    const [outcomeReports, setOutcomeReports] = useState(null);
    const [patients, setPatients] = useState(null);
    const [unapproved, setUnapproved] = useState(0);
    const [width, setWidth] = useState(null);
    const tableRef = useRef(null);
    // let history = useHistory();

    const breadcrumbLinks = () => {
        return [
            {
                label: 'Reports',
                url: '/reports'
            }
        ];
    };

    useEffect(() => {
    }, [width]);

    useEffect(() => {
        if (tableRef.current !== null) {
            setWidth(tableRef.current.offsetWidth);
        }
    }, [loading]);

    useEffect(() => {
        API.get(`/patients/?pending_outcome_reports=${true}`)
           .then(response => {
               let p = {};
               response.data.results.forEach(e => {
                   p[e.id] = e;
               });
               setPatients(p);
               let ids = response.data.results.map(a => a.id);
               API.get(`/outcome_reports/?reviewable=true`)
                  .then(response => {
                      let reports = response.data.results;
                      setOutcomeReports(reports);
                      let unapprovedCount = 0;
                      reports.forEach(e => {
                          if (!e.approved) {
                              unapprovedCount++;
                          }
                      });
                      setUnapproved(unapprovedCount);
                      setLoading(false);
                  })
                  .catch(
                      error => {
                          setErrors(error);
                          console.error(error);
                          setOutcomeReports([]);
                      }
                  );
           })
           .catch(
               error => {
                   setErrors(error);
                   console.error(error);
                   setPatients([]);
               }
           );
    }, []);

    useEffect(() => {
        if (outcomeReports) {
            let reports = outcomeReports;

            if (filter) {
                let f = filter.trim();
                f = f.toLowerCase();
                reports = reports
                    .filter(r => {
                        return r.patient_name.toLowerCase().includes(f);
                    });
            }

            reports.sort(
                (reportA, reportB) => {
                    const dateA = moment(reportA.prepared_date);
                    const dateB = moment(reportB.prepared_date);
                    return dateA !== dateB ? dateB - dateA : reportB.plan_week - reportA.plan_week;
                }
            );

            setFilteredOutcomeReports(reports);
        }

    }, [outcomeReports, filter]);

    const loadOutcomeReport = (id) => {
        if (id) {
            history.push(`${id && `/outcomes/${id}`}`);
        }
    };

    const renderTable = () => {
        let t = [];
        for (let i = 0; i < filteredOutcomeReports.length; i++) {
            let reportId = (filteredOutcomeReports[i].id);
            let postProgramReport = filteredOutcomeReports[i].is_post_program_checkin !== undefined && filteredOutcomeReports[i].is_post_program_checkin === true;

            let name = filteredOutcomeReports[i].patient_name.split(' ')[0].length < 15 ? filteredOutcomeReports[i].patient_name : (filteredOutcomeReports[i].patient_name.split(' ')[0] + ' ' + filteredOutcomeReports[i].patient_name.split(' ')[1][0]);
            t.push(<React.Fragment>
                <tr className="whitespace-nowrap border-y-2 even:bg-white odd:bg-white border-gray-200">
                    <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px] ">
                        <button
                            className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex"
                            onClick={() => {
                                loadOutcomeReport(reportId);
                            }}
                        >
                            <img className="relative" src="/icons/file-search-02.svg" alt="file-search-02"/>
                            <div className="text-white text-sm font-semibold leading-tight">Review</div>
                        </button>
                    </td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-900 leading-[18px]">{filteredOutcomeReports[i].patient_name}</td>
                    <td className="whitespace-nowrap border-x-0 px-3 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]">{!postProgramReport ? 'Week ' + filteredOutcomeReports[i].included_weeks : '6 Months Post'}</td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 leading-[18px]">{patients[filteredOutcomeReports[i].patient_id] !== undefined ? patients[filteredOutcomeReports[i].patient_id].coach_name : '\u2014'}</td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 leading-[18px]">{patients[filteredOutcomeReports[i].patient_id] !== undefined ? patients[filteredOutcomeReports[i].patient_id].physician_name : '\u2014'}</td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 leading-[18px]">{patients[filteredOutcomeReports[i].patient_id] !== undefined ? patients[filteredOutcomeReports[i].patient_id].profile.third_party_administrators : '\u2014'}</td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 leading-[18px]">{patients[filteredOutcomeReports[i].patient_id] !== undefined ? patients[filteredOutcomeReports[i].patient_id].profile.insurers : '\u2014'}</td>
                    <td className="whitespace-nowrap border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500 leading-[18px]">{moment(filteredOutcomeReports[i].prepared_date).format('MMM Do, YYYY')}</td>
                </tr>
            </React.Fragment>);
        }

        return t;
    };

    return (
        <Page errors={errors} headerObj={{ trail: breadcrumbLinks() }} internal={true}>
            <div className="w-full h-full justify-center items-start inline-flex mt-8">
                {
                    loading || !filteredOutcomeReports ?
                        (
                            renderLoader()
                        )
                        :
                        (
                            <div className={' bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex ' + (filteredOutcomeReports.length > 0 ? (width < window.innerWidth ? 'w-[90%]' : 'w-[90%]') : 'w-[90%]')}>
                                <div className="w-full h-[10%] px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex">
                                    <div className="w-[50%] h-full justify-start items-center gap-2 flex">
                                        <div className="text-gray-900 text-lg font-medium  leading-7">Reports for Review</div>
                                        {
                                            unapproved > 0 &&
                                            <div className="mix-blend-multiply justify-start items-start flex">
                                                <div className="px-2 py-0.5 bg-slate-50 rounded-2xl justify-center items-center flex">
                                                    <div className="text-center text-blue-800 text-xs font-medium  leading-[18px]">{unapproved}</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="grow shrink basis-0 h-5 px-[25px] justify-end items-center gap-2 flex"/>
                                    <div className="w-[25%] flex-col justify-start items-start inline-flex">
                                        <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
                                            <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
                                                <div className="self-stretch px-3.5 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                                                    <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                                                        <img className="w-6 h-6 relative" src="/icons/search.svg"/>
                                                        <Input
                                                            className=" outline-none border-0 focus:outline-none	 active:border-0 focus:border-0 focus:ring-0 	overflow-hidden	w-full grow shrink basis-0 h-6 text-gray-500 text-base font-normal  leading-normal	"
                                                            type="text"
                                                            name="Search by name"
                                                            onChange={setFilter}
                                                            value={filter ? filter : ''}
                                                            required
                                                            formErrors={errors}
                                                            overrideText={'Search by name'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    filteredOutcomeReports.length > 0 ?
                                        (
                                            <div className="grid grid-col w-full whitespace-nowrap overflow-y-auto	">
                                                <div className=" whitespace-nowrap w-full	">
                                                    <div className="whitespace-nowrap w-full	inline-block align-middle ">
                                                        <div className=" whitespace-nowrap	w-full">
                                                            <table className=" divide-gray-200 w-full dark:divide-gray-700 " ref={tableRef}>
                                                                <thead className="whitespace-nowrap w-full	">
                                                                <tr className="shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-50 border-gray-200 whitespace-nowrap sticky top-0 z-[2]">
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Actions</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Patient</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-3 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Report Week</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Coach</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Physician</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">TPA</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Insurer</th>
                                                                    <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Created</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody className="whitespace-nowrap	">
                                                                <React.Fragment>
                                                                    {
                                                                        renderTable()
                                                                    }
                                                                </React.Fragment>
                                                                </tbody>

                                                            </table>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className=" w-full h-full justify-center items-center inline-flex flex-col">
                                                <p className="px-6 text-gray-900 text-lg font-medium ">No reports found</p>
                                            </div>
                                        )
                                }
                            </div>
                        )
                }
            </div>
        </Page>
    );
};

export default withRouter(ReportReviewPage);
