import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import cookie from 'react-cookies';
import moment from 'moment';

import API from 'services/API';
import CustomDropDown from 'input/CustomDropDown';
import Page from 'components/Page';
import ReviewSectionGenerator from '../components/compositions/OutcomePagesCompositions/ReviewSectionGenerator.tsx';
import SectionDivider from '../components/compositions/OutcomePagesCompositions/SectionDivider.tsx';
import { GetIndicator, getTooltipText, UsePercentage, LowerIsBetter, createMeasurementImprovement, getMeasurementDelta } from 'utility/OutcomeMeasureUtils.js';
import { KarunaCLOUD_responseValidator } from '../services/KarunaCloudServices';
import {
    addDiscoveriesManual, addDiscovery, addGoal, addGoalsManual, clearDiscoveries, clearGoals, getDiscoveries, getGoals, validatePlan,
    discoveriesHtml, goalsHtml, keyImprovementsGraphId
} from 'utility/OutcomeReportUtils';
import { api_Diagnostics, logout } from 'services/AccountServices.jsx';
import { bestRomMeasurement, isRomType, normalizedRomImprovementPercent } from 'utility/OutcomeMeasureRomUtils.js';
import { notifications, NotificationTypes, OutcomeMeasureTypes, payerEnums, templateWeekOptions } from 'utility/OutcomeMeasureTypes.js';
import { renderLoader } from 'utility/LoaderUtils.jsx';

/////////////////////
//  CHARTING TOOLS  //
//////////////////////

// noinspection ES6UnusedImports
import { Chart as ChartJS } from 'chart.js/auto'; // DO NOT REMOVE! Although the 'chart.js/auto' imports appear unused, they are required for use by react-chartjs-2.
import { Line } from 'react-chartjs-2';

//////////////////////////////////
//  REPORT PDF RENDERING TOOLS  //
//////////////////////////////////

import jsPDF from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
applyPlugin(jsPDF);

import { font1 } from 'fonts/Inter-normal.jsx';
import { font2 } from 'fonts/Inter-bold.jsx';
import { AxiosError } from 'axios';

const ReportVerification = ({ history }) => {

    // ---=== React State ===--- //

    // const [idleTime, setIdleTime] = useState(0);
    const [DOB, setDOB] = useState('');
    const [HistoryInjury, setHistoryInjury] = useState('');
    const [HistoryInjuryOriginal, setHistoryInjuryOriginal] = useState('');
    const [ImprovementsComplete, setImprovementsComplete] = useState([]);
    const [assessment, setAssessment] = useState('');
    const [changedWeek, setChangedWeek] = useState(0);
    const [coach, setCoach] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [data, setData] = useState(null);
    const [discoveries, setDiscoveries] = useState([]);
    const [discoveriesOriginal, setDiscoveriesOriginal] = useState([]);
    const [discoveriesRefresh, onDiscoveriesRefresh] = useState(null);
    const [errors, setErrors] = useState(null);
    const [generate, setGenerate] = useState(false);
    const [goals, setGoals] = useState([]);
    const [goalsOriginal, setGoalsOriginal] = useState([]);
    const [goalsRefresh, onGoalsRefresh] = useState(null);
    const [hasPlan, setHasPlan] = useState(true);
    const [headerInfo, setHeaderInfo] = useState(null);
    const [improvementToggle, setImprovementToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedVerification, SetLoadedVerification] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Retrieving Outcome Report');
    const [manual, SetManual] = useState(false);
    const [oversight, setOversight] = useState(false);
    const [patientId, setPatientId] = useState(null);
    const [patientName, setPatientName] = useState(null);
    const [patientNameCheck, setPatientNameCheck] = useState(null);
    const [payerType, setPayerType] = useState(payerEnums.KarunaHome);
    const [physician, setPhysician] = useState('');
    const [plan, setPlan] = useState('');
    const [previewPDF, setPreviewPDF] = useState(false);
    const [program, setProgram] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [reportWeek, setReportWeek] = useState(0);
    const [saveReport, setSaveReport] = useState(false);
    const [saveReportDraft, setSaveReportDraft] = useState(false);
    const [selectedImprovements, setSelectedImprovements] = useState([]);
    const [successes, setSuccesses] = useState(null);
    const [summary, setSummary] = useState('');
    const [summaryOriginal, setSummaryOriginal] = useState('');
    const [templateNotification, setTemplateNotification] = useState(NotificationTypes.PsychotherapyReferral);
    const [templateWeeks, setTemplateWeeks] = useState('1');
    const [upload, setUpload] = useState(false);

    //#region checkboxes
    const [DOI, setDOI] = useState('');
    const [Dash, setDash] = useState(false);
    const [FABQActivity, setFABQActivity] = useState(false);
    const [FABQTotal, setFABQTotal] = useState(false);
    const [FABQWork, setFABQWork] = useState(false);
    const [GAD7, setGAD7] = useState(false);
    const [KarunaRating, setKarunaRating] = useState(false);
    const [NDI, setNDI] = useState(false);
    const [PCS, setPCS] = useState(false);
    const [PCSS, setPCSS] = useState(false);
    const [PHQ9, setPHQ9] = useState(false);
    const [PSEQ, setPSEQ] = useState(false);
    const [UEFI, setUEFI] = useState(false);
    const [WHODAS, setWHODAS] = useState(false);
    const [activity, setActivity] = useState(false);
    const [averageTime, setAverageTime] = useState(false);
    const [behavioral, setBehavioral] = useState(true);
    const [claimNumber, setClaimNumber] = useState('');
    const [confidence, setConfidence] = useState(false);
    const [continueProgram, setContinueProgram] = useState(false);
    const [continuedNeed, setContinuedNeed] = useState(true);
    const [engagementLevel, setEngagementLevel] = useState(false);
    const [extension, setExtension] = useState(false);
    const [goalProgress, setGoalProgress] = useState(false);
    const [leftAbduction, setLeftAbduction] = useState(false);
    const [leftFlexion, setLeftFlexion] = useState(false);
    const [leftScaption, setLeftScaption] = useState(false);
    const [lowerFlexion, setLowerFlexion] = useState(false);
    const [medReduction, setMedReduction] = useState(false);
    const [mood, setMood] = useState(false);
    const [notification, setNotification] = useState(false);
    const [oswestry, setOwestry] = useState(false);
    const [pain, setPain] = useState(false);
    const [physical, setPhysical] = useState(true);
    const [planEnd, setPlanEnd] = useState(false);
    const [prepareDate, setPrepareDate] = useState('');
    const [preparer, setPreparer] = useState('');
    const [returnToWork, setReturnToWork] = useState(false);
    const [revertBehavioral, setRevertBehavioral] = useState(false);
    const [revertDiscovery, setRevertDiscovery] = useState(false);
    const [revertGoal, setRevertGoal] = useState(false);
    const [revertHistoryInjury, setRevertHistoryInjury] = useState(false);
    const [revertPhysical, setRevertPhysical] = useState(false);
    const [revertSocial, setRevertSocial] = useState(false);
    const [revertSummary, setRevertSummary] = useState(false);
    const [rightAbduction, setRightAbduction] = useState(false);
    const [rightFlexion, setRightFlexion] = useState(false);
    const [rightScaption, setRightScaption] = useState(false);
    const [sessionsAttended, setSessionsAttended] = useState(false);
    const [sleep, setSleep] = useState(false);
    const [social, setSocial] = useState(true);
    const [uniqueDays, setUniqueDays] = useState(false);
    const [useCheck, setUseCheck] = useState(null);
    //#endregion checkboxes

    //#region raw data
    const [RawAverageTimeData, setRawAverageTimeData] = useState(null);
    const [RawSessionsAttendedData, setRawSessionsAttendedData] = useState(null);
    const [RawUniqueDaysData, setRawUniqueDaysData] = useState(null);
    const [rawEngagementLevelData, setRawEngagementLevelData] = useState(null);
    const [rawGoalProgressData, setRawGoalProgressData] = useState(null);
    //#endregion raw data

    //#region formatted data
    const [DashData, setDashData] = useState([]);
    const [FABQActivityData, setFABQActivityData] = useState([]);
    const [FABQTotalData, setFABQTotalData] = useState([]);
    const [FABQWorkData, setFABQWorkData] = useState([]);
    const [GAD7Data, setGAD7Data] = useState([]);
    const [KarunaRatingData, setKarunaRatingData] = useState([]);
    const [NDIData, setNDIData] = useState([]);
    const [PCSData, setPCSData] = useState([]);
    const [PCSSData, setPCSSData] = useState([]);
    const [PHQ9Data, setPHQ9Data] = useState([]);
    const [PSEQData, setPSEQData] = useState([]);
    const [UEFIData, setUEFIData] = useState([]);
    const [WHODASData, setWHODASData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [behavioralText, setBehavioralText] = useState('');
    const [behavioralTextOriginal, setBehavioralTextOriginal] = useState('');
    const [confidenceData, setConfidenceData] = useState([]);
    const [continuedNeedText, setContinuedNeedText] = useState('');
    const [leftAbductionData, setLeftAbductionData] = useState([]);
    const [leftFlexionData, setLeftFlexionData] = useState([]);
    const [leftScaptionData, setLeftScaptionData] = useState([]);
    const [lowerFlexionData, setLowerFlexionData] = useState([]);
    const [medReductionData, setMedReductionData] = useState(null);
    const [moodData, setMoodData] = useState([]);
    const [oswestryData, setOwestryData] = useState([]);
    const [painData, setPainData] = useState([]);
    const [physicalText, setPhysicalText] = useState('');
    const [physicalTextOriginal, setPhysicalTextOriginal] = useState('');
    const [returnToWorkData, setReturnToWorkData] = useState(null);
    const [rightAbductionData, setRightAbductionData] = useState([]);
    const [rightFlexionData, setRightFlexionData] = useState([]);
    const [rightScaptionData, setRightScaptionData] = useState([]);
    const [sleepData, setSleepData] = useState([]);
    const [socialText, setSocialText] = useState('');
    const [socialTextOriginal, setSocialTextOriginal] = useState('');
    //#endregion formatted data

    //#region improvement
    const [DashImp, setDashImp] = useState(null);
    const [FABQActivityImp, setFABQActivityImp] = useState(null);
    const [FABQTotalImp, setFABQTotalImp] = useState(null);
    const [FABQWorkImp, setFABQWorkImp] = useState(null);
    const [GAD7Imp, setGAD7Imp] = useState(null);
    const [KarunaRatingImp, setKarunaRatingImp] = useState(null);
    const [NDIImp, setNDIImp] = useState(null);
    const [PCSImp, setPCSImp] = useState(null);
    const [PCSSImp, setPCSSImp] = useState(null);
    const [PHQ9Imp, setPHQ9Imp] = useState(null);
    const [PSEQImp, setPSEQImp] = useState(null);
    const [UEFIImp, setUEFIImp] = useState(null);
    const [WHODASImp, setWHODASImp] = useState(null);
    const [activityImp, setActivityImp] = useState(null);
    const [confidenceImp, setConfidenceImp] = useState(null);
    const [leftAbductionImp, setLeftAbductionImp] = useState([]);
    const [leftFlexionImp, setLeftFlexionImp] = useState([]);
    const [leftScaptionImp, setLeftScaptionImp] = useState([]);
    const [lowerFlexionImp, setLowerFlexionImp] = useState([]);
    const [medImp, setMedImp] = useState(null);
    const [moodImp, setMoodImp] = useState(null);
    const [oswestryImp, setOwestryImp] = useState(null);
    const [painImp, setPainImp] = useState(null);
    const [rightAbductionImp, setRightAbductionImp] = useState([]);
    const [rightFlexionImp, setRightFlexionImp] = useState([]);
    const [rightScaptionImp, setRightScaptionImp] = useState([]);
    const [sleepImp, setSleepImp] = useState(null);
    //#endregion improvement

    const location = window.location;
    const reportId = useParams().outcomeId;
    const user = cookie.load('user');

    // ---=== Constants ===--- //

    const colors = ['#17B26A', '#F79009', '#488CE6', '#67E3F9', '#EE46BC'];

    const custom_canvas_background_color = {
        id: 'custom_canvas_background_color',
        beforeDraw: (chart, args, options) => {
            const {
                ctx,
                chartArea: { top, right, bottom, left, width, height },
                scales: { x, y }
            } = chart;
            ctx.save();
            ctx.fillStyle = 'rgba(0,255,0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(100), width, y.getPixelForValue(75) - y.getPixelForValue(100));
            ctx.fillStyle = 'rgba(255, 165, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(75), width, y.getPixelForValue(50) - y.getPixelForValue(75));
            ctx.fillStyle = 'rgba(255, 165, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(50), width, y.getPixelForValue(25) - y.getPixelForValue(50));
            ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
            ctx.fillRect(left, y.getPixelForValue(25), width, y.getPixelForValue(0) - y.getPixelForValue(25));
            ctx.restore();
        }
    };

    // ---=== Utilities ===--- //

    const goToHome = () => {
        history.push('/');
    };

    const medicationReductionCheck = (ar) => {
        let length = ar.length;
        if (length <= 1) {
            return true;
        }
        let result = true;
        let ar2 = ar.slice(0, length - 1);
        ar2.forEach(e => {
            if (parseInt(e.value) !== 0) {
                result = false;
            }
        });
        return result;
    };

    const medicationReductionCheckFull = (ar) => {
        let length = ar.length;
        if (length <= 1) {
            return true;
        }
        let result = true;
        let ar2 = ar.slice(0, length);
        ar2.forEach(e => {
            if (parseInt(e.value) !== 0) {
                result = false;
            }
        });
        return result;
    };

    const getMedicationReductionResult = (ar) => {
        let medRed;

        if (ar.slice(-1)[0].value >= 2 || medicationReductionCheckFull(ar)) {
            medRed = 'No';
        }
        else {
            if (ar.slice(-1)[0].value <= 1 && !medicationReductionCheck(ar)) {
                medRed = 'Yes';
            }
            else {
                medRed = 'No';
            }
        }
        return medRed;
    };

    // ---=== Session Timeout Timer ===--- //

    // const updateTimer = () => {
    //     const timer = setTimeout(() => {
    //         if (idleTime > 3600) {
    //             console.log('timed out');
    //             handleLogout();
    //         }
    //         else {
    //             setIdleTime(idleTime + 1);
    //         }
    //     }, 1000);
    //
    //     return () => clearTimeout(timer);
    // };
    //
    // const resetTimer = () => {
    //     setIdleTime(0);
    // };
    //
    // useEffect(() => {
    //     window.onload = resetTimer;
    //     document.onmousemove = resetTimer;
    //     document.onload = resetTimer;
    //     document.onmousedown = resetTimer;
    //     document.ontouchstart = resetTimer;
    //     document.onclick = resetTimer;
    //     document.onkeydown = resetTimer;
    //     document.addEventListener('scroll', resetTimer, true);
    //     return updateTimer();
    // }, [idleTime]);

    // ---=== Logout Handler ===--- //

    const handleLogout = async (event) => {
        data.plan = plan;
        data.included_weeks = reportWeek !== 'M6' ? reportWeek : currentWeek;

        data.enabled = {};
        data.enabled.goalProgress = goalProgress;
        data.enabled.engagmentLevel = engagementLevel;
        data.enabled.sessionsAttended = sessionsAttended;
        data.enabled.uniqueDays = uniqueDays;
        data.enabled.averageTime = averageTime;
        data.enabled.leftFlexion = leftFlexion;
        data.enabled.leftScaption = leftScaption;
        data.enabled.leftAbduction = leftAbduction;
        data.enabled.rightFlexion = rightFlexion;
        data.enabled.rightScaption = rightScaption;
        data.enabled.rightAbduction = rightAbduction;
        data.enabled.lowerFlexoion = lowerFlexion;
        data.enabled.pain = pain;
        data.enabled.mood = mood;
        data.enabled.activity = activity;
        data.enabled.sleep = sleep;
        data.enabled.confidence = confidence;
        data.enabled.returnToWork = returnToWork;
        data.enabled.medicationReduction = medReduction;
        data.enabled.oswestry = oswestry;
        data.enabled.Dash = Dash;
        data.enabled.PSEQ = PSEQ;
        data.enabled.PCS = PCS;
        data.enabled.UEFI = UEFI;
        data.enabled.WHODAS = WHODAS;
        data.enabled.NDI = NDI;
        data.enabled.GAD7 = GAD7;
        data.enabled.PHQ9 = PHQ9;
        data.enabled.KarunaRating = KarunaRating;
        data.enabled.social = social;
        data.enabled.physical = physical;
        data.enabled.behavioral = behavioral;
        data.enabled.FABQTotal = FABQTotal;
        data.enabled.FABQActivity = FABQActivity;
        data.enabled.FABQWork = FABQWork;

        data.customizations = {};
        data.customizations.customSummary = summary;
        data.customizations.customGoals = getGoals(true).map(g => [g.goal, g.detail]);
        data.customizations.customDiscovery = getDiscoveries(true).split('\n\n').filter(d => {return d !== '';});
        data.customizations.customSocial = socialText;
        data.customizations.customPhysical = physicalText;
        data.customizations.customBehavioral = behavioralText;
        data.customizations.customContinuedNeed = continuedNeedText;
        data.customizations.customHistoryInjury = HistoryInjury;
        data.customizations.selectedImp = selectedImprovements;

        await API
            .put(
                `/outcome_reports/${reportId}/`,
                { report_json_data: data }
            )
            .then(
                response => {
                    KarunaCLOUD_responseValidator(response, setErrors);
                }
            )
            .catch(
                error => {
                    console.error(error);
                    setErrors(error.message);
                }
            )
            .finally(
                () => {
                    setIsLoading(false);
                    logout(onLogout);
                }
            );
    };

    const onLogout = () => {
        cookie.remove('token', { path: '/' });
        cookie.remove('csrftoken', { path: '/' });
        cookie.remove('role', { path: '/' });
        cookie.remove('user', { path: '/' });
        localStorage.clear();
        history.push('/login');
    };

    // ---=== Report Generation ===--- //

    function padTo2Digits (num) {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
            date.getFullYear()
        ].join('/');
    };

    const getGraphCanvas = () => {
        let graphElement;
        let graphCanvas;
        let errorReasons = [];

        try {
            graphElement = document.getElementById(keyImprovementsGraphId);
            if (!graphElement) {
                errorReasons.push(`No HTML element with id=${keyImprovementsGraphId} found.`);
            }
            else {
                graphCanvas = graphElement.toDataURL('image/png');
                if (!graphCanvas) {
                    errorReasons.push(`Unable to create image canvas for HTML element with id=${keyImprovementsGraphId}.`);
                }
                else {
                    return graphCanvas;
                }
            }
        }
        catch (e) {
            errorReasons.push(e.message);
        }

        throw new Error(`Cannot render key improvements graph in this Outcome Report! [${errorReasons.join(', ')}]`);
    };

    const generateProviderSection = (doc) => {
        var {
            ComboBox,
            ListBox,
            CheckBox,
            PushButton,
            TextField,
            PasswordField,
            RadioButton,
            Appearance
        } = jsPDF.AcroForm;

        var pageWidth = doc.internal.pageSize.width;
        let y = 30;

        doc.addPage();
        doc.setFont('Inter', 'normal');
        doc.setFontSize(12);
        doc.setFillColor(234, 236, 240);
        doc.setDrawColor(234, 236, 240);
        doc.setTextColor(71, 84, 103);

        let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Physician Review`) * doc.internal.getFontSize() / 2);

        doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (52), y);
        doc.line(((doc.internal.pageSize.width / 2)) + (52), y, (pageWidth - 40), y);
        doc.text(`Physician Review`, xOffset, y + 3);
        doc.setFont('Inter', 'normal');
        doc.setFontSize(8);
        doc.setTextColor(71, 84, 103);
        xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`For provider use only`) * doc.internal.getFontSize() / 2);
        y += 25;

        doc.text(`For provider use only`, xOffset, y);
        y += 15;
        doc.setFillColor(207, 212, 220);
        doc.setDrawColor(207, 212, 220);
        doc.setLineWidth(1);

        var checkBox = new CheckBox();
        checkBox.fieldName = 'CheckBox1';
        checkBox.Rect = [40, y += 5, 10, 10];
        checkBox.width = 10;
        checkBox.height = 10;
        checkBox.fontSize = 10;
        checkBox.appearanceState = 'Off';
        checkBox.maxFontSize = 10;
        doc.addField(checkBox);
        doc.setFillColor('Black');
        doc.rect(40, y, 10, 10);

        doc.setTextColor(52, 64, 83);
        doc.setFontSize(10);

        doc.autoTable({
            pageBreak: 'avoid',

            showHead: 'firstPage',
            body: [[`Having reviewed the subjective and objective outcomes of ${patientName}, I concur with and hold as my own ${coach}’s assessment and onward plan of care.`]],
            startY: y - 5,
            theme: 'plain',
            bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344053' },
            margin: {
                left: 60
            }

        });
        y = doc.lastAutoTable.finalY;

        doc.setTextColor(50, 96, 158);
        doc.setFontSize(14);
        doc.setFont('Inter', 'bold');
        doc.text(40, y = y + 30, 'Other Comments:');
        doc.setFont('Inter', 'normal');
        doc.setFillColor(207, 212, 220);
        doc.setTextColor('Black');

        doc.rect(40, y += 10, 530, 400);
        doc.setFillColor(207, 212, 220);
        doc.setDrawColor(207, 212, 220);

        var notesField = new TextField();
        notesField.multiline = true;
        notesField.maxFontSize = 12;
        notesField.fontSize = 12;
        notesField.height = 150;
        notesField.Rect = [40, y, 530, 400];
        notesField.fontSize = 10;
        notesField.fontName = 'Inter';

        doc.addField(notesField);

        y += 400;
        doc.setLineWidth(3);

        doc.setFillColor(217, 217, 217);
        doc.setDrawColor(217, 217, 217);
        doc.setTextColor(52, 64, 84);

        doc.fontSize = 12;
        doc.line(pageWidth - 270, y += 80, pageWidth - 40, y);
        doc.setFont('Inter', 'normal');
        doc.text(pageWidth - 40, y += 20, 'Provider Signature', { align: 'right' });
        doc.line(pageWidth - 270, y += 80, pageWidth - 40, y);
        doc.text(pageWidth - 40, y += 20, 'Date', { align: 'right' });
    };

    const getMeasureDifferenceType = (a, b, wantDecrease) => {
        let change = a - b;

        if (change === 0) {
            return 0;

        }
        if (!wantDecrease) {
            if (change > 0) {
                return -1;
            }
            else {
                return 1;
            }
        }
        else {
            if (change > 0) {
                return 1;
            }
            else {
                return -1;
            }
        }
    };

    const numberReportPages = (doc) => {
        var pageWidth = doc.internal.pageSize.width;
        var pageHeight = doc.internal.pageSize.height;

        var pages = doc.internal.getNumberOfPages();

        doc.setFontSize(11);  //Optional
        doc.setFont('Inter', 'normal');

        for (let j = 1; j < pages + 1; j++) {
            let horizontalPos = pageWidth / 2;  //Can be fixed number
            let verticalPos = pageHeight - 20;  //Can be fixed number
            doc.setPage(j);
            doc.text(`Rewire Your Brain. Unlearn Your Pain`, 40, verticalPos, { align: 'left' });
            doc.text(`${j}`, pageWidth - 40, verticalPos, { align: 'right' });

            doc.setDrawColor(30, 170, 241);
            doc.setFillColor(30, 170, 241);

            verticalPos = pageHeight - 10;  //Can be fixed number
            doc.rect(0, verticalPos, pageWidth, 10, 'FD');
            doc.rect(0, 0, pageWidth, 10, 'FD');
        }
    };

    const dispatchGeneratePDF = async (uploading) => {
        setErrors(null);
        setIsLoading(true);

        try {
            if (uploading) {
                setLoadingMessage('Approving Outcome Report');
            }
            else {
                setLoadingMessage('Creating Outcome Report');
            }

            let callAddFont1 = function () {
                this.addFileToVFS('Inter-normal.ttf', font1);
                this.addFont('Inter-normal.ttf', 'Inter', 'normal');
            };
            jsPDF.API.events.push(['addFonts', callAddFont1]);

            let callAddFont2 = function () {
                this.addFileToVFS('Inter-bold.ttf', font2);
                this.addFont('Inter-bold.ttf', 'Inter', 'bold');
            };
            jsPDF.API.events.push(['addFonts', callAddFont2]);

            const processText = (text) => {
                return text.split(/(<[^>]+>)/).filter(part => part.trim() !== '');
            };

            const renderTable = (doc, cell, text, colIndex, isRom = false) => {
                let rows = [];
                let largeText = '-';
                let smallText = '';
                let supportColor = '#475467';
                let measureData;

                if (text === '-') {
                    rows = [['-']];
                }
                else {
                    largeText = text[0] ? text[0] : '-';
                    measureData = text[1];
                    if (measureData !== null) {
                        if (colIndex === 0) {
                            let week = measureData.value[0].week;
                            let changeIndicator = getMeasureDifferenceType(measureData.value[0].value, measureData.value[measureData.value.length - 1].value, LowerIsBetter(measureData.type));
                            let change = (measureData.value[0].value - measureData.value[measureData.value.length - 1].value);
                            change = Math.round(change * 100) / 100;
                            change = Math.abs(change);
                            if (changeIndicator === 1) {
                                smallText = 'Improved ';
                                smallText = smallText + change.toString() + (UsePercentage(measureData.type) ? '%' : '') + ' from Week ' + week;
                                supportColor = '#069454';
                            }
                            else if (changeIndicator === -1) {
                                smallText = 'Worsened ';
                                smallText = smallText + (change).toString() + ' from Week ' + week;
                                supportColor = '#c14b24';
                            }
                            else {
                                smallText = 'No Change from Week ' + week;
                                supportColor = '#475467';
                            }
                        }
                        else {
                            let val = parseInt(text[0]);
                            let indicator = GetIndicator(measureData.type, val);
                            smallText = indicator[0];
                            if (indicator[1][1].includes('warning')) {
                                supportColor = '#db6803';
                            }
                            else if (indicator[1][1].includes('success')) {
                                supportColor = '#069454';
                            }
                            else {
                                supportColor = '#c14b24';
                            }
                        }
                    }
                }

                if (rows.length === 0) {
                    rows = [[largeText], [smallText]];
                }

                const expandBodyDetails = (detail) => {
                    let bod = [];
                    if (colIndex === 0) {
                        if (measureData === null) {
                            bod.push(['No Data Available']);
                        }
                        else {
                            switch (measureData.type) {
                                case OutcomeMeasureTypes.FABQActivity:
                                    bod.push(['Activity Fear Avoidance (0-24)']);
                                    break;
                                case OutcomeMeasureTypes.FABQTotal:
                                    bod.push(['Fear Avoidance Beliefs (0-96)']);
                                    break;
                                case OutcomeMeasureTypes.FABQWork:
                                    bod.push(['Work Fear Avoidance (0-42)']);
                                    break;
                                case OutcomeMeasureTypes.PCS:
                                    bod.push(['Pain Catastrophizing (0-52)']);
                                    break;
                                case OutcomeMeasureTypes.PSEQ:
                                    bod.push(['Self Efficacy (0-60)']);
                                    break;
                                case OutcomeMeasureTypes.PHQ9:
                                    bod.push(['Depression (1-27)']);
                                    break;
                                case OutcomeMeasureTypes.GAD7:
                                    bod.push(['General Anxiety (0-21)']);
                                    break;
                                case OutcomeMeasureTypes.Oswestry:
                                    bod.push(['Low Back Disability (0-50)']);
                                    break;
                                case OutcomeMeasureTypes.DASH:
                                    bod.push(['Upper Limb Disability (0-100)']);
                                    break;
                                case OutcomeMeasureTypes.NDI:
                                    bod.push(['Neck Disability (0-50)']);
                                    break;
                                case OutcomeMeasureTypes.WHODAS:
                                    bod.push(['General Disability (0.0-5.0)']);
                                    break;
                                case OutcomeMeasureTypes.UEFI:
                                    bod.push(['Upper Limb Function (0-80)']);
                                    break;
                                case OutcomeMeasureTypes.Pain:
                                    bod.push(['(0-10)']);
                                    break;
                                case OutcomeMeasureTypes.PCSS:
                                    bod.push(['Post Concussive Sx (0-90)']);
                                    break;
                                case OutcomeMeasureTypes.Activity:
                                    bod.push(['(0-10)']);
                                    break;
                                case OutcomeMeasureTypes.Confidence:
                                    bod.push(['(0-10)']);
                                    break;
                                case OutcomeMeasureTypes.Sleep:
                                    bod.push(['(0-10)']);
                                    break;
                                case OutcomeMeasureTypes.Mood:
                                    bod.push(['(0-10)']);
                                    break;
                                case OutcomeMeasureTypes.LowerFlexion:
                                    bod.push(['(0°-90°)']);
                                    break;
                                case OutcomeMeasureTypes.LeftFlexion:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.LeftAbduction:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.LeftScaption:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.RightFlexion:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.RightAbduction:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.RightScaption:
                                    bod.push(['(0°-180°)']);
                                    break;
                                case OutcomeMeasureTypes.KarunaRating:
                                    bod.push(['General Function (0-5000)']);
                                    break;
                            }
                        }
                    }

                    bod.push(detail);
                    return bod;
                };

                if (rows.length > 1) {
                    doc.autoTable({
                        colSpan: 1,
                        tableWidth: (cell.width),
                        body: expandBodyDetails(rows[1]),
                        head: [rows[0]],
                        startY: colIndex === 0 ? (!isRom + (cell.height * (1 / 4)) ? cell.y + (cell.height * (1 / 4)) : cell.y) : cell.y + (cell.height * (1 / 4)),
                        margin: {
                            left: cell.x + 5
                        },
                        theme: 'plain',
                        headStyles: {
                            fontSize: 12,
                            textColor: colIndex === 0 ? '#101828' : '#475467',
                            fontStyle: colIndex === 0 ? 'bold' : 'normal'
                        },
                        bodyStyles: {
                            fontSize: 8,
                            textColor: supportColor
                        },
                        alternateRowStyles: {
                            textColor: colIndex === 0 ? '#475467' : supportColor
                        },
                        styles: {
                            cellPadding: 1,
                            cellWidth: cell.width - 2,
                            halign: colIndex === 0 ? 'left' : 'center', // left, center, right
                            valign: 'top' // top, middle, bottom
                        }
                    });
                }
                else {
                    doc.autoTable({
                        colSpan: 1,
                        tableWidth: (cell.width),
                        body: [rows[0]],
                        startY: cell.y + (cell.height * (2 / 5)),
                        margin: {
                            left: cell.x + 2
                        },
                        theme: 'plain',
                        bodyStyles: {
                            fontSize: 14,
                            textColor: colIndex === 0 ? '#101828' : '#475467',
                            fontStyle: colIndex === 0 ? 'bold' : 'normal'

                        },
                        styles: {
                            cellPadding: 1,
                            cellWidth: cell.width - 2,
                            halign: colIndex === 0 ? 'left' : 'center', // left, center, right
                            valign: 'top' // top, middle, bottom
                        }
                    });
                }
            };

            const renderText = (doc, cell, text) => {
                let pageWidth = cell.width;
                var addY = 0;
                var parts = [];

                if (typeof text === 'string' || text instanceof String) {
                    var blocks = processText(text);
                    blocks.forEach(e => {
                        if (e.includes('<')) {
                            parts.push(e);
                        }
                        else {
                            var pieces = e.split(' ');
                            parts = parts.concat(pieces);
                        }
                    });

                    const xPos = cell.x + 2; // Add some padding from the left
                    let yPos = cell.y + (cell.height / 2);

                    let cursorX = xPos;
                    doc.setFont('Inter', 'normal');
                    parts.forEach(part => {
                        if (part === '<b>') {
                            doc.setFont('Inter', 'bold');
                        }
                        else if (part === '</b>') {
                            doc.setFont('Inter', 'normal');
                        }
                        else if (part === '<0>') {
                            doc.setTextColor(colors[0]);
                        }
                        else if (part === '</0>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<1>') {
                            doc.setTextColor(colors[1]);
                        }
                        else if (part === '</1>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<2>') {
                            doc.setTextColor(colors[2]);
                        }
                        else if (part === '</2>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<3>') {
                            doc.setTextColor(colors[3]);
                        }
                        else if (part === '</3>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<4>') {
                            doc.setTextColor(colors[4]);
                        }
                        else if (part === '</4>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<red>') {
                            doc.setTextColor('#ED5C2D');
                        }
                        else if (part === '</red>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<orange>') {
                            doc.setTextColor('#EF6820');
                        }
                        else if (part === '</orange>') {
                            doc.setTextColor('#344054');
                        }
                        else if (part === '<green>') {
                            doc.setTextColor('#17B26A');
                        }
                        else if (part === '</green>') {
                            doc.setTextColor('#344054');
                        }
                        else {
                            const textWidth = doc.getTextWidth(part + ' ');

                            if (cursorX + textWidth > (pageWidth)) {
                                cursorX = xPos;
                                yPos += doc.getTextDimensions(part).h + 1;
                                addY += doc.getTextDimensions(part).h + 1;
                                doc.text(part + ' s', cursorX, yPos);
                                cursorX += textWidth;
                            }
                            else {
                                doc.text(part + '', cursorX, yPos);
                                cursorX += textWidth;
                            }
                        }
                    });
                }

                return addY;
            };

            var weeks = [1, 4, 8, 12];
            if (reportWeek !== 'M6') {
                weeks = weeks.filter((i) => {
                    return i <= parseInt(reportWeek);
                });
                if (!weeks.includes(parseInt(reportWeek))) {weeks.push(parseInt(reportWeek));}
            }
            else {
                weeks = weeks.filter((i) => {
                    return i <= parseInt(currentWeek);
                });
                if (!weeks.includes(parseInt(currentWeek))) {weeks.push(parseInt(currentWeek));}

                weeks.push('M6');
            }

            var Today = formatDate(new Date());
            var week = reportWeek.toString();

            var doc = new jsPDF('p', 'pt', 'letter');
            doc.setLineHeightFactor(1.5);
            var y = 10;
            doc.setLineWidth(2);

            doc.setFontSize(20);
            var pageWidth = doc.internal.pageSize.width;
            var pageHeight = doc.internal.pageSize.height;
            var img = new Image();
            if (payerType === payerEnums.KarunaHome) {
                img.src = '/icons/title.png';
                doc.addImage(img, 'png', 250, y, 120, 100);

            }
            else if (payerType === payerEnums.Reclaim) {
                img.src = '/icons/Reclaim-System-Stacked-Full-Color.png';
                y += 20;
                doc.addImage(img, 'png', 190, y, 112 * 2, 41 * 2);

            }
            else {
                img.src = '/icons/ValorTitle.png';
                y += 20;
                doc.addImage(img, 'png', 190, y, 112 * 2, 41 * 2);

            }
            doc.autoTable({
                pageBreak: 'avoid',
                body: [[(payerType !== payerEnums.VA ? patientName : '#' + patientId)]],
                startY: y = y + (payerType === payerEnums.KarunaHome ? 100 : (payerType === payerEnums.Reclaim ? 100 : 100)),
                theme: 'plain',
                styles: { font: 'Inter', fontSize: 25, textColor: [50, 96, 158], halign: 'center', valign: 'middle', fontStyle: 'bold' }
            });
            y = doc.lastAutoTable.finalY;
            doc.setFont('Inter', 'normal');

            //patient info heading
            doc.setFontSize(12);
            y += 10;

            doc.setFillColor(234, 236, 240);
            doc.setDrawColor(234, 236, 240);
            doc.setTextColor(71, 84, 103);
            let xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Patient Outcome Report`) * doc.internal.getFontSize() / 2);

            doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (70), y);
            doc.line(((doc.internal.pageSize.width / 2)) + (70), y, (pageWidth - 40), y);
            doc.text(`Patient Outcome Report`, xOffset, y + 3);

            doc.setTextColor('#344054');

            let intro = [
                ['Date of Birth', (payerType !== payerEnums.VA ? DOB : 'N/A'), 'Reporter', preparer],
                ['Date of Injury', ((DOI === 'Unknown' || DOI === '' || payerType === payerEnums.VA) ? 'N/A' : DOI), 'Report Date', ((prepareDate === 'Unknown' || prepareDate === '') ? 'N/A' : prepareDate)],
                ['Physician', physician === '' ? 'N/A' : `${physician}, M.D.`, 'Program Stage', (reportWeek !== 'M6' ? 'Week ' + reportWeek.toString() : '6 Months Post')],
                ['Claim Number', ((claimNumber === 'Unknown' || claimNumber === '') ? 'N/A' : claimNumber), 'Program Type', program]
            ];
            doc.autoTable({
                pageBreak: 'avoid',
                body: intro,
                startY: y = y + 10,
                theme: 'plain',
                styles: { font: 'Inter', fontSize: 10, textColor: '#344054', valign: 'middle' },
                columnStyles: {
                    0: { halign: 'left', fontStyle: 'bold' },
                    1: { halign: 'left' },
                    2: { halign: 'right', fontStyle: 'bold' },
                    3: { halign: 'right' }
                }
            });
            y = doc.lastAutoTable.finalY;
            doc.setFontSize(12);
            y += 15;

            if (hasImprovements() && hasSelectedImprovements()) {
                let chartingCanvas = getGraphCanvas();
                if (chartingCanvas) {
                    doc.setFillColor(234, 236, 240);
                    doc.setDrawColor(234, 236, 240);
                    doc.setTextColor(71, 84, 103);

                    xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Key Improvements`) * doc.internal.getFontSize() / 2);

                    doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (55), y);
                    doc.line(((doc.internal.pageSize.width / 2)) + (56), y, (pageWidth - 40), y);
                    doc.text(`Key Improvements`, xOffset, y + 3);
                    y += 20;
                    let symbol = new Image();
                    symbol.src = '/icons/info.png';
                    doc.addImage(symbol, 'png', 40, y, 10, 10);
                    doc.setFontSize(10);
                    doc.text('Measures in the graph have been converted to a standard percentage score, where higher is better.', 53, y + 8.5, { align: 'left' });

                    doc.setTextColor('#344054');
                    y += 20;
                    doc.addImage(chartingCanvas, 'png', 15, y, pageWidth - 30, 150);
                    y += 150;

                    let textbody = [];
                    let tempTextBody = [];
                    selectedImprovements.forEach((label, index) => {
                        let metric = getMeasureFromType(label);
                        let first = GetIndicator(metric.type, metric.value[0].value);
                        let last = null;
                        if (isRomType(metric.type)) {
                            last = GetIndicator(metric.type, bestRomMeasurement(metric.value, 3).value);
                        }
                        else {
                            last = GetIndicator(metric.type, metric.value.slice(-1)[0].value);

                        }
                        let firstColor = first[1][0].includes('success') ? 'green' : (first[1][0].includes('warning') ? 'orange' : 'red');
                        let lastColor = last[1][0].includes('success') ? 'green' : (last[1][0].includes('warning') ? 'orange' : 'red');

                        let text = '<' + index.toString() + '>';
                        text += metric.name;
                        text += '</' + index.toString() + '>';
                        text += ' has improved from ';
                        text += '<' + firstColor + '>';
                        text += metric.value[0].value;
                        text += ' (';
                        text += first[0];
                        text += ')';
                        text += '</' + firstColor + '>';

                        text += ' to ';
                        text += '<' + lastColor + '>';
                        text += (isRomType(metric.type) ? bestRomMeasurement(metric.value, 3).value : metric.value.slice(-1)[0].value);
                        text += ' (';
                        text += last[0];
                        text += ')';
                        text += '</' + lastColor + '>';

                        let tempText = '';
                        tempText += metric.name;
                        tempText += ' has improved from ';
                        tempText += metric.value[0].value;
                        tempText += ' (';
                        tempText += first[0];
                        tempText += ')';

                        tempText += ' to ';
                        tempText += (isRomType(metric.type) ? bestRomMeasurement(metric.value, 3).value : metric.value.slice(-1)[0].value);
                        tempText += ' (';
                        tempText += last[0];
                        tempText += ')';

                        tempTextBody.push(text);
                        textbody.push({ content: tempText });
                    });
                    let cellIndex = 0;
                    doc.autoTable({

                        body: textbody.map(row => [row.content]),
                        willDrawCell: function (textbody) {
                            if (textbody.section === 'body') {

                                const cell = textbody.cell;
                                const text = tempTextBody[cellIndex];
                                cellIndex++;
                                cell.text = [];
                                renderText(doc, cell, text, pageWidth);
                            }
                        },
                        startY: y = y + 10,
                        theme: 'plain',
                        styles: { fontSize: 10, halign: 'left' }

                    });
                    y = doc.lastAutoTable.finalY;
                }
            }
            y += 10;

            //history of injury
            if (HistoryInjury.length > 0) {
                doc.setFontSize(12);
                doc.setFillColor(234, 236, 240);
                doc.setDrawColor(234, 236, 240);
                doc.setTextColor(71, 84, 103);

                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Patient History`) * doc.internal.getFontSize() / 2);

                doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (45), y);
                doc.line(((doc.internal.pageSize.width / 2)) + (45), y, (pageWidth - 40), y);
                doc.text(`Patient History`, xOffset, y + 3);
                y += 10;

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [['History of Injury']],
                    startY: y,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], halign: 'left', valign: 'middle', fontStyle: 'bold' }
                });
                y = doc.lastAutoTable.finalY;
                let pHeight = doc.internal.pageSize.height;

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [[HistoryInjury]],
                    startY: y += 2.5,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 10, textColor: '#344054', halign: 'left' }
                });
                y = doc.lastAutoTable.finalY;

                if (y % pHeight > (pHeight * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }
            }

            //SUBJECTIVE OUTCOMES

            var col = [{ header: 'Program Goals', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
            var body = goals;

            if (goals.length > 0 || discoveries.length > 0 || engagementLevel || goalProgress) {
                doc.setFontSize(12);
                doc.setFillColor(234, 236, 240);
                doc.setDrawColor(234, 236, 240);
                doc.setTextColor(71, 84, 103);
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Subjective Outcomes`) * doc.internal.getFontSize() / 2);
                doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (65), y);
                doc.line(((doc.internal.pageSize.width / 2)) + (65), y, (pageWidth - 40), y);
                doc.text(`Subjective Outcomes`, xOffset, y + 3);
            }

            if (body.length > 0) {
                doc.autoTable({
                    showHead: 'firstPage',
                    body: body,
                    columns: col,
                    startY: y = y + 30,
                    theme: 'plain',
                    headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' },
                    columnStyles: {
                        goal: { cellWidth: 250, font: 'Inter', fontSize: 10, fontStyle: 'bold' },
                        detail: { cellWidth: 250, halign: 'left', font: 'Inter', fontSize: 10 }
                    },
                    bodyStyles: { cellPadding: 10, textColor: '#344054' }
                });

                y = doc.lastAutoTable.finalY;
            }

            //perspective
            var col = [{ header: 'Patient Perspective', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
            var body = [];
            if (goalProgress && rawGoalProgressData) {body.push({ goal: 'Perceived Progress Towards Goals', detail: (rawGoalProgressData.value.toString() + '/10') });}
            if (engagementLevel && rawEngagementLevelData) {body.push({ goal: 'Level of Engagement with Program', detail: (rawEngagementLevelData.value.toString() + '/10') });}
            if (discoveries && discoveries.length > 0) {body.push({ goal: 'Discoveries', detail: discoveries });}

            if (body.length > 0) {
                doc.autoTable({
                    pageBreak: 'avoid',
                    showHead: 'firstPage',
                    body: body,
                    columns: col,
                    startY: y = y + 20,
                    theme: 'plain',
                    headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' },
                    bodyStyles: { cellPadding: 10, textColor: '#344054' },
                    columnStyles: {
                        goal: { cellWidth: 250, font: 'Inter', fontSize: 10, fontStyle: 'bold' },
                        detail: { cellWidth: 250, halign: 'left', font: 'Inter', fontSize: 10 }
                    }
                });

                y = doc.lastAutoTable.finalY + 10;
            }

            //summary

            var col = [{ header: 'Subjective Progress Summary', dataKey: 'summary' }];
            var body = [{ summary: summary }];

            if (summary.length > 0) {
                doc.autoTable({
                    pageBreak: 'avoid',
                    showHead: 'firstPage',
                    body: body,
                    columns: col,
                    startY: y = y + 10,
                    theme: 'plain',
                    headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' },
                    bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' }
                });

                y = doc.lastAutoTable.finalY + 20;
            }

            if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                doc.addPage();
                y = 30;
            }

            //objective

            if (sessionsAttended || uniqueDays || averageTime || leftAbduction || leftScaption || leftFlexion || rightAbduction || rightScaption || rightFlexion || returnToWork || medReduction || PSEQ || PCS || oswestry || Dash || lowerFlexion || UEFI || FABQActivity || FABQTotal || FABQWork ||
                PCSS ||
                NDI || PHQ9 || GAD7 || WHODAS) {
                doc.setFontSize(12);
                doc.setFillColor(234, 236, 240);
                doc.setDrawColor(234, 236, 240);
                doc.setTextColor(71, 84, 103);
                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Objecive Outcomes`) * doc.internal.getFontSize() / 2);
                doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (57), y);
                doc.line(((doc.internal.pageSize.width / 2)) + (61), y, (pageWidth - 40), y);
                doc.text(`Objective Outcomes`, xOffset, y + 3);
            }

            var col = [{ header: 'Adherence', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
            var body = [
                (sessionsAttended && RawSessionsAttendedData ? { goal: 'Sessions Attended', detail: RawSessionsAttendedData.toString() } : null),
                (uniqueDays && RawUniqueDaysData ? { goal: 'Avg. Days DME Usage per Week', detail: RawUniqueDaysData.toString() } : null),
                (averageTime && RawAverageTimeData ? { goal: 'Avg. Time Spent in DME per Week', detail: RawAverageTimeData.toString() } : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {
                doc.autoTable({
                    pageBreak: 'avoid',
                    showHead: 'firstPage',
                    body: body,
                    columns: col,
                    startY: y = y + 10,
                    theme: 'plain',
                    headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' },
                    bodyStyles: { cellPadding: 10, textColor: '#344054' },
                    columnStyles: {
                        goal: { cellWidth: 250, font: 'Inter', fontSize: 10, fontStyle: 'bold' },
                        detail: { cellWidth: 250, halign: 'left', font: 'Inter', fontSize: 10 }
                    }
                });

                y = doc.lastAutoTable.finalY + 10;
            }

            var col = [{ header: 'Program Impact', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
            var body = [
                (returnToWork && returnToWorkData ? { goal: 'Return to Work', detail: returnToWorkData.slice(-1)[0].value >= 3 ? 'Yes' : 'No' } : null),
                (medReduction && medImp ? { goal: 'Medication Reduction', detail: medImp } : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {
                doc.autoTable({
                    pageBreak: 'avoid',
                    showHead: 'firstPage',
                    body: body,
                    columns: col,
                    startY: y = y + 10,
                    theme: 'plain',
                    headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' },
                    bodyStyles: { cellPadding: 10, textColor: '#344054' },
                    columnStyles: {
                        goal: { cellWidth: 250, font: 'Inter', fontSize: 10, fontStyle: 'bold' },
                        detail: { cellWidth: 250, halign: 'left', font: 'Inter', fontSize: 10 }
                    }
                });

                y = doc.lastAutoTable.finalY;
            }

            body = [];

            var dashRow = { field: ['DASH', getMeasureFromType(OutcomeMeasureTypes.DASH), 'title'] };
            DashData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    dashRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.DASH), element.week];
                }
            });
            weeks.forEach((i) => {
                if (dashRow['week' + i.toString()] === undefined) {dashRow['week' + i.toString()] = '-';}
            });

            var NDIRow = { field: ['NDI', getMeasureFromType(OutcomeMeasureTypes.NDI), 'title'] };
            NDIData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    NDIRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.NDI), element.week];
                }
            });
            weeks.forEach((i) => {
                if (NDIRow['week' + i.toString()] === undefined) {NDIRow['week' + i.toString()] = '-';}
            });

            var oswRow = { field: ['Oswestry', getMeasureFromType(OutcomeMeasureTypes.Oswestry), 'title'] };
            oswestryData.forEach(element => {

                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    oswRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Oswestry), element.week];
                }
            });
            weeks.forEach((i) => {
                if (oswRow['week' + i.toString()] === undefined) {oswRow['week' + i.toString()] = '-';}
            });

            var WHODASRow = { field: ['WHODAS', getMeasureFromType(OutcomeMeasureTypes.WHODAS), 'title'] };
            WHODASData.forEach(element => {

                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    WHODASRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.WHODAS), element.week];
                }
            });
            weeks.forEach((i) => {
                if (WHODASRow['week' + i.toString()] === undefined) {WHODASRow['week' + i.toString()] = '-';}
            });

            body = [
                (Dash ? dashRow : null),
                (NDI ? NDIRow : null),
                (oswestry ? oswRow : null),
                (WHODAS ? WHODASRow : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [['Physical Disability']],
                    startY: y += 10,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], halign: 'left', valign: 'middle', fontStyle: 'bold' }
                });
                y = doc.lastAutoTable.finalY;

                var header = [
                    { header: 'Measure', dataKey: 'field' }

                ];

                weeks.forEach((i, index) =>
                    (
                        header.push({ header: (i !== 'M6' ? 'Week ' + i.toString() : '6 Months Post'), dataKey: 'week' + i.toString() })
                    )
                );
                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }
                doc.autoTable({
                    tableLineWidth: 1,
                    tableLineColor: '#eaecf0',
                    rowPageBreak: 'avoid',
                    body: body,
                    willDrawCell: function (t) {
                        if (t.section === 'body') {
                            const cell = t.cell;
                            const text = cell.raw;
                            const colIndex = t.column.index;
                            cell.text = [];
                            renderTable(doc, cell, text, colIndex, false);
                        }
                    },
                    bodyStyles: { lineColor: '#eaecf0', lineWidth: 1, fillColor: false, fontSize: 12, cellPadding: { top: 10, bottom: 15 }, textColor: '#344054', halign: 'center', valign: 'middle' },
                    headStyles: { fillColor: '#f9fafb', lineWidth: 1, textColor: '#475467', halign: 'center', valign: 'middle', lineColor: '#eaecf0', fontSize: 12 },
                    columnStyles: { 0: { halign: 'left', fontSize: 10, minCellHeight: 61 } },
                    columns: header,
                    startY: y += 20,
                    theme: 'grid'
                });

                y = doc.lastAutoTable.finalY;
            }

            var activityRow = { field: ['Activity', getMeasureFromType(OutcomeMeasureTypes.Activity), 'title'] };
            activityData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    activityRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Activity), element.week];
                }
            });
            weeks.forEach((i) => {
                if (activityRow['week' + i.toString()] === undefined) {activityRow['week' + i.toString()] = '-';}
            });

            var KarunaRow = { field: ['Karuna Rating', getMeasureFromType(OutcomeMeasureTypes.KarunaRating), 'title'] };
            KarunaRatingData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    KarunaRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.KarunaRating), element.week];
                }
            });
            weeks.forEach((i) => {
                if (KarunaRow['week' + i.toString()] === undefined) {KarunaRow['week' + i.toString()] = '-';}
            });

            var painRow = { field: ['Pain', getMeasureFromType(OutcomeMeasureTypes.Pain), 'title'] };
            painData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    painRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Pain), element.week];
                }
            });
            weeks.forEach((i) => {
                if (painRow['week' + i.toString()] === undefined) {painRow['week' + i.toString()] = '-';}
            });

            var PCSSRow = { field: ['PCSS', getMeasureFromType(OutcomeMeasureTypes.PCSS), 'title'] };
            PCSSData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    PCSSRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.PCSS), element.week];
                }
            });
            weeks.forEach((i) => {
                if (PCSSRow['week' + i.toString()] === undefined) {PCSSRow['week' + i.toString()] = '-';}
            });

            var sleepRow = { field: ['Sleep', getMeasureFromType(OutcomeMeasureTypes.Sleep), 'title'] };
            sleepData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    sleepRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Sleep), element.week];
                }
            });
            weeks.forEach((i) => {
                if (sleepRow['week' + i.toString()] === undefined) {sleepRow['week' + i.toString()] = '-';}
            });

            var UEFIRow = { field: ['UEFI', getMeasureFromType(OutcomeMeasureTypes.UEFI), 'title'] };
            UEFIData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    UEFIRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.UEFI), element.week];
                }
            });
            weeks.forEach((i) => {
                if (UEFIRow['week' + i.toString()] === undefined) {UEFIRow['week' + i.toString()] = '-';}
            });

            body = [
                (activity ? activityRow : null),
                (KarunaRating ? KarunaRow : null),
                (pain ? painRow : null),
                (PCSS ? PCSSRow : null),
                (sleep ? sleepRow : null),
                (UEFI ? UEFIRow : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [['Physical Function']],
                    startY: y += 10,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], halign: 'left', valign: 'middle', fontStyle: 'bold' }
                });
                y = doc.lastAutoTable.finalY;

                if (KarunaRating) {
                    if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                        doc.addPage();
                        y = 30;
                    }
                    else {
                        y += 5;
                    }
                    let symbol = new Image();
                    symbol.src = '/icons/info.png';
                    doc.addImage(symbol, 'png', 40, y, 7, 7);
                    doc.setFontSize(10);

                    doc.autoTable({
                        pageBreak: 'avoid',
                        showHead: 'firstPage',
                        body: [[`Karuna Rating is a bespoke measure of function across multiple VET™ movement activities. Range of motion is combined with functional capability demonstrated in each activity to produce a single score.`]],
                        startY: y - 5,
                        theme: 'plain',
                        bodyStyles: { fontSize: 8, font: 'Inter', textColor: '#344053' },
                        margin: {
                            left: 45
                        }

                    });
                    y = doc.lastAutoTable.finalY;
                }

                var header = [{ header: 'Measure', dataKey: 'field' }];

                weeks.forEach((i, index) =>
                    (
                        header.push({ header: (i !== 'M6' ? 'Week ' + i.toString() : '6 Months Post'), dataKey: 'week' + i.toString() })
                    )
                );

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    tableLineWidth: 1,
                    tableLineColor: '#eaecf0',
                    rowPageBreak: 'avoid',
                    body: body,
                    willDrawCell: function (t) {
                        if (t.section === 'body') {
                            const cell = t.cell;
                            const text = cell.raw;
                            const colIndex = t.column.index;
                            cell.text = [];
                            renderTable(doc, cell, text, colIndex, false);
                        }
                    },
                    bodyStyles: { lineColor: '#eaecf0', lineWidth: 1, fillColor: false, fontSize: 12, cellPadding: { top: 10, bottom: 15 }, textColor: '#344054', halign: 'center', valign: 'middle' },
                    headStyles: { fillColor: '#f9fafb', lineWidth: 1, textColor: '#475467', halign: 'center', valign: 'middle', lineColor: '#eaecf0', fontSize: 12 },
                    columnStyles: {
                        0: { halign: 'left', fontSize: 10, minCellHeight: 61 }
                    },
                    columns: header,
                    startY: y += (KarunaRating ? 7 : 20),
                    theme: 'grid'
                });

                y = doc.lastAutoTable.finalY;
            }

            var header = [{ header: 'Measure', dataKey: 'field' }];
            header.push({ header: 'First Measurement', dataKey: 'first' });
            header.push({ header: 'Recent Best', dataKey: 'recent' });

            var lfRow = { field: ['Left Shoulder Flexion', getMeasureFromType(OutcomeMeasureTypes.LeftFlexion), 'title'] };
            if (leftFlexionData.length > 0) {
                lfRow['first'] = [leftFlexionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftFlexion), leftFlexionData[0].week];
                lfRow['recent'] = [bestRomMeasurement(leftFlexionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftFlexion), bestRomMeasurement(leftFlexionData, 3).week];
            }

            var laRow = { field: ['Left Shoulder Abduction', getMeasureFromType(OutcomeMeasureTypes.LeftAbduction), 'title'] };
            if (leftAbductionData.length > 0) {
                laRow['first'] = [leftAbductionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftAbduction), leftAbductionData[0].week];
                laRow['recent'] = [bestRomMeasurement(leftAbductionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftAbduction), bestRomMeasurement(leftAbductionData, 3).week];
            }

            var leRow = { field: ['Left Shoulder Scaption', getMeasureFromType(OutcomeMeasureTypes.LeftScaption), 'title'] };
            if (leftScaptionData.length > 0) {
                leRow['first'] = [leftScaptionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftScaption), leftAbductionData[0].week];
                leRow['recent'] = [bestRomMeasurement(leftScaptionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LeftScaption), bestRomMeasurement(leftScaptionData, 3).week];
            }

            var rfRow = { field: ['Right Shoulder Flexion', getMeasureFromType(OutcomeMeasureTypes.RightFlexion), 'title'] };
            if (rightFlexionData.length > 0) {
                rfRow['first'] = [rightFlexionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightFlexion), rightFlexionData[0].week];
                rfRow['recent'] = [bestRomMeasurement(rightFlexionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightFlexion), bestRomMeasurement(rightFlexionData, 3).week];
            }

            var raRow = { field: ['Right Shoulder Abduction', getMeasureFromType(OutcomeMeasureTypes.RightAbduction), 'title'] };
            if (rightAbductionData.length > 0) {
                raRow['first'] = [rightAbductionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightAbduction), rightAbductionData[0].week];
                raRow['recent'] = [bestRomMeasurement(rightAbductionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightAbduction), bestRomMeasurement(rightAbductionData, 3).week];
            }

            var reRow = { field: ['Right Shoulder Scaption', getMeasureFromType(OutcomeMeasureTypes.RightScaption), 'title'] };
            if (rightScaptionData.length > 0) {
                reRow['first'] = [rightScaptionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightScaption), rightAbductionData[0].week];
                reRow['recent'] = [bestRomMeasurement(rightScaptionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.RightScaption), bestRomMeasurement(rightScaptionData, 3).week];
            }

            var lbRow = { field: ['Back Flexion (Estimate)', getMeasureFromType(OutcomeMeasureTypes.LowerFlexion), 'title'] };
            if (lowerFlexionData.length > 0) {
                lbRow['first'] = [lowerFlexionData[0].value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LowerFlexion), lowerFlexionData[0].week];
                lbRow['recent'] = [bestRomMeasurement(lowerFlexionData, 3).value.toString() + '°', getMeasureFromType(OutcomeMeasureTypes.LowerFlexion), bestRomMeasurement(lowerFlexionData, 3).week];
            }

            body = [
                (leftFlexion ? lfRow : null),
                (leftScaption ? leRow : null),
                (leftAbduction ? laRow : null),
                (rightFlexion ? rfRow : null),
                (rightScaption ? reRow : null),
                (rightAbduction ? raRow : null),
                (lowerFlexion ? lbRow : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [['Range of Motion']],
                    startY: y += 10,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], halign: 'left', valign: 'middle', fontStyle: 'bold' }
                });
                y = doc.lastAutoTable.finalY;
                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    tableLineWidth: 1,
                    tableLineColor: '#eaecf0',
                    rowPageBreak: 'avoid',
                    body: body,
                    willDrawCell: function (t) {
                        if (t.section === 'body') {

                            const cell = t.cell;
                            const text = cell.raw;
                            const colIndex = t.column.index;
                            cell.text = [];
                            renderTable(doc, cell, text, colIndex, true);
                        }
                    },
                    bodyStyles: { lineColor: '#eaecf0', lineWidth: 1, fillColor: false, fontSize: 12, cellPadding: { top: 10, bottom: 15 }, textColor: '#344054', halign: 'center', valign: 'middle' },
                    headStyles: { fillColor: '#f9fafb', lineWidth: 1, textColor: '#475467', halign: 'center', valign: 'middle', lineColor: '#eaecf0', fontSize: 12 },
                    columnStyles: {
                        0: { halign: 'left', fontSize: 10, minCellHeight: 61 }
                    },
                    columns: header,
                    startY: y += 20,
                    theme: 'grid'
                });

                y = doc.lastAutoTable.finalY;
            }

            var confidenceRow = { field: ['Confidence', getMeasureFromType(OutcomeMeasureTypes.Confidence), 'title'] };
            confidenceData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    confidenceRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Confidence), element.week];
                }
            });
            weeks.forEach((i) => {
                if (confidenceRow['week' + i.toString()] === undefined) {confidenceRow['week' + i.toString()] = '-';}
            });

            var FABQARow = { field: ['FABQ Activity', getMeasureFromType(OutcomeMeasureTypes.FABQActivity), 'title'] };
            FABQActivityData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    FABQARow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.FABQActivity), element.week];
                }
            });
            weeks.forEach((i) => {
                if (FABQARow['week' + i.toString()] === undefined) {FABQARow['week' + i.toString()] = '-';}
            });

            var FABQWRow = { field: ['FABQ Work', getMeasureFromType(OutcomeMeasureTypes.FABQWork), 'title'] };
            FABQWorkData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    FABQWRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.FABQWork), element.week];
                }
            });
            weeks.forEach((i) => {
                if (FABQWRow['week' + i.toString()] === undefined) {FABQWRow['week' + i.toString()] = '-';}
            });

            var FABQTRow = { field: ['FABQ Total', getMeasureFromType(OutcomeMeasureTypes.FABQTotal), 'title'] };
            FABQTotalData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    FABQTRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.FABQTotal), element.week];
                }
            });
            weeks.forEach((i) => {
                if (FABQTRow['week' + i.toString()] === undefined) {FABQTRow['week' + i.toString()] = '-';}
            });

            var gad7Row = { field: ['GAD-7', getMeasureFromType(OutcomeMeasureTypes.GAD7), 'title'] };
            GAD7Data.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    gad7Row['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.GAD7), element.week];
                }
            });
            weeks.forEach((i) => {
                if (gad7Row['week' + i.toString()] === undefined) {gad7Row['week' + i.toString()] = '-';}
            });

            var moodRow = { field: ['Mood', getMeasureFromType(OutcomeMeasureTypes.Mood), 'title'] };
            moodData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    moodRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.Mood), element.week];
                }
            });
            weeks.forEach((i) => {
                if (moodRow['week' + i.toString()] === undefined) {moodRow['week' + i.toString()] = '-';}
            });

            var PCSRow = { field: ['PCS', getMeasureFromType(OutcomeMeasureTypes.PCS), 'title'] };
            PCSData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    PCSRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.PCS), element.week];
                }
            });
            weeks.forEach((i) => {
                if (PCSRow['week' + i.toString()] === undefined) {PCSRow['week' + i.toString()] = '-';}
            });

            var PHQ9Row = { field: ['PHQ9', getMeasureFromType(OutcomeMeasureTypes.PHQ9), 'title'] };
            PHQ9Data.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    PHQ9Row['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.PHQ9), element.week];
                }
            });
            weeks.forEach((i) => {
                if (PHQ9Row['week' + i.toString()] === undefined) {PHQ9Row['week' + i.toString()] = '-';}
            });

            var PSEQRow = { field: ['PSEQ', getMeasureFromType(OutcomeMeasureTypes.PSEQ), 'title'] };
            PSEQData.forEach(element => {
                if (weeks.includes(!isNaN(parseInt(element.week)) ? parseInt(element.week) : 'M6')) {
                    PSEQRow['week' + element.week.toString()] = [element.value.toString(), getMeasureFromType(OutcomeMeasureTypes.PSEQ), element.week];
                }
            });
            weeks.forEach((i) => {
                if (PSEQRow['week' + i.toString()] === undefined) {PSEQRow['week' + i.toString()] = '-';}
            });

            body = [
                (confidence ? confidenceRow : null),
                (FABQActivity ? FABQARow : null),
                (FABQWork ? FABQWRow : null),
                (FABQTotal ? FABQTRow : null),
                (GAD7 ? gad7Row : null),
                (mood ? moodRow : null),
                (PCS ? PCSRow : null),
                (PHQ9 ? PHQ9Row : null),
                (PSEQ ? PSEQRow : null)
            ];
            body = body.filter(n => n);

            if (body.length > 0) {

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    pageBreak: 'avoid',
                    body: [['Behavioral']],
                    startY: y += 10,
                    theme: 'plain',
                    styles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], halign: 'left', valign: 'middle', fontStyle: 'bold' }
                });
                y = doc.lastAutoTable.finalY;

                var header = [{ header: 'Measure', dataKey: 'field' }];

                weeks.forEach((i, index) =>
                    (
                        header.push({ header: (i !== 'M6' ? 'Week ' + i.toString() : '6 Months Post'), dataKey: 'week' + i.toString() })
                    )
                );

                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.autoTable({
                    rowPageBreak: 'avoid',
                    tableLineWidth: 1,
                    tableLineColor: '#eaecf0',
                    body: body,
                    willDrawCell: function (t) {
                        if (t.section === 'body') {
                            const cell = t.cell;
                            const text = cell.raw;
                            const colIndex = t.column.index;
                            cell.text = [];
                            renderTable(doc, cell, text, colIndex, false);
                        }
                    },
                    bodyStyles: { lineColor: '#eaecf0', lineWidth: 1, fillColor: false, fontSize: 12, cellPadding: { top: 10, bottom: 15 }, textColor: '#344054', halign: 'center', valign: 'middle' },
                    headStyles: { fillColor: '#f9fafb', lineColor: '#eaecf0', lineWidth: 1, textColor: '#475467', halign: 'center', valign: 'middle', fontSize: 12 },
                    columnStyles: {
                        0: { halign: 'left', fontSize: 10, minCellHeight: 61 }
                    },
                    columns: header,
                    startY: y += 20,
                    theme: 'grid'

                });

                y = doc.lastAutoTable.finalY;
            }

            //Assessments

            y += 30;
            if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                doc.addPage();
                y = 30;
            }
            if (physical || behavioral || social || continuedNeed) {
                if (y % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }
                doc.setFontSize(12);
                doc.setFillColor(234, 236, 240);
                doc.setDrawColor(234, 236, 240);
                doc.setTextColor(71, 84, 103);

                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Assessments`) * doc.internal.getFontSize() / 2);

                doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (37), y);
                doc.line(((doc.internal.pageSize.width / 2)) + (37), y, (pageWidth - 40), y);
                doc.text(`Assessments`, xOffset, y + 3);
                y += 10;

                if (physical && physicalText.length > 0) {
                    var col = [
                        { header: 'Physical Assessment', dataKey: 'goal' }, { header: '', dataKey: 'detail' }
                    ];
                    doc.autoTable({
                        pageBreak: 'avoid',
                        showHead: 'firstPage',
                        body: [[physicalText]],
                        columns: col,
                        startY: y,
                        theme: 'plain',
                        bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' },
                        headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' }

                    });

                    y = doc.lastAutoTable.finalY + 10;

                }

                if (behavioral && behavioralText.length > 0) {
                    var col = [{ header: 'Behavioral Assessment', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
                    doc.autoTable({
                        pageBreak: 'avoid',
                        showHead: 'firstPage',
                        body: [[behavioralText]],
                        columns: col,
                        startY: y,
                        theme: 'plain',
                        bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' },
                        headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' }

                    });

                    y = doc.lastAutoTable.finalY + 10;
                }

                if (social && socialText.length > 0) {
                    var col = [{ header: 'Social Assessment', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
                    doc.autoTable({
                        pageBreak: 'avoid',
                        showHead: 'firstPage',
                        body: [[socialText]],
                        columns: col,
                        startY: y,
                        theme: 'plain',
                        bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' },
                        headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' }
                    });

                    y = doc.lastAutoTable.finalY + 10;
                }

                if (continuedNeed && continuedNeedText.length > 0) {
                    var col = [{ header: 'Continued Need', dataKey: 'goal' }, { header: '', dataKey: 'detail' }];
                    doc.autoTable({
                        pageBreak: 'avoid',
                        showHead: 'firstPage',
                        body: [[continuedNeedText]],
                        columns: col,
                        startY: y,
                        theme: 'plain',
                        bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' },
                        headStyles: { font: 'Inter', fontSize: 12, textColor: [50, 96, 158], fontStyle: 'bold' }
                    });

                    y = doc.lastAutoTable.finalY + 10;
                }
            }

            y += 10;

            var body = [{ summary: plan }];

            if (plan.length > 0) {
                if ((y + 30) % doc.internal.pageSize.height > (doc.internal.pageSize.height * (4 / 5))) {
                    doc.addPage();
                    y = 30;
                }

                doc.setFontSize(12);
                doc.setFillColor(234, 236, 240);
                doc.setDrawColor(234, 236, 240);
                doc.setTextColor(71, 84, 103);

                xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(`Plan`) * doc.internal.getFontSize() / 2);

                doc.line(40, y, ((doc.internal.pageSize.width / 2)) - (15), y);
                doc.line(((doc.internal.pageSize.width / 2)) + (15), y, (pageWidth - 40), y);
                doc.text(`Plan`, xOffset, y + 3);
                y += 10;

                doc.autoTable({
                    pageBreak: 'avoid',
                    showHead: 'firstPage',
                    body: body,
                    startY: y,
                    theme: 'plain',
                    bodyStyles: { fontSize: 10, font: 'Inter', textColor: '#344054' }

                });

                y = doc.lastAutoTable.finalY + 10;
            }

            if (oversight) {generateProviderSection(doc);}

            numberReportPages(doc);

            if (uploading) {
                let returnOutput = doc.output('datauristring');

                data.plan = plan;
                data.included_weeks = reportWeek !== 'M6' ? reportWeek : currentWeek;

                data.enabled = {};
                data.enabled.goalProgress = goalProgress;
                data.enabled.engagmentLevel = engagementLevel;
                data.enabled.sessionsAttended = sessionsAttended;
                data.enabled.uniqueDays = uniqueDays;
                data.enabled.averageTime = averageTime;
                data.enabled.leftFlexion = leftFlexion;
                data.enabled.leftScaption = leftScaption;
                data.enabled.leftAbduction = leftAbduction;
                data.enabled.rightFlexion = rightFlexion;
                data.enabled.rightScaption = rightScaption;
                data.enabled.rightAbduction = rightAbduction;
                data.enabled.pain = pain;
                data.enabled.mood = mood;
                data.enabled.activity = activity;
                data.enabled.sleep = sleep;
                data.enabled.confidence = confidence;
                data.enabled.returnToWork = returnToWork;
                data.enabled.medicationReduction = medReduction;
                data.enabled.oswestry = oswestry;
                data.enabled.Dash = Dash;
                data.enabled.PSEQ = PSEQ;
                data.enabled.PCS = PCS;
                data.enabled.UEFI = UEFI;
                data.enabled.PCSS = PCSS;
                data.enabled.WHODAS = WHODAS;
                data.enabled.NDI = NDI;
                data.enabled.GAD7 = GAD7;
                data.enabled.PHQ9 = PHQ9;
                data.enabled.KarunaRating = KarunaRating;
                data.enabled.social = social;
                data.enabled.physical = physical;
                data.enabled.behavioral = behavioral;
                data.enabled.FABQTotal = FABQTotal;
                data.enabled.FABQActivity = FABQActivity;
                data.enabled.FABQWork = FABQWork;

                data.customizations = {};
                data.customizations.customSummary = summary;
                data.customizations.customGoals = goals.map(e => [e.goal, e.detail]);
                data.customizations.customDiscovery = discoveries.split('\n\n').filter(function (el) {return el !== '';});
                data.customizations.customSocial = socialText;
                data.customizations.customPhysical = physicalText;
                data.customizations.customBehavioral = behavioralText;
                data.customizations.customContinuedNeed = continuedNeedText;
                data.customizations.customHistoryInjury = HistoryInjury;

                let success = true;
                await API
                    .put(
                        `/outcome_reports/${reportId}/`,
                        { report_json_data: data, report_pdf_data: returnOutput }
                    )
                    .then(
                        response => {
                            KarunaCLOUD_responseValidator(response, setErrors);
                        }
                    )
                    .catch(
                        error => {
                            console.error(error);
                            setErrors(error);
                            success = false;
                        }
                    );

                if (success) {
                    await API
                        .get(`/outcome_reports/${reportId}/approve`)
                        .then(
                            response => {
                                if (!KarunaCLOUD_responseValidator(response, setErrors)) {
                                    return;
                                }

                                let successMessage = `Approved: ${patientName} ${reportWeek === 'M6' ? '6 Months Post' : `Week ${reportWeek.toString()}`} Outcome Report`;
                                localStorage.setItem('success', successMessage);
                                history.push('/reports', { state: { message: successMessage } });
                            }
                        )
                        .catch(
                            error => {
                                console.error(error);
                                setErrors(error);
                            }
                        );
                }

                setIsLoading(false);
            }
            else {
                doc.save(`${patientName}_Outcomes_${formatDate(new Date())}.pdf`);
            }
        }
        catch (error) {
            console.error(error);
            setErrors(['Error generating Outcome Report PDF! Contact a Karuna Admin.', error]);
        }
        finally {
            setIsLoading(false);
        }
    };

    // ---=== Report Operations ===--- //

    const updateNotificationType = (val) => {
        if (val !== notification) {
            setTemplateNotification(val);
        }
    };

    const updateTemplateWeekType = (val) => {
        if (val !== templateWeeks) {
            setTemplateWeeks(val);
        }
    };

    const getReport = async () => {
        setIsLoading(true);
        setLoadingMessage('Retrieving Outcome Report');
        setErrors(null);

        await API
            .get(
                `/outcome_reports/${reportId}/?json_data=${true}/`
            )
            .then(
                response => {
                    setIsLoading(false);

                    let data = KarunaCLOUD_responseValidator(response, setErrors);

                    if (!data) {
                        return;
                    }

                    let error = data.error;
                    if (error) {
                        if (
                            error.includes('No matching active Practice Better account found') ||
                            error.includes('must not start with \'changeme\'') ||
                            error.includes('Active Practice Better account found with e-mail')
                        ) {
                            setErrors(`Could not retrieve Practice Better data for this patient.\n${error}`);
                        }
                        return;
                    }

                    if (data.brand !== undefined && data.brand !== 'KarunaHOME') {
                        setIsKaruna(false);
                    }

                    var reportJsonData = data.report_json_data;

                    if (reportJsonData.program_type === 'No Active Coaching Plan') {
                        setErrors('Cannot generate an Outcome Report for this patient. Patients requires an active Coaching Plan to generate reports.');
                        return;
                    }
                    else {
                        setProgram(reportJsonData.program_type);
                        setHasPlan(true);
                    }

                    if (reportJsonData.current_coaching_plan_week > 0) {
                        var week = -1;
                        setCurrentWeek(reportJsonData.current_coaching_plan_week);
                        if (reportJsonData.isFutureReport !== undefined && reportJsonData.isFutureReport === true) {
                            setChangedWeek('M6');
                            setReportWeek('M6');
                            week = 'M6';
                        }
                        else {
                            setChangedWeek(reportJsonData.included_weeks !== 'All' ? reportJsonData.included_weeks : reportJsonData.current_coaching_plan_week);
                            setReportWeek(reportJsonData.included_weeks !== 'All' ? reportJsonData.included_weeks : reportJsonData.current_coaching_plan_week);
                            week = reportJsonData.included_weeks !== 'All' ? reportJsonData.included_weeks : reportJsonData.current_coaching_plan_week;
                        }
                    }

                    if (reportJsonData.payer_type !== undefined) {
                        if (reportJsonData.payer_type === 2) {
                            setPayerType(payerEnums.VA);
                        }
                        else if (reportJsonData.payer_type === 1) {
                            setPayerType(payerEnums.Reclaim);
                        }
                        else {
                            setPayerType(payerEnums.KarunaHome);
                        }
                    }

                    if (reportJsonData.coach !== undefined) {
                        setCoach(reportJsonData.coach);
                    }
                    if (reportJsonData.physician !== undefined) {
                        setPhysician(reportJsonData.physician);
                    }

                    if (reportJsonData.has_physician_oversight_agreement !== undefined) {
                        setOversight(reportJsonData.has_physician_oversight_agreement);
                    }

                    if (reportJsonData.subjective_outcomes !== undefined) {
                        var selected = reportJsonData.included_weeks !== 'All' ? reportJsonData.included_weeks : reportJsonData.current_coaching_plan_week;
                        var discoveries = [];
                        var goals = [];
                        var engage = [];
                        var prog = [];
                        var summary = [];
                        var tempDisc = Object.entries(reportJsonData.subjective_outcomes);

                        tempDisc.forEach(
                            temp => {
                                if ((week === 'M6' && temp[0] !== 0) || (temp[0] > 0 && temp[0] <= selected)) {

                                    if (temp[1].discoveries !== undefined) {
                                        discoveries.push(temp[1].discoveries);
                                    }
                                    if (temp[1].program_goals !== undefined) {
                                        if (temp[1].program_goals.goals !== undefined) {

                                            goals.push(Object.values(temp[1].program_goals.goals));
                                        }
                                    }

                                    if (temp[1].overall_subjective_progress !== undefined) {
                                        summary.push({ week: !isNaN(parseInt(temp[0])) ? parseInt(temp[0]) : 'M6', value: temp[1].overall_subjective_progress });
                                    }
                                    if (temp[1].level_of_engagement_in_program !== undefined) {
                                        engage.push({ week: !isNaN(parseInt(temp[0])) ? parseInt(temp[0]) : 'M6', value: temp[1].level_of_engagement_in_program });
                                    }
                                    if (temp[1].perceived_progress_towards_goals !== undefined) {
                                        prog.push({ week: !isNaN(parseInt(temp[0])) ? parseInt(temp[0]) : 'M6', value: temp[1].perceived_progress_towards_goals });
                                    }
                                }
                            }
                        );

                        setSummary(reportJsonData.customizations.customSummary);
                        if (reportJsonData.customizations.customHistoryInjury !== undefined) {
                            setHistoryInjury(reportJsonData.customizations.customHistoryInjury);
                        }
                        addGoalsManual(reportJsonData.customizations.customGoals, onGoalsRefresh, true);
                        addDiscoveriesManual(reportJsonData.customizations.customDiscovery, onDiscoveriesRefresh, true);
                        setSummaryOriginal(summary);
                        setGoalsOriginal(goals);
                        setDiscoveriesOriginal(discoveries);

                        if (prog.length > 0) {
                            setRawGoalProgressData(prog[prog.length - 1]);
                        }
                        else {
                            setRawGoalProgressData(null);
                        }

                        if (engage.length > 0) {
                            setRawEngagementLevelData(engage[engage.length - 1]);
                        }
                        else {
                            setRawEngagementLevelData(null);
                        }
                    }

                    if (reportJsonData?.condition_history) {
                        let history = [];
                        let histories = Object.entries(reportJsonData.condition_history);
                        histories.forEach(element => {
                            if ((week === 'M6' && (element[0] !== 0)) || (element[0] > 0 && element[0] <= selected)) {
                                if (element[1].length > 0) {
                                    var text = '';
                                    element[1].forEach(b => {
                                            if (b.charAt(b.length - 1) != '.') {b += '.';}

                                            text += b + '\r\n';

                                        }
                                    );
                                    history.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: text });
                                }

                            }
                        });
                        if (history.length > 0) {
                            setHistoryInjuryOriginal(history[history.length - 1].value.trim());
                        }
                        else {
                            setHistoryInjuryOriginal('');
                        }

                    }

                    let ImpComplete = [];

                    if (reportJsonData?.objective_outcomes) {
                        let objectiveOutcomes = reportJsonData.objective_outcomes;
                        var selected = reportJsonData.included_weeks !== 'All' ? reportJsonData.included_weeks : reportJsonData.current_coaching_plan_week;

                        if (objectiveOutcomes.adherence) {
                            let adherence = objectiveOutcomes.adherence;

                            if (adherence.sessions_attended) {
                                setRawSessionsAttendedData(adherence.sessions_attended);
                            }

                            if (adherence.unique_day_vet_usage_by_week !== undefined && Object.keys(adherence.unique_day_vet_usage_by_week).length > 0) {
                                let values = Object.keys(adherence.unique_day_vet_usage_by_week);
                                let sum = 0;

                                values.forEach(e => {
                                    if (parseInt(e) > 0 && parseInt(e) <= selected) {sum += adherence.unique_day_vet_usage_by_week[e];}
                                });
                                setRawUniqueDaysData((sum / selected).toFixed(1));
                            }

                            if (adherence.vet_seconds_by_week !== undefined && Object.keys(adherence.vet_seconds_by_week).length > 0) {
                                let values = Object.keys(adherence.vet_seconds_by_week);
                                let sum = 0;
                                values.forEach(e => {
                                    if (parseInt(e) > 0 && parseInt(e) <= selected) {sum += adherence.vet_seconds_by_week[e];}
                                });

                                let avg = (sum / selected);
                                let duration = `${(moment.duration(avg, 'seconds').hours() + (moment.duration(avg, 'seconds').days() * 24)) > 0
                                    ? ((moment.duration(avg, 'seconds').hours() + (moment.duration(avg, 'seconds').days() * 24)) + ' h and ')
                                    : ''}${moment.duration(avg, 'seconds').minutes()
                                    ? moment.duration(avg, 'seconds').minutes() + ' m '
                                    : ''}  ${moment.duration(avg, 'seconds').seconds() > 0 ? moment.duration(avg, 'seconds').seconds() + ' s' : ''}`;
                                setRawAverageTimeData(duration);
                            }
                        }

                        if (objectiveOutcomes.rom_improvements) {
                            let romImprovements = objectiveOutcomes.rom_improvements;
                            let leftFlex = [];
                            let leftAbd = [];
                            let leftSca = [];
                            let rightFlex = [];
                            let rightAbd = [];
                            let rightSca = [];
                            let lowerFlex = [];

                            Object.entries(romImprovements).forEach(element => {
                                let weekx = element[0];
                                if (weekx > 0 && weekx <= selected) {
                                    Object.entries(romImprovements[weekx]).forEach(
                                        e => {
                                            switch (e[0]) {
                                                case 'upper_extremity':
                                                    Object.entries(e[1]).forEach(arm => {
                                                        if (arm[0] === 'left') {
                                                            if (arm[1].abduction !== undefined) {
                                                                if (arm[1].abduction.max_measurement > -1) {
                                                                    leftAbd.push({ week: parseInt(weekx), value: Math.round(arm[1].abduction.max_measurement) });
                                                                }
                                                            }
                                                            if (arm[1].scaption !== undefined) {
                                                                if (arm[1].scaption.max_measurement > -1) {
                                                                    leftSca.push({ week: parseInt(weekx), value: Math.round(arm[1].scaption.max_measurement) });
                                                                }
                                                            }
                                                            if (arm[1].flexion !== undefined) {
                                                                if (arm[1].flexion.max_measurement > -1) {
                                                                    leftFlex.push({ week: parseInt(weekx), value: Math.round(arm[1].flexion.max_measurement) });
                                                                }
                                                            }
                                                        }
                                                        if (arm[0] === 'right') {
                                                            if (arm[1].abduction !== undefined) {
                                                                if (arm[1].abduction.max_measurement > -1) {
                                                                    rightAbd.push({ week: parseInt(weekx), value: Math.round(arm[1].abduction.max_measurement) });
                                                                }
                                                            }
                                                            if (arm[1].scaption !== undefined) {
                                                                if (arm[1].scaption.max_measurement > -1) {
                                                                    rightSca.push({ week: parseInt(weekx), value: Math.round(arm[1].scaption.max_measurement) });
                                                                }
                                                            }
                                                            if (arm[1].flexion !== undefined) {
                                                                if (arm[1].flexion.max_measurement > -1) {
                                                                    rightFlex.push({ week: parseInt(weekx), value: Math.round(arm[1].flexion.max_measurement) });
                                                                }
                                                            }
                                                        }
                                                    });
                                                    break;
                                                case 'lower_back':
                                                    Object.entries(e[1]).forEach(metric => {
                                                        if (metric[0] === 'flexion') {
                                                            if (metric[1].max_measurement > -1) {
                                                                lowerFlex.push({ week: parseInt(weekx), value: Math.round(metric[1].max_measurement) });
                                                            }
                                                        }
                                                    });
                                                    break;
                                            }
                                        }
                                    );
                                }
                            });

                            lowerFlex = removeDuplicateWeeklyMeasures(lowerFlex, LowerIsBetter(OutcomeMeasureTypes.lowerFlexion));
                            leftAbd = removeDuplicateWeeklyMeasures(leftAbd, LowerIsBetter(OutcomeMeasureTypes.leftAbduction));
                            leftSca = removeDuplicateWeeklyMeasures(leftSca, LowerIsBetter(OutcomeMeasureTypes.leftScaption));
                            leftFlex = removeDuplicateWeeklyMeasures(leftFlex, LowerIsBetter(OutcomeMeasureTypes.leftFlexion));
                            rightAbd = removeDuplicateWeeklyMeasures(rightAbd, LowerIsBetter(OutcomeMeasureTypes.RightAbduction));
                            rightSca = removeDuplicateWeeklyMeasures(rightSca, LowerIsBetter(OutcomeMeasureTypes.RightScaption));
                            rightFlex = removeDuplicateWeeklyMeasures(rightFlex, LowerIsBetter(OutcomeMeasureTypes.RightFlexion));

                            let hasCheck = false;

                            if (objectiveOutcomes?.rom_first_measurements) {
                                /**
                                 * Include only non-week-zero first ROM measurements in improvements data.
                                 */
                                const processFirstMeasurements = (processors) => {
                                    processors.map(
                                        ([measures, firstMeasures]) => {
                                            if (measures?.length > 0 && firstMeasures?.plan_week !== 0) {
                                                measures.unshift({ week: parseInt(firstMeasures.plan_week), value: Math.round(firstMeasures.first_measurement) });
                                                setUseCheck(true);
                                                hasCheck = true;
                                            }
                                        });
                                };

                                let romFirstMeasurements = objectiveOutcomes.rom_first_measurements;
                                let ueLeftFirsts = romFirstMeasurements.upper_extremity?.left;
                                let ueRightFirsts = romFirstMeasurements.upper_extremity?.right;
                                let lbFirsts = romFirstMeasurements.lower_back;
                                let firstMeasurementProcessors = [
                                    [leftAbd, ueLeftFirsts?.['abduction']],
                                    [leftSca, ueLeftFirsts?.['scaption']],
                                    [leftAbd, ueLeftFirsts?.['flexion']],
                                    [rightAbd, ueRightFirsts?.['abduction']],
                                    [rightSca, ueRightFirsts?.['scaption']],
                                    [rightFlex, ueRightFirsts?.['flexion']],
                                    [lowerFlex, lbFirsts?.['flexion']]
                                ];

                                processFirstMeasurements(firstMeasurementProcessors);
                            }

                            if (rightAbd.length === 0 && rightSca.length === 0 && rightFlexion.length === 0 && leftAbd.length === 0 && leftSca.length === 0 && leftFlex.length === 0 && lowerFlex === 0) {
                                setUseCheck(true);
                            }

                            setRightAbductionData(rightAbd);
                            setRightScaptionData(rightSca);
                            setRightFlexionData(rightFlex);
                            setLeftAbductionData(leftAbd);
                            setLeftScaptionData(leftSca);
                            setLeftFlexionData(leftFlex);
                            setLowerFlexionData(lowerFlex);

                            /**
                             * Process improvements.
                             */
                            const processImprovements = (processors) => {
                                processors.map(
                                    ([measureType, measureName, measures, setter]) => {
                                        if (measures?.length > 0) {
                                            let bestMeasurement = bestRomMeasurement(measures, 3, !hasCheck);
                                            let improvement = normalizedRomImprovementPercent(measureType, measures[0].value, bestMeasurement.value);
                                            let improvementObj = createMeasurementImprovement(measureType, measureName, measures, improvement, bestMeasurement);
                                            setter && setter(improvement);
                                            ImpComplete.push(improvementObj);
                                        }
                                    });
                            };

                            processImprovements([
                                [OutcomeMeasureTypes.LeftAbduction, 'Left Shoulder Abduction', leftAbd, setLeftAbductionImp],
                                [OutcomeMeasureTypes.LeftScaption, 'Left Shoulder Scaption', leftSca, setLeftScaptionImp],
                                [OutcomeMeasureTypes.LeftFlexion, 'Left Shoulder Flexion', leftFlex, setLeftFlexionImp],
                                [OutcomeMeasureTypes.RightAbduction, 'Right Shoulder Abduction', rightAbd, setRightAbductionImp],
                                [OutcomeMeasureTypes.RightScaption, 'Right Shoulder Scaption', rightSca, setRightScaptionImp],
                                [OutcomeMeasureTypes.RightFlexion, 'Right Shoulder Flexion', rightFlex, setRightFlexionImp],
                                [OutcomeMeasureTypes.LowerFlexion, 'Lower Back Flexion (Estimate)', lowerFlex, setLowerFlexionImp]
                            ]);
                        }
                        else {
                            setUseCheck(true);
                        }

                        if (objectiveOutcomes?.measurements) {
                            let measurements = objectiveOutcomes.measurements;
                            let pain = [];
                            let mood = [];
                            let confidence = [];
                            let activity = [];
                            let sleep = [];
                            let osw = [];
                            let dash = [];
                            let pcs = [];
                            let pseq = [];
                            let uefi = [];
                            let FABQT = [];
                            let FABQA = [];
                            let FABQW = [];
                            let pcss = [];
                            let whodas = [];
                            let gad7 = [];
                            let phq9 = [];
                            let ndi = [];
                            let meds = [];
                            let work = [];

                            Object.entries(measurements).forEach(element => {
                                element[1].forEach(e => {
                                    if ((week === 'M6' && element[0] !== 0) || (element[0] > 0 && element[0] <= selected)) {
                                        switch (true) {
                                            case e.label.includes('PCSS'):
                                                pcss.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('WHODAS'):
                                                whodas.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseFloat(e.value) });
                                                break;
                                            case e.label.includes('PHQ9'):
                                                phq9.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('GAD-7'):
                                                gad7.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('NDI'):
                                                ndi.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Sleep'):
                                                sleep.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Confidence'):
                                                confidence.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Activity') && !e.label.includes('FABQ'):
                                                activity.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Pain'):
                                                pain.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Mood'):
                                                mood.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('PSEQ Score'):
                                                pseq.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('DASH Score'):
                                                var trueScore = ((e.value / 30) - 1) * 25;
                                                dash.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(trueScore) });
                                                break;
                                            case e.label.includes('PCS Score'):
                                                pcs.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('UEFI Score'):
                                                uefi.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Oswestry Score'):
                                                osw.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Work Status'):
                                                work.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('Medication'):
                                                meds.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('FABQ-Total'):
                                                FABQT.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('FABQ-Work'):
                                                FABQW.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                            case e.label.includes('FABQ-Physical'):
                                                FABQA.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: parseInt(e.value) });
                                                break;
                                        }
                                    }
                                });
                            });

                            pain = removeDuplicateWeeklyMeasures(pain, LowerIsBetter(OutcomeMeasureTypes.Pain));
                            mood = removeDuplicateWeeklyMeasures(mood, LowerIsBetter(OutcomeMeasureTypes.Mood));
                            confidence = removeDuplicateWeeklyMeasures(confidence, LowerIsBetter(OutcomeMeasureTypes.Confidence));
                            activity = removeDuplicateWeeklyMeasures(activity, LowerIsBetter(OutcomeMeasureTypes.Activity));
                            sleep = removeDuplicateWeeklyMeasures(sleep, LowerIsBetter(OutcomeMeasureTypes.Sleep));
                            osw = removeDuplicateWeeklyMeasures(osw, LowerIsBetter(OutcomeMeasureTypes.Oswestry));
                            dash = removeDuplicateWeeklyMeasures(dash, LowerIsBetter(OutcomeMeasureTypes.Dash));
                            pcs = removeDuplicateWeeklyMeasures(pcs, LowerIsBetter(OutcomeMeasureTypes.PCS));
                            pseq = removeDuplicateWeeklyMeasures(pseq, LowerIsBetter(OutcomeMeasureTypes.PSEQ));
                            uefi = removeDuplicateWeeklyMeasures(uefi, LowerIsBetter(OutcomeMeasureTypes.UEFI));
                            FABQT = removeDuplicateWeeklyMeasures(FABQT, LowerIsBetter(OutcomeMeasureTypes.FABQTotal));
                            FABQA = removeDuplicateWeeklyMeasures(FABQA, LowerIsBetter(OutcomeMeasureTypes.FABQActivity));
                            FABQW = removeDuplicateWeeklyMeasures(FABQW, LowerIsBetter(OutcomeMeasureTypes.FABQWork));
                            pcss = removeDuplicateWeeklyMeasures(pcss, LowerIsBetter(OutcomeMeasureTypes.PCSS));
                            whodas = removeDuplicateWeeklyMeasures(whodas, LowerIsBetter(OutcomeMeasureTypes.WHODAS));
                            gad7 = removeDuplicateWeeklyMeasures(gad7, LowerIsBetter(OutcomeMeasureTypes.GAD7));
                            phq9 = removeDuplicateWeeklyMeasures(phq9, LowerIsBetter(OutcomeMeasureTypes.PHQ9));
                            ndi = removeDuplicateWeeklyMeasures(ndi, LowerIsBetter(OutcomeMeasureTypes.NDI));

                            setNDIData(ndi);
                            setGAD7Data(gad7);
                            setPCSSData(pcss);
                            setWHODASData(whodas);
                            setPHQ9Data(phq9);
                            setSleepData(sleep);
                            setOwestryData(osw);
                            setDashData(dash);
                            setActivityData(activity);
                            setPCSData(pcs);
                            setPSEQData(pseq);
                            setConfidenceData(confidence);
                            setMoodData(mood);
                            setPainData(pain);
                            setUEFIData(uefi);
                            setFABQActivityData(FABQA);
                            setFABQTotalData(FABQT);
                            setFABQWorkData(FABQW);

                            if (work.length > 0) {
                                setReturnToWorkData(work);
                            }
                            if (meds.length > 0) {
                                setMedReductionData(meds);
                                setMedImp(getMedicationReductionResult(meds));
                            }

                            /**
                             * Process improvements.
                             */
                            const processImprovements = (processors) => {
                                processors.map(
                                    ([measureType, measureName, measures, setter]) => {
                                        if (measures?.length > 0) {
                                            let improvement = getMeasurementDelta(measures[0].value, measures[measures.length - 1].value);
                                            let improvementObj = createMeasurementImprovement(measureType, measureName, measures, improvement);
                                            setter && setter(improvement);
                                            ImpComplete.push(improvementObj);
                                        }
                                    });
                            };

                            processImprovements([
                                [OutcomeMeasureTypes.Sleep, 'Sleep', sleep, setSleepImp],
                                [OutcomeMeasureTypes.Oswestry, 'Oswestry', osw, setOwestryImp],
                                [OutcomeMeasureTypes.DASH, 'DASH', dash, setDashImp],
                                [OutcomeMeasureTypes.Activity, 'Activity', activity, setActivityImp],
                                [OutcomeMeasureTypes.PCS, 'PCS', pcs, setPCSImp],
                                [OutcomeMeasureTypes.PSEQ, 'PSEQ', pseq, setPSEQImp],
                                [OutcomeMeasureTypes.Confidence, 'Confidence', confidence, setConfidenceImp],
                                [OutcomeMeasureTypes.Mood, 'Mood', mood, setMoodImp],
                                [OutcomeMeasureTypes.Pain, 'Pain', pain, setPainImp],
                                [OutcomeMeasureTypes.UEFI, 'UEFI', uefi, setUEFIImp],
                                [OutcomeMeasureTypes.FABQTotal, 'FABQ Total', FABQT, setFABQTotalImp],
                                [OutcomeMeasureTypes.FABQActivity, 'FABQ Activity', FABQA, setFABQActivityImp],
                                [OutcomeMeasureTypes.FABQWork, 'FABQ Work', FABQW, setFABQWorkImp],
                                [OutcomeMeasureTypes.PCSS, 'PCSS', pcss, setPCSSImp],
                                [OutcomeMeasureTypes.NDI, 'NDI', ndi, setNDIImp],
                                [OutcomeMeasureTypes.WHODAS, 'WHODAS', whodas, setWHODASImp],
                                [OutcomeMeasureTypes.PHQ9, 'PHQ9', phq9, setPHQ9Imp],
                                [OutcomeMeasureTypes.GAD7, 'GAD7', gad7, setGAD7Imp]
                            ]);
                        }

                        if (objectiveOutcomes.ratings) {
                            let ratings = objectiveOutcomes.ratings;
                            const karunaRatings = Object.entries(ratings).map(
                                ([week, value]) => ({
                                    week: !isNaN(parseInt(week)) ? parseInt(week) : 'M6', value
                                })
                            );
                            setKarunaRatingData(karunaRatings);

                            /**
                             * Process improvements.
                             */
                            const processImprovements = (processors) => {
                                processors?.map(
                                    ([measureType, measureName, measures, setter]) => {
                                        if (measures?.length > 0) {
                                            let improvement = getMeasurementDelta(measures[0].value, measures[measures.length - 1].value);
                                            let improvementObj = createMeasurementImprovement(measureType, measureName, measures, improvement);
                                            setter && setter(improvement);
                                            ImpComplete.push(improvementObj);
                                        }
                                    });
                            };

                            processImprovements([
                                [OutcomeMeasureTypes.KarunaRating, 'Karuna Ratings', karunaRatings, setKarunaRatingImp]
                            ]);
                        }
                    }

                    if (reportJsonData.assessments) {
                        var physical = [];
                        var behave = [];
                        var social = [];
                        var assessments = Object.entries(reportJsonData.assessments);
                        assessments.forEach(element => {
                            if ((week === 'M6' && (element[0] !== 0)) || (element[0] > 0 && element[0] <= selected)) {

                                if (element[1].physical !== undefined && element[1].physical.length > 0) {
                                    var text = '';
                                    element[1].physical.forEach(b => {
                                            if (b.charAt(b.length - 1) != '.') {b += '.';}
                                            text += b + '\r\n';
                                        }
                                    );
                                    physical.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: text });
                                }

                                if (element[1].behavioral !== undefined && element[1].behavioral.length > 0) {
                                    var text = '';
                                    element[1].behavioral.forEach(b => {
                                            if (b.charAt(b.length - 1) != '.') {b += '.';}
                                            text += b + '\r\n';
                                        }
                                    );
                                    behave.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: text });

                                }

                                if (element[1].social !== undefined && element[1].social.length > 0) {
                                    var text = '';
                                    element[1].social.forEach(b => {
                                            if (b.charAt(b.length - 1) != '.') {b += '.';}
                                            text += b + '\r\n';
                                        }
                                    );
                                    social.push({ week: !isNaN(element[0]) ? parseInt(element[0]) : 'M6', value: text });
                                }
                            }
                        });

                        if (physical.length > 0) {
                            setPhysicalTextOriginal(physical[physical.length - 1].value);
                        }
                        else {
                            setPhysicalTextOriginal('');
                        }

                        if (behave.length > 0) {
                            setBehavioralTextOriginal(behave[behave.length - 1].value);
                        }
                        else {
                            setBehavioralTextOriginal('');
                        }

                        if (social.length > 0) {
                            setSocialTextOriginal(social[social.length - 1].value);
                        }
                        else {
                            setSocialTextOriginal('');
                        }

                        setPhysicalText(reportJsonData.customizations.customPhysical);
                        setBehavioralText(reportJsonData.customizations.customBehavioral);
                        setSocialText(reportJsonData.customizations.customSocial);
                        if (reportJsonData.customizations.customContinuedNeed) {
                            setContinuedNeedText(reportJsonData.customizations.customContinuedNeed);
                        }
                    }

                    if (reportJsonData.plan) {
                        setPlan(reportJsonData.plan);
                    }
                    if (reportJsonData.dob) {
                        setDOB(moment(reportJsonData.dob).format('MM/DD/YYYY'));
                    }
                    if (reportJsonData.claim_number) {
                        setClaimNumber(reportJsonData.claim_number);
                    }
                    if (reportJsonData.date_of_injury) {
                        if (reportJsonData.date_of_injury !== 'Unknown') {
                            setDOI(moment(reportJsonData.date_of_injury).format('MM/DD/YYYY'));
                        }
                        else {
                            setDOI(reportJsonData.date_of_injury);
                        }
                    }
                    if (reportJsonData.preparer) {
                        setPreparer(reportJsonData.preparer);
                    }
                    if (reportJsonData.date) {
                        let dateMod = moment(reportJsonData.date).format('MM/DD/YYYY');
                        setPrepareDate(dateMod);
                    }
                    if (reportJsonData.patient) {
                        setPatientNameCheck(reportJsonData.patient);
                        setPatientId(reportJsonData.patient_id);
                        if (reportJsonData.payer_type === undefined) {
                            setPatientName(reportJsonData.patient);
                        }
                        else {
                            if (reportJsonData.payer_type === 2) {
                                setPatientName('Patient');
                            }
                            else {
                                setPatientName(reportJsonData.patient);
                            }
                        }
                    }
                    ImpComplete.sort((a, b) => b.normalizedPercent - a.normalizedPercent);

                    if (!reportJsonData.customizations.selectedImp) {
                        let index = 0;
                        let selectedImp = ImpComplete && ImpComplete.map((option) => {
                            if (option.ImprovedValue > 0 && index < 5) {
                                index++;
                                return option.type;
                            }
                        });
                        selectedImp = selectedImp.filter(n => n);
                        setSelectedImprovements(selectedImp);
                    }
                    else {
                        setSelectedImprovements(reportJsonData.customizations.selectedImp);
                    }

                    setImprovementsComplete(ImpComplete);
                    setData(reportJsonData);
                }
            )
            .catch(
                error => {
                    setErrors(error);
                }
            );
    };

    const dispatchSaveReport = async () => {
        setLoadingMessage('Saving Outcome Report');
        setIsLoading(true);
        setErrors('');

        data.plan = plan;
        data.included_weeks = reportWeek !== 'M6' ? reportWeek : currentWeek;
        data.isFutureReport = reportWeek === 'M6';

        data.enabled = {};
        data.enabled.goalProgress = goalProgress;
        data.enabled.engagmentLevel = engagementLevel;
        data.enabled.sessionsAttended = sessionsAttended;
        data.enabled.uniqueDays = uniqueDays;
        data.enabled.averageTime = averageTime;
        data.enabled.leftFlexion = leftFlexion;
        data.enabled.leftScaption = leftScaption;
        data.enabled.leftAbduction = leftAbduction;
        data.enabled.rightFlexion = rightFlexion;
        data.enabled.rightScaption = rightScaption;
        data.enabled.rightAbduction = rightAbduction;
        data.enabled.lowerFlexoion = lowerFlexion;
        data.enabled.pain = pain;
        data.enabled.mood = mood;
        data.enabled.activity = activity;
        data.enabled.sleep = sleep;
        data.enabled.confidence = confidence;
        data.enabled.returnToWork = returnToWork;
        data.enabled.medicationReduction = medReduction;
        data.enabled.oswestry = oswestry;
        data.enabled.Dash = Dash;
        data.enabled.PSEQ = PSEQ;
        data.enabled.PCS = PCS;
        data.enabled.UEFI = UEFI;
        data.enabled.PCSS = PCSS;
        data.enabled.WHODAS = WHODAS;
        data.enabled.NDI = NDI;
        data.enabled.GAD7 = GAD7;
        data.enabled.PHQ9 = PHQ9;
        data.enabled.KarunaRating = KarunaRating;
        data.enabled.social = social;
        data.enabled.physical = physical;
        data.enabled.behavioral = behavioral;
        data.enabled.FABQTotal = FABQTotal;
        data.enabled.FABQActivity = FABQActivity;
        data.enabled.FABQWork = FABQWork;

        data.customizations = {};
        data.customizations.customSummary = summary;
        data.customizations.customGoals = goals.map(e => [e.goal, e.detail]);
        data.customizations.customDiscovery = discoveries.split('\n\n').filter(function (el) {return el !== '';});
        data.customizations.customSocial = socialText;
        data.customizations.customPhysical = physicalText;
        data.customizations.customBehavioral = behavioralText;
        data.customizations.customContinuedNeed = continuedNeedText;
        data.customizations.customHistoryInjury = HistoryInjury;
        data.customizations.selectedImp = selectedImprovements;

        await API
            .put(
                `/outcome_reports/${reportId}/`,
                { report_json_data: data }
            )
            .then(
                response => {
                    if (!KarunaCLOUD_responseValidator(response, setErrors)) {
                        return;
                    }

                    let successMessage = `Saved: ${patientName} ${reportWeek === 'M6' ? '6 Months Post' : `Week ${reportWeek.toString()}`} Outcome Report`;
                    localStorage.setItem('success', successMessage);
                    window.location.reload(false);
                }
            )
            .catch(
                error => {
                    console.error(error);
                    setErrors(error.message);
                }
            )
            .finally(
                () => {
                    setIsLoading(false);
                }
            );
    };

    const dispatchSaveReportDraft = () => {
        let text = 'Warning!\n\nAre you sure you want to save the current version of this report as your working draft?\n\nThe originally submitted version of the report will be overwritten with any changes you have made and cannot be recovered.';
        if (!window.confirm(text)) {
            return;
        }

        if (validatePlan(
            reportWeek,
            hasPlan,
            plan,
            assessment,
            social,
            socialText,
            physical,
            physicalText,
            behavioral,
            behavioralText,
            continuedNeed,
            continuedNeedText,
            summary,
            patientNameCheck,
            payerType,
            // Callbacks
            setErrors
        )) {
            setGoals(getGoals(true));
            setDiscoveries(getDiscoveries(true));
            setSaveReport(true);
        }
    };

    const dispatchApproveReport = () => {
        if (validatePlan(
            reportWeek,
            hasPlan,
            plan,
            assessment,
            social,
            socialText,
            physical,
            physicalText,
            behavioral,
            behavioralText,
            continuedNeed,
            continuedNeedText,
            summary,
            patientNameCheck,
            payerType,
            // Callbacks
            setErrors
        )) {
            setGoals(getGoals(true));
            setDiscoveries(getDiscoveries(true));
            setUpload(true);
        }
    };

    const dispatchPreviewPDF = () => {
        if (validatePlan(
            reportWeek,
            hasPlan,
            plan,
            assessment,
            social,
            socialText,
            physical,
            physicalText,
            behavioral,
            behavioralText,
            continuedNeed,
            continuedNeedText,
            summary,
            patientNameCheck,
            payerType,
            // Callbacks
            setErrors
        )) {
            setGoals(getGoals(true));
            setDiscoveries(getDiscoveries(true));
            setGenerate(true);
        }
    };

    const deleteReport = async () => {
        setIsLoading(true);
        setLoadingMessage('Deleting Outcome Report');

        await API
            .get(`/outcome_reports/${reportId}/delete`)
            .then(
                _response => {
                    let successMessage = `Deleted: ${patientName} ${reportWeek === 'M6' ? '6 Months Post' : `Week ${reportWeek.toString()}`} Outcome Report`;
                    localStorage.setItem('success', successMessage);
                    history.push('/reports', { state: { message: successMessage } });
                }
            )
            .catch(
                error => {
                    setErrors(error);
                    setIsLoading(false);
                }
            );
    };

    const dispatchDeleteReport = () => {
        let text = 'WARNING!\n\nAre you sure you want to delete this Outcome Report?\n\nThere is no way to recover it once deleted!\n\nPress OK to delete or Cancel to return to editing.';
        if (window.confirm(text) === true) {
            void deleteReport();
        }
    };

    // ---=== Improvements ===--- //

    const hasImprovements = () => {
        return ImprovementsComplete?.filter(improvement => improvement.ImprovedValue > 0).length > 0;
    };

    const hasSelectedImprovements = () => {
        return selectedImprovements?.length > 0;
    };

    const removeItemAll = (arr, value) => {
        let i = 0;
        while (i < arr.length) {
            if (arr[i] === value) {
                arr.splice(i, 1);
            }
            else {
                ++i;
            }
        }
        return arr;
    };

    const hasSelectedImprovement = (measureType) => {
        return selectedImprovements.includes(measureType);
    };

    const addSelectedImprovement = (measureType) => {
        setSelectedImprovements((prev) => {
            if (!prev.includes(measureType)) {
                prev.push(measureType);
            }
            return prev;
        });
    };

    const removeSelectedImprovement = (measureType) => {
        setSelectedImprovements((prev) => {
            if (prev.includes(measureType)) {
                removeItemAll(prev, measureType);
            }
            return prev;
        });
    };

    // ---=== Measurements ===--- //

    const getMeasureFromType = (measureType) => {
        let measure = null;
        ImprovementsComplete.forEach(e => {
            if (e.type === measureType) {
                measure = e;
            }
        });
        return measure;
    };

    const forceToggleMeasure = (measureType, value) => {
        switch (measureType) {
            case OutcomeMeasureTypes.FABQActivity:
                setFABQActivity(value);
                break;
            case OutcomeMeasureTypes.FABQTotal:
                setFABQTotal(value);
                break;
            case OutcomeMeasureTypes.FABQWork:
                setFABQWork(value);
                break;
            case OutcomeMeasureTypes.PCS:
                setPCS(value);
                break;
            case OutcomeMeasureTypes.PSEQ:
                setPSEQ(value);
                break;
            case OutcomeMeasureTypes.PHQ9:
                setPHQ9(value);
                break;
            case OutcomeMeasureTypes.GAD7:
                setGAD7(value);
                break;
            case OutcomeMeasureTypes.Oswestry:
                setOwestry(value);
                break;
            case OutcomeMeasureTypes.DASH:
                setDash(value);
                break;
            case OutcomeMeasureTypes.NDI:
                setNDI(value);
                break;
            case OutcomeMeasureTypes.WHODAS:
                setWHODAS(value);
                break;
            case OutcomeMeasureTypes.UEFI:
                setUEFI(value);
                break;
            case OutcomeMeasureTypes.Pain:
                setPain(value);
                break;
            case OutcomeMeasureTypes.PCSS:
                setPCSS(value);
                break;
            case OutcomeMeasureTypes.Activity:
                setActivity(value);
                break;
            case OutcomeMeasureTypes.Confidence:
                setConfidence(value);
                break;
            case OutcomeMeasureTypes.Sleep:
                setSleep(value);
                break;
            case OutcomeMeasureTypes.Mood:
                setMood(value);
                break;
            case OutcomeMeasureTypes.LowerFlexion:
                setLowerFlexion(value);
                break;
            case OutcomeMeasureTypes.LeftFlexion:
                setLeftFlexion(value);
                break;
            case OutcomeMeasureTypes.LeftAbduction:
                setLeftAbduction(value);
                break;
            case OutcomeMeasureTypes.LeftScaption:
                setLeftScaption(value);
                break;
            case OutcomeMeasureTypes.RightFlexion:
                setRightFlexion(value);
                break;
            case OutcomeMeasureTypes.RightAbduction:
                setRightAbduction(value);
                break;
            case OutcomeMeasureTypes.RightScaption:
                setRightScaption(value);
                break;
            case OutcomeMeasureTypes.KarunaRating:
                setKarunaRating(value);
                break;

        }
    };

    // const checkIfMeasurePercentIsIncluded = (measureType) => {
    //
    //     switch (measureType) {
    //         case OutcomeMeasureTypes.FABQActivity:
    //             return FABQActivity;
    //         case OutcomeMeasureTypes.FABQTotal:
    //             return FABQTotal;
    //         case OutcomeMeasureTypes.FABQWork:
    //             return FABQWork;
    //         case OutcomeMeasureTypes.PCS:
    //             return PCS;
    //         case OutcomeMeasureTypes.PSEQ:
    //             return PSEQ;
    //         case OutcomeMeasureTypes.PHQ9:
    //             return PHQ9;
    //         case OutcomeMeasureTypes.GAD7:
    //             return GAD7;
    //         case OutcomeMeasureTypes.Oswestry:
    //             return oswestry;
    //         case OutcomeMeasureTypes.DASH:
    //             return Dash;
    //         case OutcomeMeasureTypes.NDI:
    //             return NDI;
    //         case OutcomeMeasureTypes.WHODAS:
    //             return WHODAS;
    //         case OutcomeMeasureTypes.UEFI:
    //             return UEFI;
    //         case OutcomeMeasureTypes.Pain:
    //             return pain;
    //         case OutcomeMeasureTypes.PCSS:
    //             return PCSS;
    //         case OutcomeMeasureTypes.Activity:
    //             return activity;
    //         case OutcomeMeasureTypes.Confidence:
    //             return confidence;
    //         case OutcomeMeasureTypes.Sleep:
    //             return sleep;
    //         case OutcomeMeasureTypes.Medication:
    //             return medReduction;
    //         case OutcomeMeasureTypes.Work:
    //             return returnToWork;
    //         case OutcomeMeasureTypes.Mood:
    //             return mood;
    //         case OutcomeMeasureTypes.LowerFlexion:
    //             return lowerFlexion;
    //         case OutcomeMeasureTypes.KarunaRating:
    //             return KarunaRating;
    //     }
    // };

    const checkIfMeasureIsIncluded = (measureType) => {
        switch (measureType) {
            case OutcomeMeasureTypes.FABQActivity:
                return FABQActivity;
            case OutcomeMeasureTypes.FABQTotal:
                return FABQTotal;
            case OutcomeMeasureTypes.FABQWork:
                return FABQWork;
            case OutcomeMeasureTypes.PCS:
                return PCS;
            case OutcomeMeasureTypes.PSEQ:
                return PSEQ;
            case OutcomeMeasureTypes.PHQ9:
                return PHQ9;
            case OutcomeMeasureTypes.GAD7:
                return GAD7;
            case OutcomeMeasureTypes.Oswestry:
                return oswestry;
            case OutcomeMeasureTypes.DASH:
                return Dash;
            case OutcomeMeasureTypes.NDI:
                return NDI;
            case OutcomeMeasureTypes.WHODAS:
                return WHODAS;
            case OutcomeMeasureTypes.UEFI:
                return UEFI;
            case OutcomeMeasureTypes.Pain:
                return pain;
            case OutcomeMeasureTypes.PCSS:
                return PCSS;
            case OutcomeMeasureTypes.Activity:
                return activity;
            case OutcomeMeasureTypes.Confidence:
                return confidence;
            case OutcomeMeasureTypes.Sleep:
                return sleep;
            case OutcomeMeasureTypes.Medication:
                return medReduction;
            case OutcomeMeasureTypes.Work:
                return returnToWork;
            case OutcomeMeasureTypes.Mood:
                return mood;
            case OutcomeMeasureTypes.KarunaRating:
                return KarunaRating;
            case OutcomeMeasureTypes.LeftFlexion:
                return leftFlexion;
            case OutcomeMeasureTypes.RightFlexion:
                return rightFlexion;
            case OutcomeMeasureTypes.LeftScaption:
                return leftScaption;
            case OutcomeMeasureTypes.RightScaption:
                return rightScaption;
            case OutcomeMeasureTypes.LeftAbduction:
                return leftAbduction;
            case OutcomeMeasureTypes.RightAbduction:
                return rightAbduction;
            case OutcomeMeasureTypes.LowerFlexion:
                return lowerFlexion;
        }
    };

    const removeDuplicateWeeklyMeasures = (measures, wantLower) => {
        let duplicateWeek = {};
        let toRemove = [];
        measures.forEach((e, index) => {
            if (duplicateWeek[e.week] !== undefined) {
                duplicateWeek[e.week].push(index);
            }
            else {
                duplicateWeek[e.week] = [index];
            }
        });

        for (const key in duplicateWeek) {
            if (duplicateWeek[key].length > 1) {
                if (wantLower) {
                    let index = duplicateWeek[key][0];
                    let lowest = measures[index].value;
                    duplicateWeek[key].forEach(i => {
                        if (measures[i].value < lowest) {
                            index = i;
                            lowest = measures[i].value;
                        }
                    });
                    duplicateWeek[key].forEach(i => {
                        if (i !== index) {
                            toRemove.push(i);
                        }
                    });
                }
                else {
                    let index = duplicateWeek[key][0];
                    let highest = measures[index].value;
                    duplicateWeek[key].forEach(i => {
                        if (measures[i].value > highest) {
                            index = i;
                            highest = measures[i].value;
                        }
                    });
                    duplicateWeek[key].forEach(i => {
                        if (i !== index) {
                            toRemove.push(i);
                        }
                    });
                }
            }
        }

        toRemove.sort((a, b) => b - a);

        for (const index of toRemove) {
            if (index >= 0 && index < measures.length) {
                measures.splice(index, 1);
            }
        }

        return measures;
    };

    const removeNoneFirstMeasure = (measures) => {
        let newMeasures = [...measures];
        newMeasures.splice(1, 1);
        return newMeasures;
    };

    // ---=== Key Improvements Graph ===--- //

    const reportWeekLabels = () => {
        let labels = [];
        if (reportWeek === 'M6') {
            for (let i = 1; i <= currentWeek; i++) {
                labels.push(`Week ${i}`);
            }
            labels.push('6 Month');
        }
        else {
            for (let i = 1; i <= reportWeek; i++) {
                labels.push(`Week ${i}`);
            }
        }

        return labels;
    };

    const nudgeArray = (arr) => {
        arr.map(n => n);

        if (arr.length === 2) {
            if (arr[0].value === 0) {
                arr[1].value += 1;
            }
            else {
                arr[0].value -= 1;
            }
        }

        if (arr.length === 3) {
            if (arr[0].value === 0) {
                arr[1].value += 1;
                arr[2].value += 2;
            }
            else if (arr[0].value >= 99) {
                arr[1].value -= 1;
                arr[2].value -= 2;
            }
            else {
                arr[0].value -= 1;
                arr[2].value += 1;
            }
        }
        if (arr.length === 4) {
            if (arr[0].value <= 1) {
                arr[1].value += 1;
                arr[2].value += 2;
                arr[3].value += 3;
            }
            else if (arr[0].value >= 99) {
                arr[1].value -= 1;
                arr[2].value -= 2;
                arr[3].value -= 3;
            }
            else {
                arr[0].value -= 2;
                arr[1].value -= 1;
                arr[3].value += 1;
            }
        }

        if (arr.length === 5) {
            if (arr[0].value <= 1) {
                arr[1].value += 1;
                arr[2].value += 2;
                arr[3].value += 3;
                arr[4].value += 4;
            }
            else if (arr[0].value >= 99) {
                arr[1].value -= 1;
                arr[2].value -= 2;
                arr[3].value -= 3;
                arr[4].value -= 4;
            }
            else {
                arr[0].value -= 2;
                arr[1].value -= 1;
                arr[3].value += 1;
                arr[4].value += 2;
            }
        }

        return arr;
    };

    const nudgeValues = (improvementSelections) => {
        let selections = {};
        let measures = [];

        improvementSelections.forEach((selection, index) => {
            let measure = getMeasureFromType(selection);
            measure.nudged = [];
            measures.push(measure);
            measure.value.forEach((n, index2) => {
                if (n.week in selections) {
                    if (Math.round(measure.normalizedValues[index2]) in selections[n.week]) {
                        selections[n.week][Math.round(measure.normalizedValues[index2])].push(
                            {
                                value: measure.normalizedValues[index2],
                                index: index,
                                index2: index2
                            }
                        );
                    }
                    else {
                        selections[n.week][Math.round(measure.normalizedValues[index2])] = [
                            {
                                value: measure.normalizedValues[index2],
                                index: index,
                                index2: index2
                            }
                        ];
                    }
                }
                else {
                    selections[n.week] = {};
                    selections[n.week][Math.round(measure.normalizedValues[index2])] = [
                        {
                            value: measure.normalizedValues[index2],
                            index: index,
                            index2: index2
                        }
                    ];
                }
            });
        });

        for (let i in selections) {
            for (let j in selections[i]) {
                let nudged = nudgeArray(selections[i][j]);
                nudged.forEach(n => {
                    measures[n.index].nudged.push(n.value);
                });
            }
        }

        return measures;
    };

    const getTrueGraphVal = (metrics, label, week) => {
        let validatedWeek = (week === 'M6' ? week : parseInt(week.split(' ')[1]));
        let result = -1;
        metrics.forEach(m => {
            if (m.name === label) {
                m.value.forEach((val, index) => {
                    if (val.week === validatedWeek) {
                        result = m.normalizedValues[index];
                        result = Math.round(result * 10) / 10;
                        return result;
                    }
                });
            }
        });

        return result;
    };

    const configureGraphPoints = (values, normalizedValues) => {
        let week = 1;
        let index = 0;
        let result = [];

        while (index < values.length) {
            let obj = values[index];
            if (obj.week === week || obj.week === 'M6') {
                result.push(normalizedValues[index]);
                index++;
                week++;
            }
            else {
                result.push(null);
                week++;
            }
            if (week > values[values.length - 1].week) {
                break;
            }
        }
        return result;
    };

    const configureGraph = () => {
        let metrics = nudgeValues(selectedImprovements);

        return metrics.map(
            (measure, index) => {
                let dataset = isRomType(measure.type) ? configureGraphPoints(removeNoneFirstMeasure(measure.value), removeNoneFirstMeasure(measure.nudged)) : configureGraphPoints(measure.value, measure.nudged); // Specify the data values array
                return {
                    label: measure.name,
                    connectNullData: true,
                    data: dataset,
                    fill: false,
                    borderWidth: 1, // Specify bar border width
                    spanGaps: true,
                    borderColor: colors[index],
                    backgroundColor: colors[index],
                    segment: {
                        borderDash: ctx => {
                            return dataset[ctx.p0DataIndex] === null || dataset[ctx.p1DataIndex] === null ? [10, 19] : [];
                        }
                    }
                };
            }
        );
    };

    // ---=== Render ===--- //

    useEffect(
        () => {
            void getReport();
        }, []
    );

    useEffect(
        () => {
            if (data) {
                let head = {};
                head.trail = breadcrumbLinks();
                head.body = {};
                head.body.subheader = 'Review ' + (data.isFutureReport ? '6 Months Post' : 'Week ' + data.included_weeks) + ' Outcome Report';
                head.body.name = data.patient;
                head.body.buttons = [
                    {
                        title: 'Back',
                        img: '/icons/flip-backward.svg',
                        click: goToHome
                    },
                    {
                        title: 'Preview Final PDF',
                        img: '/icons/file-download-02-black.svg',
                        click: (() => {setPreviewPDF(true);})
                    },
                    {
                        title: 'Save Draft',
                        img: '/icons/save-01-black.svg',
                        click: (() => {setSaveReportDraft(true);})
                    }
                ];
                setHeaderInfo(head);
            }
        }, [data]
    );

    useEffect(
        () => {
            if (refresh) {
                setRefresh(false);
                void getReport();
            }
        }, [refresh, data]
    );

    useEffect(
        () => {
            if (saveReport) {
                setSaveReport(false);
                void dispatchSaveReport();
            }
        }, [saveReport]
    );

    useEffect(
        () => {
            if (saveReportDraft) {
                setSaveReportDraft(false);
                dispatchSaveReportDraft();
            }
        }, [saveReportDraft]
    );

    useEffect(
        () => {
            if (previewPDF) {
                setPreviewPDF(false);
                dispatchPreviewPDF();
            }
        }, [previewPDF]
    );

    useEffect(
        () => {
            if (revertSummary) {
                if (summaryOriginal[summaryOriginal.length - 1].week === reportWeek) {
                    let fullSum = '';
                    summaryOriginal[summaryOriginal.length - 1].value.forEach(e => {
                        fullSum += (e + '\n\n');
                    });
                    setSummary(fullSum);
                }
                else {
                    setSummary('');
                }
            }
            else {
                if (data) {
                    setSummary(data.customizations.customSummary);
                }
            }
        }, [revertSummary]
    );

    useEffect(
        () => {
            if (revertHistoryInjury) {
                setHistoryInjury(HistoryInjuryOriginal);
            }
            else {
                if (data && data.customizations.customHistoryInjury !== undefined) {
                    setHistoryInjury(data.customizations.customHistoryInjury);
                }
                else {
                    setHistoryInjury('');
                }
            }
        }, [revertHistoryInjury]
    );

    useEffect(
        () => {
            if (revertBehavioral) {
                setBehavioralText(behavioralTextOriginal);
            }
            else {
                if (data) {
                    setBehavioralText(data.customizations.customBehavioral);
                }
            }

        }, [revertBehavioral]
    );

    useEffect(
        () => {
            if (revertSocial) {
                setSocialText(socialTextOriginal);
            }
            else {
                if (data) {
                    setSocialText(data.customizations.customSocial);
                }
            }

        }, [revertSocial]
    );

    useEffect(
        () => {
            if (revertPhysical) {
                setPhysicalText(physicalTextOriginal);
            }
            else {
                if (data) {
                    setPhysicalText(data.customizations.customPhysical);
                }
            }

        }, [revertPhysical]
    );

    useEffect(
        () => {
            if (revertGoal) {
                clearGoals();
                if (goalsOriginal[goalsOriginal.length - 1]) {
                    addGoalsManual(goalsOriginal[goalsOriginal.length - 1], onGoalsRefresh, true);
                }
            }
            else {
                if (data) {
                    addGoalsManual(data.customizations.customGoals, onGoalsRefresh, true);
                }
            }

        }, [revertGoal]
    );

    useEffect(
        () => {
            if (revertDiscovery) {
                clearDiscoveries();
                if (discoveriesOriginal[discoveriesOriginal.length - 1]) {
                    addDiscoveriesManual(discoveriesOriginal[discoveriesOriginal.length - 1], onDiscoveriesRefresh, true);
                }
            }
            else {
                if (data) {
                    addDiscoveriesManual(data.customizations.customDiscovery, onDiscoveriesRefresh, true);
                }
            }
        }, [revertDiscovery]
    );

    useEffect(
        () => {
            if (!loadedVerification && data) {
                setKarunaRating(data.enabled.KarunaRating !== undefined ? data.enabled.KarunaRating : false);
                setGoalProgress(data.enabled.goalProgress);
                setEngagementLevel(data.enabled.engagmentLevel);
                setSessionsAttended(data.enabled.sessionsAttended);
                setUniqueDays(data.enabled.uniqueDays);
                setAverageTime(data.enabled.averageTime);
                setLeftFlexion(data.enabled.leftFlexion);
                setLeftAbduction(data.enabled.leftAbduction);
                setLeftScaption(data.enabled.leftScaption);
                setRightAbduction(data.enabled.rightAbduction);
                setRightFlexion(data.enabled.rightFlexion);
                setRightScaption(data.enabled.rightScaption);
                setLowerFlexion(data.enabled.lowerFlexoion);
                setPain(data.enabled.pain);
                setMood(data.enabled.mood);
                setActivity(data.enabled.activity);
                setSleep(data.enabled.sleep);
                setConfidence(data.enabled.confidence);
                setReturnToWork(data.enabled.returnToWork);
                setMedReduction(data.enabled.medicationReduction);
                setOwestry(data.enabled.oswestry);
                setDash(data.enabled.Dash);
                setPSEQ(data.enabled.PSEQ);
                setPCS(data.enabled.PCS);
                setUEFI(data.enabled.UEFI);
                setFABQTotal(data.enabled.FABQTotal);
                setFABQActivity(data.enabled.FABQActivity);
                setFABQWork(data.enabled.FABQWork);
                setBehavioral(data.enabled.behavioral);
                setPhysical(data.enabled.physical);
                setSocial(data.enabled.social);
                setPCSS(data.enabled.PCSS !== undefined ? data.enabled.PCSS : false);
                setGAD7(data.enabled.GAD7 !== undefined ? data.enabled.GAD7 : false);
                setWHODAS(data.enabled.WHODAS !== undefined ? data.enabled.WHODAS : false);
                setNDI(data.enabled.NDI !== undefined ? data.enabled.NDI : false);
                setPHQ9(data.enabled.PHQ9 !== undefined ? data.enabled.PHQ9 : false);
                SetLoadedVerification(true);
            }
        }, [
            rawGoalProgressData,
            rawEngagementLevelData,
            RawSessionsAttendedData,
            RawUniqueDaysData,
            RawAverageTimeData,
            returnToWorkData,
            medReductionData,
            leftFlexionData,
            leftScaptionData,
            leftAbductionData,
            rightFlexionData,
            rightScaptionData,
            rightAbductionData,
            painData,
            moodData,
            activityData,
            sleepData,
            confidenceData,
            PSEQData,
            PCSData,
            oswestryData,
            DashData,
            lowerFlexionData,
            UEFIData,
            data,
            FABQTotalData,
            FABQActivityData,
            FABQWorkData,
            PCSSData,
            WHODASData,
            NDIData,
            PHQ9Data,
            GAD7Data
        ]
    );

    useEffect(
        () => {
            let text = '';
            if (continueProgram) {
                let programName = 'KarunaHOME';
                if (payerType === payerEnums.VA) {programName = 'Valor';}
                if (payerType === payerEnums.Reclaim) {programName = 'Reclaim';}

                if (reportWeek < 12) {
                    text += `Given ${patientName.split(' ')[0]}’s progress and current needs, it is my recommendation to continue with the ongoing ${programName} program. The upcoming weeks will cover the following topics, tailored to support ${patientName.split(' ')[0]}'s specific goals and to build upon their existing knowledge:\n\n` +
                        ('Pain Defined, Neural Circuit Pain and Calming the Nervous System, Danger Signals, Graded Exposure, Emotions, Thoughts and Behaviors, Stress Interpretation and Relaxation Skills, Mindfulness and Gratitude, Enjoyable Activities, Social Connection, Restoring Life Balance, Reclaiming Your Life, Planning For Success'.split(', ').slice(reportWeek -
                            1, 11).join(', ') + '\n\n') +
                        `${patientName.split(' ')[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`;
                }
                else {
                    text += `Given ${patientName.split(' ')[0]}'s progress and current needs, it is my recommendation to continue with the ongoing ${programName} program. Having completed the core 12-week curriculum, ${patientName.split(' ')[0]}'s coaching sessions will be tailored to support their specific goals and to build upon their existing knowledge.\n\n` +
                        `${patientName.split(' ')[0]} will be encouraged to continue engaging with 5-25 minutes of daily VET™ and maintain regular physical activity. Regular check-ins and adjustments will be made as necessary to ensure the patient's optimal engagement and benefit.`;
                }
            }
            if (notification) {
                text += ((text.length > 0 ? '\n\n' : ''));
                switch (templateNotification) {
                    case NotificationTypes.PsychotherapyReferral:
                        text += `${patientName.split(' ')[0]} has expressed challenges with their mental health. They are interested in exploring options such as psychotherapy or counseling.`;
                        break;
                    case NotificationTypes.MedicationReEvaluation:
                        text += `${patientName.split(' ')[0]} has voiced concerns regarding their current medication regimen. They would like to request a re-evaluation of their current needs to address any potential side-effects or concerns.`;
                        break;
                    case NotificationTypes.NewInjury:
                        text += `${patientName.split(' ')[0]} has reported a recent injury and is requesting further medical attention to assess the extent of the injury and advise any necessary modifications to their current plan of care.`;
                        break;
                    case NotificationTypes.NewSymptoms:
                        text += `${patientName.split(' ')[0]} has reported experiencing new concerning symptoms. They are requesting a physician re-evaluation to ascertain the cause and implications of these symptoms.`;
                        break;
                }
            }
            if (extension) {
                let template = `In consideration of the subjective and objective progress exhibited by ${patientName} over the initial ${reportWeek} week${reportWeek > 1
                    ? 's'
                    : ''} of the ${programName} program, it is my recommendation that a request for an additional ${templateWeeks} week${templateWeeks > 1 ? 's' : ''} be submitted for authorization.`;
                text += ((text.length > 0 ? '\n\n' : '') + template);
            }
            if (planEnd) {
                let template = `Given ${patientName.split(' ')[0]}'s progress and current needs, I recommend that they continue implementing the skills and concepts of the ${programName} program independently. Having completed the core 12-week curriculum, ${patientName.split(' ')[0]} will be encouraged to continue working towards their goals. ${patientName.split(' ')[0]} will have a 6-month check-in, and adjustments will be made as necessary to ensure their optimal engagement and benefit. If ${patientName.split(' ')[0]}'s current state starts to decline, it is my recommendation that they are re-evaluated for additional sessions with the ${programName} program.`;
                text += ((text.length > 0 ? '\n\n' : '') + template);
            }
            setPlan(text);

        }, [extension, continueProgram, notification, templateWeeks, templateNotification, planEnd]
    );

    useEffect(
        () => {
            if (reportWeek !== changedWeek) {
                setChangedWeek(reportWeek);
            }
        }, [reportWeek]
    );

    useEffect(
        () => {
            if (manual) {
                setErrors('');
                setIsLoading(true);
                void getReport(changedWeek);
                SetManual(false);
            }
        }, [changedWeek]
    );

    useEffect(
        () => {
            let text = '';

            if (physical) {
                if (physicalText.trim() !== '') {
                    text += physicalText.trim();
                    if (text.charAt(text.length - 1) !== '.') {
                        text += '.';
                    }
                }
            }

            if (behavioral) {
                if (behavioralText.trim() !== '') {
                    if (text.length > 0) {
                        text += '\n\n';
                    }
                    text += behavioralText.trim();
                    if (text.charAt(text.length - 1) !== '.') {
                        text += '.';
                    }
                }
            }

            if (social) {
                if (socialText.trim() !== '') {
                    if (text.length > 0) {
                        text += '\n\n';
                    }
                    text += socialText.trim();
                    if (text.charAt(text.length - 1) !== '.') {
                        text += '.';
                    }
                }
            }

            if (continuedNeed) {
                if (continuedNeedText.trim() !== '') {
                    if (text.length > 0) {
                        text += '\n\n';
                    }
                    text += continuedNeedText.trim();
                    if (text.charAt(text.length - 1) !== '.') {
                        text += '.';
                    }
                }
            }

            setAssessment(text);

        }, [social, behavioral, physical, socialText, behavioralText, physicalText, continuedNeed, continuedNeedText]
    );

    useEffect(
        () => {
            if (generate) {
                void dispatchGeneratePDF(false);
                setGenerate(false);
            }
            if (upload) {
                void dispatchGeneratePDF(true);
                setUpload(false);
            }

        }, [generate, upload]
    );

    useEffect(
        () => {
        }, [isLoading]
    );

    useEffect(
        () => {
        }, [goalsRefresh, discoveriesRefresh]
    );

    useEffect(
        () => {
            if (useCheck === false) {
                setErrors('Data for this report is formatted for an outdated report format for the range of motion tables, please create a new report if you would like the report to be in the new format');
            }
        }, [useCheck]
    );

    useEffect(
        () => {
            if (user === null) {
                if (cookie.load('user') === undefined && cookie.load('token') === undefined) {
                    if (window.location.pathname.pathname !== '/login' && !location.pathname.includes('/password/reset') && !location.pathname.includes('/account-confirm-email/')) {
                        void api_Diagnostics(
                            new Error('[ReportVerification] Forced logout: Cookies not found'),
                            handleLogout,
                            handleLogout
                        );
                    }
                }
            }
        }, [user]
    );

    const breadcrumbLinks = () => {
        return [
            {
                label: 'Reports',
                url: `/reports`
            },
            {
                label: 'Edit Outcome Report',
                url: `/outcomes/${reportId}/`
            }
        ];
    };

    const metricTooltip = (result) => {
        return (
            <React.Fragment>
                <span className="relative inline-flex">
                    <div className=" has-tooltip relative pl-1 inline-flex 	">
                        <img className="w-3.5 h-3.5 relative hs-tooltip translate-y-1" src={'/icons/info-circle-measure.svg'} alt="icon"/>
                        <span className="tooltip rounded shadow-lg whitespace-normal break-normal min-w-[10vw] max-w-[40vw] [@media(max-width:1200px)]:max-w-[45vw] [@media(min-width:1600px)]:max-w-[30vw] w-max   bottom-1  ">{result}</span>
                    </div>
                </span>
            </React.Fragment>
        );
    };

    const noDataDecorator = () => {
        return (
            <React.Fragment>
                <div className=" pl-1.5 pr-2 py-0.5 mb-2 mx-1.5 bg-error-50 rounded-full border border-error-200 justify-start items-center gap-1.5 inline-flex">
                    <img className="w-3 h-3 relative" src="/icons/warning.svg" alt="icon"/>
                    <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">No Data</div>
                </div>
            </React.Fragment>
        );
    };

    const weekDecorator = (week) => {
        return (
            <React.Fragment>
                <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-brand-50 rounded-full border border-brand-200 justify-start items-center gap-1.5 inline-flex">
                    <img className="w-3 h-3 relative" src="/icons/clock-rewind.svg" alt="icon"/>
                    <div className="text-center text-brand-700 text-xs font-medium  leading-[18px]">Week {week}</div>
                </div>
            </React.Fragment>
        );
    };

    const indicatorDecorator = (result) => {
        let svg;

        if (result[1][2].includes('error')) {
            svg = 'info-circle-error';
        }
        else if (result[1][2].includes('warning')) {
            svg = 'info-circle-warning';
        }
        else {
            svg = 'info-circle-success';
        }

        return (
            <React.Fragment>
                <span className="relative">
                    <div className={'has-tooltip  pl-0.5 pr-2 py-0.5 mx-1.5  rounded-full border  justify-start items-center gap-1.5 inline-flex ' + result[1][0] + ' ' + result[1][1]}>
                        <span className="tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[30vw] [@media(max-width:1200px)]:max-w-[40vw] [@media(min-width:1600px)]:max-w-[30vw] w-max bottom-[125%] right-1   ">{result[2]}</span>
                        <div className="relative pl-1 inline-flex">
                            <img className="w-3 h-3 relative hs-tooltip " src={'/icons/' + svg + '.svg'} alt="icon"/>
                        </div>
                        <div className={'text-center  text-xs font-medium  leading-[18px] ' + result[1][2]}>{result[0]}</div>
                    </div>
                </span>
            </React.Fragment>
        );
    };

    const changeDecorator = (change, firstWeek, percent = false, wantDecrease = false) => {
        if (change === 0) {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-gray-50 rounded-full border border-gray-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/minus.svg" alt="icon"/>
                        <div className="text-center text-gray-700 text-xs font-medium  leading-[18px]">No Change from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            );
        }
        if (!wantDecrease) {
            if (change > 0) {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/upgood.svg" alt="icon"/>
                            <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                );
            }
            else {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/downBad.svg" alt="icon"/>
                            <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                );
            }
        }
        else {
            if (change > 0) {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/upbad.svg" alt="icon"/>
                            <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                );
            }
            else {
                return (
                    <React.Fragment>
                        <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                            <img className="w-3 h-3 relative" src="/icons/downgood.svg" alt="icon"/>
                            <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                        </div>
                    </React.Fragment>
                );
            }
        }
    };

    const ActivityIndicator = (val) => {
        if (val >= 8) {
            return ['Very High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates excellent functional capacity with the ability to perform strenuous activities.'];
        }
        if (val >= 6) {
            return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates good functional capacity with minimal limitations and likely to manage daily activities and most tasks efficiently.'];
        }
        if (val >= 4) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate functional capacity with the ability to perform most daily activities. May experience fatigue or difficulty with more strenuous tasks.'];
        }
        if (val >= 2) {
            return ['Low', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some functional limitations. Daily activities may be challenging and require additional effort. '];
        }
        return ['Very Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant functional impairment with considerable limitations in daily activities.'];
    };
    const ConfidenceIndicator = (val) => {
        if (val >= 9) {
            return ['Very High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates a positive mindset when approaching tasks, with full belief in their abilities.'];
        }
        if (val >= 7) {
            return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates general positivity about their ability to engage in most tasks and activities with minimal restrictions.'];
        }

        if (val >= 5) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates willingness to attempt tasks with caution with some functional limitation.'];
        }
        if (val >= 3) {
            return ['Low', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates willingness to attempt some activities but remains hesitant and cautious.'];
        }
        return ['Very Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates possible decrease in participation in activities of daily living and daily life roles.'];
    };
    const DashIndicator = (val) => {
        if (val >= 81) {
            return ['Extreme Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates minimal to no functional capacity and complete dependence on others for most activities.'];
        }
        if (val >= 61) {
            return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low functional capacity with major restrictions in daily activities.'];
        }
        if (val >= 41) {
            return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates reduced functional capacity with significant challenges in daily activities.'];
        }
        if (val >= 21) {
            return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates ability to perform most daily activities but may need occasional assistance.'];
        }

        return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high functional capacity and ability to perform most daily activities with minimal difficulty.'];
    };
    const FABQActivityIndicator = (val) => {
        if (val >= 17) {
            return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant avoidance of physical activities and likely to experience poor physical function.'];
        }
        if (val >= 9) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some avoidance of physical activities and ikely to have moderate impact on physical function.'];
        }
        return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates active engagement in physical activities and likely to experience better physical function.'];
    };
    const FABQTotalIndicator = (val) => {
        if (val >= 65) {
            return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant impact on function and likely to avoid many activities, leading to greater disability.'];
        }
        if (val >= 49) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates potential impact on function and may avoid some activities due to fear of pain.'];
        }
        return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal impact on function with better physical activity and function.'];
    };
    const FABQWorkIndicator = (val) => {
        if (val >= 25) {
            return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant avoidance of physical activities and functional tasks, leading to considerable reductions in physical function and activity levels.'];
        }
        if (val >= 15) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some functional limitations, but are not entirely incapacitated.'];
        }
        return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher likelihood to engage in physical activities and work tasks without excessive fear of pain exacerbation.'];
    };
    const GAD7Indicator = (val) => {
        if (val >= 15) {
            return ['Severe Anxiety', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe symptoms, often significantly interfering with daily activities and overall well-being.'];
        }
        if (val >= 10) {
            return ['Moderate Anxiety', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates symptoms of anxiety are more pronounced and may begin to impact daily functioning and quality of life. '];
        }
        if (val >= 5) {
            return ['Mild Anxiety', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some manageable symptoms of anxiety, but may not interfere significantly with daily activities. '];
        }
        return ['No Anxiety', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates little to no symptoms of anxiety and likely to perform effectively in their life roles.'];
    };
    // const KarunaRatingIndicator = (val) => {
    //     if (val >= 4000) {
    //         return ['Min. to No Limitations', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high levels of physical function. Likely able to perform most daily tasks independently.'];
    //     }
    //     if (val >= 2500) {
    //         return ['Mild Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate levels of physical function. Likely independent in most daily tasks with some adjustments needed.'];
    //     }
    //     if (val >= 1000) {
    //         return ['Moderate Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates low levels of physical function. May be able to perform some basic tasks with difficulty and limited endurance.'];
    //     }
    //     if (val >= 0) {
    //         return ['Severe Limitations', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe limitations in function. Likely to experience significant mobility limitations and may struggle with basic daily tasks.'];
    //     }
    // };
    const MoodIndicator = (val) => {
        if (val >= 9) {
            return ['Very Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher energy and motivation, leading to higher levels of physical activity and function and excels in life roles.'];
        }
        if (val >= 7) {
            return ['Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher energy and motivation and able to manage challenges and engage in daily functions with resilience.'];
        }
        if (val >= 5) {
            return ['Fair', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates low energy, but still can engage in physical activities at a reduced level.'];
        }
        if (val >= 3) {
            return ['Poor', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates fatigue, low energy, and decreased motivation, but may complete essential tasks and responsibilities.'];

        }
        return ['Very Poor', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates fatigue, low energy, and decreased motivation, leading to reduced physical activity and participation in daily tasks.'];
    };
    const NDIIndicator = (val) => {
        if (val >= 35) {
            return ['Complete Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates complete disability from neck pain, unable to carry out most daily activities.'];
        }
        if (val >= 25) {
            return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe pain and significant limitations in daily activities, with a substantial impact on their quality of life.'];
        }
        if (val >= 15) {
            return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates noticeable pain and moderate limitations in daily activities, affecting their ability to function normally.'];
        }
        if (val >= 5) {
            return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some pain and minor limitations in daily activities, but generally without significant impairment.'];
        }
        return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates no significant impact on daily activities due to neck pain.'];
    };
    const OswestryIndicator = (val) => {
        if (val >= 35) {
            return ['Completely Disabled', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates inability to carry out most daily activities independently.'];
        }
        if (val >= 25) {
            return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant pain and substantial limitations in their ability to perform daily activities.'];
        }
        if (val >= 15) {
            return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates difficulty with heavier or more strenuous activities.'];
        }
        if (val >= 5) {
            return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates general ability to perform most activities, but may experience some pain and slight limitations in function.'];
        }
        return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal or no pain and can perform all activities without significant restriction.'];
    };
    const PainIndicator = (val) => {
        if (val >= 7) {
            return ['Severe', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant interference with daily activities or functions.'];
        }
        if (val >= 4) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates difficulty with heavier or more strenuous activities and may need some assistance for daily tasks.'];
        }
        if (val >= 1) {
            return ['Mild', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates no significant interference with daily activities or functions.'];
        }
        return ['None', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates no impact on function.'];
    };
    const PCSIndicator = (val) => {
        if (val >= 30) {
            return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.'];
        }
        if (val >= 15) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some confidence in their ability to perform activities, but with some limitations requiring support.'];
        }
        return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high confidence in their ability to manage pain and perform various activities. leading to better physical function.'];
    };
    const PCSSIndicator = (val) => {
        if (val >= 76) {
            return ['Extreme Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates symptoms are debilitating, severely limiting the ability to carry out daily activities. May require substantial support.'];
        }
        if (val >= 61) {
            return ['Very Severe Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates symptoms are very severe, causing major disruptions to daily life and functioning. '];
        }
        if (val >= 46) {
            return ['Severe Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well.'];
        }
        if (val >= 31) {
            return ['Moderate Symptoms', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates symptoms are significantly interfering with daily activities and life functions.'];
        }
        if (val >= 16) {
            return ['Mild Symptoms', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well.'];
        }
        return ['Min. or No symptoms', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal or mild symptoms, generally not interfering with daily activities.'];
    };
    const PHQ9Indicator = (val) => {
        if (val >= 20) {
            return ['Severe Depression', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates debilitating symptoms, making it very difficult to function in daily life.'];
        }
        if (val >= 15) {
            return ['Moderately Severe Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates daily functioning is significantly impacted and may struggle with daily life functions.'];
        }
        if (val >= 10) {
            return ['Moderate Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates decreased concentration, energy, and interest in daily activities making them difficult to complete.'];
        }
        if (val >= 5) {
            return ['Mild Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates minor difficulties in daily activities, but can generally manage their responsibilities.'];
        }
        return ['Minimal Depression', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal impact on daily functioning, including work, social activities, and personal care.'];
    };
    const PSEQIndicator = (val) => {
        if (val > 40) {
            return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.'];
        }
        if (val > 20) {
            return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some confidence in their ability to perform activities, but with some limitations requiring support.'];
        }
        return ['Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.'];
    };
    const SleepIndicator = (val) => {
        if (val >= 9) {
            return ['Excellent', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates optimal energy and physical performance, with little to no fatigue.'];
        }
        if (val >= 7) {
            return ['Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates good energy levels and physical performance, slightly reduced productivity.'];
        }
        if (val >= 5) {
            return ['Fair', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates mild fatigue, but generally able to perform daily activities.'];
        }
        if (val >= 3) {
            return ['Poor', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates noticeable fatigue and reduced stamina, with moderate impact on physical activities.'];
        }
        return ['Very Poor', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates extreme fatigue and decreased physical performance.'];
    };
    const UEFIIndicator = (val) => {
        if (val >= 61) {
            return ['Severe Limitations', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe limitations in function, significant need for assistance, and high impact on daily living activities.'];
        }
        if (val >= 41) {
            return ['Moderate Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some need for assistance or modifications, and notable impact on daily activities.'];
        }
        if (val >= 21) {
            return ['Mild Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates good overall function and most likely independent in daily activities, with minor assistance.'];
        }
        return ['Min. to No Limitations', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates full independence and high function in daily activities and work.'];
    };
    const WHODASIndicator = (val) => {
        if (val >= 4.5) {
            return ['Extreme Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates major limitations in daily activities and overall functioning. Likely dependent on others for most tasks.'];
        }
        if (val >= 3.5) {
            return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant limitations in performing daily activities. Likely require substantial assistance and support.'];
        }
        if (val >= 2.5) {
            return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate difficulties in performing daily tasks. These limitations can impact work, social participation, and quality of life.'];
        }
        if (val >= 1.5) {
            return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates slight difficulties in performing certain tasks, but these do not significantly affect their overall independence or quality of life.'];
        }
        return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal interference with daily activities and overall functioning.'];
    };

    const renderKeyImprovementsGraph = () => {
        let metrics = nudgeValues(selectedImprovements);

        return (
            <React.Fragment key={`key-improvements-graph`}>
                <div className="[@media(max-width:1200px)]:w-[552px] w-[1104px] [@media(min-width:1700px)]:w-[1656px] [@media(max-width:1200px)]:h-[150px] h-[300px] [@media(min-width:1700px)]:h-[450px]">
                    <Line
                        id={keyImprovementsGraphId}
                        data={
                            {
                                labels: reportWeekLabels(),
                                datasets: configureGraph()
                            }
                        }
                        height={null}
                        width={null}
                        options={{
                            scales: {
                                y: {
                                    title: {
                                        display: true,
                                        align: 'center',
                                        text: 'Standardized Score',
                                        color: 'grey',
                                        font: {
                                            family: 'Arial',
                                            size: 14
                                        }
                                    },
                                    max: 100,
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 25,
                                        autoSkip: false,
                                        callback: function (value, index, ticks) {
                                            return value + '%';
                                        },
                                        color: ['red', 'orange', 'orange', 'green', 'green'],
                                        font: {
                                            size: 12
                                        }
                                    }
                                }
                            },
                            x: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: 'X Axis Name' // Name of x-axis
                                },
                                ticks: {
                                    font: {
                                        size: 12
                                    }
                                }
                            },
                            aspectRatio: 1,
                            responsive: true,
                            maintainAspectRatio: false,
                            beforeDraw: function (chart) {
                                var ctx = chart.chart.ctx;
                                ctx.save();
                                let xAxis = chart.scales['x-axis-0'];
                                let yAxis = chart.scales['y-axis-0'];
                                let dataset = chart.data.datasets[0];
                                var valueFrom = null;
                                var valueFromIndex = 0;
                                var xFrom = null;
                                let yFrom = null;
                                ctx.strokeStyle = dataset.borderColor;
                                dataset.data.forEach((value, index) => {
                                    if (value != null) {
                                        var x = xAxis.getPixelForTick(index);
                                        var y = yAxis.getPixelForValue(value);
                                        if (valueFrom != null) {
                                            ctx.lineWidth = dataset.borderWidth;
                                            if (index - valueFromIndex > 1) {
                                                ctx.setLineDash([5, 5]);
                                            }
                                            else {
                                                ctx.setLineDash([]);
                                            }
                                            ctx.beginPath();
                                            ctx.moveTo(xFrom, yFrom);
                                            ctx.lineTo(x, y);
                                            ctx.stroke();
                                        }
                                        valueFrom = value;
                                        valueFromIndex = index;
                                        xFrom = x;
                                        yFrom = y;
                                    }
                                });
                                ctx.restore();
                            },
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (context) => {
                                            let label = '';
                                            label += context.dataset.label + ': ';
                                            label += getTrueGraphVal(metrics, context.dataset.label, context.label);
                                            label += '%';
                                            return label;
                                        }
                                    }

                                },
                                legend: {
                                    align: 'end',
                                    labels: {
                                        usePointStyle: true,
                                        font: {
                                            size: 12
                                        }
                                    }
                                }
                            }
                        }}
                        plugins={[custom_canvas_background_color]}
                    />
                </div>
            </React.Fragment>
        );
    };

    const renderKeyImprovementIndicators = (measureObject, index) => {
        let values = measureObject.value;
        if (values.length === 0) {
            return;
        }

        let percent = measureObject.percent;
        let measureHint = getTooltipText(measureObject.type);

        return <React.Fragment key={`key-improvements-${index}`}>
            <div className="w-full inline-flex items-baseline">
                <div className="[@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                    <label className={`justify-start items-center flex gap-x-2 text-base font-normal leading-normal ${KarunaRatingData.length > 0 ? 'text-gray-600' : 'text-gray-400'}`}>
                        <input
                            className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                            type="checkbox"
                            checked={hasSelectedImprovement(measureObject.type)}
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    removeSelectedImprovement(measureObject.type);
                                }
                                else {
                                    if (selectedImprovements.length >= 5) {
                                        return;
                                    }
                                    addSelectedImprovement(measureObject.type);
                                    forceToggleMeasure(measureObject.type, true);
                                }
                                setImprovementToggle(!improvementToggle);
                            }}
                            disabled={(selectedImprovements.Length >= 5 && !hasSelectedImprovement(measureObject.type))}
                        />
                        {
                            measureObject.name
                        }
                    </label>
                    {
                        measureHint && metricTooltip(measureHint)
                    }
                </div>
                <div className="w-fit text-base font-normal text-gray-600 leading-normal inline-flex justify-start items-center">
                {
                    isRomType(measureObject.type) ?
                        (
                            values.length > 0 ?
                                <span className="text-base font-normal text-gray-600 leading-normal ">
                                    {
                                        bestRomMeasurement(values, 3).value.toString()
                                    }
                                    {
                                        bestRomMeasurement(values, 3).week !== reportWeek ? weekDecorator(bestRomMeasurement(values, 3).week) : ''
                                    }
                                    {
                                        values.length > 1 &&
                                        changeDecorator(percent, values[0].week, UsePercentage(measureObject.type), LowerIsBetter(measureObject.type))
                                    }
                                    {
                                        indicatorDecorator(GetIndicator(measureObject.type, bestRomMeasurement(values, 3).value))
                                    }
                                </span>
                                :
                                <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                        )
                        :
                        (
                            values.length > 0 ?
                                <span className="text-base font-normal text-gray-600 leading-normal">
                                    {
                                        values.slice(-1)[0].value.toString()
                                    }
                                    {
                                        values.slice(-1)[0].week !== reportWeek ? weekDecorator(values.slice(-1)[0].week) : ''
                                    }
                                    {
                                        values.length > 1 &&
                                        changeDecorator(percent, values[0].week, UsePercentage(measureObject.type), LowerIsBetter(measureObject.type))
                                    }
                                    {
                                        indicatorDecorator(GetIndicator(measureObject.type, values.slice(-1)[0].value))
                                    }
                                </span>
                                :
                                <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                        )
                }
                </div>
            </div>
        </React.Fragment>;
    };

    const renderKeyImprovementOptions = () => {
        return ImprovementsComplete && ImprovementsComplete.map((option, index) => {
            if (option.ImprovedValue > 0) {
                return renderKeyImprovementIndicators(option, index);
            }
        });
    };

    return (
        <Page
            errors={errors}
            headerObj={headerInfo !== null ? headerInfo : { trail: breadcrumbLinks() }}
            internal={true}
            successes={successes}
        >
        {
            isLoading ?
                (
                    renderLoader(loadingMessage)
                )
                : !data ?
                    (
                        <div id="body" className={'w-full p-4 h-full gap-y-1 flex-col inline-flex justify-start items-center whitespace-nowrap overflow-x-hidden overflow-y-auto'}>
                            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
                                <p className="px-6 text-gray-900 text-lg font-medium ">The Outcome Report could not be loaded. Contact a Karuna Admin for support.</p>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div id="body" className={'w-full p-4 h-full gap-y-1 flex-col inline-flex justify-start items-center whitespace-nowrap overflow-x-hidden overflow-y-auto'}>
                            <ReviewSectionGenerator hasPhysicianReview={oversight}/>
                            <span className="mb-2"/>
                            <SectionDivider key={`divider-key-improvements`} label={'Key Improvements'} locked={true}>
                                {
                                    hasImprovements() ?
                                        (
                                            <React.Fragment key={`content-key-improvements`}>
                                                <div className="h-6 justify-start items-start gap-3 inline-flex">
                                                    <img className="w-6 h-6 relative" src="/icons/percent-03.svg"/>
                                                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap	">
                                                        <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal  leading-normal">When displayed in the graph, measures are converted to a standard percentage score, where higher is better.</div>
                                                    </div>
                                                </div>
                                                {
                                                    renderKeyImprovementsGraph()
                                                }
                                                <div id="metrics" className="mt-5">
                                                    <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                                        <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                                            <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                                                <div className="grow relative shrink basis-0   justify-center items-start gap-1 inline-flex">
                                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Included Measures</div>
                                                                    <div className=" align-text-bottom	has-tooltip self-center float-bottom text-center 	content-center	items-end	 pl-2 object-bottom whitespace-nowrap  inline-flex 	">
                                                                        <span className="tooltip rounded shadow-lg  whitespace-normal	 break-normal min-w-[10vw] max-w-[40vw] [@media(max-width:1200px)]:max-w-[45vw] [@media(min-width:1600px)]:max-w-[30vw] w-max  mb-2 ml-2 ">To qualify for the Key Improvements graph, a measure must have improved into a higher score band since its first measurement.</span>
                                                                        <img className="h-5 w-5 hs-tooltip " src={'/icons/help-circle.svg'}></img>
                                                                    </div>
                                                                    <div className="text-gray-400 text-xs font-normal  translate-y-1.5 pl-2 leading-[18px]">{selectedImprovements.length}/5 included</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                renderKeyImprovementOptions()
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                        :
                                        (
                                            <React.Fragment key={`content-key-improvements`}>
                                                <div className="justify-start items-start gap-3 inline-flex">
                                                    <img id="chart" className="w-6 h-6 relative overflow-hidden" src="/icons/noKeyImp.svg"/>
                                                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                                                        <div className="self-stretch text-gray-600 text-base font-normal  leading-normal">Patient has not made sufficient progress in any outcome measure to include a Key Improvements graph.</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                }
                            </SectionDivider>

                            <SectionDivider key={`divider-patient-history`} label={'Patient History'}>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">History of Injury</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Write a short summary of the Nature and Mechanism of the patient’s injury.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className="w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex"
                                            placeholder="Enter text"
                                            onChange={e => setHistoryInjury(e.target.value)}
                                            value={HistoryInjury}></textarea>
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal  leading-normal '}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox" checked={revertHistoryInjury}
                                                onChange={() => {
                                                    setRevertHistoryInjury(!revertHistoryInjury);
                                                }}/> Restore Unedited History of Injury
                                        </label>

                                    </div>
                                </div>
                            </SectionDivider>
                            <SectionDivider key={`divider-subjective-outcomes`} label={'Subjective Outcomes'}>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-3 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Patient Goals</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Outline the real-life goals the patient set for themselves at the start of the program.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            goalsHtml && goalsHtml.length > 0 ?
                                                (
                                                    goalsHtml
                                                )
                                                :
                                                (
                                                    <span className="self-stretch text-warning-600 font-semibold leading-7">&#9888; No Goals found in Practice Better data. You can add them manually below.</span>
                                                )
                                        }
                                        <div className="self-stretch w-full  flex-col justify-start items-start gap-x-4 flex">
                                            <label className={'justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal  leading-normal '}>
                                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox" checked={revertGoal}
                                                    onChange={() => {
                                                        setRevertGoal(!revertGoal);
                                                    }}/> Restore Unedited Goals
                                            </label>
                                        </div>
                                        <div className="self-stretch w-full h-11 flex-col justify-center items-center gap-4 flex">
                                            <button onClick={() => {addGoal(onGoalsRefresh);}} className="w-fit px-4 py-2.5 bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
                                                <div className="px-0.5 justify-center gap-x-2 items-center inline-flex">
                                                    <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/plus.svg"/>
                                                    <div className="text-gray-700 text-base font-semibold  leading-normal">{'Add New Goal'}</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Patient Perspective</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex gap-x-2  text-base font-normal leading-normal  ' + (rawGoalProgressData === null ? 'text-gray-400 ' : 'text-gray-600 ')}>
                                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                        type="checkbox" checked={goalProgress} onChange={() => {
                                                        setGoalProgress(!goalProgress);
                                                    }} disabled={rawGoalProgressData === null ? true : false}/> Perceived Progress Towards Goals
                                                </label>
                                            </div>
                                            <div className="w-fit text-base self-start font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    rawGoalProgressData ? <span className="text-base font-normal text-gray-600 leading-normal ">{rawGoalProgressData.value.toString() + '/10'}{(reportWeek === rawGoalProgressData.week ? '' : weekDecorator(rawGoalProgressData.week))}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>

                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (rawEngagementLevelData === null ? 'text-gray-400 ' : 'text-gray-600 ')}>
                                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                        type="checkbox" checked={engagementLevel} onChange={() => {
                                                        setEngagementLevel(!engagementLevel);
                                                    }} disabled={rawEngagementLevelData === null ? true : false}/>Level of Engagement in Program
                                                </label>
                                            </div>
                                            <div className="w-fit text-base font-normal self-start text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    rawEngagementLevelData ? <span>{rawEngagementLevelData.value.toString() + '/10'}{(reportWeek === rawEngagementLevelData.week ? '' : weekDecorator(rawEngagementLevelData.week))}</span> :
                                                        <span className="gap-x-2 ext-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-3 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Patient Discoveries</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Outline any breakthroughs the patient has made in understanding and managing their pain.</div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            discoveriesHtml && discoveriesHtml.length > 0 ?
                                                (
                                                    discoveriesHtml
                                                )
                                                :
                                                (
                                                    <span className="self-stretch text-warning-600 font-semibold leading-7">&#9888; No Discoveries found in Practice Better data. You can add them manually below.</span>
                                                )
                                        }
                                        <div className="self-stretch w-full  flex-col justify-start items-start gap-x-4 flex">
                                            <label className={'justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal  leading-normal '}>
                                                <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox" checked={revertDiscovery}
                                                    onChange={() => {
                                                        setRevertDiscovery(!revertDiscovery);
                                                    }}/> Restore Unedited Discoveries
                                            </label>
                                        </div>
                                        <div className="self-stretch w-full flex-col justify-center items-center gap-4 flex">
                                            <button onClick={() => {addDiscovery(onDiscoveriesRefresh);}} className="w-fit px-4 py-2.5 bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex">
                                                <div className="px-0.5 justify-center gap-x-2 items-center inline-flex">
                                                    <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/plus.svg"/>
                                                    <div className="text-gray-700 text-base font-semibold  leading-normal">{'Add New Discovery'}</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[50%] [@media(max-width:1200px)]:w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Subjective Progress Summary</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal leading-tight">Write a short summary of the patient’s engagement with the program and improvements in any area of their lives. Focus on the patient’s own perspective and the progress they have communicated to you.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className="w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex" placeholder="Enter text" onChange={e => setSummary(e.target.value)} value={summary}></textarea>
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2 text-base font-normal leading-normal'}>
                                            <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                type="checkbox" checked={revertSummary} onChange={() => {
                                                setRevertSummary(!revertSummary);
                                            }}/> Restore Unedited Progress Summary
                                        </label>
                                    </div>
                                </div>
                            </SectionDivider>
                            <SectionDivider key={`divider-objective-outcomes`} label={'Objective Outcomes'}>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Adherence</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (RawSessionsAttendedData === null ? 'text-gray-400 ' : 'text-gray-600 ')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={sessionsAttended} onChange={() => {
                                                        setSessionsAttended(!sessionsAttended);
                                                    }} disabled={RawSessionsAttendedData === null ? true : false}/>Coaching Sessions Attended
                                                </label>
                                            </div>
                                            <div className="w-fit text-base self-start	 font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    RawSessionsAttendedData !== null ? <span className="text-base font-normal text-gray-600 leading-normal ">{RawSessionsAttendedData}</span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (RawUniqueDaysData === null ? 'text-gray-400 ' : 'text-gray-600 ')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={uniqueDays} onChange={() => {
                                                        setUniqueDays(!uniqueDays);
                                                    }} disabled={RawUniqueDaysData === null ? true : false}/> Unique Days in Headset p/w
                                                </label>
                                            </div>
                                            <div className="w-fit self-start text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    RawUniqueDaysData ? <span className="text-base font-normal text-gray-600 leading-normal ">{RawUniqueDaysData}</span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex gap-x-2  text-base font-normal leading-normal  ' + (RawAverageTimeData === null ? 'text-gray-400 ' : 'text-gray-600 ')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={averageTime} onChange={() => {
                                                        setAverageTime(!averageTime);
                                                    }} disabled={RawAverageTimeData === null ? true : false}/> Average Time in Activities p/w
                                                </label>
                                            </div>
                                            <div className="w-fit text-base self-start font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    RawAverageTimeData ? <span className="text-base font-normal text-gray-600 leading-normal ">{RawAverageTimeData}</span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                                <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Program Impact</div>
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (returnToWorkData !== null && returnToWorkData.slice(-1)[0].week <= reportWeek ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={returnToWork && returnToWorkData !== null && returnToWorkData.slice(-1)[0].week <= reportWeek} onChange={() => {
                                                        setReturnToWork(!returnToWork);
                                                    }} disabled={returnToWorkData !== null && returnToWorkData.slice(-1)[0].week <= reportWeek ? false : true}/> Return To Work
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    returnToWorkData !== null && returnToWorkData.slice(-1)[0].week <= reportWeek ? <span className="text-base font-normal text-gray-600 leading-normal ">
                                            {returnToWorkData.slice(-1)[0].value >= 3 ? 'Yes' : (returnToWorkData.slice(-1)[0].value !== 0 ? 'No' : 'N/A')}
                                                        {returnToWorkData.slice(-1)[0].week !== reportWeek ? weekDecorator(returnToWorkData.slice(-1)[0].week) : ''}
                                            </span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (returnToWorkData !== null && medReductionData.slice(-1)[0].week <= reportWeek ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={medReduction && medReductionData !== null && medReductionData.slice(-1)[0].week <= reportWeek} onChange={() => {
                                                        setMedReduction(!medReduction);
                                                    }} disabled={medReductionData !== null && medReductionData.slice(-1)[0].week <= reportWeek ? false : true}/> Medication Reduction
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    medReductionData !== null && medReductionData.slice(-1)[0].week <= reportWeek ? <span className="text-base font-normal text-gray-600 leading-normal ">
                                            {medImp}
                                                        {medReductionData.slice(-1)[0].week !== reportWeek ? weekDecorator(medReductionData.slice(-1)[0].week) : ''}
                                            </span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                                <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Physical Disability</div>
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%]  [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (DashData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={Dash} onChange={() => {
                                                        setDash(!Dash);
                                                    }} disabled={DashData.length > 0 ? false : true}/> DASH

                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.DASH))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    DashData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{DashData.slice(-1)[0].value.toString()}{DashData.slice(-1)[0].week !== reportWeek ? weekDecorator(DashData.slice(-1)[0].week) : ''}{DashData.length > 1
                                                            ? changeDecorator(DashImp, DashData[0].week, false, true)
                                                            : ''}{DashData.length > 0 ? indicatorDecorator(DashIndicator(DashData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (NDIData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={NDI} onChange={() => {
                                                        setNDI(!NDI);
                                                    }} disabled={NDIData.length > 0 ? false : true}/> NDI
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.NDI))}
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    NDIData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{NDIData.slice(-1)[0].value.toString()}{NDIData.slice(-1)[0].week !== reportWeek ? weekDecorator(NDIData.slice(-1)[0].week) : ''}{NDIData.length > 1
                                                            ? changeDecorator(NDIImp, NDIData[0].week, false, true)
                                                            : ''}{NDIData.length > 0 ? indicatorDecorator(NDIIndicator(NDIData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (oswestryData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={oswestry} onChange={() => {
                                                        setOwestry(!oswestry);
                                                    }} disabled={oswestryData.length > 0 ? false : true}/> Oswestry
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.Oswestry))}
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    oswestryData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{oswestryData.slice(-1)[0].value.toString()}{oswestryData.slice(-1)[0].week !== reportWeek ? weekDecorator(oswestryData.slice(-1)[0].week) : ''}{oswestryData.length > 1
                                                            ? changeDecorator(oswestryImp, oswestryData[0].week, false, true)
                                                            : ''}{oswestryData.length > 0 ? indicatorDecorator(OswestryIndicator(oswestryData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (WHODASData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={WHODAS} onChange={() => {
                                                        setWHODAS(!WHODAS);
                                                    }} disabled={WHODASData.length > 0 ? false : true}/> WHODAS
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.WHODAS))}
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    WHODASData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{WHODASData.slice(-1)[0].value.toString()}{WHODASData.slice(-1)[0].week !== reportWeek ? weekDecorator(WHODASData.slice(-1)[0].week) : ''}{WHODASData.length > 1
                                                            ? changeDecorator(WHODASImp, WHODASData[0].week, false, true)
                                                            : ''}{WHODASData.length > 0 ? indicatorDecorator(WHODASIndicator(WHODASData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                                <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Physical Function</div>
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (activityData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={activity} onChange={() => {
                                                        setActivity(!activity);
                                                    }} disabled={activityData.length > 0 ? false : true}/> Activity
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    activityData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{activityData.slice(-1)[0].value.toString()}{activityData.slice(-1)[0].week !== reportWeek ? weekDecorator(activityData.slice(-1)[0].week) : ''}{activityData.length > 1
                                                            ? changeDecorator(activityImp, activityData[0].week)
                                                            : ''}{activityData.length > 0 ? indicatorDecorator(ActivityIndicator(activityData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (KarunaRatingData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={KarunaRating} onChange={() => {
                                                        setKarunaRating(!KarunaRating);
                                                    }} disabled={KarunaRatingData.length > 0 ? false : true}/> Karuna Rating
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.KarunaRating))}
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    KarunaRatingData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{KarunaRatingData.slice(-1)[0].value.toString()}{KarunaRatingData.slice(-1)[0].week !== reportWeek
                                                            ? weekDecorator(KarunaRatingData.slice(-1)[0].week)
                                                            : ''}{KarunaRatingData.length >
                                                        1
                                                            ? changeDecorator(KarunaRatingImp, KarunaRatingData[0].week)
                                                            : ''}{KarunaRatingData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.KarunaRating, KarunaRatingData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (painData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={pain} onChange={() => {
                                                        setPain(!pain);
                                                    }} disabled={painData.length > 0 ? false : true}/> Pain
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    painData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{painData.slice(-1)[0].value.toString()}{painData.slice(-1)[0].week !== reportWeek ? weekDecorator(painData.slice(-1)[0].week) : ''}{painData.length > 1
                                                            ? changeDecorator(painImp, painData[0].week, false, true)
                                                            : ''}{painData.length > 0 ? indicatorDecorator(PainIndicator(painData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (PCSSData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={PCSS} onChange={() => {
                                                        setPCSS(!PCSS);
                                                    }} disabled={PCSSData.length > 0 ? false : true}/> PCSS
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.PCSS))}
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    PCSSData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{PCSSData.slice(-1)[0].value.toString()}{PCSSData.slice(-1)[0].week !== reportWeek ? weekDecorator(PCSSData.slice(-1)[0].week) : ''}{PCSSData.length > 1
                                                            ? changeDecorator(PCSSImp, PCSSData[0].week, false, true)
                                                            : ''}{PCSSData.length > 0 ? indicatorDecorator(PCSSIndicator(PCSSData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (sleepData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={sleep} onChange={() => {
                                                        setSleep(!sleep);
                                                    }} disabled={sleepData.length > 0 ? false : true}/> Sleep
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    sleepData.length > 0 ? <span
                                                        className="text-base font-normal text-gray-600 leading-normal ">{sleepData.slice(-1)[0].value.toString()}{sleepData.slice(-1)[0].week !== reportWeek ? weekDecorator(sleepData.slice(-1)[0].week) : ''}{sleepData.length > 1
                                                        ? changeDecorator(sleepImp, sleepData[0].week)
                                                        : ''}{sleepData.length > 0 ? indicatorDecorator(SleepIndicator(sleepData.slice(-1)[0].value)) : ''}
                                                        </span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (UEFIData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={UEFI} onChange={() => {
                                                        setUEFI(!UEFI);
                                                    }} disabled={UEFIData.length > 0 ? false : true}/> UEFI
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.UEFI))}
                                            </div>
                                            <div className="w-fit text-base font-normal text-gray-600 leading-normal inline-flex justify-start items-center">
                                                {
                                                    UEFIData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{UEFIData.slice(-1)[0].value.toString()}{UEFIData.slice(-1)[0].week !== reportWeek ? weekDecorator(UEFIData.slice(-1)[0].week) : ''}{UEFIData.length > 1
                                                            ? changeDecorator(UEFIImp, UEFIData[0].week, false, false)
                                                            : ''}{UEFIData.length > 0 ? indicatorDecorator(UEFIIndicator(UEFIData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                                <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Range of Motion</div>
                                                <div className=" align-text-bottom	has-tooltip self-center float-bottom text-center 	content-center	items-end	 pl-2 object-bottom whitespace-nowrap  inline-flex 	">
                                                    <span className="tooltip rounded shadow-lg p-1 ml-6 ">Range of motion values are "recent bests".<br/> The highest weekly best measurement from the past three weeks is selected as the recent best for each motion.</span>
                                                    <img className="h-5 w-5 hs-tooltip " src={'/icons/help-circle.svg'}></img>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex gap-x-2  text-base font-normal leading-normal  ' + (leftFlexionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={leftFlexion} onChange={() => {
                                                        setLeftFlexion(!leftFlexion);
                                                    }} disabled={leftFlexionData.length > 0 ? false : true}/> Left Arm Flexion
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    leftFlexionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(leftFlexionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(leftFlexionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(leftFlexionData, 3).week)
                                                            : ''}{leftAbductionData.length > 1 && !(leftFlexionData[0].week === bestRomMeasurement(leftFlexionData, 3).week && leftFlexionData[0].value === bestRomMeasurement(leftFlexionData, 3).value)
                                                            ? changeDecorator(leftFlexionImp, leftFlexionData[0].week, true)
                                                            : ''}{leftFlexionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftFlexion, bestRomMeasurement(leftFlexionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (leftScaptionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={leftScaption} onChange={() => {
                                                        setLeftScaption(!leftScaption);
                                                    }} disabled={leftScaptionData.length > 0 ? false : true}/> Left Arm Scaption
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    leftScaptionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(leftScaptionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(leftScaptionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(leftScaptionData, 3).week)
                                                            : ''}{leftScaptionData.length > 1 && !(leftScaptionData[0].week === bestRomMeasurement(leftScaptionData, 3).week && leftScaptionData[0].value === bestRomMeasurement(leftScaptionData, 3).value)
                                                            ? changeDecorator(leftScaptionImp, leftScaptionData[0].week, true)
                                                            : ''}{leftScaptionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftScaption, bestRomMeasurement(leftScaptionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (leftAbductionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={leftAbduction} onChange={() => {
                                                        setLeftAbduction(!leftAbduction);
                                                    }} disabled={leftAbductionData.length > 0 ? false : true}/> Left Arm Abduction
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    leftAbductionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(leftAbductionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(leftAbductionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(leftAbductionData, 3).week)
                                                            : ''}{leftAbductionData.length > 1 && !(leftAbductionData[0].week === bestRomMeasurement(leftAbductionData, 3).week && leftAbductionData[0].value === bestRomMeasurement(leftAbductionData, 3).value)
                                                            ? changeDecorator(leftAbductionImp, leftAbductionData[0].week, true)
                                                            : ''}{leftAbductionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.LeftAbduction, bestRomMeasurement(leftAbductionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (rightFlexionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={rightFlexion} onChange={() => {
                                                        setRightFlexion(!rightFlexion);
                                                    }} disabled={rightFlexionData.length > 0 ? false : true}/> Right Arm Flexion
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    rightFlexionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(rightFlexionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(rightFlexionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(rightFlexionData, 3).week)
                                                            : ''}{rightAbductionData.length > 1 && !(rightFlexionData[0].week === bestRomMeasurement(rightFlexionData, 3).week && rightFlexionData[0].value === bestRomMeasurement(rightFlexionData, 3).value)
                                                            ? changeDecorator(rightFlexionImp, rightFlexionData[0].week, true)
                                                            : ''}{rightFlexionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightFlexion, bestRomMeasurement(rightFlexionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (rightScaptionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={rightScaption} onChange={() => {
                                                        setRightScaption(!rightScaption);
                                                    }} disabled={rightScaptionData.length > 0 ? false : true}/> Right Arm Scaption
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    rightScaptionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(rightScaptionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(rightScaptionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(rightScaptionData, 3).week)
                                                            : ''}{rightScaptionData.length > 1 && !(rightScaptionData[0].week === bestRomMeasurement(rightScaptionData, 3).week && rightScaptionData[0].value === bestRomMeasurement(rightScaptionData, 3).value)
                                                            ? changeDecorator(rightScaptionImp, rightScaptionData[0].week, true)
                                                            : ''}{rightScaptionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightScaption, bestRomMeasurement(rightScaptionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (rightAbductionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={rightAbduction} onChange={() => {
                                                        setRightAbduction(!rightAbduction);
                                                    }} disabled={rightAbductionData.length > 0 ? false : true}/> Right Arm Abduction
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">
                                                {
                                                    rightAbductionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(rightAbductionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(rightAbductionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(rightAbductionData, 3).week)
                                                            : ''}{rightAbductionData.length > 1 && !(rightAbductionData[0].week === bestRomMeasurement(rightAbductionData, 3).week && rightAbductionData[0].value === bestRomMeasurement(rightAbductionData, 3).value)
                                                            ? changeDecorator(rightAbductionImp, rightAbductionData[0].week, true)
                                                            : ''}{rightAbductionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.RightAbduction, bestRomMeasurement(rightAbductionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (lowerFlexionData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={lowerFlexion} onChange={() => {
                                                        setLowerFlexion(!lowerFlexion);
                                                    }} disabled={lowerFlexionData.length > 0 ? false : true}/> Lower Back Flexion (Estimate)
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    lowerFlexionData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{bestRomMeasurement(lowerFlexionData, 3).value.toFixed(0).toString() + '°'}{bestRomMeasurement(lowerFlexionData, 3).week !== reportWeek
                                                            ? weekDecorator(bestRomMeasurement(lowerFlexionData, 3).week)
                                                            : ''}{lowerFlexionData.length > 1 && !(lowerFlexionData[0].week === bestRomMeasurement(lowerFlexionData, 3).week && lowerFlexionData[0].value === bestRomMeasurement(lowerFlexionData, 3).value)
                                                            ? changeDecorator(lowerFlexionImp, lowerFlexionData[0].week, true)
                                                            : ''} {lowerFlexionData.length > 0 ? indicatorDecorator(GetIndicator(OutcomeMeasureTypes.LowerFlexion, bestRomMeasurement(lowerFlexionData, 3).value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className="w-full flex-col justify-start items-start pb-2 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-x-4 gap-y-2 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="grow relative shrink basis-0  justify-center items-start gap-1 inline-flex">
                                                <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Behavioral</div>
                                            </div>

                                        </div>


                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (confidenceData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={confidence} onChange={() => {
                                                        setConfidence(!confidence);
                                                    }} disabled={confidenceData.length > 0 ? false : true}/> Confidence
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    confidenceData.length > 0 ? <span
                                                        className="text-base font-normal text-gray-600 leading-normal ">{confidenceData.slice(-1)[0].value.toString()}{confidenceData.slice(-1)[0].week !== reportWeek ? weekDecorator(confidenceData.slice(-1)[0].week) : ''}{confidenceData.length > 1
                                                        ? changeDecorator(confidenceImp, confidenceData[0].week)
                                                        : ''}{confidenceData.length > 0 ? indicatorDecorator(ConfidenceIndicator(confidenceData.slice(-1)[0].value)) : ''}
                                                        </span> : <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (FABQActivityData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={FABQActivity} onChange={() => {
                                                        setFABQActivity(!FABQActivity);
                                                    }} disabled={FABQActivityData.length > 0 ? false : true}/> FABQ Activity
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.FABQActivity))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    FABQActivityData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{FABQActivityData.slice(-1)[0].value.toString()}{FABQActivityData.slice(-1)[0].week !== reportWeek
                                                            ? weekDecorator(FABQActivityData.slice(-1)[0].week)
                                                            : ''}{FABQActivityData.length >
                                                        1
                                                            ? changeDecorator(FABQActivityImp, FABQActivityData[0].week, false, true)
                                                            : ''}{FABQActivityData.length > 0 ? indicatorDecorator(FABQActivityIndicator(FABQActivityData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (FABQWorkData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={FABQWork} onChange={() => {
                                                        setFABQWork(!FABQWork);
                                                    }} disabled={FABQWorkData.length > 0 ? false : true}/> FABQ Work
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.FABQWork))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    FABQWorkData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{FABQWorkData.slice(-1)[0].value.toString()}{FABQWorkData.slice(-1)[0].week !== reportWeek ? weekDecorator(FABQWorkData.slice(-1)[0].week) : ''}{FABQWorkData.length > 1
                                                            ? changeDecorator(FABQWorkImp, FABQWorkData[0].week, false, true)
                                                            : ''}{FABQWorkData.length > 0 ? indicatorDecorator(FABQWorkIndicator(FABQWorkData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (FABQTotalData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={FABQTotal} onChange={() => {
                                                        setFABQTotal(!FABQTotal);
                                                    }} disabled={FABQTotalData.length > 0 ? false : true}/> FABQ Total
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.FABQTotal))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    FABQTotalData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{FABQTotalData.slice(-1)[0].value.toString()}{FABQTotalData.slice(-1)[0].week !== reportWeek ? weekDecorator(FABQTotalData.slice(-1)[0].week) : ''}{FABQTotalData.length > 1
                                                            ? changeDecorator(FABQTotalImp, FABQTotalData[0].week, false, true)
                                                            : ''}{FABQTotalData.length > 0 ? indicatorDecorator(FABQTotalIndicator(FABQTotalData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (GAD7Data.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={GAD7} onChange={() => {
                                                        setGAD7(!GAD7);
                                                    }} disabled={GAD7Data.length > 0 ? false : true}/> GAD-7
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.GAD7))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    GAD7Data.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{GAD7Data.slice(-1)[0].value.toString()}{GAD7Data.slice(-1)[0].week !== reportWeek ? weekDecorator(GAD7Data.slice(-1)[0].week) : ''}{GAD7Data.length > 1
                                                            ? changeDecorator(GAD7Imp, GAD7Data[0].week, false, true)
                                                            : ''}{GAD7Data.length > 0 ? indicatorDecorator(GAD7Indicator(GAD7Data.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (moodData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={mood} onChange={() => {
                                                        setMood(!mood);
                                                    }} disabled={moodData.length > 0 ? false : true}/> Mood
                                                </label>
                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    moodData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{moodData.slice(-1)[0].value.toString()}{moodData.slice(-1)[0].week !== reportWeek ? weekDecorator(moodData.slice(-1)[0].week) : ''}{moodData.length > 1
                                                            ? changeDecorator(moodImp, moodData[0].week)
                                                            : ''}{moodData.length > 0 ? indicatorDecorator(MoodIndicator(moodData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>

                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%]  justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (PCSData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={PCS} onChange={() => {
                                                        setPCS(!PCS);
                                                    }} disabled={PCSData.length > 0 ? false : true}/> PCS
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.PCS))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    PCSData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{PCSData.slice(-1)[0].value.toString()}{PCSData.slice(-1)[0].week !== reportWeek ? weekDecorator(PCSData.slice(-1)[0].week) : ''}{PCSData.length > 1
                                                            ? changeDecorator(PCSImp, PCSData[0].week, false, true)
                                                            : ''}{PCSData.length > 0 ? indicatorDecorator(PCSIndicator(PCSData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (PHQ9Data.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={PHQ9} onChange={() => {
                                                        setPHQ9(!PHQ9);
                                                    }} disabled={PHQ9Data.length > 0 ? false : true}/> PHQ-9
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.PHQ9))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    PHQ9Data.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{PHQ9Data.slice(-1)[0].value.toString()}{PHQ9Data.slice(-1)[0].week !== reportWeek ? weekDecorator(PHQ9Data.slice(-1)[0].week) : ''}{PHQ9Data.length > 1
                                                            ? changeDecorator(PHQ9Imp, PHQ9Data[0].week, false, true)
                                                            : ''}{PHQ9Data.length > 0 ? indicatorDecorator(PHQ9Indicator(PHQ9Data.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="w-full inline-flex items-baseline ">
                                            <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] justify-start items-start pb-4 gap-2 inline-flex">
                                                <label className={'justify-start items-center flex  gap-x-2  text-base font-normal leading-normal  ' + (PSEQData.length > 0 ? 'text-gray-600' : 'text-gray-400')}>
                                                    <input className="w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300 justify-start items-start flex"
                                                        type="checkbox" checked={PSEQ} onChange={() => {
                                                        setPSEQ(!PSEQ);
                                                    }} disabled={PSEQData.length > 0 ? false : true}/> PSEQ
                                                </label>
                                                {metricTooltip(getTooltipText(OutcomeMeasureTypes.PSEQ))}

                                            </div>
                                            <div className="w-fit text-base  font-normal text-gray-600 leading-normal  inline-flex justify-start items-center">

                                                {
                                                    PSEQData.length > 0 ? <span
                                                            className="text-base font-normal text-gray-600 leading-normal ">{PSEQData.slice(-1)[0].value.toString()}{PSEQData.slice(-1)[0].week !== reportWeek ? weekDecorator(PSEQData.slice(-1)[0].week) : ''}{PSEQData.length > 1
                                                            ? changeDecorator(PSEQImp, PSEQData[0].week)
                                                            : ''}{PSEQData.length > 0 ? indicatorDecorator(PSEQIndicator(PSEQData.slice(-1)[0].value)) : ''}</span> :
                                                        <span className="gap-x-10 text-base font-normal text-gray-600 leading-normal ">- {noDataDecorator()}</span>
                                                }
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </SectionDivider>
                            <SectionDivider key={`divider-assessments`} label={'Assessments'}>
                                <div className=" pb-2 justify-center items-start gap-3  content-center inline-flex	">
                                    <img className="w-[18px] h-[18px] translate-y-0.5 relative bg-gray-50 rounded-full" src="/icons/info-circle.svg"/>
                                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex text-wrap  pb-5	">
                                        <div className="self-stretch text-wrap	 text-gray-600 text-base max-[1200px]:text-sm font-normal  leading-normal">The Assessments section was automatically generated to include statements for improving or declining Outcome Measures that were enabled during report creation. Before approving the report, ensure you are
                                            satisfied with the Outcome Measures enabled in the Objective Outcomes section, and be sure to proof-read the Assessments section to ensure the generated statements remain accurate.
                                        </div>
                                    </div>
                                </div>
                                <div className="  [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6  ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex ">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <label className={'justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7  '}>
                                                        <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                            type="checkbox" checked={physical} onChange={() => {
                                                            setPhysical(!physical);
                                                        }}/> Physical Assessment
                                                    </label>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Write a concise objective summary of improvements in physical function. Refer to Outcome Measures and/or patient reported milestones (ROM, Activity, Disability, Function, Sleep, Pain, Work, Medication.)</div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex ' +
                                            (physical ? 'bg-white' : 'bg-gray-200')} disabled={!physical} placeholder="Enter text" onChange={e => setPhysicalText(e.target.value)} value={physicalText}>

                                </textarea>
                                        <span className="self-stretch flex-col justify-start items-start  flex">
                                <span className="text-right w-full">
                                <span className={(physicalText.length <= 900 ? 'text-gray-600' : 'text-error-600') + '   text-sm float-right '}>{physicalText.length}/ 900</span>
                                </span>

                                </span>
                                    </div>
                                </div>

                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <label className={'justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7  '}>
                                                        <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                            type="checkbox" checked={behavioral} onChange={() => {
                                                            setBehavioral(!behavioral);
                                                        }}/> Behavioral Assessment
                                                    </label>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Write a concise objective summary of improvements in behavioral and cognitive function. Refer to behavioral Outcome Measures and/or patient-reported milestones (Mood, Confidence, PSEQ, PCS, FABQ, Positive Behaviors, Stress
                                                        Reduction, Engagement/Education.)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex ' +
                                            (behavioral ? 'bg-white' : 'bg-gray-200')} disabled={!behavioral} placeholder="Enter text" onChange={e => setBehavioralText(e.target.value)}
                                            value={behavioralText}></textarea>
                                        <span className="self-stretch flex-col justify-start items-start  flex">

                                <span className="text-right w-full">
                                <span className={(behavioralText.length <= 900 ? 'text-gray-600' : 'text-error-600') + '   text-sm float-right '}>{behavioralText.length}/ 900</span>
                                </span>

                                </span>

                                    </div>
                                </div>


                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <label className={'justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7  '}>
                                                        <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                            type="checkbox" checked={social} onChange={() => {
                                                            setSocial(!social);
                                                        }}/> Social Assessment
                                                    </label>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Write a concise objective summary of improvements in behavioral and cognitive function. Refer to behavioral Outcome Measures and/or patient-reported milestones (Mood, Confidence, PSEQ, PCS, FABQ, Positive Behaviors, Stress
                                                        Reduction, Engagement/Education.)
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex ' +
                                            (social ? 'bg-white' : 'bg-gray-200')} disabled={!social} placeholder="Enter text" onChange={e => setSocialText(e.target.value)} value={socialText}></textarea>

                                        <span className="self-stretch flex-col justify-start items-start  flex">

                                <span className="text-right w-full">
                                <span className={(socialText.length <= 600 ? 'text-gray-600' : 'text-error-600') + '   text-sm float-right '}>{socialText.length}/ 600</span>
                                </span>
                                <label className={'justify-start items-center flex text-gray-600 gap-x-2   text-base font-normal  leading-normal '}>
                                    <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex' type="checkbox" checked={revertSocial}
                                        onChange={() => {
                                            setRevertSocial(!revertSocial);
                                        }}/> Restore Unedited Social Assessment
                                </label>
                                </span>


                                    </div>
                                </div>

                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <label className={'justify-start items-center flex text-gray-900 gap-x-2  text-lg font-semibold leading-7  '}>
                                                        <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                            type="checkbox" checked={continuedNeed} onChange={() => {
                                                            setContinuedNeed(!continuedNeed);
                                                        }}/> Continued Need
                                                    </label>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Write a concise summary of any aspects of the patient's life which require ongoing focus and attention. Refer to objective evidence such as declining or stagnating Outcome Measures.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea className={'w-full resize-none h-[15vh] px-3.5 py-2.5  rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex ' +
                                            (continuedNeed ? 'bg-white' : 'bg-gray-200')} disabled={!continuedNeed} placeholder="Enter text" onChange={e => setContinuedNeedText(e.target.value)}
                                            value={continuedNeedText}></textarea>
                                        <span className="text-right w-full">
                                <span className={(continuedNeedText.length <= 900 ? 'text-gray-600' : 'text-error-600') + '   text-sm float-right '}>{continuedNeedText.length}/ 900</span>
                                </span>
                                    </div>
                                </div>
                            </SectionDivider>
                            <SectionDivider key={`divider-plan`} label={'Plan'}>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Templates</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">Choose any pre-written templates first, then edit the final plan below. Modifying the templates will overwrite any custom changes made to the final plan.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full ">
                                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal  '}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox" checked={continueProgram} onChange={() => {
                                                setContinueProgram(!continueProgram);
                                            }}/> Continue Program
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full pt-2 ">
                                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal  '}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox" checked={planEnd} onChange={() => {
                                                setPlanEnd(!planEnd);
                                            }}/> Completed 12-Week Program
                                        </label>
                                    </div>
                                </div>
                                <div className="w-full  inline-flex pt-2">
                                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal  '}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox" checked={notification} onChange={() => {
                                                setNotification(!notification);
                                            }}/> Physician Notification
                                        </label>
                                    </div>
                                    <div className="w-fit  ">
                                        <CustomDropDown locked={!notification} options={notifications} onchange={updateNotificationType} defaultValue={templateNotification} right={false} fitSpace={true}/>
                                    </div>
                                </div>
                                <br/>
                                <div className="w-full  inline-flex pt-2">
                                    <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 inline-flex">
                                        <label className={'justify-start items-center flex text-gray-600 gap-x-2  text-base font-normal leading-normal  '}>
                                            <input className='w-4 h-4 p-0.5 bg-gray-50 rounded border border-gray-300" justify-start items-start flex'
                                                type="checkbox" checked={extension} onChange={() => {
                                                setExtension(!extension);
                                            }}/> Recommend Program Extension
                                        </label>
                                    </div>
                                    <div className="w-fit  ">
                                        <CustomDropDown locked={!extension} options={templateWeekOptions} onchange={updateTemplateWeekType} defaultValue={templateWeeks} right={false} fitSpace={true}/>
                                    </div>
                                </div>
                                <div className=" [@media(max-width:1200px)]:w-[50%] w-[50%] [@media(min-width:1600px)]:w-[35%] flex-col justify-start items-start pb-4 gap-6 ">
                                    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                                        <div className="self-stretchflex-col justify-start items-start gap-5 flex mb-2">
                                            <div className="self-stretch justify-start items-start gap-4 inline-flex">
                                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
                                                    <div className="self-stretch text-gray-900 text-lg font-semibold  leading-7">Final Plan</div>
                                                    <div className="self-stretch text-gray-600 text-sm text-wrap font-normal  leading-tight">The Plan section should provide a concise overview of the recommended next steps in the patient's care. Keep in mind that physicians and payers often refer to this section first for a quick understanding of the care
                                                        direction.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea
                                            className="w-full resize-none h-[15vh] px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-start gap-2 inline-flex"
                                            placeholder="Enter text"
                                            onChange={e => setPlan(e.target.value)}
                                            value={plan}>
                                        </textarea>
                                    </div>
                                </div>
                            </SectionDivider>
                            <div className="w-1/2 [@media(min-width:1600px)]:w-[35%] h-11 flex-col justify-start items-start gap-4 flex">
                                <button onClick={dispatchApproveReport} className="self-stretch px-4 py-2.5 bg-success-600 hover:bg-success-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
                                    <div className="px-0.5 justify-center gap-x-1 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/file-check-02.svg"/>
                                        <div className="text-white text-base font-semibold  leading-normal">Approve Report</div>
                                    </div>
                                </button>
                            </div>
                            <div className="w-1/2 pt-2 [@media(min-width:1600px)]:w-[35%] h-11 flex-col justify-start items-start gap-4 flex">
                                <button onClick={dispatchDeleteReport} className="self-stretch px-4 py-2.5 bg-error-600 hover:bg-error-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
                                    <div className="px-0.5 justify-center gap-x-1 items-center inline-flex">
                                        <img className="w-5 h-5 pl-[3.33px] justify-center items-center inline-flex" src="/icons/delete.svg"/>
                                        <div className="text-white text-base font-semibold  leading-normal">Delete Report</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    )
        }
        </Page>
    );
};

export default withRouter(ReportVerification);
