import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from 'services/API.jsx';
import Input from 'input/Input.jsx';

const EditUser = ({history}) => {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState(null);

    useEffect(() => {

        getData();

    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();

        const data = JSON.stringify({
            first_name: firstName,
            last_name: lastName
        });

        updateData(data);

    };

    const getData = () => {
        API.get(`/user/`)
            .then(response => {
                console.log(response.data);
                setFirstName(response.data.first_name);
                setLastName(response.data.last_name);
                setEmail(response.data.email);

            })
            .catch(error => console.error(error));
    };

    const updateData = (data) => {
        API.post(`/user/`, data)
            .then((response) => {
                console.log(response);
                goToUser();
            })
            .catch(error => {
                console.error(error.response);
                setErrors(error.response && error.response.data);
            });
    };

    const goToUser = () => {
        history.push(`/user/`);
    };

    return (
        <Page title={"Edit your data"} showMenu>
			<form onSubmit={handleSubmit}>
				<Input
                    type="text"
                    name="First name"
                    value={firstName ? firstName : ''}
                    onChange={setFirstName}
                    required
                    formErrors={errors}
                />
				<Input
                    type="text"
                    name="Last name"
                    value={lastName ? lastName : ''}
                    onChange={setLastName}
                    required
                    formErrors={errors}
                />

				<Input
                    type="text"
                    name="Email (optional)"
                    value={email ? email : ''}
                    onChange={setEmail}
                    validations={[{type: "email"}]}
                    formErrors={errors}
                />

				<button>Save</button>

			</form>
		</Page>

    );

};

export default withRouter(EditUser);
