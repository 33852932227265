import moment from 'moment';
import { getTimezone } from './UserUtility.jsx';

export const getDuration = ( visitData ) => {
  // console.log("getting duration", visitData);
  //console.log(visitData)
  if(visitData.start_time ){
    // console.log(moment(visitData.end_time));
    //NOTE: In older versions of KarunaHOME, we didn't have motion_time so we use the total time from start to end to calculate the approximate duration
    let duration = (visitData.motion_time && visitData.motion_time > 0) ? visitData.motion_time * 1000 :0; 
    // console.log(duration)
    return duration;
  }

  return 0;
  
}

export const getDurationComplete = ( visitData ) => {

  if(visitData.start_time &&visitData.end_time ){

    let now =moment(visitData.end_time)
    let then =moment(visitData.start_time)

    let duration = moment.duration(now.diff(then))


    return now-then;
  }

  return 0;
  
}


export const getRoms = ( roms, visitData ) => {

}