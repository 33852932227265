import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Page from '../components/Page';
import API from 'services/API.jsx';
import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';
import { renderLoader } from '../utility/LoaderUtils';

const PatientProfile = ({
    children,
    clear = false,
    errors = null,
    forceOverFlow = false,
    redirect = false,
    successes = null,
    // Callbacks
    onPatientData = null
}) => {
    const [headerInfo, setHeaderInfo] = useState(null);
    const [insurer, setInsurer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState(null);
    const [physician, setPhysician] = useState(null);
    const [provider, setProvider] = useState(null);
    const [tpa, setTpa] = useState(null);
    const [vetkitVersion, setVetkitVersion] = useState(null);
    const patientId = useParams().id;
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    let history = useHistory();

    const breadcrumbLinks = () => {
        let crumbs = [
            {
                label: 'Patients',
                url: '/'
            }
        ];

        if (patientData) {
            crumbs.push();
        }

        return crumbs;
    };

    const toCoach = () => {
        history.push(`/patient/${patientId}/coaching-plan`);
    };

    const toActivity = () => {
        history.push(`/patient/${patientId}/activity`);
    };

    const toReports = () => {
        history.push(`/patient/${patientId}/reports`);
    };

    useEffect(
        () => {
            if (patientId === null) {
                history.push('/');
            }

            getPatientData();
        },
        []
    );

    useEffect(
        () => {
            let tabs = [];
            let roleType = role.toLowerCase();

            if (roleType === 'coach' || roleType === 'facilitator') {
                tabs.push({
                    key: 'coaching-plan',
                    text: 'Coaching Plan',
                    click: toCoach,
                    current: window.location.href.includes('coaching-plan')
                });
            }

            tabs.push({
                key: 'activity',
                text: 'Activity',
                click: toActivity,
                current: window.location.href.includes('activity')
            });

            if (roleType === 'coach' || roleType === 'facilitator') {
                tabs.push({
                    key: 'reports',
                    text: 'Reports',
                    click: toReports,
                    current: window.location.href.includes('reports')
                });
            }

            let info = {
                trail: breadcrumbLinks(),
                body: {
                    tabs: tabs
                }
            };

            if (patientData) {
                info['body'] = {
                    ...info['body'], ...{
                        name: (patientData ? patientData.first_name + ' ' + patientData.last_name : ''),
                        subheader: (patientData ? '#' + patientId + ' | ' + getGender(patientData.profile.gender) + ' | ' + getPatientHeight() : ''),
                        sideButton: { img: '/icons/edit.svg', text: 'Edit Patient', click: EditPatient },
                        info: ({ tpa: tpa, provider: provider, insurer: insurer, physician: physician }),
                        vetkit: vetkitVersion,
                        tabs: tabs
                    }
                };
            }

            setHeaderInfo(info);
        },
        [patientData]
    );

    const getPatientData = () => {
        API.get(`/patients/${patientId}`)
           .then(response => {
                   let data = response.data;

                   setPatientData(data);

                   if (data.client && data.client.version) {
                       let val = data.client.version.split('+unitycloudbuild')[0];
                       if (val.includes('prod')) {
                           val = val.split('-')[0];
                       }
                       setVetkitVersion(val);
                   }

                   data.physician_name ? setPhysician(data.physician_name) : setPhysician('n/a');
                   data.provider_name ? setProvider(data.provider_name) : setProvider('n/a');
                   if (data.profile) {
                       let profile = data.profile;
                       profile.third_party_administrators ? setTpa(profile.third_party_administrators) : setTpa('n/a');
                       profile.insurers ? setInsurer(profile.insurers) : setInsurer('n/a');
                   }
                   else {
                       setTpa('n/a');
                       setInsurer('n/a');
                   }

                   onPatientData && onPatientData(data);
                   setIsLoading(false);
               }
           )
           .catch(error => console.error(error));
    };

    const getPatientHeight = () => {
        let feet = patientData.profile.height * 3.2808;
        let inches = Math.round((feet - Math.floor(feet)) * 12);
        if (inches >= 12) {
            inches -= 12;
            feet += 1;
        }
        return `${Math.floor(feet)}' ${inches}"`;
    };

    const getGender = (gender) => {
        switch (gender) {
            case 0:
                return 'Female';
            case 1:
                return 'Male';
            default:
                return 'Nonbinary';
        }
    };

    const EditPatient = () => {
        if (patientId !== null && patientId !== undefined) {
            history.push(`/patient/${patientId}/edit`);
        }
    };

    const Redirect = () => {
        let roleType = role.toLowerCase();
        if (roleType === 'coach') {
            history.push(`/patient/${patientId}/coaching-plan`);
        }
        else if (roleType === 'facilitator') {
            history.push(`/patient/${patientId}/reports`);
        }
        else {
            history.push(`/reportsExternal`);
        }

        return null;
    };

    return (
        redirect ?
            (
                <React.Fragment>
                {
                    Redirect()
                }
                </React.Fragment>
            )
            :
            (
                <Page
                    clear={clear}
                    errors={errors}
                    headerObj={headerInfo ? headerInfo : { trail: breadcrumbLinks() }}
                    internal={true}
                    successes={successes}
                >
                {
                    isLoading ?
                        (
                            renderLoader('Loading Patient Data')
                        )
                        :
                        (
                            <div className={`w-full h-full justify-center items-start inline-flex whitespace-nowrap max-[1200px]:overflow-auto ${forceOverFlow ? 'overflow-y-auto' : ''}`}>
                                {children}
                            </div>
                        )
                }
                </Page>
            )
    );
};

export default PatientProfile;
