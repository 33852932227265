import { OutcomeMeasureTypes } from 'utility/OutcomeMeasureTypes.tsx';

export const isRomType = (measureType) => {
    const romTypes = [
        OutcomeMeasureTypes.LeftFlexion,
        OutcomeMeasureTypes.RightFlexion,
        OutcomeMeasureTypes.LeftScaption,
        OutcomeMeasureTypes.RightScaption,
        OutcomeMeasureTypes.LeftAbduction,
        OutcomeMeasureTypes.RightAbduction,
        OutcomeMeasureTypes.LowerFlexion
    ];
    return romTypes.includes(measureType);
};

export const isUpperRomType = (measureType) => {
    const upperRomTypes = [
        OutcomeMeasureTypes.LeftFlexion,
        OutcomeMeasureTypes.RightFlexion,
        OutcomeMeasureTypes.LeftScaption,
        OutcomeMeasureTypes.RightScaption,
        OutcomeMeasureTypes.LeftAbduction,
        OutcomeMeasureTypes.RightAbduction
    ];
    return upperRomTypes.includes(measureType);
};

export const isLowerRomType = (measureType) => {
    return measureType === OutcomeMeasureTypes.LowerFlexion;
};

export const bestRomMeasurement = (measures, range, noFilter = false) => {
    const adjustedRange = noFilter ? 1 : range;
    let best = null;
    let maxVal = Number.MIN_SAFE_INTEGER;
    for (let i = measures.length - 1, count = 0; i >= 0 && count < adjustedRange; i--, count++) {
        if (measures[i].value > maxVal) {
            maxVal = measures[i].value;
            best = measures[i];
        }
    }
    return best;
};

export const romImprovementPercent = (measurementA: number, measurementB: number): number => {
    if (measurementA === 0) {
        throw new Error('measureA cannot be zero to avoid division by zero.');
    }
    const delta = measurementB - measurementA;
    return (delta / measurementA) * 100;
};

export const normalizedRomImprovementPercent = (measurementType, measurementA: number, measurementB: number): number => {
    if (measurementA === 0 && measurementB === 0) {
        return 0;
    }
    const divider = isUpperRomType(measurementType) ? 180 : 90;
    const normalizedX = measurementA === 0 ? 1 : measurementA;
    const percent = Math.abs((measurementB - normalizedX) * 100 / divider);
    return percent === 0 ? 0 : Number(percent.toFixed(1));
};

export const upperROMIndicator = (val) => {
    if (val >= 165) {
        return ['Full Function', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates ability to perform various activities without limitation.', 5];
    }
    if (val >= 145) {
        return ['Functional/Near Normal', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates ability to perform various activities without significant limitation.', 4];
    }
    if (val >= 120) {
        return ['Minimally Limited', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates slight difficulty with tasks requiring shoulder movement.', 3];
    }
    if (val >= 30) {
        return ['Moderately Limited', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates increased difficulty with tasks requiring shoulder movement.', 2];
    }
    return ['Severely Limited', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant challenges with shoulder tasks, especially lifting, reaching, or overhead activities.', 1];
};

export const lowerROMIndicator = (val) => {
    if (val >= 86) {
        return ['Full Function/Normal', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates an ability to perform a wide range of activities without limitations, including bending and lifting.', 5];
    }
    if (val >= 76) {
        return ['Functional/Near Normal', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates an ability to perform various activities without significant limitation, including bending and lifting.', 4];
    }
    if (val >= 61) {
        return ['Minimally Limited', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some difficulty with tasks that involve bending forward or lifting.', 3];
    }
    if (val >= 16) {
        return ['Moderately Limited', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates greater challenges in performing tasks that require forward bending or lifting.', 2];
    }
    if (val >= 0) {
        return ['Severely Limited', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant impairment in tasks involving bending, lifting, and prolonged sitting.', 1];
    }
};