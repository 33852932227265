import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Prompt } from 'react-router';
import { ViewState } from './EditCoachingPlanHead.tsx';
import ActivityAssignmentPanel from './ActivityAssignmentPanel.tsx';
import { getValueAtPath } from '../../../utility/ObjectUtils.tsx';

interface RoutineVar {
    state: ViewState;
    firstName: String;
    personalRoutine: any;
    TogglePersonalRoutine: any;
    routineBadgeData: any;
    selectedWeek: any;
    activityStats: any;
    setActivity: any;
    setDuration: any;
    constRoutine: any;
    setOption: any;
    
}

function classNames (...classes) {
    return classes.filter(Boolean).join(' ');
}

const RoutineSection = ({ state, firstName, personalRoutine, activityStats, TogglePersonalRoutine, routineBadgeData, selectedWeek, setActivity, setDuration, constRoutine, setOption }: RoutineVar) => {
    
    const [refresh, setRefresh] = useState(false);
//console.log(routineBadgeData)
    useEffect(() => {/*console.log(refresh)*/
    }, [personalRoutine, refresh]);
    
    const RefreshSection = () => {
        setRefresh(!refresh);
    };
    const ContextBarColor = (raw) => {
        switch (true) {
            
            case raw < 1000:
                return 'bg-yellow-300';
            case raw < 2500:
                return 'bg-lime-300';
            case raw < 4000:
                return 'bg-green-light-300';
            default:
                return 'bg-success-300';
            
        }
        
    };
    
    const ContextTextColor = (raw) => {
        switch (true) {
            
            case raw < 1000:
                return 'text-yellow-700';
            case raw < 2500:
                return 'text-lime-800';
            case raw < 4000:
                return 'text-green-light-700';
            default:
                return 'text-success-700';
            
        }
        
    };
    
    const ContextBadgeBackgroundColor = (raw) => {
        switch (true) {
            
            case raw < 1000:
                return 'bg-yellow-50';
            case raw < 2500:
                return 'bg-lime-50';
            case raw < 4000:
                return 'bg-green-light-50';
            default:
                return 'bg-success-50';
            
        }
        
    };
    
    const ContextLevel = (raw) => {
        let level = '';
        switch (true) {
            
            case raw < 1000:
                level = 'Keep Going ';
                break;
            case raw < 2500:
                level = 'Good';
                break;
            case raw < 4000:
                level = 'Great';
                break;
            default:
                level = 'Excellent';
                break;
        }
        
        return level;
    };
    
    const badgeParse = (rank) => {
        if (rank === 1) {return 'Bronze';}
        if (rank === 2) {return 'Silver';}
        if (rank >= 3) {return 'Gold';}
    };
    
    const GetRoutineBadge = (week) => {
        if (routineBadgeData && routineBadgeData[week] !== undefined && routineBadgeData[week].count > 0) {
            
            let rank = routineBadgeData[week].count;
            let rankString = badgeParse(rank);
            if (week > 12) {week = 12;}
            return '/icons/badges/CoachingPlan_Week' + week + rankString + '.png';
        }
        else {
            return '/icons/badges/CoachingPlanNone.png';
        }
    };
    
    const hasDuration = (val) => {
        if (val && typeof (val) !== 'string') {
            let duration: number = getValueAtPath(activityStats[val.Id], 'Settings.Duration.Value', 120);
            return getValueAtPath(val, 'Settings.Duration', duration);
        }
        return 0;
    };
    
    const GetActivityBadge = (actType, rank) => {
        let rankString = badgeParse(rank);
        return '/icons/badges/' + actType + 'Activity_' + rankString + '.png';
    };
    
    return (
        <div className="text-wrap w-full h- px-6 py-[15px]   flex-col justify-start items-start gap-2.5 inline-flex">
            <div className="self-stretch  justify-start items-center gap-6 inline-flex">
                <div className="text-gray-900 text-xl font-semibold  leading-[30px]">{firstName && firstName.split(' ')[0]}’s Routine</div>
                
                {state && (state === ViewState.Active || state === ViewState.Completed || state === ViewState.Interrupted || state === ViewState.CompletedPost) &&
                    <img className={'w-9 h-9 '} src={GetRoutineBadge(selectedWeek)}/>}
                {
                    state && (state === ViewState.Active || state === ViewState.Completed || state === ViewState.Interrupted || state === ViewState.CompletedPost) && <div className="w-28 h-[22px] mix-blend-multiply justify-start items-start inline-flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center gap-1 flex">
                            <img className="w-3 h-3 relative" src="/icons/check-circle-fancy.svg"/>
                            <div className="text-center text-blue-800 text-xs font-medium  leading-[18px]">{routineBadgeData && routineBadgeData[selectedWeek] !== undefined ? routineBadgeData[selectedWeek].count + (routineBadgeData[selectedWeek].count !== 1 ? ' completions' : ' completion') : 0 +
                                ' completions'}</div>
                        </div>
                    </div>
                }
                {state && (state === ViewState.Active) && personalRoutine && <div key={personalRoutine} className="grow shrink w-[100%] basis-0 h-full  items-center gap-2 flex  ">
                    <div className="justify-center items-center flex">
                        <input
                            key={personalRoutine}
                            className="w-5 h-5 p-0.5 bg-gray-50 rounded border border-blue-600 justify-center items-center flex"
                            type="checkbox"
                            checked={personalRoutine ? personalRoutine.enabled : false}
                            onChange={TogglePersonalRoutine}
                        />
                    </div>
                    <div className="text-gray-600 text-normal  grow shrink   w-[100%] leading-tight whitespace-nowrap">Enable in VR</div>
                </div>}
            </div>
            
            {state && state === ViewState.Pending && <div className="w-full justify-start items-start gap-6 inline-flex">
                <div className="w-full text-wrap	 text-gray-900 text-sm font-normal  leading-tight">Personal Routines must be edited in the current plan week.</div>
            </div>}
            {state && state === ViewState.Cancelled && <div className="w-full justify-start items-start gap-6 inline-flex">
                <div className="w-full text-wrap	 text-gray-900 text-sm font-normal  leading-tight"> No recorded routine data for this week.</div>
            </div>}
            {state && (state === ViewState.Active || state === ViewState.Completed || state === ViewState.Interrupted || state === ViewState.CompletedPost) && <div className="w-full justify-start items-start gap-6 ">
                <div className="w-full text-wrap	 text-gray-900 text-sm font-normal  leading-tight"> A sequence of movement activities for restoring functional capability, tailored to each patient’s individual needs. A Karuna Rating is awarded on completion, representing the patient’s level of function and range of motion. The patient may complete their
                    routine across multiple sessions.
                </div>
                {routineBadgeData !== null && routineBadgeData !== undefined && routineBadgeData[selectedWeek] !== undefined && routineBadgeData[selectedWeek].rating.score !== 0 ?
                    <div className="w-full  rounded-lg shadow border border-gray-300 p-2 justify-start mt-2 gap-2 inline-flex">
                        <div className="  inline-flex justify-start items-center gap-1  ">
                            <div className="justify-start  items-start flex">
                                <div className="w-5 h-5 rounded-lg justify-center items-center flex">
                                    <img className="w-5 h-5" src="/icons/icon.png"/>
                                </div>
                            </div>
                            <div className="text-slate-700 text-sm font-medium    leading-tight">Best Karuna Rating</div>
                        </div>
                        <div className=" grow shrink h-2 mt-1.5 relative rounded-lg">
                            <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full"/>
                            <div className={classNames('h-2 left-0 top-0 absolute  rounded-full', ContextBarColor(routineBadgeData[selectedWeek].rating.score))} style={{ width: (Math.round((routineBadgeData[selectedWeek].rating.score / 5000) * 100)) + '%' }}/>
                        </div>
                        <div className=" inline-flex  items-center gap-1 right-0">

                            <div className="text-right text-gray-700 text-wrap text-sm font-xs  leading-tight">{routineBadgeData[selectedWeek].rating.score}</div>
                            <div className="mix-blend-multiply justify-start items-start flex">
                                <div className={'px-2 py-0.5  rounded-2xl justify-center items-center flex ' + ContextBadgeBackgroundColor(routineBadgeData[selectedWeek].rating.score)}>
                                    <div className={'text-center   text-xs font-medium  leading-[18px] text-nowrap ' + ContextTextColor(routineBadgeData[selectedWeek].rating.score)}>{ContextLevel(routineBadgeData[selectedWeek].rating.score)}</div>
                                </div>
                            </div>

                        </div>
                    </div> :
                    
                    <div className="w-full  rounded-lg shadow border p-2 border-gray-300 mt-2 justify-start items-center gap-2 inline-flex">
                        <div className="inline-flex justify-start items-center gap-1  ">
                            <div className="justify-start items-start flex">
                                <div className="w-5 h-5 rounded-lg justify-center items-center flex">
                                    <img className="w-5 h-5" src="/icons/icon.png"/>
                                </div>
                            </div>
                            <div className="text-slate-700 text-sm font-medium    leading-tight">Best Karuna Rating</div>
                        </div>
                        <div className="grow shrink h-2  relative rounded-lg">
                            <div className="w-[100%] h-2 left-0 top-0 absolute bg-gray-200 rounded-full"/>
                        </div>
                        <div className=" inline-flex  items-center gap-1  right-0">

                            <div className="text-right text-gray-700 text-wrap text-sm font-xs  leading-tight">0</div>
                            <div className="mix-blend-multiply justify-start items-start flex">
                                <div className="px-2 py-0.5 bg-gray-50 rounded-2xl justify-center items-center flex">
                                    <div className="text-center text-nowrap text-gray-700 text-xs font-medium  leading-[18px]">None</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {activityStats !== undefined && (personalRoutine !== null && (state === ViewState.Active || (routineBadgeData && routineBadgeData[selectedWeek] !== undefined && routineBadgeData[selectedWeek].scores.length !== 0)) ?
                        (<div key={selectedWeek} className="w-full h-[100%] max-[1800px]:h-[100%]	  grid min-[1200px]:grid-rows-2 grid-cols-2  max-[1200px]:grid-cols-1 gap-4  my-2">
                            <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full    min-w-0 min-h-0	 relative rounded-[10px] shadow border border-gray-200">
                                <ActivityAssignmentPanel setOptionMethod={setOption} refreshCheck={refresh} refreshMethod={RefreshSection} currentAssignments={personalRoutine.assignments} setActivityMethod={setActivity} setDurationMethod={setDuration}
                                    originalDuration={constRoutine && constRoutine.assignments && constRoutine.assignments[0] && constRoutine.assignments[0].Id ? hasDuration(constRoutine.assignments[0]) : null}
                                    defaultDuration={personalRoutine && personalRoutine.assignments && personalRoutine.assignments[0] && personalRoutine.assignments[0].Id
                                        ? hasDuration(personalRoutine.assignments[0])
                                        : null} state={state} index={0} activities={activityStats} completedRoutineData={routineBadgeData} week={selectedWeek}
                                    originalAssignment={constRoutine.assignments[0]} defaultAssignment={personalRoutine.assignments[0]} isCalibrationActivity={true} isRoutineActivity={true}/>
                            </div>
                            <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full   min-w-0   min-h-0	relative rounded-[10px] shadow border border-gray-200">
                                <ActivityAssignmentPanel setOptionMethod={setOption} refreshCheck={refresh} refreshMethod={RefreshSection} currentAssignments={personalRoutine.assignments} setActivityMethod={setActivity} setDurationMethod={setDuration}
                                    originalDuration={constRoutine && constRoutine.assignments && constRoutine.assignments[2] && constRoutine.assignments[2].Id ? hasDuration(constRoutine.assignments[2]) : null}
                                    defaultDuration={personalRoutine && personalRoutine.assignments && personalRoutine.assignments[2] && personalRoutine.assignments[2].Id
                                        ? hasDuration(personalRoutine.assignments[2])
                                        : null} state={state} index={2} activities={activityStats} completedRoutineData={routineBadgeData} week={selectedWeek}
                                    originalAssignment={constRoutine.assignments[2]} defaultAssignment={personalRoutine.assignments[2]} isCalibrationActivity={false} isRoutineActivity={true}/>

                            </div>

                            <div className=" 	justify-start items-center inline-flex  max-[1200px]:w-full    min-w-0   min-h-0	 relative rounded-[10px] shadow border border-gray-200">
                                <ActivityAssignmentPanel setOptionMethod={setOption} refreshCheck={refresh} refreshMethod={RefreshSection} currentAssignments={personalRoutine.assignments} setActivityMethod={setActivity} setDurationMethod={setDuration}
                                    originalDuration={constRoutine && constRoutine.assignments && constRoutine.assignments[4] && constRoutine.assignments[4].Id ? hasDuration(constRoutine.assignments[4]) : null}
                                    defaultDuration={personalRoutine && personalRoutine.assignments && personalRoutine.assignments[4] && personalRoutine.assignments[4].Id
                                        ? hasDuration(personalRoutine.assignments[4])
                                        : null} state={state} index={4} activities={activityStats} completedRoutineData={routineBadgeData} week={selectedWeek}
                                    originalAssignment={constRoutine.assignments[4]} defaultAssignment={personalRoutine.assignments[4]} isCalibrationActivity={false} isRoutineActivity={true}/>
                            </div>
                            <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full   min-w-0   min-h-0  relative rounded-[10px] shadow border border-gray-200">
                                <ActivityAssignmentPanel setOptionMethod={setOption} refreshCheck={refresh} refreshMethod={RefreshSection} currentAssignments={personalRoutine.assignments} setActivityMethod={setActivity} setDurationMethod={setDuration}
                                    originalDuration={constRoutine && constRoutine.assignments && constRoutine.assignments[6] && constRoutine.assignments[6].Id ? hasDuration(constRoutine.assignments[6]) : null}
                                    defaultDuration={personalRoutine && personalRoutine.assignments && personalRoutine.assignments[6] && personalRoutine.assignments[6].Id
                                        ? hasDuration(personalRoutine.assignments[6])
                                        : null} state={state} index={6} completedRoutineData={routineBadgeData} activities={activityStats} week={selectedWeek}
                                    originalAssignment={constRoutine.assignments[6]} defaultAssignment={personalRoutine.assignments[6]} isCalibrationActivity={false} isRoutineActivity={true}/>

                            </div>
                        </div>) : (
                            
                            <div className="w-full text-wrap pt-2	 text-gray-900 text-sm font-normal  leading-tight"> No recorded routine data for this week.</div>
                        
                        )
                
                )
                
                }
            </div>
            
            }


        </div>
    );
};

export default RoutineSection;
