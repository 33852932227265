import React from 'react';

import { OutcomeMeasureTypes } from 'utility/OutcomeMeasureTypes.tsx';
import { isRomType, isUpperRomType, lowerROMIndicator, upperROMIndicator } from 'utility/OutcomeMeasureRomUtils.tsx';

export const getMeasurementDelta = (measurementA: number, measurementB: number) => {
    return measurementB - measurementA;
};

export const createMeasurementImprovement = (measureType, displayName, measures, normalizedImprovementPercent, bestMeasurement) => {
    let normalizedValues = measures?.map(
        (measure) => {
            return GetNormalizedValue(measureType, measure.value);
        }
    );
    
    return {
        type: measureType,
        name: displayName,
        value: measures,
        percent: normalizedImprovementPercent,
        ImprovedValue: HasImprovedScoreBand(measureType, measures, bestMeasurement),
        normalizedValues: normalizedValues,
        normalizedPercent: normalizedValues.length > 0 ? normalizedValues[normalizedValues.length - 1] - normalizedValues[0] : 0
    };
};

export const GetIndicator = (measureType, value) => {
    if (isUpperRomType(measureType)) {
        return upperROMIndicator(value);
    }
    switch (measureType) {
        case OutcomeMeasureTypes.FABQActivity:
            return FABQActivityIndicator(value);
        case OutcomeMeasureTypes.FABQTotal:
            return FABQTotalIndicator(value);
        case OutcomeMeasureTypes.FABQWork:
            return FABQWorkIndicator(value);
        case OutcomeMeasureTypes.PCS:
            return PCSIndicator(value);
        case OutcomeMeasureTypes.PSEQ:
            return PSEQIndicator(value);
        case OutcomeMeasureTypes.PHQ9:
            return PHQ9Indicator(value);
        case OutcomeMeasureTypes.GAD7:
            return GAD7Indicator(value);
        case OutcomeMeasureTypes.Oswestry:
            return OswestryIndicator(value);
        case OutcomeMeasureTypes.DASH:
            return DashIndicator(value);
        case OutcomeMeasureTypes.NDI:
            return NDIIndicator(value);
        case OutcomeMeasureTypes.WHODAS:
            return WHODASIndicator(value);
        case OutcomeMeasureTypes.UEFI:
            return UEFIIndicator(value);
        case OutcomeMeasureTypes.Pain:
            return PainIndicator(value);
        case OutcomeMeasureTypes.PCSS:
            return PCSSIndicator(value);
        case OutcomeMeasureTypes.Activity:
            return ActivityIndicator(value);
        case OutcomeMeasureTypes.Confidence:
            return ConfidenceIndicator(value);
        case OutcomeMeasureTypes.Sleep:
            return SleepIndicator(value);
        case OutcomeMeasureTypes.Medication:
            return MedicationIndicator(value);
        case OutcomeMeasureTypes.Work:
            return WorkIndicator(value);
        case OutcomeMeasureTypes.Mood:
            return MoodIndicator(value);
        case OutcomeMeasureTypes.LowerFlexion:
            return lowerROMIndicator(value);
        case OutcomeMeasureTypes.KarunaRating:
            return KarunaRatingIndicator(value);
    }
};

export const HasImprovedScoreBand = (measureType, measures, bestMeasurement) => {
    if (isUpperRomType(measureType)) {
        return UpperROMScoreBand(measures, bestMeasurement);
    }
    switch (measureType) {
        case OutcomeMeasureTypes.FABQActivity:
            return FABQActivityScoreBand(measures);
        case OutcomeMeasureTypes.FABQTotal:
            return FABQTotalScoreBand(measures);
        case OutcomeMeasureTypes.FABQWork:
            return FABQWorkScoreBand(measures);
        case OutcomeMeasureTypes.PCS:
            return PCSScoreBand(measures);
        case OutcomeMeasureTypes.PSEQ:
            return PSEQScoreBand(measures);
        case OutcomeMeasureTypes.PHQ9:
            return PHQ9ScoreBand(measures);
        case OutcomeMeasureTypes.GAD7:
            return GAD7ScoreBand(measures);
        case OutcomeMeasureTypes.Oswestry:
            return OswestryScoreBand(measures);
        case OutcomeMeasureTypes.DASH:
            return DashScoreBand(measures);
        case OutcomeMeasureTypes.NDI:
            return NDIScoreBand(measures);
        case OutcomeMeasureTypes.WHODAS:
            return WHODASScoreBand(measures);
        case OutcomeMeasureTypes.UEFI:
            return UEFIScoreBand(measures);
        case OutcomeMeasureTypes.Pain:
            return PainScoreBand(measures);
        case OutcomeMeasureTypes.PCSS:
            return PCSSScoreBand(measures);
        case OutcomeMeasureTypes.Activity:
            return ActivityScoreBand(measures);
        case OutcomeMeasureTypes.Confidence:
            return ConfidenceScoreBand(measures);
        case OutcomeMeasureTypes.Sleep:
            return SleepScoreBand(measures);
        case OutcomeMeasureTypes.Mood:
            return MoodScoreBand(measures);
        case OutcomeMeasureTypes.LowerFlexion:
            return LowerROMScoreBand(measures, bestMeasurement);
        case OutcomeMeasureTypes.KarunaRating:
            return KarunaRatingScoreBand(measures);
    }
};

export const LowerIsBetter = (measureType) => {
    switch (measureType) {
        case OutcomeMeasureTypes.FABQActivity:
            return true;
        case OutcomeMeasureTypes.FABQTotal:
            return true;
        case OutcomeMeasureTypes.FABQWork:
            return true;
        case OutcomeMeasureTypes.PCS:
            return true;
        case OutcomeMeasureTypes.PSEQ:
            return false;
        case OutcomeMeasureTypes.PHQ9:
            return true;
        case OutcomeMeasureTypes.GAD7:
            return true;
        case OutcomeMeasureTypes.Oswestry:
            return true;
        case OutcomeMeasureTypes.DASH:
            return true;
        case OutcomeMeasureTypes.NDI:
            return true;
        case OutcomeMeasureTypes.WHODAS:
            return true;
        case OutcomeMeasureTypes.UEFI:
            return false;
        case OutcomeMeasureTypes.Pain:
            return true;
        case OutcomeMeasureTypes.PCSS:
            return true;
        case OutcomeMeasureTypes.Activity:
            return false;
        case OutcomeMeasureTypes.Confidence:
            return false;
        case OutcomeMeasureTypes.Sleep:
            return false;
        case OutcomeMeasureTypes.Mood:
            return false;
        case OutcomeMeasureTypes.LowerFlexion:
            return false;
        case OutcomeMeasureTypes.KarunaRating:
            return false;
        
    }
    if (isUpperRomType(measureType)) {
        return false;
    }
};

export const UsePercentage = (measureType) => {
    return (isRomType(measureType));
};

const NormalizeValue = (value, maxVal, lowerIsBetter = false) => {
    let score_actual = value;
    if (lowerIsBetter) {
        score_actual = maxVal - score_actual;
    }
    return ((score_actual - 0) / (maxVal - 0)) * 100;
};

export const GetNormalizedValue = (measureType, value) => {
    if (isUpperRomType(measureType)) {
        return NormalizeValue(value, 180, false);
    }
    switch (measureType) {
        case OutcomeMeasureTypes.FABQActivity:
            return NormalizeValue(value, 24, true);
        case OutcomeMeasureTypes.FABQTotal:
            return NormalizeValue(value, 96, true);
        case OutcomeMeasureTypes.FABQWork:
            return NormalizeValue(value, 42, true);
        case OutcomeMeasureTypes.PCS:
            return NormalizeValue(value, 52, true);
        case OutcomeMeasureTypes.PSEQ:
            return NormalizeValue(value, 60, false);
        case OutcomeMeasureTypes.PHQ9:
            return NormalizeValue(value, 27, true);
        case OutcomeMeasureTypes.GAD7:
            return NormalizeValue(value, 21, true);
        case OutcomeMeasureTypes.Oswestry:
            return NormalizeValue(value, 50, true);
        case OutcomeMeasureTypes.DASH:
            return NormalizeValue(value, 100, true);
        case OutcomeMeasureTypes.NDI:
            return NormalizeValue(value, 50, true);
        case OutcomeMeasureTypes.WHODAS:
            return NormalizeValue(value, 5, true);
        case OutcomeMeasureTypes.UEFI:
            return NormalizeValue(value, 80, false);
        case OutcomeMeasureTypes.Pain:
            return NormalizeValue(value, 10, true);
        case OutcomeMeasureTypes.PCSS:
            return NormalizeValue(value, 132, true);
        case OutcomeMeasureTypes.Activity:
            return NormalizeValue(value, 10, false);
        case OutcomeMeasureTypes.Confidence:
            return NormalizeValue(value, 10, false);
        case OutcomeMeasureTypes.Sleep:
            return NormalizeValue(value, 10, false);
        case OutcomeMeasureTypes.Mood:
            return NormalizeValue(value, 10, false);
        case OutcomeMeasureTypes.LowerFlexion:
            return NormalizeValue(value, 90, false);
        case OutcomeMeasureTypes.KarunaRating:
            return NormalizeValue(value, 5000, false);
    }
};

export const getTooltipText = (measureType) => {
    
    switch (measureType) {
        case OutcomeMeasureTypes.DASH:
            return 'A measure of a patient\'s physical function and symptoms with musculoskeletal disorders of the upper limb (0-100).';
        case OutcomeMeasureTypes.NDI:
            return 'A measure of a patient’s neck-specific disability. Widely used to assess the impact of neck pain on daily activities (0-50).';
        case OutcomeMeasureTypes.Oswestry:
            return 'A measure of a patient’s function disability related to low back pain (0-50).';
        case OutcomeMeasureTypes.WHODAS:
            return 'A measure of general health and disability across cultures (1.0-5.0).';
        case OutcomeMeasureTypes.KarunaRating:
            return 'A bespoke measure of function across multiple VET™ movement activities. Combines range of motion with functional capability demonstrated in each activity into a single score (0-5000).';
        case OutcomeMeasureTypes.PCSS:
            return 'A measure of  the severity of post-concussion symptoms (0-132).';
        case OutcomeMeasureTypes.UEFI:
            return 'A measure of the functional status of the upper limb musculoskeletal disorders (0-80).';
        case OutcomeMeasureTypes.FABQActivity:
            return 'A measure of the patient\'s beliefs about how general physical activity may affect their pain (0-24).';
        case OutcomeMeasureTypes.FABQWork:
            return 'A measure of the patient\'s beliefs about how work and work-related activities may contribute to their pain (0-42).';
        case OutcomeMeasureTypes.FABQTotal:
            return 'A measure of the patient\'s beliefs about how physical activity and work affect their pain (0-96).';
        case OutcomeMeasureTypes.GAD7:
            return 'A measure of the severity of generalized anxiety disorder (0-21).';
        case OutcomeMeasureTypes.PCS:
            return 'A measure of the extent of catastrophic thinking related to pain (0-52).';
        case OutcomeMeasureTypes.PHQ9:
            return 'A measure of the severity of depression (0-27).';
        case OutcomeMeasureTypes.PSEQ:
            return 'A measure of the confidence of patients with chronic pain in performing activities despite their pain (0-60).';
        
        default:
            return null;
    }
    
};
// #endregion

//#region change stuff
export const changeDecorator = (change, firstWeek, percent = false, wantDecrease = false) => {
    if (change === 0) {
        return (
            <React.Fragment>
                <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-gray-50 rounded-full border border-gray-200 justify-start items-center gap-1.5 inline-flex">
                    <img className="w-3 h-3 relative" src="/icons/minus.svg"/>
                    <div className="text-center text-gray-700 text-xs font-medium  leading-[18px]">No Change from Week {firstWeek}</div>
                </div>
            </React.Fragment>
        );
        
    }
    if (!wantDecrease) {
        if (change > 0) {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/upgood.svg"/>
                        <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/downBad.svg"/>
                        <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            );
        }
    }
    else {
        if (change > 0) {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-warning-50 rounded-full border border-warning-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/upbad.svg"/>
                        <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <div className=" pl-1.5 pr-2 py-0.5 mx-1.5 bg-success-50 rounded-full border border-success-200 justify-start items-center gap-1.5 inline-flex">
                        <img className="w-3 h-3 relative" src="/icons/downgood.svg"/>
                        <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">{Math.abs(change)}{percent ? '%' : ''} from Week {firstWeek}</div>
                    </div>
                </React.Fragment>
            );
        }
    }
    
};

export const changeText = (change, firstWeek, percent = false, wantDecrease = false) => {
    if (change === 0) {
        return (
            'No Change'
        );
        
    }
    if (!wantDecrease) {
        if (change > 0) {
            return (
                `Given ${Math.abs(change)}${percent ? '%' : ''} from Week ${firstWeek}`
            );
        }
        else {
            return (
                `${Math.abs(change)}${percent ? '%' : ''} from Week ${firstWeek}`
            );
        }
    }
    else {
        if (change > 0) {
            return (
                `${Math.abs(change)}{percent?"%":""} from Week ${firstWeek}`
            );
        }
        else {
            return (
                `${Math.abs(change)}${percent ? '%' : ''} from Week ${firstWeek}`
            );
        }
    }
    
};

export const isPercentChange = (measureType) => {
    if (measureType !== 'other' || !isRomType(measureType)) {
        return false;
    }
    else {
        return false;
    }
};

export const wantsDecreaseChange = (measureType) => {
    if (measureType === 'other' || isRomType(measureType)) {
        return false;
    }
    else {
    
    }
};

export const changeColor = (change, firstWeek, percent = false, wantDecrease = false) => {
    if (change === 0) {
        return (
            '#344054'
        );
        
    }
    if (!wantDecrease) {
        if (change > 0) {
            return (
                '#067647'
            );
        }
        else {
            return (
                '#B54708'
            );
        }
    }
    else {
        if (change > 0) {
            return (
                '#B54708'
            );
        }
        else {
            return (
                '#067647'
            );
        }
    }
    
};

// #endregion

// #region indicators internal functions

const PSEQIndicator = (val) => {
    if (val > 40) {
        return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.', 3];
        
    }
    if (val > 20) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some confidence in their ability to perform activities, but with some limitations requiring support.', 2];
        
    }
    return ['Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.', 1];
    
};
const PCSIndicator = (val) => {
    if (val >= 30) {
        return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low confidence in their ability to perform activities despite pain, leading to reduced physical activity and greater disability.', 1];
        
    }
    if (val >= 15) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some confidence in their ability to perform activities, but with some limitations requiring support.', 2];
        
    }
    return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high confidence in their ability to manage pain and perform various activities. leading to better physical function.', 3];
    
};

const FABQTotalIndicator = (val) => {
    if (val >= 65) {
        return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant impact on function and likely to avoid many activities, leading to greater disability.', 1];
        
    }
    if (val >= 49) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates potential impact on function and may avoid some activities due to fear of pain.', 2];
        
    }
    return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal impact on function with better physical activity and function.', 3];
    
};
const FABQWorkIndicator = (val) => {
    if (val >= 25) {
        return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant avoidance of physical activities and functional tasks, leading to considerable reductions in physical function and activity levels.', 1];
        
    }
    if (val >= 15) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some functional limitations, but are not entirely incapacitated.', 2];
        
    }
    return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher likelihood to engage in physical activities and work tasks without excessive fear of pain exacerbation.', 3];
    
};
const FABQActivityIndicator = (val) => {
    if (val >= 17) {
        return ['High', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant avoidance of physical activities and likely to experience poor physical function.', 1];
        
    }
    if (val >= 9) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some avoidance of physical activities and ikely to have moderate impact on physical function.', 2];
        
    }
    return ['Low', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates active engagement in physical activities and likely to experience better physical function.', 3];
    
};

const PHQ9Indicator = (val) => {
    if (val >= 20) {
        return ['Severe Depression', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates debilitating symptoms, making it very difficult to function in daily life.', 1];
        
    }
    if (val >= 15) {
        return ['Moderately Severe Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates daily functioning is significantly impacted and may struggle with daily life functions.', 2];
        
    }
    if (val >= 10) {
        return ['Moderate Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates decreased concentration, energy, and interest in daily activities making them difficult to complete.', 3];
        
    }
    if (val >= 5) {
        return ['Mild Depression', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates minor difficulties in daily activities, but can generally manage their responsibilities.', 4];
        
    }
    return ['Minimal Depression', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal impact on daily functioning, including work, social activities, and personal care.', 5];
    
};
const GAD7Indicator = (val) => {
    if (val >= 15) {
        return ['Severe Anxiety', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe symptoms, often significantly interfering with daily activities and overall well-being.', 1];
        
    }
    if (val >= 10) {
        return ['Moderate Anxiety', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates symptoms of anxiety are more pronounced and may begin to impact daily functioning and quality of life. ', 2];
        
    }
    if (val >= 5) {
        return ['Mild Anxiety', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some manageable symptoms of anxiety, but may not interfere significantly with daily activities. ', 3];
        
    }
    
    return ['No Anxiety', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates little to no symptoms of anxiety and likely to perform effectively in their life roles.', 4];
    
};
const OswestryIndicator = (val) => {
    
    if (val >= 35) {
        return ['Completely Disabled', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates inability to carry out most daily activities independently.', 1];
        
    }
    if (val >= 25) {
        return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant pain and substantial limitations in their ability to perform daily activities.', 2];
        
    }
    if (val >= 15) {
        return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates difficulty with heavier or more strenuous activities.', 3];
        
    }
    if (val >= 5) {
        return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates general ability to perform most activities, but may experience some pain and slight limitations in function.', 4];
        
    }
    
    return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal or no pain and can perform all activities without significant restriction.', 5];
    
};
const DashIndicator = (val) => {
    if (val >= 81) {
        return ['Extreme Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates minimal to no functional capacity and complete dependence on others for most activities.', 1];
        
    }
    if (val >= 61) {
        return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates low functional capacity with major restrictions in daily activities.', 2];
        
    }
    if (val >= 41) {
        return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates reduced functional capacity with significant challenges in daily activities.', 3];
        
    }
    if (val >= 21) {
        return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates ability to perform most daily activities but may need occasional assistance.', 4];
        
    }
    
    return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high functional capacity and ability to perform most daily activities with minimal difficulty.', 5];
    
};
const NDIIndicator = (val) => {
    if (val >= 35) {
        return ['Complete Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates complete disability from neck pain, unable to carry out most daily activities.', 1];
        
    }
    if (val >= 25) {
        return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe pain and significant limitations in daily activities, with a substantial impact on their quality of life.', 2];
        
    }
    if (val >= 15) {
        return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates noticeable pain and moderate limitations in daily activities, affecting their ability to function normally.', 3];
        
    }
    if (val >= 5) {
        return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some pain and minor limitations in daily activities, but generally without significant impairment.', 4];
        
    }
    
    return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates no significant impact on daily activities due to neck pain.', 5];
    
};

const WHODASIndicator = (val) => {
    if (val >= 4.5) {
        return ['Extreme Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates major limitations in daily activities and overall functioning. Likely dependent on others for most tasks.', 1];
        
    }
    if (val >= 3.5) {
        return ['Severe Disability', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant limitations in performing daily activities. Likely require substantial assistance and support.', 2];
        
    }
    if (val >= 2.5) {
        return ['Moderate Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate difficulties in performing daily tasks. These limitations can impact work, social participation, and quality of life.', 3];
        
    }
    if (val >= 1.5) {
        return ['Mild Disability', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates slight difficulties in performing certain tasks, but these do not significantly affect their overall independence or quality of life.', 4];
        
    }
    
    return ['No Disability', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal interference with daily activities and overall functioning.', 5];
    
};

const KarunaRatingIndicator = (val) => {
    
    if (val >= 4000) {
        return ['Min. to No Limitations', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates high levels of physical function. Likely able to perform most daily tasks independently.', 4];
        
    }
    if (val >= 2500) {
        return ['Mild Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate levels of physical function. Likely independent in most daily tasks with some adjustments needed.', 3];
    }
    if (val >= 1000) {
        return ['Moderate Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates low levels of physical function. May be able to perform some basic tasks with difficulty and limited endurance.', 2];
    }
    if (val >= 0) {
        return ['Severe Limitations', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe limitations in function. Likely to experience significant mobility limitations and may struggle with basic daily tasks.', 1];
    }
};

const UEFIIndicator = (val) => {
    if (val >= 61) {
        return ['Min. to No Limitations', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates full independence and high function in daily activities and work.', 4];
        
    }
    
    if (val >= 41) {
        return ['Mild Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates good overall function and most likely independent in daily activities, with minor assistance.', 3];
        
    }
    if (val >= 21) {
        return ['Moderate Limitations', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some need for assistance or modifications, and notable impact on daily activities.', 2];
        
    }
    return ['Severe Limitations', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates severe limitations in function, significant need for assistance, and high impact on daily living activities.', 1];
    
};

const PainIndicator = (val) => {
    if (val >= 7) {
        return ['Severe', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant interference with daily activities or functions.', 1];
        
    }
    
    if (val >= 4) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates difficulty with heavier or more strenuous activities and may need some assistance for daily tasks.', 2];
        
    }
    if (val >= 1) {
        return ['Mild', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates no significant interference with daily activities or functions.', 3];
        
    }
    
    return ['None', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates no impact on function.', 4];
    
};

const PCSSIndicator = (val) => {
    if (val >= 76) {
        return ['Extreme Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates symptoms are debilitating, severely limiting the ability to carry out daily activities. May require substantial support.', 1];
        
    }
    if (val >= 61) {
        return ['Very Severe Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates symptoms are very severe, causing major disruptions to daily life and functioning. ', 2];
        
    }
    if (val >= 46) {
        return ['Severe Symptoms', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well.', 3];
        
    }
    
    if (val >= 31) {
        return ['Moderate Symptoms', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates symptoms are significantly interfering with daily activities and life functions.', 4];
        
    }
    if (val >= 16) {
        return ['Mild Symptoms', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates mild symptoms with possible disruption to daily activities, but can still function relatively well.', 5];
        
    }
    
    return ['Min. or No symptoms', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates minimal or mild symptoms, generally not interfering with daily activities.', 6];
    
};

const ActivityIndicator = (val) => {
    if (val >= 8) {
        return ['Very High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates excellent functional capacity with the ability to perform strenuous activities.', 5];
        
    }
    if (val >= 6) {
        return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates good functional capacity with minimal limitations and likely to manage daily activities and most tasks efficiently.', 4];
        
    }
    
    if (val >= 4) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates moderate functional capacity with the ability to perform most daily activities. May experience fatigue or difficulty with more strenuous tasks.', 3];
        
    }
    if (val >= 2) {
        return ['Low', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates some functional limitations. Daily activities may be challenging and require additional effort. ', 2];
        
    }
    
    return ['Very Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates significant functional impairment with considerable limitations in daily activities.', 1];
    
};

const ConfidenceIndicator = (val) => {
    if (val >= 9) {
        return ['Very High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates a positive mindset when approaching tasks, with full belief in their abilities.', 5];
        
    }
    if (val >= 7) {
        return ['High', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates general positivity about their ability to engage in most tasks and activities with minimal restrictions.', 4];
        
    }
    
    if (val >= 5) {
        return ['Moderate', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates willingness to attempt tasks with caution with some functional limitation.', 3];
        
    }
    if (val >= 3) {
        return ['Low', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates willingness to attempt some activities but remains hesitant and cautious.', 2];
        
    }
    
    return ['Very Low', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates possible decrease in participation in activities of daily living and daily life roles.', 1];
    
};

const SleepIndicator = (val) => {
    if (val >= 9) {
        return ['Excellent', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates optimal energy and physical performance, with little to no fatigue.', 5];
        
    }
    if (val >= 7) {
        return ['Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates good energy levels and physical performance, slightly reduced productivity.', 4];
        
    }
    
    if (val >= 5) {
        return ['Fair', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates mild fatigue, but generally able to perform daily activities.', 3];
        
    }
    if (val >= 3) {
        return ['Poor', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates noticeable fatigue and reduced stamina, with moderate impact on physical activities.', 2];
        
    }
    
    return ['Very Poor', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates extreme fatigue and decreased physical performance.', 1];
    
};

const MoodIndicator = (val) => {
    if (val >= 9) {
        return ['Very Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher energy and motivation, leading to higher levels of physical activity and function and excels in life roles.', 5];
        
    }
    if (val >= 7) {
        return ['Good', ['bg-success-50', 'border-success-200', 'text-success-700'], 'Indicates higher energy and motivation and able to manage challenges and engage in daily functions with resilience.', 4];
        
    }
    
    if (val >= 5) {
        return ['Fair', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates low energy, but still can engage in physical activities at a reduced level.', 3];
        
    }
    if (val >= 3) {
        return ['Poor', ['bg-warning-50', 'border-warning-200', 'text-warning-700'], 'Indicates fatigue, low energy, and decreased motivation, but may complete essential tasks and responsibilities.', 2];
        
    }
    
    return ['Very Poor', ['bg-error-50', 'border-error-200', 'text-error-700'], 'Indicates fatigue, low energy, and decreased motivation, leading to reduced physical activity and participation in daily tasks.', 1];
    
};

const MedReductionCheck = (ar) => {
    let length = ar.length;
    if (length <= 1) {
        return true;
    }
    let result = true;
    let ar2 = ar.slice(0, length - 1);
    ar2.forEach(e => {
        if (parseInt(e.value) !== 0) {
            result = false;
        }
    });
    return result;
};

const MedReductionCheckFull = (ar) => {
    let length = ar.length;
    if (length <= 1) {
        return true;
    }
    let result = true;
    let ar2 = ar.slice(0, length);
    ar2.forEach(e => {
        if (parseInt(e.value) !== 0) {
            
            result = false;
        }
    });
    return result;
};

const MedicationIndicator = (ar) => {
    let medRed = 'No';
    
    if (ar.slice(-1)[0].value >= 2 || MedReductionCheckFull(ar)) {
        medRed = 'No';
    }
    else {
        
        if (ar.slice(-1)[0].value <= 1 && !MedReductionCheck(ar)) {
            
            medRed = 'Yes';
            
        }
        else {
            
            medRed = 'No';
        }
    }
    return medRed;
    
};

const WorkIndicator = (val) => {
    if (val >= 3) {
        return 'Yes';
    }
    else {
        return 'No';
    }
};

// #endregion

// #region scoreBand internal functions

const UpperROMScoreBand = (measures, bestMeasurement) => {
    const start = upperROMIndicator(measures[0].value)[3];
    const end = upperROMIndicator(bestMeasurement.value)[3];
    return start === end ? 0 : start < end ? 1 : -1;
};

const LowerROMScoreBand = (measures, bestMeasurement) => {
    const start = lowerROMIndicator(measures[0].value)[3];
    const end = lowerROMIndicator(bestMeasurement.value)[3];
    return start === end ? 0 : start < end ? 1 : -1;
};

const PSEQScoreBand = (val) => {
    let start = PSEQIndicator(val[0].value)[3];
    let end = PSEQIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const PCSScoreBand = (val) => {
    let start = PCSSIndicator(val[0].value)[3];
    let end = PCSSIndicator(val[val.length - 1].value)[3];
    
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const FABQTotalScoreBand = (val) => {
    let start = FABQTotalIndicator(val[0].value)[3];
    let end = FABQTotalIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const FABQWorkScoreBand = (val) => {
    let start = FABQWorkIndicator(val[0].value)[3];
    let end = FABQWorkIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const FABQActivityScoreBand = (val) => {
    let start = FABQActivityIndicator(val[0].value)[3];
    let end = FABQActivityIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const PHQ9ScoreBand = (val) => {
    let start = PHQ9Indicator(val[0].value)[3];
    let end = PHQ9Indicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const GAD7ScoreBand = (val) => {
    let start = GAD7Indicator(val[0].value)[3];
    let end = GAD7Indicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const OswestryScoreBand = (val) => {
    
    let start = OswestryIndicator(val[0].value)[3];
    let end = OswestryIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const DashScoreBand = (val) => {
    
    let start = DashIndicator(val[0].value)[3];
    let end = DashIndicator(val[val.length - 1].value)[3];
    
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};
const NDIScoreBand = (val) => {
    let start = NDIIndicator(val[0].value)[3];
    let end = NDIIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const WHODASScoreBand = (val) => {
    let start = WHODASIndicator(val[0].value)[3];
    let end = WHODASIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const KarunaRatingScoreBand = (val) => {
    let start = KarunaRatingIndicator(val[0].value)[3];
    let end = KarunaRatingIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const UEFIScoreBand = (val) => {
    let start = UEFIIndicator(val[0].value)[3];
    let end = UEFIIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const PainScoreBand = (val) => {
    let start = PainIndicator(val[0].value)[3];
    let end = PainIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const PCSSScoreBand = (val) => {
    
    let start = PCSSIndicator(val[0].value)[3];
    let end = PCSSIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const ActivityScoreBand = (val) => {
    
    let start = ActivityIndicator(val[0].value)[3];
    let end = ActivityIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const ConfidenceScoreBand = (val) => {
    
    let start = ConfidenceIndicator(val[0].value)[3];
    let end = ConfidenceIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const SleepScoreBand = (val) => {
    let start = SleepIndicator(val[0].value)[3];
    let end = SleepIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

const MoodScoreBand = (val) => {
    let start = MoodIndicator(val[0].value)[3];
    let end = MoodIndicator(val[val.length - 1].value)[3];
    if (start === end) {
        return 0;
    }
    if (start < end) {
        return 1;
    }
    else {
        return -1;
    }
};

// #endregion

// #region assessment automation
export const AutomateStatement = (values, displayName, measureType, nonfunctional = false) => {
    
    let textVals = GetIndicator(measureType, values[values.length - 1].value);
    if (nonfunctional && textVals[1][1].includes('success')) {
        return '';
    }
    
    if (nonfunctional) {
        const lastValue = values[values.length - 1].value;
        const firstValue = values[0].value;
        const words = lastValue === firstValue ? 'remained at' : LowerIsBetter(measureType) === (lastValue < firstValue) ? 'improved to' : 'declined to';
        return `${displayName} has ${words} ${lastValue} (${textVals[0]}). ${textVals[2]}`;
    }
    else {
        return `${displayName} has improved to ${values[values.length - 1].value} (${textVals[0]}). ${textVals[2]}`;
    }
};

// #endregion