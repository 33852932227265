import React, { useEffect } from 'react';
import Divider from './Divider.tsx';
import EditCoachingPlanHead, { ViewState } from './EditCoachingPlanHead.tsx';
import BonusSection from './BonusSection.tsx';
import RoutineSection from './RoutineSection.tsx';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const CurrentWeekView = ({ activityStats = null, selectedWeek = null, name = null, addWeeks = null, setActivityBonus = null, setDurationBonus = null, setActivityPersonal = null, setDurationPersonal = null, weekOptions = null, personalRoutine = null, ToggleRoutine = null, currentTemplate = null, coachingPlan = null, endPlan = null, saveChanges = null, completeWeek = null, templates = null, setTemplate = null, changeWeek = null, routineScoreData = null, constPlan = null, constRoutine = null, setOptionBonus = null, setOptionRoutine = null }) => {

    useEffect(() => {}, [coachingPlan, personalRoutine, activityStats]);

    if (setOptionBonus !== null && setOptionRoutine !== null && constPlan !== null && constRoutine !== null && activityStats !== null && addWeeks !== null && selectedWeek !== null && name !== null && setActivityBonus !== null && setDurationBonus !== null && setActivityPersonal !== null &&
        setDurationPersonal !== null && weekOptions !== null && personalRoutine !== null && ToggleRoutine !== null && currentTemplate !== null && coachingPlan !== null && endPlan !== null && saveChanges !== null && completeWeek !== null && templates !== null && setTemplate !== null && changeWeek !==
        null && routineScoreData !== null) {
        return (
            <div key={personalRoutine} className="w-[100%]  p-10 flex-col justify-center items-center gap-y-6 inline-flex ">

        <div id="head" className="w-full  justify-center items-center gap-x-3 inline-flex  ">
        <EditCoachingPlanHead addWeeks={addWeeks} jumpBack={true} weekOptions={weekOptions} setSelectedWeek={changeWeek} selectedWeek={selectedWeek} state={ViewState.Active} coachingPlan={coachingPlan} currentTemplate={currentTemplate} saveChanges={saveChanges} endPlan={endPlan} completeWeek={completeWeek} coachingPlanTemplate={templates} setCoachingPlanTemplate={setTemplate}/>
        </div>
        <div id="divider" className="w-full  justify-center items-center inline-flex   ">
        <Divider label={'Assignments'}/>
</div>
<div id="divider" className="w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 ">
    <RoutineSection setOption={setOptionRoutine} constRoutine={constRoutine} setActivity={setActivityPersonal} setDuration={setDurationPersonal} key={personalRoutine} state={ViewState.Active} firstName={name} personalRoutine={personalRoutine} TogglePersonalRoutine={ToggleRoutine} routineBadgeData={routineScoreData} selectedWeek={selectedWeek} activityStats={activityStats}/>
</div>
<div id="divider" className="w-full  justify-center items-center gap-3 inline-flex  rounded-[10px] shadow border border-gray-200 ">
    <BonusSection setOption={setOptionBonus} constPlan={constPlan} setActivity={setActivityBonus} setDuration={setDurationBonus} key={coachingPlan} state={ViewState.Active} activityStats={activityStats} selectedWeek={selectedWeek} coachingplan={coachingPlan}/>
</div>

    </div>
        );
    }
    else {
        return (
            renderLoader()
        );
    }
};

export default CurrentWeekView;
