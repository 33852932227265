import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { withRouter } from 'react-router';
import StickyFooter from './StickyFooter';
import NotificationManager from './notifications/NotificationManager';
import SideMenu from './SideMenu';
import ModularHeader from './ModularHeader';
import { api_Diagnostics, logout } from 'services/AccountServices.jsx';

const Page = ({
    children,
    clearNotifications = false,
    errors = null,
    handleLogoutHook = null,
    headerObj,
    history,
    internal = true,
    location,
    successes = null
}) => {

    const prependStoredSuccess = (successes) => {
        let success = localStorage.getItem('success');
        if (success) {
            localStorage.removeItem('success');
            // Ensure successes is converted to an array in case the successes
            // passed into the Page constructor was a single Object.
            successes = Array.isArray(successes) ? [...successes] : successes ? [successes] : [];
            successes.unshift(success);
        }
        return successes;
    };

    // const [idleTime, setIdleTime] = useState(0);
    const [updatedSuccesses, setUpdatedSuccesses] = useState(() => prependStoredSuccess(successes));
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    const token = cookie.load('token') ? cookie.load('token') : null;
    const user = cookie.load('user') ? cookie.load('user') : null;

    const checkForLogout = async (location) => {
        if (location.pathname.includes('account-confirm-email')) {
            cookie.remove('token', { path: '/' });
            cookie.remove('csrftoken', { path: '/' });
            cookie.remove('role', { path: '/' });
            cookie.remove('user', { path: '/' });
            localStorage.clear();
            return;
        }
        if (user === null) {
            if (cookie.load('user') === undefined && cookie.load('token') === undefined) {
                if (location.pathname !== '/login' && !location.pathname.includes('/password/reset') && !location.pathname.includes('/account-confirm-email/')) {
                    await api_Diagnostics(
                        new Error('[Page] Forced logout: Cookies not found'),
                        handleLogout,
                        handleLogout
                    );
                }
            }
        }
    };

    useEffect(
        () => {
            if (token && role !== null) {
                let roleType = role.toLowerCase();
                if (roleType.toLowerCase().includes('admin') && location.pathname.includes('reportsExternal') === false) {
                    history.push('/reportsExternal');
                }
            }
        },
        [role]
    );

    useEffect(
        () => {
            void checkForLogout(location);
        },
        [user]
    );

    useEffect(
        () => {
            if (user && headerObj && headerObj.body && headerObj.body.name === undefined) {
                headerObj.body.name = `${user.first_name} ${user.last_name}`;
            }
        },
        []
    );

    // const updateTimer = () => {
    //     const timer = setTimeout(
    //         () => {
    //             if (idleTime > 1800) {
    //                 console.log('timed out');
    //                 handleLogout();
    //             }
    //             else {
    //                 setIdleTime(idleTime + 1);
    //             }
    //         },
    //         1000
    //     );
    //
    //     return () => clearTimeout(timer);
    // };
    //
    // const resetTimer = () => {
    //     setIdleTime(0);
    // };
    //
    // useEffect(
    //     () => {
    //         window.onload = resetTimer;
    //         document.onmousemove = resetTimer;
    //         document.onload = resetTimer;
    //         document.onmousedown = resetTimer;
    //         document.ontouchstart = resetTimer;
    //         document.onclick = resetTimer;
    //         document.onkeydown = resetTimer;
    //         document.addEventListener('scroll', resetTimer, true);
    //         return updateTimer();
    //     },
    //     [idleTime]
    // );

    const isEditPageCheck = () => {
        let parts = location.pathname.split('/');
        if (parts[1] === 'outcomes') {
            handleLogout();
        }
        return parts[1] === 'outcomes';
    };

    const handleLogout = () => {
        if (handleLogoutHook) {
            handleLogoutHook();
            return;
        }

        if (isEditPageCheck()) {
            return;
        }

        logout(onLogout);
    };

    const forceLogout = (_event) => {
        logout(onLogout);
    };

    const onLogout = () => {
        cookie.remove('token', { path: '/' });
        cookie.remove('csrftoken', { path: '/' });
        cookie.remove('role', { path: '/' });
        cookie.remove('user', { path: '/' });
        localStorage.clear();
        history.push('/login');
    };

    useEffect(() => {
        setUpdatedSuccesses(prependStoredSuccess(successes));
    }, [successes]);

    return (
        <React.Fragment>
		    <div className={`flex h-screen bg-white`}>
                {
                    internal &&
                    <SideMenu
                        role={role ? role : 'None'}
                        name={user ? `${user.first_name} ${user.last_name}` : 'user'}
                        onLogout={forceLogout}
                    />
                }
                <div
                    id="page-container"
                    className={`flex flex-col w-full h-full bg-white`}
                >
                    <div id="header-container" className="z-100 relative w-full">
                        <header id="header" className={'z-101 flex justify-center w-full shadow-md bg-white pointer-events-auto'}>
                            <ModularHeader
                                headerObj={headerObj}
                                internal={internal}
                            >
                            </ModularHeader>
                        </header>
                        {
                            <NotificationManager
                                clear={clearNotifications}
                                errors={errors}
                                successes={updatedSuccesses}
                            />
                        }
                    </div>
                    <div id="child-container" className={'z-25 flex-1 overflow-auto bg-white'}>
                        {children}
                    </div>
                    <StickyFooter internal={internal}/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Page);