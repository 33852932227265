import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Page from '../components/Page';
import API from 'services/API.jsx';
import Input from 'input/Input.jsx';
import { set } from 'lodash';

const ResetPassword = ({}) => {

    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmit = (event) => {
        event.preventDefault();

        setErrors(null);
        if (email.length < 1) {
            setErrors('please enter email');
            return;
        }

        const data = JSON.stringify({ email: email });
        API.post('password/reset/', data).then(response => {
            console.log(response);

            if (response.status !== 200) {
                if (response.code === 'ERR_BAD_REQUEST') {
                    throw new Error(response.response.data.errors[0].message);

                }
                else {

                    throw new Error(response);
                    return;
                }
            }

            setEmailSent(true);

        }).catch(error => {

            console.log(error);
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors[0] && error.response.data.errors[0].message) {
                setErrors(error.response.data.errors[0].message);
            }
            else {
                if (error.message !== undefined) {
                    setErrors(error.message);
                }
                else {
                    setErrors(error);
                }
            }
        });
    };

    const handleReset = () => {
        setEmailSent(false);
        setEmail('');

    };

    return (
        <Page errors={errors} internal={false}>
			{
                emailSent === false ?
                    (
                        <div className="h-screen flex items-center justify-center pb-12 ">
		  <div className="w-96 h-96 flex-col justify-start items-center gap-8 inline-flex">
			<div className="self-stretch h-40 flex-col justify-start items-center gap-6 flex">
			  <div className="w-14 h-14 p-3.5 rounded-xl shadow border border-gray-200 justify-center items-center inline-flex">
				<img className="w-7 h-7 relative flex-col justify-start items-start flex" src="/icons/key-01.svg"/>
			  </div>

			  <div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex">
				<div className="self-stretch text-center text-gray-900 text-3xl font-semibold  leading-9">Forgot password?</div>
				<div className="self-stretch text-center text-gray-600 text-base font-normal  leading-normal">No worries, we’ll send you reset instructions.</div>
			  </div>
			</div>

			<form className="w-full max-w-sm" onSubmit={handleSubmit}>
				<div className="self-stretch h-36 rounded-xl flex-col justify-start items-center gap-6 flex">
				<div className="self-stretch h-16 flex-col justify-start items-start gap-5 flex">
					<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
					<div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
						<div className="text-slate-700 text-sm font-medium  leading-tight">Email</div>
						<Input name="Email" className={'shadow appearance-none border rounded-lg w-full py-2.5 px-3.5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'} value={email
                            ? email
                            : ''} type="text" onChange={(value) => setEmail(value)} noLabel required validations={[{ type: 'email' }]} formErrors={errors}/>
					</div>
					</div>
				</div>
				<button className="self-stretch px-4 py-2.5 bg-brand-600 hover:bg-brand-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
				<div className="px-0.5 justify-center items-center flex">
										<div className="text-white text-base font-semibold  leading-normal">Reset Password</div>
									</div>
				</button>
				</div>
			</form>

			<a href="/src/pages/Login">
			  <div className="justify-center items-center gap-1.5 inline-flex">
				<img className="w-5 h-5" src="/icons/arrow-left.svg"/>
				<div className="text-gray-600  text-sm font-semibold  leading-tight"> Back to log in</div>
			  </div>
			</a>
		  </div>
		</div>
                    )
                    :
                    (
                        <div className="h-screen flex items-center justify-center pb-12 ">
			<div className="w-96 h-96 flex-col justify-start items-center gap-12 inline-flex">
			  <div className="self-stretch h-40 flex-col justify-start items-center gap-6 flex">
				<div className="w-14 h-14 p-3.5 rounded-xl shadow border border-gray-200 justify-center items-center inline-flex">
				  <img className="w-7 h-7 relative flex-col justify-start items-start flex" src="/icons/mail-01.svg"/>
				</div>
				<div className="self-stretch h-20 flex-col justify-start items-start gap-2 flex ">
				  <div className="self-stretch text-center text-gray-900 text-3xl font-semibold  pb-2 leading-9">Check your email</div>
				  <div className="self-stretch text-center text-gray-600 text-base font-normal  leading-normal">We sent a password reset link to </div>
				  <div className="self-stretch text-center text-gray-600 text-base font-medium  leading-normal">{email} </div>

				</div>
			  </div>
				  <div className="self-stretch h-6 pt-3 rounded-xl flex-col justify-start items-center  flex">
					<div className="self-stretch h-16 flex-col justify-start items-start gap-5 flex">
					<div className="self-stretch text-center text-gray-600 text-sm font-normal  leading-normal">Didn't recieve the email? <button onClick={handleReset} className="text-brand-600">Try again</button></div>

					</div>

				  </div>

			  <a href="/src/pages/Login">
				<div className="justify-center items-center gap-1.5 inline-flex">
				  <img className="w-5 h-5" src="/icons/arrow-left.svg"/>
				  <div className="text-gray-600  text-sm font-semibold  leading-tight"> Back to log in</div>
				</div>
			  </a>
			</div>
		  </div>
                    )
            }
	  </Page>

    );

};

export default withRouter(ResetPassword);
