import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useParams } from 'react-router';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';

import API from 'services/API.jsx';
import PatientProfile from './PatientProfile';
import { renderLoader } from 'utility/LoaderUtils.jsx';

const PatientReviewReportPage = ({ history }) => {
    const [download, setDownload] = useState(false);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
    const [outcomeReports, setOutcomeReports] = useState(null);
    const [pageWidth, setPageWidth] = useState(null);
    const [patientName, setPatientName] = useState(null);
    const [successes, setSuccesses] = useState(null);
    const [width, setWidth] = useState(null);
    const pageRef = useRef(null);
    const patientId = useParams().id;
    const tableRef = useRef(null);

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const divStyle = {
        width: (width < window.innerWidth ? 'fitContent' : '90%')
    };

    const handleResize = () => {
        setDimensions({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    const getOutcomeReports = async () => {
        await API
            .get(`/outcome_reports/?patient_id=${patientId}`)
            .then(
                response => {
                    let reports = response.data.results;
                    reports = reports.sort((a, b) => (Number.parseInt(a.included_weeks) !== Number.parseInt(b.included_weeks) ? (Number.parseInt(a.included_weeks) < Number.parseInt(b.included_weeks) ? 1 : -1) : (moment(a.prepared_date).isBefore(moment(b.prepared_date)) ? 1 : -1)));
                    setOutcomeReports(reports);
                    setLoading(false);
                }
            )
            .catch(
                error => {
                    console.error(error);
                    setErrors(error);
                    setOutcomeReports([]);
                    setLoading(false);
                }
            );
    };

    const getPDF = async (id, week, date) => {
        if (id !== undefined && week !== undefined && date !== undefined) {
            setDownload(true);
            await API
                .get(`/outcome_reports/${id}/?pdf_data=${true}/`)
                .then(
                    (response) => {
                        if (response.data.report_pdf_data) {
                            let url = `/report/${id}`;
                            localStorage.setItem('pdf', JSON.stringify(response.data));
                            window.open(url, '_blank');
                        }
                        setDownload(false);
                    }
                )
                .catch(
                    error => {
                        console.error(error);
                        setDownload(false);
                    }
                );
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
    }, []);

    useEffect(() => {
        if (pageRef.current !== null) {
            setPageWidth(pageRef.current.offsetWidth);
        }
        if (tableRef.current !== null) {
            setWidth(tableRef.current.offsetWidth);
        }
    }, [dimensions]);

    useEffect(() => {
    }, [divStyle]);

    useEffect(() => {
    }, [width]);

    useEffect(() => {
        if (pageRef.current !== null) {
            setPageWidth(pageRef.current.offsetWidth);
        }
    }, [pageRef.current, loading]);

    useEffect(() => {
        if (tableRef.current !== null) {
            setWidth(tableRef.current.offsetWidth);
        }
    }, [tableRef.current, loading]);

    useEffect(() => {
    }, [width, pageWidth]);

    useEffect(
        () => {
            if (patientId == null) {
                history.push('/');
                return;
            }

            void getOutcomeReports();
        },
        []
    );

    const RefreshOutcomes = () => {
        window.location.reload();
    };

    const GetAllPDF = () => {
        if (patientId !== null && patientId !== undefined) {
            let url = `/reports/${patientId}`;
            localStorage.clear();
            window.open(url, '_blank');
        }
    };

    const DisapproveReport = async (id, week, post = false) => {
        setDownload(true);

        await API
            .get(`/outcome_reports/${id}/disapprove`)
            .then(
                (response) => {
                    if (response.code === 'ERR_BAD_RESPONSE') {
                        throw new Error(response.message);
                    }

                    let successMessage = `Disapproved: ${patientName} ${post ? '6 Months Post' : `Week ${week}`} Outcome Report`;
                    localStorage.setItem('success', successMessage);

                    RefreshOutcomes();
                }
            )
            .catch(
                error => {
                    setDownload(false);
                    setErrors(error);
                }
            );
    };

    const DisapproveWarning = (id, week, post = false) => {
        let text = 'WARNING!\n\nDisapproving this report will disallow external organizations and non-reviewers from downloading it.\n\nTo edit and re-approve the report, see the Outcome Report Review table in your Home page.\n\nPress OK to disapprove.';
        if (window.confirm(text) === true) {
            void DisapproveReport(id, week, post);
        }
    };

    const DeleteWarning = (id, week, post = false) => {
        let text = 'WARNING!\n\nAre you sure you want to delete this Outcome Report?\n\nThere is no way to recover it once deleted!\n\nPress OK to delete or Cancel to return to editing.';
        if (window.confirm(text) === true) {
            void DeleteReport(id, week, post);
        }
    };

    const DeleteReport = async (id, week, post = false) => {
        setDownload(true);
        await API
            .get(`/outcome_reports/${id}/delete`)
            .then(
                (response) => {
                    if (response.code === 'ERR_BAD_RESPONSE') {
                        throw new Error(response.message);
                    }

                    let successMessage = `Deleted: ${patientName} ${post ? '6 Months Post' : `Week ${week}`} Outcome Report`;
                    localStorage.setItem('success', successMessage);

                    RefreshOutcomes();
                }
            )
            .catch(
                error => {
                    setDownload(false);
                    setErrors(error.message);
                }
            );
    };

    const toOutcomePage = () => {
        if (patientId) {
            history.push(`/patient/${patientId}/outcomes`);
        }
    };

    const RenderStatus = (report) => {
        if (report.approved) {
            return (
                <div className=" pl-1.5 pr-2 py-0.5 bg-success-50 rounded-2xl justify-center items-center gap-1.5 inline-flex">
                    <div className="w-2 h-2 relative">
                        <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-success-500 rounded-full"/>
                    </div>
                    <div className="text-center text-success-700 text-xs font-medium  leading-[18px]">Approved</div>
                </div>
            );
        }
        else {

            if (report.valid_approvers === undefined || report.valid_approvers === null || report.valid_approvers.length === 0) {
                return (
                    <div className=" mix-blend-multiply justify-start items-start inline-flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-error-50 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-error-500 rounded-full"/>
                            </div>
                            <div className="text-center text-error-700 text-xs font-medium  leading-[18px]">Missing Approver</div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className=" mix-blend-multiply justify-start items-start inline-flex">
                        <div className="pl-1.5 pr-2 py-0.5 bg-warning-50 rounded-2xl justify-center items-center gap-1.5 flex">
                            <div className="w-2 h-2 relative">
                                <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-warning-500 rounded-full"/>
                            </div>
                            <div className="text-center text-warning-700 text-xs font-medium  leading-[18px]">In Review</div>
                        </div>
                    </div>
                );
            }
        }
    };

    const renderTable = () => {
        let t = [];

        for (let i = 0; i < outcomeReports.length; i++) {
            let id = (outcomeReports[i].id);
            let week = outcomeReports[i].included_weeks;
            let prepared = outcomeReports[i].prepared_date;
            let permissions = outcomeReports[i].user_can_delete;
            let postProgramReport = outcomeReports[i].is_post_program_checkin && outcomeReports[i].is_post_program_checkin === true;

            t.push(
                <React.Fragment key={`outcome-report-${id}`}>
                    <tr id={`outcome-report-${id}`} className="whitespace-nowrap border-y-2 even:bg-white odd:bg-white border-gray-200">
                        <td className="whitespace-nowrap border-x-0 content-center text-center px-6 py-3 text-xs font-medium text-gray-500 leading-[18px]">
                            {
                                permissions === true &&
                                <span>
                                    <button
                                        className=" mr-1 px-3.5 py-2 bg-error-600 hover:bg-error-700 rounded-lg shadow border border-orange-800 justify-center items-center gap-2 inline-flex"
                                        onClick={() => {DeleteWarning(id, week, postProgramReport);}}
                                    >
                                        <img className="relative" src="/icons/trash-01.svg" alt="trash-01"/>
                                        <div className="text-white text-sm font-semibold leading-tight">Delete</div>
                                    </button>
                                    {
                                        outcomeReports[i].approved &&
                                        <button
                                            className="mr-1 px-3.5 py-2 bg-warning-600 hover:bg-warning-700 rounded-lg shadow border border-warning-600 justify-center items-center gap-2 inline-flex"
                                            onClick={() => {DisapproveWarning(id, week, postProgramReport);}}
                                        >
                                            <img className="relative" src="/icons/file-x-02.svg" alt="file-x-02"/>
                                            <div className="text-white text-sm font-semibold  leading-tight">Disapprove</div>
                                        </button>
                                    }
                                </span>
                            }
                            {
                                outcomeReports[i].approved &&
                                <button
                                    className=" px-3.5 py-2 bg-brand-600 hover:bg-brand-700 rounded-lg shadow border border-brand-600 justify-center items-center gap-2 inline-flex"
                                    onClick={() => {void getPDF(id, week, prepared);}}
                                >
                                    <img className="relative" src="/icons/file-download-02.svg" alt="file-download"/>
                                    <div className="text-white text-sm font-semibold leading-tight">Download</div>
                                </button>
                            }
                        </td>
                        <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]">{!postProgramReport ? `Week ${outcomeReports[i].included_weeks}` : '6 Months Post'}</td>
                        <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]">{RenderStatus(outcomeReports[i])}</td>
                        <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]">{moment(outcomeReports[i].prepared_date).format('MM/DD/YYYY')}</td>
                        <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]"></td>
                        <td className="whitespace-nowrap border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 leading-[18px]"></td>
                    </tr>
                </React.Fragment>
            );
        }

        return t;
    };

    const onPatientData = (patientData) => {
        if (patientData) {
            setPatientName(`${patientData.first_name ? patientData.first_name : ''} ${patientData.last_name ? patientData.last_name : ''}`);
        }
    };

    return (
        <PatientProfile
            errors={errors}
            forceOverFlow={true}
            successes={successes}
            onPatientData={onPatientData}
        >
            <div className="w-full h-full justify-center items-center inline-flex" ref={pageRef}>
                {loading === false && download === false ?
                    (
                        <React.Fragment>
                            <div className={'bg-white h-[90%] rounded-lg shadow-lg border-2 border-gray-200 flex-col items-end flex ' + (outcomeReports.length > 0 ? ((width <= pageWidth) ? 'w-[90%]' : 'w-[90%]') : 'w-[90%]')}>
                                <div className="w-full h-[10%] px-6 pt-5 pb-[19px] justify-start items-center gap-x-1 inline-flex">
                                    <div className="w-[75%] h-full justify-start items-center gap-x-4 inline-flex">
                                        <div className="text-gray-900 text-lg font-medium leading-7 whitespace-nowrap">Outcome Reports</div>
                                        {
                                            outcomeReports.length > 0 &&
                                            <div className="mix-blend-multiply justify-start items-start flex">
                                                <div className="px-2 py-0.5 bg-brand-50 rounded-2xl justify-center items-center flex">
                                                    <div className="text-center text-brand-800 text-xs font-medium leading-[18px]">{outcomeReports.length} Submitted</div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="gap-x-4 inline-flex items-center">
                                        <div className="grow shrink w-full basis-0 relative items-center flex justify-end">
                                            <button className="px-4 h-1/2 w-full  py-2.5 max-[1200px]:px-4 max-[1200px]:py-2 bg-white rounded-lg shadow border border-gray-300 hover:bg-gray-100 justify-center items-center gap-1.5 inline-flex" onClick={toOutcomePage}>
                                                <img className=" grow shrink h-5 relative" src="/icons/file-plus-03.svg"/>
                                                <div className="px-0.5 justify-center items-center flex grow shrink">
                                                    <div className="text-gray-700 whitespace-nowrap text-base max-[1200px]:text-xs font-semibold ">Create New Report</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="grow shrink w-full basis-0 h-full items-center flex justify-end">
                                            <button className="px-4 py-2.5 max-[1200px]:px-4 max-[1200px]:py-2 bg-white rounded-lg shadow border border-gray-300 hover:bg-gray-100 justify-center items-center gap-1.5 flex" onClick={GetAllPDF}>
                                                <img className="grow shrink h-5  relative" src="/icons/folder-download.svg"/>
                                                <div className="px-0.5 justify-center items-center flex grow shrink">
                                                    <div className="text-gray-700 whitespace-nowrap text-base max-[1200px]:text-xs font-semibold leading-normal">Download All</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                {
                                    outcomeReports && outcomeReports.length > 0 ?
                                        <div className="grid grid-col w-full whitespace-nowrap overflow-y-auto">
                                            <div className="whitespace-nowrap w-full">
                                                <div className="whitespace-nowrap w-full inline-block align-middle">
                                                    <div className=" whitespace-nowrap w-full">
                                                        <table className=" w-full" ref={tableRef}>
                                                            <thead className="whitespace-nowrap	w-full">
                                                            <tr className="shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-25 border-gray-200 whitespace-nowrap sticky top-0 z-[2]">
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Actions</th>
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Report Week</th>
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Status</th>
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px]">Created</th>
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px] text-opacity-0">Created</th>
                                                                <th scope="col" className="sticky top-0 z-[2] whitespace-nowrap	border-x-0 px-6 py-3 text-center text-xs font-medium text-gray-500 bg-gray-300 leading-[18px] text-opacity-0">Created</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody className="whitespace-nowrap	">
                                                            <React.Fragment key={'outcome-reports'}>
                                                                {
                                                                    renderTable()
                                                                }
                                                            </React.Fragment>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
                                            <p className="px-6 text-gray-900 text-lg font-medium ">No Reports Found</p>
                                        </div>
                                }
                            </div>
                        </React.Fragment>
                    )
                    :
                    (
                        renderLoader()
                    )
                }
            </div>
        </PatientProfile>
    );
};

export default withRouter(PatientReviewReportPage);
