import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";

import Page from '../components/Page';

const PageNotFound = ({}) => {
    const [submitButtonText, setSubmitButtonText] = useState("Sign in");
    const [errors, setErrors] = useState(null);
    const history = useHistory();

    /** Utilities **/

    const handleSubmit = async(e) => {
        e.preventDefault();
        setErrors(null);
        history.push('/login');
    };

    const handleSubmitButton = async(e) => {
        void handleSubmit(e);
    };

    const handleKeyPress = async(e) => {
        if (e.key === "Enter") {
            void handleSubmit(e);
        }
    };

    const handleLogoutHook = () => {
        // Prevent the base Page.handleLogout() from performing a logout.
        // This allows PageNotFound page to render its own UI to the user.
        return false;
    };

    /** Render **/

    return (
        <Page errors={errors} internal={false} handleLogoutHook={handleLogoutHook}>
            <div className="h-screen flex items-center justify-center pb-12 ">
                <div className=" w-96 h-96 flex-col justify-center content-center items-center gap-8 inline-flex">
                    <div className="self-stretch h-36 flex-col justify-start items-center gap-6 flex" id="title">
                        <div className="justify-start items-start inline-flex">
                            <img className="w-12 h-12" src="/icons/icon.png" alt='Karuna Logo'/>
                        </div>
                        <div className="self-stretch h-20 flex-col justify-start items-start gap-3 flex lg:h-40">
                            <div className="self-stretch text-center text-gray-900 text-3xl font-semibold leading-9">Karuna Portal</div>
                            <div className="self-stretch text-center text-gray-600 text-2xl font-normal leading-normal">
                                Page Not Found!
                                <br/>
                            </div>
                            <a href={document.URL} className="self-stretch text-center text-gray-600 text-base font-normal leading-normal" style={{ textDecoration: 'underline' }}>
                                {
                                    document.URL
                                }
                            </a>
                        </div>
                    </div>
                    <br/>
                    <form className="w-full max-w-sm" onSubmit ={handleSubmitButton} onKeyUp={(e) => handleKeyPress(e)}>
                        <div className="self-stretch h-11 flex-col justify-start items-start gap-4 flex">
                            <button type="submit" className="self-stretch px-4 py-2.5 bg-brand-600 hover:bg-brand-700 rounded-lg shadow justify-center items-center gap-1.5 inline-flex">
                                <div className="px-0.5 justify-center items-center flex">
                                    <div className="text-white text-base font-semibold leading-normal">{submitButtonText}</div>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Page>
    );
}

export default PageNotFound;
