import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router';
import moment from 'moment';

import API from 'services/API.jsx';
import CompletedCurrentWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedCurrentWeekView';
import CompletedFutureWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedFutureWeekView';
import CompletedPastWeekView from '../components/compositions/EditCoachingPlanCompositions/CompletedPastWeekView';
import CurrentWeekView from '../components/compositions/EditCoachingPlanCompositions/CurrentWeekView';
import DefaultZeroView from '../components/compositions/EditCoachingPlanCompositions/DefaultZeroView';
import FutureWeekView from '../components/compositions/EditCoachingPlanCompositions/FutureWeekView';
import NewPlan from '../components/compositions/EditCoachingPlanCompositions/NewPlan';
import PastWeekView from '../components/compositions/EditCoachingPlanCompositions/PastWeekView';
import PatientProfile from './PatientProfile';
import { delValueAtPath, getValueAtPath, setValueAtPath } from '../utility/ObjectUtils.tsx';
import { renderLoader } from 'utility/LoaderUtils';

export const EditCoachingPlan = () => {
    const [activities, setActivities] = useState(null);
    const [activityStats, setActivityStats] = useState(null);
    const [bestPriority, setBestPriority] = useState(null);
    const [coachingPlans, setCoachingPlans] = useState(null);
    const [coachingTracks, setCoachingTracks] = useState(null);
    const [constPlan, setConstPlan] = useState(null);
    const [constRoutine, setConstRoutine] = useState(null);
    const [currentWeek, setCurrentWeek] = useState(null);
    const [duplicatePlans, setDuplicatePlans] = useState(null);
    const [duplicateProtocol, setDuplicateProtocol] = useState(null);
    const [editPlanSettings, setEditPlanSettings] = useState(false);
    const [errors, setErrors] = useState(null);
    const [havePlan, setPlan] = useState(false);
    const [init, setInit] = useState(false);
    const [instanceID, setInstanceId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState('Loading Coaching Plan');
    const [patientName, setPatientName] = useState(null);
    const [personalRoutine, setPersonalRoutine] = useState(null);
    const [personalRoutineEnabled, setPersonalRoutineEnabled] = useState(false);
    const [planName, setPlanName] = useState('_');
    const [revertedPlans, setRevertedPlans] = useState(null);
    const [revertedRoutine, setRevertedRoutine] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [settings, setSettings] = useState(null);
    const [startDate, _setStartDate] = useState(new Date());
    const [stateView, setStateView] = useState(null);
    const [successes, setSuccesses] = useState(null);
    const [templatePlan, setTemplatePlan] = useState(null);
    const [timeZone, setTimeZone] = useState(null);
    const [trackInstance, setTrackInstance] = useState(null);
    const [trackPlan, setTrackPlans] = useState(null);
    const [weeks, setWeeks] = useState(null);
    const patientId = (useParams() as {id: string}).id;
    
    /////////////////////////////////////
    //  ASYNC COACHING PLAN FUNCTIONS  //
    /////////////////////////////////////
    
    const redoState = () => {
        setStateView(renderState());
        void getCurrentPlan(selectedWeek);
    };
    
    const parseTrackName = (name) => {
        if (name.includes('CRPS')) {
            if (name.includes('Lower')) {
                return 'CRPS - Lower Back';
            }
            else {
                return 'CRPS - Upper Extremity';
            }
        }
        else {
            return name.replace(/_|-|\\. /g, ' ');
        }
    };
    
    const savePlan = async () => {
        setLoadingMessage('Saving Coaching Plan');
        setIsLoading(true);
        clearNotifications();
        
        let plansToSave = coachingPlans;
        setCoachingPlans(null);
        
        let newId = null;
        for (let i = 0; i < plansToSave.length; i++) {
            if (i >= currentWeek) {
                let cleanedAssignments = cleanAssignments(plansToSave[i].assignments);
                if (duplicatePlans[i].assignments !== JSON.stringify(cleanedAssignments)) {
                    let data = {
                        patient: patientId,
                        time_zone: plansToSave[i].time_zone,
                        start_date: plansToSave[i].start_date.format('YYYY-MM-DD'),
                        track_name: plansToSave[i].track_name,
                        plan_title: plansToSave[i].plan_title,
                        plan_week: plansToSave[i].plan_week,
                        assignments: cleanedAssignments,
                        state: plansToSave[i].state
                    };
                    if (currentWeek === i) {
                        await API
                            .post(
                                `/coaching_plans/`,
                                JSON.stringify(data)
                            )
                            .then(
                                response => {
                                    newId = response.data.id;
                                }
                            )
                            .catch(
                                error => {
                                    setErrors(error);
                                }
                            );
                    }
                    else {
                        await API
                            .put(
                                `/coaching_plans/${plansToSave[i].id}/`,
                                JSON.stringify(data)
                            )
                            .then(
                                _response => {
                                }
                            )
                            .catch(
                                error => {
                                    setErrors(error);
                                }
                            );
                    }
                }
            }
        }
        
        let cleanedProtocolAssignments = cleanAssignments(personalRoutine.assignments);
        if (personalRoutine.enabled !== personalRoutineEnabled || duplicateProtocol !== JSON.stringify(cleanedProtocolAssignments)) {
            await API
                .put(
                    `/personal_routines/${personalRoutine.id}/`,
                    {
                        patient: patientId,
                        assignments: cleanedProtocolAssignments,
                        enabled: personalRoutine.enabled
                    }
                )
                .then(
                    (_response) => {
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                    }
                );
        }
        
        if (newId !== null) {
            await API
                .post(`coaching_plans/${newId}/replace/`).then(
                    (_response) => {
                        setSuccesses('Saved Coaching Plan.');
                        redoState();
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                        setIsLoading(false);
                    }
                );
        }
        else {
            redoState();
        }
    };
    
    const updatePlanSettings = async () => {
        let trackName = parseTrackName(templatePlan);
        
        setIsLoading(true);
        setLoadingMessage(`Changing Coaching Plan to ${trackName}`);
        clearNotifications();
        
        let temp = coachingPlans;
        setCoachingPlans(null);
        
        if (temp[0].track_name !== 'Default Week Zero') {
            for (let i = 0; i < temp.length; i++) {
                let data = {
                    patient: patientId,
                    time_zone: timeZone,
                    start_date: temp[i].start_date.format('YYYY-MM-DD'),
                    track_name: trackName,
                    plan_title: (i <= 12 ? trackPlan[templatePlan].plans[i].title : ''),
                    plan_week: temp[i].plan_week,
                    assignments: cleanAssignments(i <= 12 ? (FormatPlanAssignments(trackPlan[templatePlan].plans[i].assignments)) : (FormatPlanAssignments(trackPlan[templatePlan].plans[12].assignments))),
                    state: temp[i].state
                };
                
                if (currentWeek === i) {
                    await API
                        .post(
                            `/coaching_plans/`,
                            JSON.stringify(data)
                        )
                        .then(
                            response => {
                                let newId = response.data.id;
                                API
                                    .post(`/coaching_plans/${newId}/replace/`)
                                    .then(
                                        _response => {
                                        }
                                    )
                                    .catch(
                                        error => {
                                            setErrors(error);
                                        }
                                    );
                            }
                        )
                        .catch(
                            error => {
                                setErrors(error);
                            }
                        );
                }
                else if (i > currentWeek) {
                    await API
                        .put(
                            `coaching_plans/${temp[i].id}/`,
                            JSON.stringify(data)
                        )
                        .then(
                            (_response) => {
                            }
                        )
                        .catch(
                            error => {
                                setErrors(error);
                            }
                        );
                }
            }
        }
        else {
            for (let i = 0; i < (temp.length > 13 ? temp.length : 13); i++) {
                if (currentWeek === i) {
                    let data = {
                        patient: patientId,
                        time_zone: timeZone,
                        start_date: temp[i].start_date.format('YYYY-MM-DD'),
                        track_name: trackName,
                        plan_title: trackPlan[templatePlan].plans[0].title,
                        plan_week: temp[i].plan_week,
                        assignments: cleanAssignments(temp[i].assignments),
                        state: temp[i].state
                    };
                    
                    await API
                        .post(
                            `/coaching_plans/`,
                            JSON.stringify(data)
                        )
                        .then(
                            response => {
                                let newId = response.data.id;
                                API
                                    .post(`/coaching_plans/${newId}/replace/`)
                                    .then(
                                        _response => {
                                        }
                                    )
                                    .catch(
                                        error => {
                                            setErrors(error);
                                        }
                                    );
                            }
                        )
                        .catch(
                            error => {
                                setErrors(error);
                            }
                        );
                }
                else if (i > currentWeek) {
                    let data = {
                        patient: patientId,
                        time_zone: timeZone,
                        start_date: moment(temp[0].start_date).add(7 * i, 'days').format('YYYY-MM-DD'),
                        track_name: trackName,
                        plan_title: trackPlan[templatePlan].plans[0].title,
                        plan_week: i,
                        assignments: cleanAssignments(temp[i].assignments),
                        state: 'PENDING'
                    };
                    await API
                        .post(
                            `/coaching_plans/`,
                            JSON.stringify(data)
                        )
                        .then(
                            _response => {
                            }
                        )
                        .catch(
                            error => {
                                setErrors(error);
                            }
                        );
                }
            }
        }
        
        if (personalRoutine !== null) {
            let priorityprot = FormatPlanAssignments(trackPlan[templatePlan].default_personal_routine.assignments);
            let data = { patient: patientId, assignments: cleanAssignments(priorityprot), enabled: personalRoutine.enabled };
            
            await API
                .put(
                    `/personal_routines/${personalRoutine.id}/`,
                    JSON.stringify(data)
                )
                .then(
                    _response => {
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                    }
                );
        }
        else {
            let priorityprot = FormatPlanAssignments(trackPlan[templatePlan].default_personal_routine.assignments);
            let data = { patient: patientId, assignments: cleanAssignments(priorityprot) };
            
            await API
                .put(
                    `/personal_routines/`,
                    JSON.stringify(data)
                )
                .then(
                    _response => {
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                    }
                );
        }
        
        if (instanceID !== null) {
            let trackName = parseTrackName(templatePlan);
            await API
                .put(
                    `/coaching_plan_track_instances/${instanceID}/`,
                    JSON.stringify({ track_name: trackName })
                )
                .then(
                    _response => {
                        localStorage.setItem('success', `Changed Coaching Plan to ${trackName}.`);
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                    }
                );
        }
        
        RefreshPage();
    };
    
    const FormatPlanAssignments = (arr) => {
        let result = [];
        arr.forEach(element => {
            if (element !== null && element.hasOwnProperty('Id')) {
                result.push({ 'Id': element.Id });
            }
            else {
                result.push({ 'Id': element });
            }
        });
        
        return result;
    };
    
    const createNewPlan = async () => {
        
        let trackName = parseTrackName(templatePlan);
        setPlanName(trackName);
        
        setIsLoading(true);
        setLoadingMessage(`Creating new ${trackName} Coaching Plan`);
        clearNotifications();
        
        let plans = trackPlan[templatePlan].plans;
        let personalRoutineAssignments = cleanAssignments(FormatPlanAssignments(trackPlan[templatePlan].default_personal_routine.assignments));
        
        let week0Id = null;
        for (let i = 0; i < plans.length; i++) {
            let data = {
                patient: patientId, time_zone: timeZone, start_date: moment(startDate).add(7 *
                    i, 'days').format('YYYY-MM-DD'), track_name: trackPlan[templatePlan].track_name, plan_title: trackPlan[templatePlan].plans[i].title, plan_week: i, assignments: cleanAssignments(FormatPlanAssignments(plans[i].assignments)), state: 'PENDING'
            };
            await API
                .post(
                    `/coaching_plans/`,
                    JSON.stringify(data)
                )
                .then(
                    (
                        response) => {
                        if (i === 0) {
                            week0Id = response.data.id;
                        }
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                    }
                );
        }
        
        if (week0Id !== null) {
            await API
                .post(
                    `coaching_plans/${week0Id}/activate/`
                )
                .then(
                    _response => {
                    }
                )
                .catch(
                    error => {
                        setErrors([
                            `Error starting week ${week0Id}`,
                            error
                        ]);
                    }
                );
        }
        
        await API
            .post(
                `/personal_routines/`,
                JSON.stringify({ patient: patientId, assignments: personalRoutineAssignments })
            )
            .then(
                _response => {
                }
            )
            .catch(
                error => {
                    setErrors(error);
                }
            );
        
        RefreshPage();
    };
    
    const EndPlan = async () => {
        if (haveAssignmentsChanged()) {
            let text = '\nWARNING!\n\nThere are unsaved changes to the Coaching Plan!\n\nEnding the plan will result in the loss of these changes.\n\nAre you sure you want to end the plan?\n';
            if (!window.confirm(text)) {
                return;
            }
        }
        
        setIsLoading(true);
        setLoadingMessage(`Ending Coaching Plan`);
        clearNotifications();
        
        let week = currentWeek + 1;
        if (week >= coachingPlans.length) {
            await API
                .post(
                    `coaching_plans/${coachingPlans[coachingPlans.length - 1].id}/complete/`
                )
                .then(
                    _response => {
                    }
                )
                .catch(
                    error => {
                        setErrors([
                            `Errors ending Coaching Plan at week ${week}`,
                            error
                        ]);
                    }
                );
        }
        
        await API
            .post(
                `coaching_plan_track_instances/${trackInstance.id}/complete/`
            )
            .then(
                _response => {
                }
            )
            .catch(
                error => {
                    setErrors([
                        `Errors ending Coaching Plan`,
                        error
                    ]);
                }
            );
        
        RefreshPage();
    };
    
    const StartWeek = async () => {
        if (hasPlanChanged()) {
            let text = '\nWARNING!\n\nThere are unsaved changes to the Coaching Plan!\n\nEnding the plan will result in the loss of these changes.\n\nAre you sure you want to complete this week?\n';
            if (!window.confirm(text)) {
                return;
            }
        }
        
        let newWeek = currentWeek + 1;
        if (newWeek >= coachingPlans.length) {
            setErrors(`Unable to start Week ${newWeek}. The Coaching Plan only has ${coachingPlans.length} week${coachingPlans.length === 1 ? '' : 's'}.`);
        }
        
        setIsLoading(true);
        setLoadingMessage(`Starting Coaching Plan Week ${newWeek}`);
        clearNotifications();
        
        await API
            .post(`coaching_plans/${coachingPlans[currentWeek].id}/complete/`)
            .then(
                (_response) => {
                    API
                        .post(`coaching_plans/${coachingPlans[newWeek].id}/activate/`)
                        .then(
                            (_response) => {
                                localStorage.setItem('success', 'Week ' + newWeek + ' started');
                                setCurrentWeek(newWeek);
                                RefreshPage();
                            }
                        );
                }
            )
            .catch(
                error => {
                    setErrors([`Error Starting Coaching Plan Week ${newWeek.toString()}`, error]);
                    setIsLoading(false);
                }
            );
    };
    
    // ---=== KarunaCLOUD ===--- //
    
    const getProtocols = async (): Promise<any> => {
        const response = await API.get(`/protocols`);
        let protocols = response.data.results.filter(protocol => (protocol.activities.length === 1 && !protocol.id.includes('Pro') && (!actFilter.includes(protocol.id))));
        setActivities(protocols);
        return protocols;
    };
    
    const getActivityStats = async (): Promise<any> => {
        const response = await API.get(`/activity_stats/?patient_id=${patientId}`);
        
        let activityStats = response.data.activity_stats;
        setSettings(activityStats);
        
        let actStats = {};
        for (const [key, value] of Object.entries(activityStats) as [string, any]) {
            actStats[key] = {};
            actStats[key]['name'] = value.name;
            actStats[key]['type'] = value.type;
            actStats[key]['Settings'] = value.Settings;
            actStats[key]['assigned'] = value.assigned;
            actStats[key]['completions'] = value.completions;
            actStats[key]['hasOptions'] = value.Settings && Object.entries(value.Settings).length > 2;
            if (value.weekly_stats !== undefined) {
                actStats[key]['weekly_stats'] = value.weekly_stats;
            }
            if (actStats[key]['hasOptions'] === true) {
                let names = [];
                let descriptions = [];
                Object.entries(value.Settings).forEach(
                    ([name, value]: [string, any]) => {
                        if (name !== 'Duration' && name !== 'DurationIncrement') {
                            descriptions.push(value.Description);
                            names.push(name);
                        }
                    }
                );
                actStats[key]['OptionNames'] = names;
                actStats[key]['OptionDescriptions'] = descriptions;
            }
        }
        setActivityStats(actStats);
        
        return actStats;
    };
    
    const getCoachingPlanTrackData = async () => {
        await API
            .get(`/coaching_plan_tracks`)
            .then(
                response => {
                    let tracks = {};
                    let plans = [];
                    
                    response.data.results.forEach((element, i) => {
                        tracks[element.id] = element;
                        plans.push({ id: i, value: element.id, name: element.track_name });
                    });
                    setCoachingTracks(plans);
                    setTrackPlans(tracks);
                }
            )
            .catch(
                error => {
                    setCoachingTracks([]);
                    setTrackPlans({});
                    throw error;
                }
            );
    };
    
    const getCoachingPlanTrackInstances = async (): Promise<any> => {
        const response = await API.get(`/coaching_plan_track_instances/?patient=${patientId}`);
        
        const sortedTracks = response.data.results.sort((a, b) =>
            Number.parseInt(a.id) > Number.parseInt(b.id) ? -1 : 1
        );
        
        let currentTrack = sortedTracks[0];
        setTrackInstance(currentTrack);
        setInstanceId(currentTrack ? currentTrack.id : null);
        
        return currentTrack;
    };
    
    const getCoachingPlans = async (currentTrack, week, acts, aStats) => {
        await API
            .get(`/coaching_plans/?patient=${patientId}&track_instance=${currentTrack.id}`)
            .then(
                response => {
                    let plans = [];
                    let weeks = [];
                    
                    let plansById = response.data.results.sort((a, b) => (Number.parseInt(a.id) > Number.parseInt(b.id)) ? 1 : -1);
                    plansById.forEach(
                        element => {
                            if (!weeks.includes(element.plan_week)) {
                                if (element.state !== 'Replaced') {
                                    let ass = element.assignments;
                                    plans.push({
                                            id: element.id,
                                            time_zone: element.time_zone,
                                            start_date: moment(element.start_date),
                                            track_name: element.track_name,
                                            plan_title: element.plan_title,
                                            plan_week: element.plan_week,
                                            assignments: convertOldAssignmentDurations(ass),
                                            state: element.state
                                        }
                                    );
                                    weeks.push(element.plan_week);
                                }
                            }
                            else {
                                if (plans[parseInt(element.plan_week)].state !== 'Active') {
                                    plans[parseInt(element.plan_week)] = {
                                        id: element.id,
                                        time_zone: element.time_zone,
                                        start_date: moment(element.start_date),
                                        track_name: element.track_name,
                                        plan_title: element.plan_title,
                                        plan_week: element.plan_week,
                                        assignments: element.assignments,
                                        state: element.state
                                    };
                                }
                            }
                        }
                    );
                    
                    if (plans.length > 0) {
                        let plansByWeek = plans.sort((a, b) => (Number.parseInt(a.plan_week) > Number.parseInt(b.plan_week)) ? 1 : -1);
                        let formattedPlans = formatPlans(plansByWeek.map((_arrayElement) => Object.assign({}, _arrayElement)), acts);
                        
                        const deepCopyDuplicatePlans = JSON.parse(JSON.stringify(formattedPlans));
                        deepCopyDuplicatePlans.forEach(
                            (plan, _index) => {
                                plan['assignments'] = JSON.stringify(cleanAssignments(plan['assignments'], aStats));
                            }
                        );
                        
                        setCoachingPlans(formattedPlans);
                        setConstPlan(JSON.parse(JSON.stringify(formattedPlans)));
                        setRevertedPlans(JSON.stringify(formattedPlans));
                        setDuplicatePlans(deepCopyDuplicatePlans);
                        
                        if (plansByWeek[0].track_name !== 'Default Week Zero') {
                            setPlan(true);
                        }
                        else {
                            setEditPlanSettings(true);
                        }
                        
                        setWeek(plansByWeek, week);
                        setWeekOptions(plansByWeek);
                    }
                }
            )
            .catch(
                error => {
                    setErrors(error);
                    setCurrentWeek(-1);
                    setSelectedWeek(-1);
                    setCoachingPlans([]);
                    throw error;
                }
            );
    };
    
    const getPersonalRoutine = async (currentTrack: any, aStats = activityStats) => {
        await API
            .get(`/personal_routines/?patient=${patientId}`)
            .then(
                response => {
                    if (response.data.results.length > 0) {
                        response.data.results = response.data.results.sort((a, b) => (Number.parseInt(a.id) > Number.parseInt(b.id)) ? 1 : -1);
                        
                        let index = response.data.results.length - 1;
                        let priorityProt = {
                            id: response.data.results[index].id, patient: response.data.results[index].patient, assignments: convertOldAssignmentDurations(response.data.results[index].assignments), state: response.data.results[index].state, enabled: response.data.results[index].enabled
                        };
                        setPersonalRoutine(priorityProt);
                        setConstRoutine(JSON.parse(JSON.stringify((priorityProt))));
                        setRevertedRoutine(JSON.stringify(response.data.results[index].assignments));
                        setPersonalRoutineEnabled(priorityProt.enabled);
                        
                        let prErrors = [];
                        let duplicateError = validatePersonalRoutineAssignmentsUnique(priorityProt.assignments);
                        if (duplicateError !== '') {
                            prErrors.push(`Error loading Coaching Plan:\n${duplicateError}Contact a Karuna Admin for support.`);
                        }
                        if (priorityProt.assignments.length !== 7 && currentTrack.track_name !== 'Default Week Zero') {
                            prErrors.push(`Error loading Coaching Plan:\nThe routine has an irregular number (${priorityProt.assignments.length}) of activities assigned to it.\nContact a Karuna Admin for support.`);
                        }
                        if (prErrors.length > 0) {
                            setErrors(prErrors);
                        }
                        
                        let cleanedProtocolAssignments = JSON.stringify(cleanAssignments(response.data.results[index].assignments, aStats));
                        setDuplicateProtocol(cleanedProtocolAssignments);
                    }
                }
            );
    };
    
    const getPersonalRoutineProgress = async (currentTrack: any) => {
        await API
            .get(`/personal_routine_progress/?patient=${patientId}`)
            .then(
                response => {
                    if (response.data.results.length > 0) {
                        let ratingDict = {};
                        response.data.results.forEach(
                            element => {
                                if (element.track_instance === currentTrack.id && element.is_complete) {
                                    if (element.plan_week in ratingDict) {
                                        if (element.rating !== undefined && element.rating.score !== undefined) {
                                            let tempCount = ratingDict[element.plan_week].count;
                                            if (element.rating.score > ratingDict[element.plan_week].rating.score) {
                                                ratingDict[element.plan_week] = element;
                                            }
                                            element.count = tempCount + 1;
                                        }
                                        else {
                                            if (ratingDict[element.plan_week].rating === undefined) {
                                                if (ratingDict[element.plan_week].scores.length <= element.scores.length) {
                                                    element.count = 0;
                                                    element.rating = {};
                                                    element.rating.score = 0;
                                                    ratingDict[element.plan_week] = element;
                                                }
                                            }
                                            ratingDict[element.plan_week].count++;
                                        }
                                    }
                                    else {
                                        if (element.rating !== undefined && element.rating.score !== undefined) {
                                            element.count = 1;
                                            ratingDict[element.plan_week] = element;
                                        }
                                        else {
                                            element.count = 0;
                                            element.rating = {};
                                            element.rating.score = 0;
                                            ratingDict[element.plan_week] = element;
                                        }
                                    }
                                }
                            }
                        );
                        
                        if (bestPriority === null || Object.keys(bestPriority).length === 0 || Object.keys(bestPriority).length < Object.keys(ratingDict).length) {
                            setBestPriority(ratingDict);
                        }
                    }
                }
            );
    };
    
    const getCurrentPlan = async (week, acts = activities, aStats = activityStats) => {
        
        setIsLoading(true);
        setLoadingMessage('Loading Coaching Plan');
        
        await getCoachingPlanTrackInstances()
            .then(
                (currentTrack) => {
                    Promise
                        .all([
                            getCoachingPlans(currentTrack, week, acts, aStats),
                            getPersonalRoutine(currentTrack, aStats),
                            getPersonalRoutineProgress(currentTrack)
                        ])
                        .catch(
                            (error) => {
                                setErrors(error);
                            }
                        )
                        .finally(
                            () => {
                                setIsLoading(false);
                            }
                        );
                }
            );
    };
    
    const loadEditor = async () => {
        
        setIsLoading(true);
        setLoadingMessage('Loading Coaching Plan');
        
        Promise
            .all([
                getProtocols(),
                getActivityStats(),
                getCoachingPlanTrackData()
            ])
            .then(
                ([acts, aStats, _voidCoachingPlanTrackData]) => {
                    let week: string | number = localStorage.getItem('week');
                    
                    if (week) {
                        localStorage.removeItem('week');
                        week = parseInt(week);
                    }
                    
                    getCurrentPlan(week, acts, aStats);
                }
            )
            .catch(
                (error) => {
                    setErrors(error);
                    setIsLoading(false);
                }
            );
    };
    
    const HandleSubmit = (event) => {
        event.preventDefault();
        if (!havePlan) {
            if (startDate !== null && templatePlan !== null && timeZone !== null) {
                InitializeFullPlan();
            }
            else {
                setErrors('Missing value to create Coaching Plan!');
            }
        }
        else if (editPlanSettings) {
            if (templatePlan !== null && timeZone !== null) {
                InitializeFullPlan();
            }
            else {
                setErrors('Missing value to update Coaching Plan settings!');
            }
        }
        else {
            let errorMessage = validatePlan();
            if (errorMessage !== null && errorMessage !== '') {
                setErrors(errorMessage.trim());
            }
            else {
                if (hasPlanChanged()) {
                    void savePlan();
                }
            }
        }
    };
    
    const getStateColor = (state) => {
        switch (state) {
            case 'Active':
                return 0;
            case 'Pending':
                return 1;
            case 'Completed':
                return 2;
            case 'Interrupted':
                return 3;
            case 'Cancelled':
                return 3;
            default:
                return 0;
        }
    };
    
    const setWeekOptions = (plans) => {
        let temp = [];
        plans.forEach((e, i) => {
            temp.push(
                {
                    id: i,
                    name: 'Week ' + i,
                    value: i,
                    badge: { value: (e.state === 'Active' ? 'Current' : e.state), color: getStateColor(e.state) }
                }
            );
            
        });
        setWeeks(temp);
    };
    
    const setWeek = (plans, theWeek = null) => {
        API
            .get(`/patients/${patientId}`)
            .then(
                response => {
                    if (response.data.coaching_plan !== undefined) {
                        let week = response.data.coaching_plan.plan_week;
                        let selectWeek = week;
                        if (theWeek !== null) {
                            selectWeek = theWeek;
                        }
                        if (plans[week].track_name !== 'Default Week Zero') {
                            setCurrentWeek(week);
                            setSelectedWeek(selectWeek);
                            setTimeZone(plans[selectWeek].time_zone);
                            setTemplatePlan(plans[selectWeek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                            setPlanName(plans[selectWeek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                        }
                        else {
                            setCurrentWeek(week);
                            setSelectedWeek(selectWeek);
                            setTimeZone(timeZones[0].value);
                            setTemplatePlan('Upper_Extremity');
                            setPlanName(plans[selectWeek].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                        }
                    }
                    else {
                        if (plans[0].state !== 'Completed' && plans[0].state !== 'Cancelled' && plans[0].state !== 'Interrupted') {
                            setCurrentWeek(-1);
                            setSelectedWeek(-1);
                            setTemplatePlan(plans[0].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                            setPlanName(plans[0].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                            setTimeZone(plans[0].time_zone);
                        }
                        else {
                            let w = plans.length;
                            for (let i = 0; i < plans.length; i++) {
                                if (plans[i].state !== 'Completed') {
                                    w = i;
                                    break;
                                }
                            }
                            
                            setCurrentWeek(w);
                            setSelectedWeek(w);
                            setTemplatePlan(plans[plans.length - 1].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                            setPlanName(plans[plans.length - 1].track_name.replaceAll(' - ', '_').replaceAll(' ', '_'));
                            setTimeZone(plans[plans.length - 1].time_zone);
                        }
                    }
                }
            )
            .catch(
                error => {
                    setErrors(error);
                }
            );
    };
    
    const setUIFields = () => {
        setInit(false);
        if (coachingPlans.length > 0) {
            if (coachingPlans[0].track_name !== 'Default Week Zero') {
                setStateView(renderState());
            }
            else {
                if (coachingTracks !== null) {
                    setTemplatePlan(coachingTracks[0].value);
                    setTimeZone(timeZones[0].value);
                }
                else {
                    setTemplatePlan('Upper_Extremity');
                    setTimeZone(timeZones[0].value);
                }
            }
        }
        else {
            setTemplatePlan(coachingTracks[0].value);
            setTimeZone(timeZones[0].value);
        }
    };
    
    const formatWeek = (week, proto, act) => {
        let newWeek = [];
        
        if (proto && act) {
            week.forEach(
                element => {
                    if (element && !element.Settings && activityStats) {
                        setValueAtPath(element, 'Settings.Duration', getValueAtPath(activityStats[element.Id], 'Settings.Duration.Value', 120));
                    }
                    
                    newWeek.push(element);
                }
            );
            
            while (newWeek.length < 4) {
                newWeek.push(null);
            }
        }
        
        return newWeek;
    };
    
    const formatPlans = (plans, activities) => {
        let formattedPlans = [];
        // @todo activities cannot be null here or the formatWeek calls will throw errors.
        let actList = activities && activities.map(proto => (proto.id));
        plans.forEach(
            (plan) => {
                plan.assignments = formatWeek(plan.assignments, [], actList);
                formattedPlans.push(plan);
            }
        );
        return formattedPlans;
    };
    
    const ForceSetupPage = () => {
        setCoachingPlans([]);
        setPlan(false);
        setTemplatePlan(coachingTracks[0].value);
        setTimeZone(timeZones[0].value);
        setCurrentWeek(-1);
        setSelectedWeek(-1);
    };
    
    const RefreshPage = () => {
        window.location.reload();
    };
    
    const InitializeFullPlan = () => {
        if (!editPlanSettings) {
            setCoachingPlans(null);
        }
        else {
            let plans = trackPlan[templatePlan].plans;
            let temp = coachingPlans;
            
            if (temp[0].track_name !== 'Default Week Zero') {
                if (coachingPlans[currentWeek !== -1 ? currentWeek : 0].track_name !== trackPlan[templatePlan].track_name) {
                    for (let i = 0; i < plans.length; i++) {
                        temp[i].assignments = plans[i].assignments;
                    }
                    setCoachingPlans(temp);
                    setPlan(true);
                    setInit(true);
                    return;
                }
                else {
                    if (timeZone !== coachingPlans[currentWeek !== -1 ? currentWeek : 0].time_zone) {
                        setPlan(true);
                        setInit(true);
                        return;
                    }
                    else {
                        setInit(false);
                        return;
                    }
                }
            }
            else {
                let plans = trackPlan[templatePlan].plans;
                let temp = coachingPlans;
                
                for (let i = 1; i < (temp.length > 13 ? temp.length : 13); i++) {
                    let obj = Object.create(temp[0]);
                    temp.push(obj);
                    temp[i].assignments = plans[i].assignments;
                }
                
                setCoachingPlans(temp);
                setPlan(true);
                setInit(true);
                return;
            }
        }
        
        setPlan(true);
        setInit(true);
    };
    
    const GoToCurrentWeek = () => {
        ChangeWeek(currentWeek);
    };
    
    const UpdateBonusActivityValue = (value, i, j) => {
        if (value) {
            if (coachingPlans[i].assignments[j]) {
                if (coachingPlans[i].assignments[j].Id === value) {
                    return;
                }
            }
            
            coachingPlans[i].assignments[j] = {
                'Id': value,
                'Settings': {
                    'Duration': value
                }
            };
        }
        else {
            coachingPlans[i].assignments[j] = null;
        }
        
        setCoachingPlans(coachingPlans);
    };
    
    const UpdateBonusTimerValue = (value, id, i, j) => {
        if (value && !isNaN(value)) {
            let settings = coachingPlans[i].assignments[j].Settings;
            
            if (!settings) {
                settings = {
                    'Duration': value
                };
            }
            else {
                if (settings.Duration !== undefined) {
                    settings.Duration = value;
                }
                else {
                    settings['Duration'] = value;
                }
            }
            
            coachingPlans[i].assignments[j] = {
                'Id': id,
                'Settings': settings
            };
            
            setCoachingPlans(coachingPlans);
        }
    };
    
    const UpdatePriorityTimerValue = (value, id, i, j) => {
        if (value && !isNaN(value)) {
            personalRoutine.assignments[j] ? personalRoutine.assignments[j]['Settings']['Duration'] = value : personalRoutine.assignments[j] = { 'Id': id, 'Settings': { 'Duration': value } };
            setPersonalRoutine(personalRoutine);
        }
    };
    
    const UpdatePriorityValue = (value, i, j) => {
        if (value) {
            if (personalRoutine) {
                if (personalRoutine.assignments[j].Id === value) {
                    return;
                }
                personalRoutine.assignments[j] = { 'Id': value, 'Settings': { 'Duration': getValueAtPath(settings[value], 'Settings.Duration.Value', 120) } };
            }
            setPersonalRoutine(personalRoutine);
        }
    };
    
    const updateBonusAssignments = (name, value, i, j) => {
        if (coachingPlans[i].assignments[j].Settings !== undefined && coachingPlans[i].assignments[j].Settings[name] !== undefined) {
            coachingPlans[i].assignments[j].Settings[name] = value;
        }
        else {
            if (coachingPlans[i].assignments[j].Settings === undefined) {
                coachingPlans[i].assignments[j].Settings = {};
            }
            coachingPlans[i].assignments[j].Settings[name] = value;
        }
        setCoachingPlans(coachingPlans);
    };
    
    const updatePersonalRoutineAssignments = (name, value, i, j) => {
        if (personalRoutine.assignments[j] !== undefined && personalRoutine.assignments[j].Settings !== undefined && personalRoutine.assignments[j].Settings[name] !== undefined) {
            personalRoutine.assignments[j].Settings[name] = value;
        }
        else {
            if (personalRoutine.assignments[j].Settings === undefined) {
                personalRoutine.assignments[j].Settings = {};
            }
            personalRoutine.assignments[j].Settings[name] = value;
        }
        setPersonalRoutine(personalRoutine);
    };
    
    const getDuplicateActivityIds = (assignments, exclusions = null) => {
        const idCounts = new Map();
        const duplicateIds = new Set();
        
        assignments.forEach(
            (assignment) => {
                if (assignment && assignment.Id) {
                    if (!exclusions || !exclusions.includes(assignment.Id)) {
                        const count = idCounts.get(assignment.Id) || 0;
                        idCounts.set(assignment.Id, count + 1);
                        if (count + 1 > 1) {
                            duplicateIds.add(assignment.Id);
                        }
                    }
                }
            }
        );
        
        return duplicateIds;
    };
    
    const validatePersonalRoutineAssignmentsUnique = (assignments) => {
        let duplicates = getDuplicateActivityIds(assignments, ['Breathing']);
        return duplicates.size > 0 ? `Duplicate Personal Routine activities:\n[${Array.from(duplicates).join(', ')}]\n` : '';
    };
    
    const validateBonusAssignmentsUnique = (assignments, week) => {
        let duplicates = getDuplicateActivityIds(assignments);
        return duplicates.size > 0 ? `Duplicate Bonus activities in week ${week}\n[${Array.from(duplicates).join(', ')}]\n` : '';
    };
    
    const validateBonusAssigmentDurations = (arry, week) => {
        let ids = [];
        arry.forEach(
            element => {
                if (element && element.Duration !== undefined && isNaN(element.Duration)) {
                    ids.push(element.Id);
                }
            }
        );
        return ids.length > 0 ? `Duration not set or not a number for Bonus activities in week ${week}\n[${ids.join(', ')}]\n` : '';
    };
    
    const validatePlan = (errorMessage = null) => {
        errorMessage = errorMessage || '';
        errorMessage += validatePersonalRoutineAssignmentsUnique(personalRoutine.assignments);
        coachingPlans.forEach((plan, i) => {
            errorMessage += validateBonusAssignmentsUnique(plan.assignments, i);
            errorMessage += validateBonusAssigmentDurations(plan.assignments, i);
        });
        return errorMessage;
    };
    
    const haveSettingsChanged = () => {
        return planName !== templatePlan || timeZone !== coachingPlans[currentWeek !== -1 ? currentWeek : 0].time_zone;
    };
    
    const haveAssignmentsChanged = () => {
        if (coachingPlans === null || duplicatePlans === null) {return false;}
        if (personalRoutine === null || duplicateProtocol === null) {return false;}
        if (coachingPlans.length === 0 && duplicatePlans.length === 0) {return false;}
        if (personalRoutine.length === 0 && duplicateProtocol.length === 0) {return false;}
        
        for (let index = 0; index < coachingPlans.length; index++) {
            const plan = coachingPlans[index];
            const cleanedAssignments = JSON.stringify(cleanAssignments(plan.assignments));
            if (cleanedAssignments !== duplicatePlans[index].assignments) {
                return true;
            }
        }
        
        if (personalRoutine.enabled !== personalRoutineEnabled) {
            return true;
        }
        
        let cleanedProtocolAssignments = JSON.stringify(cleanAssignments(personalRoutine.assignments));
        if (cleanedProtocolAssignments !== duplicateProtocol) {
            return true;
        }
        
        return false;
    };
    
    const hasPlanChanged = () => {
        return haveSettingsChanged() || haveAssignmentsChanged();
    };
    
    const clearErrors = () => {
        setErrors(null);
    };
    
    const clearSuccesses = () => {
        setSuccesses(null);
    };
    
    const clearNotifications = () => {
        clearErrors();
        clearSuccesses();
    };
    
    let actFilter = [];
    
    const timeZones = [
        { id: 0, value: 'US/Pacific', name: 'Pacific (PST)' },
        { id: 1, value: 'US/Arizona', name: 'Arizona (AST)' },
        { id: 2, value: 'US/Mountain', name: 'Mountain (MST)' },
        { id: 3, value: 'US/Central', name: 'Central (CST)' },
        { id: 4, value: 'US/Eastern', name: 'Eastern (EST)' }
    ];
    
    const cleanAssignments = (assignments, aStats = activityStats) => {
        let cleanedAssignments = assignments.filter(assignment => assignment);
        cleanedAssignments.forEach(
            assignment => {
                if (assignment.Id) {
                    delValueAtPath(assignment, 'MinVersion');
                    
                    if (getValueAtPath(aStats[assignment.Id], 'Settings.DurationIncrement.Value') === -1) {
                        delValueAtPath(assignment, 'Settings.Duration');
                    }
                    
                    delValueAtPath(assignment, 'Settings.DurationIncrement');
                    
                    if ('Settings' in assignment && Object.keys(assignment.Settings).length === 0) {
                        delValueAtPath(assignment, 'Settings');
                    }
                    
                    // These are placeholder key deletions due to the data migrations
                    // on PROD not properly reformatting all the PersonalAssignment
                    // and CoachingPlan records. They can be removed after the
                    // migrations have been applied correctly.
                    if ('Duration' in assignment) {
                        delValueAtPath(assignment, 'Duration');
                    }
                    if ('DurationIncrement' in assignment) {
                        delValueAtPath(assignment, 'DurationIncrement');
                    }
                }
            }
        );
        return cleanedAssignments;
    };
    
    const convertOldAssignmentDurations = (assignments) => {
        let convertedAssignments = assignments.filter(assignment => assignment);
        convertedAssignments.forEach(
            assignment => {
                if (assignment.Id) {
                    if ('Duration' in assignment) {
                        setValueAtPath(assignment, 'Settings.Duration', assignment.Duration);
                        delValueAtPath(assignment, 'Duration');
                    }
                    if ('DurationIncrement' in assignment) {
                        delValueAtPath(assignment, 'DurationIncrement');
                    }
                }
            }
        );
        return convertedAssignments;
    };
    
    const TogglePersonalRoutine = () => {
        if (personalRoutine) {
            personalRoutine.enabled = !personalRoutine.enabled;
            setPersonalRoutine(personalRoutine);
            setUIFields();
        }
    };
    
    //////////////////////
    // window overloads //
    //////////////////////
    
    window.onbeforeunload = confirmExit;
    
    function confirmExit (event) {
        if (!isLoading && hasPlanChanged()) {
            const confirmationMessage = '\nWARNING!\n\nThere are unsaved changes to the Coaching Plan!\n\nAre you sure you want to leave this page?\n';
            event.preventDefault();
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        }
        
        return undefined;
    }
    
    const SubmitToUpdatePlanSettings = (template) => {
        if (template === undefined) {
            return;
        }
        
        if (template === templatePlan) {
            return;
        }
        
        clearNotifications();
        
        let text = 'WARNING!\n\nAre you sure you want to change the current Coaching Plan Track?\n\nAny custom changes made to activity assignments in the current or future weeks will be replaced.';
        if (!window.confirm(text)) {
            return;
        }
        
        setTemplatePlan(template);
        setEditPlanSettings(true);
        let plans = trackPlan[template].plans;
        let temp = coachingPlans;
        if (temp[0].track_name !== 'Default Week Zero') {
            if (coachingPlans[currentWeek !== -1 ? currentWeek : 0].track_name !== trackPlan[template].track_name) {
                for (let i = 0; i < plans.length; i++) {
                    temp[i].assignments = FormatPlanAssignments(plans[i].assignments);
                }
                setCoachingPlans(temp);
                setPlan(true);
                setInit(true);
                return;
            }
            else {
                if (timeZone !== coachingPlans[currentWeek !== -1 ? currentWeek : 0].time_zone) {
                    setPlan(true);
                    setInit(true);
                    return;
                }
                else {
                    setInit(false);
                    return;
                }
            }
        }
        else {
            let plans = trackPlan[template].plans;
            let temp = coachingPlans;
            for (let i = 1; i < (temp.length > 13 ? temp.length : 13); i++) {
                let obj = Object.create(temp[0]);
                temp.push(obj);
                temp[i].assignments = FormatPlanAssignments(plans[i].assignments);
                
            }
            setCoachingPlans(temp);
            setPlan(true);
            setInit(true);
        }
    };
    
    const ChangeWeek = (week) => {
        if (selectedWeek === null || selectedWeek === week) {
            return;
        }
        
        if (haveAssignmentsChanged()) {
            let text = '\nWARNING!\n\nThere are unsaved changes to the Coaching Plan!\n\nChanging the week will result in the loss of these changes.\n\nAre you sure you want to change the week?\n';
            if (window.confirm(text)) {
                personalRoutine.assignments = JSON.parse(revertedRoutine);
                coachingPlans[selectedWeek].assignments = JSON.parse(revertedPlans)[selectedWeek].assignments;
                setPersonalRoutine(personalRoutine);
                setCoachingPlans(coachingPlans);
                setSelectedWeek(week);
            }
            else {
                setStateView(renderState());
            }
        }
        else {
            setSelectedWeek(week);
        }
    };
    
    const AddWeeksToPlan = async (weeks) => {
        if (hasPlanChanged()) {
            setErrors('Unsaved changes to Coaching Plan! Save or revert any changes before adding additional weeks.');
            return;
        }
        
        clearNotifications();
        
        let text = `\nWARNING!\n\nAre you sure you want to add ${weeks} weeks to the Coaching Plan?\n\nThese extra weeks can only be removed by a Karuna Admin.`;
        
        if (window.confirm(text) === true) {
            setEditPlanSettings(true);
            
            setIsLoading(true);
            setLoadingMessage(`Adding ${weeks} Week${weeks === 1 ? '' : 's'} to the Coaching Plan`);
            
            await API
                .post(`/coaching_plan_track_instances/${instanceID}/add_weeks/?num_weeks=${weeks}`)
                .then(
                    (_response) => {
                        localStorage.setItem('week', selectedWeek);
                        localStorage.setItem('success', `Added ${weeks} Week${weeks === 1 ? '' : 's'} to the Coaching Plan`);
                        RefreshPage();
                    }
                )
                .catch(
                    error => {
                        setErrors(error);
                        setIsLoading(false);
                    }
                );
        }
    };
    
    // ---=== Render ===--- //
    
    useEffect(
        () => {
            void loadEditor();
        },
        []
    );
    
    useEffect(() => {
        if (init === true && editPlanSettings === false) {
            void createNewPlan();
        }
        else if (init === true && editPlanSettings === true) {
            void updatePlanSettings();
        }
        
    }, [init]);
    
    useEffect(
        () => {
            if (!isLoading) {
                setUIFields();
                setStateView(renderState());
            }
        },
        [
            isLoading,
            selectedWeek,
            settings
        ]
    );
    
    useEffect(() => {
    }, [stateView]);
    
    useEffect(
        () => {
        },
        [errors, successes]
    );
    
    const renderState = () => {
        if (isLoading || selectedWeek === null) {
            return;
        }
        
        if (coachingPlans && coachingPlans.length === 0 && activityStats && currentWeek === -1) {
            return (
                <NewPlan
                    coachingtracks={coachingTracks}
                    name={patientName}
                    setTemplatePlan={setTemplatePlan}
                    setTimeZone={setTimeZone}
                    startNewPlan={HandleSubmit}
                    timezones={timeZones}
                />
            );
        }
        else if (coachingPlans && coachingPlans.length !== 0 && activityStats) {
            if (coachingPlans[0].track_name === 'Default Week Zero') {
                return (
                    <DefaultZeroView
                        coachingtracks={coachingTracks}
                        name={patientName}
                        setEdit={setEditPlanSettings}
                        setTemplatePlan={setTemplatePlan}
                        setTimeZone={setTimeZone}
                        startNewPlan={HandleSubmit}
                        templatePlan={templatePlan}
                        timezones={timeZones}
                    />
                );
            }
            else {
                if (trackInstance.state === 'Active') {
                    if (currentWeek === selectedWeek) {
                        return (
                            <CurrentWeekView
                                ToggleRoutine={TogglePersonalRoutine}
                                activityStats={activityStats}
                                addWeeks={AddWeeksToPlan}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                completeWeek={StartWeek}
                                constPlan={constPlan}
                                constRoutine={constRoutine}
                                currentTemplate={templatePlan}
                                endPlan={EndPlan}
                                key={personalRoutine}
                                name={patientName}
                                personalRoutine={personalRoutine}
                                routineScoreData={bestPriority}
                                saveChanges={HandleSubmit}
                                selectedWeek={selectedWeek}
                                setActivityBonus={UpdateBonusActivityValue}
                                setActivityPersonal={UpdatePriorityValue}
                                setDurationBonus={UpdateBonusTimerValue}
                                setDurationPersonal={UpdatePriorityTimerValue}
                                setOptionBonus={updateBonusAssignments}
                                setOptionRoutine={updatePersonalRoutineAssignments}
                                setTemplate={SubmitToUpdatePlanSettings}
                                templates={coachingTracks}
                                weekOptions={weeks}
                            />
                        );
                    }
                    else if (currentWeek < selectedWeek) {
                        return (
                            <FutureWeekView
                                activityStats={activityStats}
                                addWeeks={AddWeeksToPlan}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                constPlan={constPlan}
                                goToCurrentWeek={GoToCurrentWeek}
                                name={patientName}
                                saveChanges={HandleSubmit}
                                selectedWeek={selectedWeek}
                                setActivity={UpdateBonusActivityValue}
                                setDuration={UpdateBonusTimerValue}
                                setOptionBonus={updateBonusAssignments}
                                setOptionRoutine={updatePersonalRoutineAssignments}
                                weekOptions={weeks}
                            />
                        );
                    }
                    else {
                        return (
                            <PastWeekView
                                activityStats={activityStats}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                constPlan={constPlan}
                                constRoutine={constRoutine}
                                goToCurrentWeek={GoToCurrentWeek}
                                name={patientName}
                                personalRoutine={personalRoutine}
                                routineScoreData={bestPriority}
                                selectedWeek={selectedWeek}
                                weekOptions={weeks}
                            />
                        );
                    }
                }
                else {
                    if (currentWeek === selectedWeek) {
                        return (
                            <CompletedCurrentWeekView
                                constPlan={constPlan}
                                StartNewPlan={ForceSetupPage}
                                activityStats={activityStats}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                constRoutine={constRoutine}
                                name={patientName}
                                personalRoutine={personalRoutine}
                                routineScoreData={bestPriority}
                                selectedWeek={selectedWeek}
                                weekOptions={weeks}
                            />
                        );
                    }
                    else if (currentWeek < selectedWeek) {
                        return (
                            <CompletedFutureWeekView
                                StartNewPlan={ForceSetupPage}
                                activityStats={activityStats}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                constPlan={constPlan}
                                constRoutine={constRoutine}
                                name={patientName}
                                selectedWeek={selectedWeek}
                                weekOptions={weeks}
                            />
                        );
                    }
                    else {
                        return (
                            <CompletedPastWeekView
                                StartNewPlan={ForceSetupPage}
                                activityStats={activityStats}
                                changeWeek={ChangeWeek}
                                coachingPlan={coachingPlans}
                                constPlan={constPlan}
                                constRoutine={constRoutine}
                                name={patientName}
                                personalRoutine={personalRoutine}
                                routineScoreData={bestPriority}
                                selectedWeek={selectedWeek}
                                weekOptions={weeks}
                            />
                        );
                    }
                }
            }
        }
    };
    
    const onPatientData = (patientData) => {
        if (patientData) {
            setPatientName(`${patientData.first_name ? patientData.first_name : ''} ${patientData.last_name ? patientData.last_name : ''}`);
        }
    };
    
    return (
        <PatientProfile
            errors={errors}
            forceOverFlow={true}
            successes={successes}
            // Callbacks
            onPatientData={onPatientData}
        >
        {
            isLoading ?
                (
                    renderLoader(loadingMessage)
                )
                :
                (
                    <React.Fragment>
                        <div className="w-full h-full">{stateView}</div>
                    </React.Fragment>
                )
        }
        </PatientProfile>
    );
};

export default withRouter(EditCoachingPlan);
