import React, { useState, Fragment, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

function classNames (...classes) {
    return classes.filter(Boolean).join(' ');
}

const AddWeekDropDown = ({ AddWeek }) => {
    
    return (
        <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="px-4 py-2 bg-white hover:bg-gray-300 rounded-lg shadow border border-gray-300 justify-center items-center gap-1.5 inline-flex   text-sm font-semibold text-gray-700">
        <img className="w-5 h-5 p-[1.67px]	justify-center items-center flex" src="/icons/plus.svg"/>
          Add Weeks
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true"/>
        </Menu.Button>
      </div>

      <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform ring/0 scale-95"
          enterTo="transform ring/10 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform wing/10 scale-100"
          leaveTo="transform ring/0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-1  h-56 score overflow-y-auto overflow-x-hidden w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/10 focus:outline-none">
          <div className="py-1">
          <form onSubmit={(event) => {
              event.preventDefault();
              AddWeek(1);
          }}>
              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    1 Week
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(2);
            }}>
                <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    2 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(3);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    3 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(4);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    4 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(5);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    5 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(6);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    6 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(7);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    7 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(8);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    8 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(9);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    9 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(10);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    10 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(11);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    11 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            <form onSubmit={(event) => {
                event.preventDefault();
                AddWeek(12);
            }}>              <Menu.Item>
                {({ active }) => (
                    <button
                        type="submit"
                        className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block w-full px-4 py-2 text-left text-sm'
                        )}
                    >
                    12 Weeks
                  </button>
                )}
              </Menu.Item>
            </form>
            
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
    );
};

export default AddWeekDropDown;