import React, { useEffect, useState } from 'react';

import API from 'services/API';
import { isLoggedIn } from 'services/AccountServices';

const StickyFooter = ({ internal }) => {
    const buildVersion = import.meta.env.VITE_BUILD_VERSION;
    const buildEnvironment = import.meta.env.VITE_BUILD_ENVIRONMENT;
    
    /** State **/
    
    const [backendVersion, setBackendVersion] = useState(null);
    
    // ---=== Render ===--- //
    
    useEffect(() => {
        if (isLoggedIn()) {
            API.get('/version/')
               .then(
                   response => {
                       if (response && response.data && response.data.version) {
                           setBackendVersion(response.data.version);
                       }
                   }
               )
               .catch(
                   error => {
                       console.log(error);
                   }
               );
        }
    }, []);
    
    return (
        <React.Fragment>
            <footer className={internal ? 'whitespace-nowrap overflow-hidden bg-brand-100 relative inset-x-0 p-2 justify-start items-center inline-flex w-full' : 'whitespace-nowrap overflow-hidden bg-brand-100 absolute inset-x-0 bottom-0 p-2 justify-center items-center inline-flex'}>
                <div className="px-2 text-gray-500 text-xs font-normal leading-none">© Karuna Labs 2025</div>
                <div className="px-2 grow basis-0 text-right text-gray-500 text-xs font-normal leading-none">
                    <span>{`${buildEnvironment && buildEnvironment !== 'Production' ? `${buildEnvironment}:` : ''}${buildVersion ? ` ${buildVersion}` : ''}`}</span>
                    <span className="ml-2">{backendVersion ? `API: ${backendVersion}` : ''}</span>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default StickyFooter;
